<div
        class="slide-addition-info"
        sxPadding="medium"
        sxCol
        sxGap="10px"
        sxAlign="stretch"
        *ngIf="template"
>
    <h1 sxTextAlign="center" sxTextSize="medium">Fill Details</h1>

    <div sxCol sxGap="10px">
        <div *ngFor="let field of share.otherFields">
            <div *ngIf="!field.sectionBreak">
                <super-input
                        *ngIf="!field.isForEachParticipant"
                        [label]="field.label ? field.label : field.name"
                        [required]="field.isMandatory"
                        [(value)]="field.value"
                        [type]="field.type"
                        [(errorNote)]="field.errorMessage"
                        [options]="field.options"
                        [validate]="true"
                        [placeholder]="field.placeholder ?? 'Enter ' + (field.label ? field.label : field.name)"
                ></super-input>
            </div>

            <div *ngIf="field.sectionBreak" sxPadding="25px 10px 5px" sxCol sxGap="5px">
                <div sxRow sxGap="10px" sxAlign="center">
                    <h1 sxTextSize="large" sxMargin="0">{{field.sectionBreak?.title}}</h1>
                    <div sxWidth="fill" sxHeight="1px" sxBackground="one"></div>
                </div>
                <p sxMargin="0" sxColor="two" sxTextSize="small"
                   *ngIf="field.sectionBreak?.description?.length > 0">{{field.sectionBreak?.description}}</p>
            </div>

        </div>

        <div *ngFor="let participant of share.participants">
            <share-participant-form [participant]="participant" [template]="template">
            </share-participant-form>
        </div>


        <ion-item id="terms-item" sxRadius="small">
            <ion-checkbox [(ngModel)]="termsChecked" mode="md">
                <p sxMargin="0" sxTextWrap="wrap">I hereby confirm that all the information provided above is accurate and
                    complete.</p>
            </ion-checkbox>
        </ion-item>

        <super-button [action]="submitClicked" [showError]="true" iconEnd="chevron-forward-outline">Submit
        </super-button>
        <div class="spacer"></div>
    </div>
</div>
