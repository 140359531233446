import {createFeatureSelector, createSelector} from '@ngrx/store';
import {accountAdapter, AccountWiseScannersState, recordAdapter, SCANNERS_FEATURE_KEY,} from './scanners.reducer';
import { AuthSelectors } from '../../authentication';

//Account
export const selectScannerAccountsState =
  createFeatureSelector<AccountWiseScannersState>(SCANNERS_FEATURE_KEY);

const accountSelectors = accountAdapter.getSelectors();

export const selectAllAccounts = createSelector(
  selectScannerAccountsState,
  (state: AccountWiseScannersState) => accountSelectors.selectAll(state)
);

export const selectScannersEntities = createSelector(
  selectScannerAccountsState,
  (state: AccountWiseScannersState) => accountSelectors.selectEntities(state)
);

export const selectActiveAccount = createSelector(
  selectScannersEntities,
  AuthSelectors.selectActiveAccountId,
  (entities, activeAccountId) => (activeAccountId ? entities[activeAccountId] : undefined)
);

//Scanners

const scannerSelectors = recordAdapter.getSelectors();

export const selectActiveAccountScanners = createSelector(
  selectActiveAccount,
  (selectedAccount) => {
    return (selectedAccount ? scannerSelectors.selectAll(selectedAccount.records) : undefined)
  }
);

export const selectIsRecordLoading = createSelector(
  selectActiveAccount,
  (selectedAccount) => {
    return (selectedAccount ? selectedAccount.isLoading : undefined)
  }
);
