import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, exhaustMap, filter, from, map, of} from 'rxjs';
import * as SharesActions from './shares.actions';
import {ShareService} from "../services/share.service";
import {ReadShareQuery} from '../model/ReadShareQuery';


@Injectable()
export class SharesEffects {
  private actions$ = inject(Actions);
  private shareService = inject(ShareService)

  addAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharesActions.addAccount),
      filter(props => {
        return props.accountBasedEntity?.account_id != null
      }),
      exhaustMap((props) => {
          return of(SharesActions.loadSharesForAccount({
            account_id: props.accountBasedEntity.account_id,
            accountType: props.accountBasedEntity.accountType,
            query: new ReadShareQuery()
          }))
        }
      )
    )
  );

  loadSharesForAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharesActions.loadSharesForAccount),
      concatMap((props) => {

          let query = props.query

          return from(this.shareService.readAll(query, props.account_id, props.accountType)).pipe(
            map(paginatedResponse => {
              const shares = paginatedResponse?.docs ? paginatedResponse.docs : []
              return SharesActions.loadShareForAccountSuccess({
                shares: shares,
                account_id: props.account_id,
                pagination: {...paginatedResponse, docs: null}
              })
            })
          )
        }
      )
    )
  );
}
