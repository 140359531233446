import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GatewayPageModule} from "./pages/gateway/gateway-page.module";
import {GatewayComponentsModule} from "./components/gateway-components.module";
import {DigilockerService, FaceIdModule} from "@qid/common";

@NgModule({
  imports: [
    CommonModule,
    GatewayPageModule,
    GatewayComponentsModule,
    FaceIdModule
  ],
  providers : [DigilockerService]
})
export class GatewayModule {
}
