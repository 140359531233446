import {Field} from '@qid/field';

export class CreateCollectionTemplateDto {
  _id: string;
  purpose: string;
  acceptedIds: Field[];
  otherInfo: Field[];
  isAtLeastOneAmongOptional: boolean;
  isPublic: boolean = true;
  org: string;
  defaultScannerId: string;
  displayPicture: any;
  allowGroup: boolean
  isParticipantPhoneRequired: boolean;
  isParticipantEmailRequired: boolean;
  isSignature: boolean;
  constructor(
    name = '',
    acceptedIds: Field[] = [],
    otherInfo: Field[] = [],
    isAtLeastOneAmongOptional: boolean = false,
  ) {
    this._id = null;
    this.purpose = name;
    this.acceptedIds = acceptedIds;
    this.otherInfo = otherInfo;
    this.isAtLeastOneAmongOptional = isAtLeastOneAmongOptional;
    // this.org = new Organisation();
  }
}
