<div class="id-card-wrapper" (click)="emitViewId()">
  <img *ngIf="sid?.logoUrl" alt="" class="id-logo" [src]="getImageUrl(sid?.logoUrl)">
  <div sxRow sxWidth="full" sxDistribution="start" sxGap="10px" sxAlign="center">
    <h1 class="title">{{sid?.name}}</h1>
    <div *ngIf="document?.sid?.verificationAvailable && !document?.verificationTime" sxRow sxTextSize="12px"
         sxColor="warning"
         sxAlign="center" sxGap="5px">
      <ion-icon sxTextSize="16px" name="alert-circle"></ion-icon>
      Verify
    </div>
    <img alt="" *ngIf="document?.verificationTime" sxWidth="15px" sxHeight="15px" src="assets/imgs/ver-icon_1.png">
  </div>

  <div class="card-detail">
    <div *ngIf="document">
      <h1 class="number" sxWidth="full" sxTextAlign="left"
          *ngIf="document?.documentNumberMasked">{{document?.documentNumberMasked}}</h1>
      <h1 class="owner-name" *ngIf="document?.documentData?.fullName">{{document?.documentData?.nameOnId}}</h1>
      <h1 class="owner-name" *ngIf="!document?.account && account">{{account?.name}}</h1>
    </div>

    <div class="card-options" *ngIf="sid">
      <button class="upload-id-button" (click)="emitUpload()" *ngIf="!document || !document._id">Upload</button>
      <ion-checkbox *ngIf="!mandatory && document" [checked]="true" color="light"
                    (ionChange)="onToggle($event)"></ion-checkbox>
    </div>
  </div>

  <div *ngIf="isDocumentDeleted" sxCol sxGap="10px" sxPadding="medium"
       sxColor="two">
    <ion-icon name="alert-circle-outline" sxTextSize="30px"></ion-icon>
    <h1 sxTextSize="medium" sxMargin="0"
    >Document Deleted</h1>
  </div>

</div>

