import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, from, map, of} from 'rxjs';
import * as ScannersActions from './scanners.actions';
import {ScannerApiService} from "../services/scanner-api.service";

@Injectable()
export class ScannersEffects {
  private actions$ = inject(Actions);
  private scannerService = inject(ScannerApiService)

  addAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScannersActions.addAccount),
      exhaustMap((props) =>
        of(ScannersActions.loadScannersForAccount({account_id: props.accountBasedEntity.account_id}))
      )
    )
  );

  loadScannersForAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScannersActions.loadScannersForAccount),
      exhaustMap((props) =>
        from(this.scannerService.readAll(null)).pipe(
          map(scanners => {
            scanners = scanners ? scanners : []
            return ScannersActions.loadScannersSuccess({
              scanners: scanners,
              account_id: props.account_id
            })
          })
        )
      ),
      catchError((error) => {
        console.error('Error', error);
        return of(ScannersActions.loadScannersFailure({error}));
      })
    )
  );
}
