import {Field} from "@qid/field";
import {ScannedImage} from "../../ocr";
import {Address} from "../../../interface/address.inteface";

export class DocumentData {

  idNumber?: string;

  expiry?: string;

  scannedImages?: ScannedImage[] = [];

  masterKeyString?: string;
  masterKeyWords?: string[];

  fullName?: string;
  firstName?: string;
  lastName?: string;
  nameMatchPercentage?: number;

  email?: string;
  mobileNumber?: string;

  dob?: string;
  yearOfBirth?: string;

  gender?: string;
  userPhotoURL?: string;
  userPhotoURI?: string;
  careOf?: string;

  address?: Address;
  signatureImage?: string;

  extra?: any;
  data?: Field[];
  manualFields?: Field[];

  verifiedBy?: string;
  verifyTime?: Date;

}
