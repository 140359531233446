import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlertController} from "@ionic/angular";
import {DateUtil, DisplayService} from "@qid/core";
import {Share} from "../../model/Share";
import {ShareService} from "../../services/share.service";

@Component({
  selector: 'share-checkin-view',
  templateUrl: './share-checkin-view.component.html',
  styleUrls: ['./share-checkin-view.component.scss'],
})
export class ShareCheckinViewComponent implements OnInit {

  @Input() share: Share
  @Output() shareChange: EventEmitter<Share> = new EventEmitter<Share>()

  stayDuration: number = null;

  constructor(private alterController: AlertController,
              private shareService: ShareService,
              private displayService: DisplayService
  ) {
  }

  ngOnInit() {
    this.stayDuration = this.getStayDuration();
  }

  async onCheckOutClicked() {
    const alert = await this.alterController.create({
      header: "Check out",
      subHeader: "Please enter the check out date & time",
      inputs: [
        {
          label: 'Date',
          type: 'date',
          value: new Date(),
          name: 'date'
        },
        {
          label: 'Time',
          type: 'time',
          value: DateUtil.getCurrentTime24(),
          name: 'time'
        },
        {
          placeholder: 'Remark',
          type: 'text',
          name: 'remark'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Submit',
          handler: async (data) => {
            let date = new Date(data.date)
            date.setHours(+data.time.split(':')[0]);
            date.setMinutes(+data.time.split(':')[1]);
            const res = await this.checkout(date, data.remark)
            if (!res) {
              return false
            }
          }
        }
      ]

    })
    alert.present();
  }


  async checkout(checkOutTime?: Date, remark?: string) {
    const checkInTime = this.share?.checkInData?.checkInTime || this?.share?.createTime

    if (new Date(checkInTime).getTime() > new Date(checkOutTime).getTime()) {
      this.displayService.toast({
        message: 'Check out cannot happen before check in'
      })
      return false;
    }


    const res = await this.shareService.update(this.share._id,
      {
        checkInData: {
          checkInTime: checkInTime,
          checkOutTime: checkOutTime,
          remark: remark
        },
        updateRemark: 'Check out',
        changeLastUpdateTime: false
      })

    if (res && res.data) {
      this.shareChange.emit(res.data)
      return true
    }

  }

  getStayDuration() {
    if (!this.share?.checkInData?.checkInTime || !this.share?.checkInData?.checkOutTime) {
      return null
    }
    const diff = DateUtil.getTimeDifferenceInDays(
      new Date(this.share?.checkInData?.checkInTime),
      new Date(this.share?.checkInData?.checkOutTime))

    return +diff.toFixed(0)
  }
}
