import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddOrgUserPageModule} from './pages/add-org-user/add-org-user.module';
import {OrganisationUserService} from './services/organisation-user.service';
import {ViewOrganisationUserPageModule} from './pages/view-organisation-user/view-organisation-user.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromOrganisationUser from './+state/organisation-user.reducer';
import {OrganisationUserEffects} from './+state/organisation-user.effects';
import {OrganisationUserDataRepository} from './+state/organisation-user-data-repository.service';
import { MasterOrganisationUserService } from './services/master-organisation-user.service';


@NgModule({
  imports: [
    CommonModule,
    AddOrgUserPageModule,
    ViewOrganisationUserPageModule,
    StoreModule.forFeature(
      fromOrganisationUser.ORGANISATION_USER_FEATURE_KEY,
      fromOrganisationUser.organisationUserReducer
    ),
    EffectsModule.forFeature([OrganisationUserEffects]),
  ],
  providers: [OrganisationUserService,MasterOrganisationUserService,OrganisationUserDataRepository],
  declarations: [],
  exports: []
})
export class OrganisationUserModule {
}
