import {FieldInputType} from "@qid/core";

export class Field<T = any> {
    label?: string;
    name?: string;
    value?: any = '';
    isMandatory?: boolean = false;
    isEnabled?: boolean = true;
    data?: T = null;
    errorMessage?: string;
    type?: FieldInputType;
    options?: string[];
    placeholder?: string;
    isEdit?: boolean
    isForEachParticipant?: boolean = true;
    sectionBreak? : {
      title? : string,
      description? : string,
    } = null

    constructor(
        name = '',
        isMandatory = false,
        value = '',
        type: FieldInputType = FieldInputType.text
    ) {
        this.name = name;
        this.isMandatory = isMandatory;
        this.value = value;
        this.type = type;
    }

    get isEmpty() {
        return this.value == null || this.value.length < 1;
    }
}


