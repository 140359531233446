import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxCursor]'})
export class CursorDirective implements OnInit {

  @Input() sxCursor: 'pointer' | string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.style.cursor = this.sxCursor
  }

}
