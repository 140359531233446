import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Field} from '../../models/Field';
import {FieldInputType} from "@qid/core";


@Component({
  selector: 'create-field',
  templateUrl: './create-field.component.html',
  styleUrls: ['./create-field.component.scss'],
})
export class CreateFieldComponent implements OnInit {


  @Input() action: (value: any) => {} = null;

  @Input() field : Field = new Field()

  inputTypes = Object.values(FieldInputType)
  @Input() selectedType = 'text';

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
    // setTimeout(() => {
    //   if (this.input) {
    //     this.input.setFocus();
    //   }
    // }, 100);
  }

  ionViewDidEnter() {
  }

  close(data = null) {
    this.modalController.dismiss({
      field: data,
    });
  }

  onSubmit = async () => {
    this.close(this.field)
  };


}
