import {Injectable} from "@angular/core";
import { RestService } from "../../../services/rest.service";
import { OrganisationUser } from '../models/organisation-user.model';
import {CreateOrganisationUserDto} from "../dto/create-organisation-user.dto";

@Injectable()
export class MasterOrganisationUserService {

  static readonly ApiUrl = 'master/organisation-user/'

  constructor(private restService: RestService,
  ) {
  }


  async create(orgId : string, addUserDto: CreateOrganisationUserDto) {
    return this.restService.send<OrganisationUser[]>({
      url: MasterOrganisationUserService.ApiUrl + 'create/' + orgId,
      body : addUserDto,
      method: 'POST',
    })
  }

  async getOrganisationUsers(orgId: string) {
    return (await this.restService.send<OrganisationUser[]>({
      url: MasterOrganisationUserService.ApiUrl + orgId,
      method: 'GET',
    })).data
  }

}
