import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxCapitalize]'})
export class CapitalizeDirective implements OnInit {

  @Input() sxCapitalize: any;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    try {
      this.el.nativeElement.style.textTransform = 'capitalize'
    } catch (e) {
      console.error("SX TEXT capitalize PROPERTY", e)
    }
  }


}
