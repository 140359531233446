import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CollectionTemplateService} from './services/collection-template.service';
import {IonicModule} from '@ionic/angular';
import {SexyModule} from '@qid/sexy';
import {FormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromCollectionTemplate from './+state/collection-template.reducer';
import {CollectionTemplateEffects} from './+state/collection-template.effects';
import {CollectionTemplateRepository} from './+state/collection-template-repository.service';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    SexyModule,
    FormsModule,
    StoreModule.forFeature(
      fromCollectionTemplate.COLLECTION_TEMPLATE_FEATURE_KEY,
      fromCollectionTemplate.collectionTemplateReducer
    ),
    EffectsModule.forFeature([CollectionTemplateEffects]),
  ],
  providers: [CollectionTemplateService, CollectionTemplateRepository],
})
export class CollectionTemplateModule {
}
