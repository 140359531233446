import {Injectable} from '@angular/core';
import {Industry} from '../model/industry.model';
import {CreateIndustryDto} from '../dto/create-industry.dto';
import {RestService} from "../../../../services/rest.service";


@Injectable()
export class IndustryService {

  private static ApiEndpoint = 'industry/';

  constructor(private restService: RestService) {
  }

  public create(dto: CreateIndustryDto) {
    return this.restService.send<Industry>({
      url: IndustryService.ApiEndpoint,
      body: dto,
      method: 'POST',
    });
  }

  public async readAll(parentIndustry: string = null) {
    return (
      await this.restService.send<Industry[]>({
        url:
          IndustryService.ApiEndpoint +
          '?parentIndustry=' +
          parentIndustry,
        method: 'GET',
      })
    ).data;
  }

  public async read(industryId: string) {
    return (
      await this.restService.send<Industry>({
        url: IndustryService.ApiEndpoint + industryId,
        method: 'GET',
      })
    ).data;
  }

  public update(industryId: string, dto: CreateIndustryDto) {
    return this.restService.send<Industry>({
      url: IndustryService.ApiEndpoint + industryId,
      body: dto,
      method: 'PUT',
    });
  }

  async delete(industryId: string) {
    return this.restService.send({
      url: IndustryService.ApiEndpoint + industryId,
      method: 'DELETE',
    });
  }
}
