import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
})
export class PageLoaderComponent implements OnInit {

  @ViewChild('spinner') spinner: ElementRef

  @Input() displayText: string = '';
  @Input() isPageLoaded: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  public hideLoader() {
    this.isPageLoaded = true;
    setTimeout(() => {
      this.spinner.nativeElement.remove()
    }, 200)
  }

}
