<ion-header mode="ios">
  <ion-toolbar>
    <ion-button slot="start" fill="clear" (click)="close()">Close</ion-button>
    <ion-title>Documents</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div sxPadding="medium">
    <super-button
      type="outline"
      [action]="downloadAllImages"
      iconStart="download-outline"
      background="light"
      textAlign="start"
      hideForPlatform="native"
    >Download All Images
    </super-button>
  </div>

  <div *ngFor="let access of documentAccesses" sxPadding="medium" sxGap="10px" sxCol>
    <p sxMargin="5px 10px 0" sxColor="two" sxTextWeight="bold">{{access?.document?.sid?.name}}</p>
    <div *ngFor="let image of access?.documentData?.scannedImages">
      <div
        class="image-wrapper"
        [style.backgroundImage]="'url(' + getImage(image.imageUrl) + ')'"
        (click)="viewPhoto(image)"></div>
    </div>
  </div>
  <app-pinch-to-zoom #photoViewer></app-pinch-to-zoom>
</ion-content>


