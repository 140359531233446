import {Component, EventEmitter, OnInit, Output, ViewChild,} from '@angular/core';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import {ImageUtil} from "@qid/core";
import { IonModal, Platform } from '@ionic/angular';
import {SuperDocumentCaptureComponent} from "../super-document-capture/super-document-capture.component";

@Component({
  selector: 'app-upload-image-button',
  templateUrl: './upload-image-button.component.html',
  styleUrls: ['./upload-image-button.component.scss'],
})
export class UploadImageButtonComponent implements OnInit {

  @ViewChild('documentCaptureComponent')
  documentCaptureComponent: SuperDocumentCaptureComponent

  @ViewChild('documentCaptureModal')
  documentCaptureModal: IonModal

  @Output() onImageUpload = new EventEmitter<any>();

  public imageTitle : string = null

  constructor(
    private platForm: Platform,
  ) {
  }

  ngOnInit() {

  }

  async upload(source: CameraSource = CameraSource.Prompt, imageTitle : string = null) {

    this.imageTitle = imageTitle;

    let image: any = null

    if (!this.platForm.platforms().includes('desktop') || source != CameraSource.Camera) {
      image = await Camera.getPhoto({
        quality: 10,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: source,
      });
    } else {
      const docCaptureRes = await this.openDocumentCaptureModal();
      if (!docCaptureRes) {
        return
      }
      image = {dataUrl: docCaptureRes}
    }


    const file = ImageUtil.dataURLtoFile(
        image.dataUrl,
        new Date().getTime() + '.' + image.format
    );
    const url: any = URL.createObjectURL(file);
    this.onImageUpload.emit({url: url, file: file});
  }

  openDocumentCaptureModal = async () => {
    await this.documentCaptureModal.present();
    const modalRes = await this.documentCaptureModal.onDidDismiss();

    if (modalRes?.data?.imageData) {
      return modalRes?.data?.imageData
    }

    return null

  }

}
