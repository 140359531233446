import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {IonModal, LoadingController, ModalController} from '@ionic/angular';
import PinchZoom from 'pinch-zoom-js';
import {DisplayService, ImageUtil} from '@qid/core';
import {Capacitor} from '@capacitor/core';

@Component({
  selector: 'app-pinch-to-zoom',
  templateUrl: './pinch-to-zoom.component.html',
  styleUrls: ['./pinch-to-zoom.component.scss']
})
export class PinchToZoomComponent {

  @ViewChild('img', {static: false}) img: ElementRef<HTMLImageElement>;


  @ViewChild('photoViewerModal') photoViewerModal: IonModal
  @ViewChild('mainImage') mainImage: ElementRef
  @ViewChild('zoomResult') zoomResult: ElementRef

  @Input() imageUrl: string = '';
  @Input() autoBase: boolean = true;

  zoomType : 'pinch' | 'mouse' = null

  constructor(private modalController: ModalController,
              private loadingController: LoadingController,
              private displayService: DisplayService) {
  }


  ionViewDidEnter() {

  }

  open(imageUrl: any) {
    this.imageUrl = imageUrl;
    const platform = Capacitor.platform;
    switch (platform) {
      case 'web':
        this.zoomType = 'mouse';
        break;
      default :
        this.zoomType = 'pinch';
        this.enablePinchToZoom();
    }
    this.photoViewerModal.present();
  }


  shareToLocal = async () => {
    const loader = await this.loadingController.create({
      message: 'Downloading'
    });

    loader.present();

    const imageUrl = ImageUtil.getImageSource(this.imageUrl);
    const uri = await ImageUtil.downloadImage(imageUrl);
    loader.dismiss();
    if (uri && uri.length > 0) {
      this.displayService.toast({
        message: 'Image Saved'
      });
    }

  };

  enablePinchToZoom() {
    if (!this.img) return;
    const pz = new PinchZoom(this.img.nativeElement);
    pz.enable();
  }

  zoomImage(event) {

    const zoomResult = this.zoomResult.nativeElement;
    const mainImage = this.mainImage.nativeElement

    zoomResult.style.visibility = "visible";
    const pos = this.getCursorPos(event);

    const xPercent = (pos.x / mainImage.width) * 100;
    const yPercent = (pos.y / mainImage.height) * 100;

    zoomResult.style.backgroundImage = `url(${mainImage.src})`;
    zoomResult.style.backgroundPosition = `${xPercent}% ${yPercent}%`;
  }

  onFocusOut() {
    const zoomResult = this.zoomResult.nativeElement;
    zoomResult.style.visibility = "hidden";
  }

  getCursorPos(e) {
    const mainImage = this.mainImage.nativeElement
    const rect = mainImage.getBoundingClientRect();
    return {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
  }

  getUrl() {
    return this.autoBase ? ImageUtil.getImageSource(this.imageUrl) : this.imageUrl;
  }

  back() {
    this.modalController.dismiss();
  }


}
