<div *ngIf="template && share" sxWidth="full">
  <div sxPadding="medium" sxCol sxGap="10px">

    <div *ngFor="let participant of share.participants; let i = index">
      <share-participant-documents [deleteButton]="share.participants.length > 2" *ngIf="isParticipant(participant)"
                                   [participant]="participant"
                                   [template]="template" (remove)="removeParticipant(i)"
      ></share-participant-documents>
    </div>


    <div *ngIf="template.allowGroup" (click)="addParticipant()" sxRadius="medium" sxBackground="light"
         sxPadding="medium"
         sxRow sxDistribution="spaceBetween"
         sxAlign="center">
      <div sxRow sxAlign="center" sxGap="10px">
        <ion-icon name="people-outline" sxTextSize="large"></ion-icon>
        <p sxMargin="0">Add More Members</p>
      </div>

      <div sxWidth="40px" sxHeight="40px" sxRadius="50%" sxCenterize="col" sxBackground="base">
        <ion-icon sxTextSize="25px" name="add-outline"></ion-icon>
      </div>

    </div>

  </div>

  <div class="spacer"></div>
  <page-action>
    <p class="submit-note" *ngIf="submitNote">{{ submitNote }}</p>
    <super-button [action]="onIdSubmitClicked" iconEnd="chevron-forward-outline">
      Submit
    </super-button>
  </page-action>

</div>


