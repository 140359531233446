import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({ selector: "[sxCenterize]" })
export class CenterizeDirective implements OnInit {
  @Input() sxCenterize: "col" | "row";

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.style.display = "flex";
    this.el.nativeElement.style.alignItems = "center";
    this.el.nativeElement.style.justifyContent = "center";
    switch (this.sxCenterize) {
      case "col":
        this.el.nativeElement.style.flexDirection = "column";
        break;
      case "row":
        this.el.nativeElement.style.flexDirection = "row";
        break;
      default:
        this.el.nativeElement.style.flexDirection = "column";
    }
  }
}
