import {Injectable} from '@angular/core';
import {jsPDF} from 'jspdf';
import {DatePipe} from '@angular/common';
import autoTable from 'jspdf-autotable';
import * as JSZip from "jszip";
import {saveAs} from 'file-saver';
import {DisplayService, ImageUtil, ValidationUtil} from "@qid/core";
import {Share} from '../model/Share';
import {ShareParticipantUtil} from "../utils/share-participant.util";
import {ParticipantRoles} from "../enum/participant-roles.enum";
import {DocumentAccessService} from "../../document-access";


@Injectable()
export class ExportPdfService {
  index: number = 0;
  totalShares: number = 0;
  isExporting: boolean = false;
  isCancelExport: boolean = false;

  constructor(
    private datePipe: DatePipe,
    private displayService: DisplayService,
    private documentAccessService: DocumentAccessService
  ) {
  }

  public async exportIndividualPdf(share: Share, noSave: boolean = false) {

    const doc = new jsPDF('p', 'mm', 'a4');
    let sno = 1;

    const organiser = ShareParticipantUtil.findOrganisingParticipant(share.participants)

    autoTable(doc, {
      head: [
        [
          {
            content: organiser.accountSnapshot.name,
            styles: {
              valign: 'bottom',
            },
          },
          {
            rowSpan: 2,
            content: 'qid',
            styles: {
              fontSize: 30,
              fontStyle: 'bolditalic',
              halign: 'right',
              valign: 'middle',
              cellPadding: {right: 10},
            },
          },
        ],
        [
          {
            content: share && share.template ? share.template.purpose : '',
            styles: {fontSize: 10, fontStyle: 'normal', valign: 'top'},
          },
          '',
        ],
      ],
      headStyles: {
        fontSize: 15,
        fontStyle: 'bold',
        fillColor: [0, 0, 0],
        textColor: 'white',
        cellPadding: 4,
      },
    });

    for (let participant of share.participants) {

      if (participant.roles.includes(ParticipantRoles.organiser)) continue;


      let shareDetailRows = [
        ['Name', participant?.accountSnapshot?.name],
        ['Email', participant?.accountSnapshot?.email],
        ['Phone', participant?.accountSnapshot?.phoneNumber],
        ['Shared On', this.datePipe.transform(share.createTime, 'medium')],
      ];

      participant.otherFields?.forEach(field => {
        shareDetailRows.push([
          ValidationUtil.isStringEmpty(field.label) ? field.name : field.label,
          field.value,
        ]);
      });

      autoTable(doc, {
        body: shareDetailRows,
        bodyStyles: {
          fontSize: 10,
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
        },
      });

      participant.documentsAccess = []
      for (let docId of participant.sharedDocumentsIds) {
        const res = await this.documentAccessService.findByDocumentId(docId)
        if (res.data && res.data[0]) {
          participant.documentsAccess.push(res.data[0])
        }
      }

      participant?.documentsAccess?.forEach(access => {

        const idDetailRows = [
          [`${access?.document?.sid?.name} Number`, access?.documentData?.idNumber],
        ];

        access?.documentData?.manualFields?.forEach(field => {
          idDetailRows.push([
            ValidationUtil.isStringEmpty(field.label) ? field.name : field.label,
            field.value,
          ]);
        });

        autoTable(doc, {
          head: [[access?.document?.sid?.name, '']],
          headStyles: {
            fontSize: 12,
            fontStyle: 'bold',
            fillColor: [0, 0, 0],
            textColor: 'white',
          },
          body: idDetailRows,
          bodyStyles: {
            fontSize: 10,
            lineWidth: 0.1,
            lineColor: [0, 0, 0],
          },
        });

        // Add the scanned images
        access?.documentData?.scannedImages.forEach((image, index) => {
          autoTable(doc, {
            body: [
              [
                `${access?.document?.sid?.name}`,
                '',
              ],
            ],
            bodyStyles: {
              fontSize: 10,
              minCellHeight: 100,
              lineWidth: 0.1,
              lineColor: [0, 0, 0],
            },
            didDrawCell: function (data) {
              if (
                data.column.index === 1 &&
                data.row.index == 0 &&
                data.cell.section === 'body'
              ) {
                const dim = data.cell.height - data.cell.padding('vertical');
                doc.addImage(
                  ImageUtil.getImageSource(
                    access?.documentData?.scannedImages[index].imageUrl
                  ),
                  data.cell.x - 100,
                  data.cell.y,
                  data.cell.width + 100,
                  data.cell.height
                );
              }
            },
          });
          if (index < access?.documentData?.scannedImages.length - 1) {
            doc.addPage();
          }
        });

        sno++;
      });

    }

    if (noSave) {
      return doc
    }

    doc.output('dataurlnewwindow');
  }

  public async bulkExport(shares: Share[]) {
    const zip = new JSZip();
    let index = 1;
    this.index = 0;
    this.totalShares = shares.length;
    this.isExporting = true;
    for (const share of shares) {
      if (!this.isExporting) {
        break;
      }
      try {
        const doc = await this.exportIndividualPdf(share, true);
        if (doc) {
          zip.file('qid-collection' + '.pdf', doc.output('blob'));
        }
      } catch (error) {
        console.log("Something went wrong while exporting PDF ", index);
      }
      await this.delay(2500);
      this.index = index;
      index++;
    }

    if (!this.isCancelExport) {
      await zip.generateAsync({type: 'blob'}).then(function (content) {
        saveAs(content, 'qid-id-collection-reports.zip');
      });
      this.stopExport();
      await this.displayService.toast({message: 'Export Completed'});
    }
  }

  public stopExport() {
    this.isExporting = false;
    this.index = 0
  }

  public cancelExport() {
    this.stopExport();
    this.isCancelExport = true
  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
