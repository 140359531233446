import imageCompression from 'browser-image-compression';
import {CoreModule} from "../core.module";
import {TextUtil} from "./TextUtil";
import {Capacitor} from "@capacitor/core";
import {Directory, Filesystem} from "@capacitor/filesystem";


export class ImageUtil {

  static async compressImage(imageFile) {

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      imageFile = await imageCompression(imageFile, options);
    } catch (error) {
      console.error(error);
      imageFile = null;
    }
    return imageFile;
  }

  static dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }

  static getImageSource(imageUrl: any) {
    return CoreModule.environment.imageBaseUrl + imageUrl;
  }

  public static async getCompressedFormData(imageFiles): Promise<FormData> {
    let compressedFiles = []
    for (let file of imageFiles) {
      if (file) {
        compressedFiles.push(await ImageUtil.compressImage(file));
      }
    }

    let data = new FormData();
    for (let file of compressedFiles) {
      data.append('files', file);
    }
    return data;
  }

  public static async fetchImageAsBlob(url): Promise<any> {
    const response = await fetch(url)
    return response.blob();
  }

  public static async urlToObjectUrl(url) {
    const blob = await ImageUtil.fetchImageAsBlob(url)
    return URL.createObjectURL(blob)
  }

  public static async urlToBase64(url): Promise<any> {
    try {
      const blob = await ImageUtil.fetchImageAsBlob(url)
      return new Promise(resolve => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    } catch (e) {
      return null
    }

  }

  public static isBase64PNGBlank(base64PNG) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height).data;
        for (let i = 0; i < imageData.length; i += 4) {
          if (imageData[i + 3] !== 0) {
            resolve(false); // image is not blank
            return;
          }
        }
        resolve(true); // image is blank
      };
      img.onerror = () => {
        reject(new Error('Invalid base64 encoded image.'));
      };
      img.src = base64PNG;
    });
  }

  static async downloadImage(imageUrl: string) {

    let fileName: string = TextUtil.getNameFromUrl(imageUrl)
    const image = await fetch(imageUrl)
    const imageBlog = await image.blob()
    const dataUrl = URL.createObjectURL(imageBlog)

    if (fileName.indexOf("blob") > -1) {
      fileName = fileName.replace("blob", "jpeg")
    }

    if (Capacitor.isNativePlatform()) {
      return ImageUtil.downloadImageOnPhone(imageUrl, fileName)
    } else {
      return ImageUtil.downloadImageOnWeb(dataUrl, fileName)
    }

    return ""

  }


  static downloadImageOnWeb(imageURL: string, fileName: string) {

    let link = document.createElement("a");
    document.body.appendChild(link);
    link.setAttribute("href", imageURL);
    link.setAttribute("download", "qid_id_" + fileName);
    link.click();
    link.remove();
    return ""
  }

  static async downloadImageOnPhone(imageUrl: string, filename: string) {
    if (!imageUrl) {
      return
    }
    const base64 = await ImageUtil.urlToBase64(imageUrl)
    const permissionStatus = await Filesystem.checkPermissions();
    if (permissionStatus.publicStorage != 'granted') {
      await Filesystem.requestPermissions();
    }
    const res = await Filesystem.writeFile({
      path: filename,
      data: base64,
      directory: Directory.Documents,
    });
    return res.uri
  }

  static getImageUrlFromPhotoLandmarks(imageUrl : string, landmarks) {

    const canvas = document.createElement('canvas');
    canvas.width = 100;
    canvas.height = 100;
    // @ts-ignore
    const ctx = canvas.getContext('2d');

    // Load image
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Enable CORS if the image is hosted on another domain
    img.src = imageUrl;

    return new Promise(resolve => {
      img.onload = function () {
        // Clear canvas
        // @ts-ignore
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Calculate bounding box
        const minX = Math.min(...landmarks.map(point => point.x));
        const minY = Math.min(...landmarks.map(point => point.y));
        const maxX = Math.max(...landmarks.map(point => point.x));
        const maxY = Math.max(...landmarks.map(point => point.y));

        // Calculate face dimensions
        const faceWidth = maxX - minX;
        const faceHeight = maxY - minY;

        // Draw cropped image
        ctx.drawImage(img, minX, minY, faceWidth, faceHeight, 0, 0, 100, 100);
        const dataUrl = canvas.toDataURL('image/png');
        resolve(dataUrl);
      };
    })



  }

}
