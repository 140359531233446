import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {DisplayService} from "@qid/core";
import {RestUtil} from "../../../services/rest.util";
import {RestService} from "../../../services/rest.service";
import {CreateUserDto} from "../../user";
import {AuthenticationResponse} from "../interface/authentication-response.interface";
import {RestResponse} from "../../../interface/RestInterface";
import {Capacitor} from "@capacitor/core";

@Injectable()
export class AuthenticationApiService {

  public static readonly ApiEndpoint = 'authentication/'

  constructor(
    private http: HttpClient,
    private displayService: DisplayService
  ) {

  }

  public refreshToken(session_id: string, refreshToken: string) {
    return RestUtil.send<AuthenticationResponse>({
      url: AuthenticationApiService.ApiEndpoint + 'refresh-token',
      method: "POST",
      body: {
        session_id: session_id,
        refreshToken: refreshToken
      }
    }, this.http, this.displayService)
  }

  public signInWithOtp(otp_id: string, otp: string) {
    return RestUtil.send<AuthenticationResponse>({
      url: AuthenticationApiService.ApiEndpoint + 'sign-in-with-otp',
      method: "POST",
      query: {
        otp_id: otp_id,
        otp: otp
      }
    }, this.http, this.displayService)
  }

  async signInAnonymously(): Promise<void> {
    const res = await RestUtil.send<{ token: string }>({
      url: 'authentication/sign-in-anonymously',
      method: 'GET',
      isNoAuth: true
    }, this.http, this.displayService, null)
    if (res?.data?.token) {
      RestService.setAuthToken(res.data?.token);
    }
  }

  signUp(createUserDto: CreateUserDto): Promise<RestResponse<AuthenticationResponse>> {
    createUserDto.createdByDevice = Capacitor.getPlatform();
    return RestUtil.send<AuthenticationResponse>({
      url: AuthenticationApiService.ApiEndpoint + 'sign-up',
      body: createUserDto,
      method: "POST"
    }, this.http, this.displayService);
  }

}
