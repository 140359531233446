import * as CryptoJS from "crypto-js";
import {RsaUtil} from "./RsaUtil";
import {AesUtil} from "./AesUtil";
import {AesRsaEncryptedData} from "../interface/AesRsaEncryptedData";

export class CryptoUtil {

  public static async generateUserKeys(password: string, salt = "RandomSalt") {

    const lockingKey: string = await CryptoUtil.generatePBKDF2Hash(password, salt);
    const keys = await RsaUtil.generateRSAKeyPair();
    const xor: string = CryptoUtil.calculateXOR(keys.privateBase64, lockingKey);
    return {
      key: xor,
      publicKey: keys.publicKeyBase64,
      salt: salt
    };
  }

  public static async extractPrivateKeyFromPassword(password: string, xorKey: string, salt: string) {
    const lockingKey = await CryptoUtil.generatePBKDF2Hash(password, salt);
    return CryptoUtil.calculateXOR(xorKey, lockingKey);
  }

  public static async extractLockingKeyFromPassword(password: string, salt: string) {
    return CryptoUtil.generatePBKDF2Hash(password, salt);
  }

  public static async extractPrivateKeyFromLockingKey(lockingKey: string, xorKey: string) {
    return CryptoUtil.calculateXOR(xorKey, lockingKey);
  }

  public static async encryptDataWithRsaAndAes(jsonData: any, publicKeyBase64: string): Promise<AesRsaEncryptedData> {

    const aesKey: CryptoKey = await AesUtil.generateKey();

    const encryptedData = await AesUtil.encryptJsonWithAESKey(aesKey, jsonData);
    const aesToEncrypted = new Uint8Array(await window.crypto.subtle.exportKey("raw", aesKey));
    const rsaEncryptedAesKey = await RsaUtil.encrypt(publicKeyBase64, aesToEncrypted);

    return {
      ivBase64: encryptedData.ivB64,
      dataCipher: encryptedData.encryptedDataB64,
      rsaEncryptedAesKey: rsaEncryptedAesKey
    };

  }


  public static async decryptDataWithRsaAndAes(cipherData: string, privateKeyBase46: string, encryptedAesKey: string, aesSaltBase64: string) {

    const aesKey: CryptoKey = await AesUtil.decryptAesKeyWithRsaPrivateKey(privateKeyBase46, encryptedAesKey);
    return AesUtil.decryptJsonWithAESKey(aesKey, CryptoUtil.base64ToUint8Array(cipherData), CryptoUtil.base64ToUint8Array(aesSaltBase64));


  }


  public static unit8ArrayToBase64(unit8Array) {
    let binaryString = "";
    unit8Array.forEach(byte => {
      binaryString += String.fromCharCode(byte);
    });
    return btoa(binaryString);
  }

  public static base64ToUint8Array(base64String) {
    const binaryString = atob(base64String);
    const length = binaryString.length;
    const uint8Array = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    return uint8Array;
  }


  public static arrayBufferToBase64(arrayBuffer) {
    const binaryString = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
    return btoa(binaryString);
  }

  public static base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
  }

  public static async importRawKeyFromArrayBuffer(rawKey) {
    return await window.crypto.subtle.importKey(
      "raw",
      rawKey,
      {name: "AES-GCM"}, // Adjust the algorithm name if necessary
      false,
      ["encrypt", "decrypt"]
    );
  }

  public static async generatePBKDF2Hash(password: string, salt: string): Promise<string> {
    if (!password || !salt) {
      return null
    }
    const iterations = 10000;
    const keyLength = 256; // in bits
    const algorithm = 'SHA256'; // The hashing algorithm

    return new Promise<string>((resolve, reject) => {
      const derivedKey = CryptoJS.PBKDF2(password, salt, {
        keySize: keyLength / 8,
        iterations,
        hasher: CryptoJS.algo[algorithm]
      });
      resolve(derivedKey.toString(CryptoJS.enc.Hex));
    });
  }

  public static encode(data: string): Uint8Array {
    const encoder = new TextEncoder();
    return encoder.encode(data);
  }

  public static decode(data: BufferSource): string {
    const decoder = new TextDecoder();
    return decoder.decode(data);
  }

  public static calculateXOR(str1, str2) {
    // Determine the common prefix length
    const minLength = Math.min(str1.length, str2.length);

    // Calculate XOR on the common prefix
    let result = '';
    for (let i = 0; i < minLength; i++) {
      const charCode1 = str1.charCodeAt(i);
      const charCode2 = str2.charCodeAt(i);
      const xorResult = charCode1 ^ charCode2;
      result += String.fromCharCode(xorResult);
    }

    // Append the remaining characters from the longer string
    if (str1.length > minLength) {
      result += str1.substring(minLength);
    } else if (str2.length > minLength) {
      result += str2.substring(minLength);
    }

    return result;
  }
}
