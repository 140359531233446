import {createAction, props} from '@ngrx/store';
import {AccountType, AccountWiseRecords, PaginatedResponse} from "@qid/core";
import {ReadShareQuery} from "../model/ReadShareQuery";
import {Share} from "../model/Share"


export const addAccount = createAction(
  '[Share/Data Repository] Add Account for Shares',
  props<{ accountBasedEntity: AccountWiseRecords<Share> }>()
)

// Shares

export const setReadShareQuery = createAction(
  '[Share/Data Repository] Set read shares query',
  props<{ query: ReadShareQuery }>()
);

export const loadSharesForAccount = createAction(
  '[Share/Data Repository] Load Shares for Account',
  props<{ account_id: string, accountType: AccountType, query: ReadShareQuery }>()
);

export const loadShareForAccountSuccess = createAction(
  '[Share/Data Repository] Load Shares for Account Success',
  props<{ shares: Share[], account_id: string, pagination: PaginatedResponse<Share> }>()
);

export const addNextPageRecords = createAction(
  '[Share] Add Next Page',
  props<{ account_id: string, accountType: AccountType, records: Share[], pagination: PaginatedResponse<Share> }>()
);


export const addRecord = createAction(
  '[Share] Add Record',
  props<{ record_id: string, account_id: string, record: Share }>()
);

export const updateRecord = createAction(
  '[Share] Update Record',
  props<{ record_id: string, account_id: string, updatedRecord: Share }>()
);

export const deleteShare = createAction(
  '[Share/Data Repository] Delete Share',
  props<{ share_id: string, account_id: string }>()
);

