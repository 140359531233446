import {Injectable} from "@angular/core";
import {DigilockerRequest} from "../models/digilocker-request.model";
import {RestService} from "../../../services/rest.service";
import {RequestedDoc} from "../models/requested-doc.model";
import {CreateDigilockerRequestDto} from "../dto/create-digilocker-request.dto";

@Injectable()
export class DigilockerService{

  public static ApiEndpoint = "digilocker/"

  constructor(private restService: RestService) {

  }

  getUrl(dto : CreateDigilockerRequestDto){
    return this.restService.send<{ url : string, digilockerRequest : DigilockerRequest }>({
      url : DigilockerService.ApiEndpoint + 'create',
      method : 'POST',
      body : dto
    })
  }

  findById(_id : string){
    return this.restService.send<DigilockerRequest>({
      url : DigilockerService.ApiEndpoint +  _id,
      method : 'GET'
    })
  }

}
