import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {AccountType} from "../enums/AccountType";
import {PaginatedResponse} from "../interface/PaginatedResponse";

export interface a {
  name: string
};

export interface AccountWiseRecords<RecordType, AdditionalAccountWiseData = {}> {
  account_id: string;
  accountType: AccountType;
  records: EntityState<RecordType>
  isLoading?: boolean;
  pagination?: PaginatedResponse<RecordType>
  hasPagination?: boolean
  nextPage?: boolean
  currentPageNumber?: number
  additionalData?: AdditionalAccountWiseData
}

export function createAccountWiseEntityAdapters<RecordType, AdditionalAccountWiseData = {}>(featureKey: string) {

  const accountAdapter: EntityAdapter<AccountWiseRecords<RecordType, AdditionalAccountWiseData>> =
    createEntityAdapter<AccountWiseRecords<RecordType, AdditionalAccountWiseData>>({
      selectId: e => e.account_id
    });

  const recordAdapter: EntityAdapter<RecordType> =
    createEntityAdapter<RecordType>({
      selectId: e => e['_id']
    });

  function createAccount(account_id: string, accountType: AccountType, additionalData = {}): AccountWiseRecords<RecordType> {
    return {
      account_id: account_id,
      accountType: accountType,
      records: recordAdapter.getInitialState(),
      additionalData : additionalData
    }
  }

  return {
    accountAdapter: accountAdapter, recordAdapter: recordAdapter,
    createAccount: createAccount
  }

}


