import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as ScannersActions from './scanners.actions';
import * as ScannersSelectors from './scanners.selectors';
import {ScannerApiService} from "../services/scanner-api.service";
import {Scanner} from "../models/scanner";
import {firstValueFrom} from "rxjs";
import {createAccount} from "./scanners.reducer";
import {AccountWiseRecords} from "@qid/core";
import {UpdateScannerDto} from "../dto/update-scanner.dto";
import {AuthSelectors} from "../../authentication";
import {ScannerTemplateOptions} from "../../../models/scanner-template-options";

@Injectable()
export class ScannersFacade {

  private readonly store = inject(Store);
  private readonly apiService = inject(ScannerApiService);
  private ACTION = ScannersActions;
  isRecordLoading$ = this.store.select(ScannersSelectors.selectIsRecordLoading)
  allRecords$ = this.store.select(ScannersSelectors.selectActiveAccountScanners);
  activeAccount$ = this.store.select(ScannersSelectors.selectActiveAccount);

  async init() {

    if (!await firstValueFrom(this.activeAccount$)) {
      await this.addAccount()
    }

  }

  async refresh() {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(ScannersActions.loadScannersForAccount({account_id: account_id}))
  }

  private async addAccount() {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    if (!account_id) {
      return
    }
    const accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType));
    const accountBasedEntity: AccountWiseRecords<Scanner> = createAccount(account_id, accountType);
    this.store.dispatch(ScannersActions.addAccount({accountBasedEntity: accountBasedEntity}))
  }

  public async create(qr: string, templates: ScannerTemplateOptions[], title?: string) {

    const res = await this.apiService.create(qr, templates, title)

    if (res && res.data) {
      const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
      this.store.dispatch(ScannersActions.addScanner({scanner: res.data, account_id: account_id}))
    }

    if (res && res.errorMessage) {
      throw new Error(res.errorMessage)
    }

  }

  async updateRecord(record_id: string, updatedRecord : UpdateScannerDto) {
    const res = await this.apiService.update(record_id, updatedRecord);
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(this.ACTION.updateRecord({
      record_id: record_id,
      account_id: account_id,
      updatedRecord: res
    }))
    return res
  };

  async delete(scanner_id: string) {
    const res = await this.apiService.delete(scanner_id);
    if (res) {
      const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
      this.store.dispatch(ScannersActions.removeScanner({scanner_id: scanner_id, account_id: account_id}))
    }

  }
}
