import {createAction, props} from '@ngrx/store';
import {User} from "../model/User";

export const initUsers = createAction('[Users Page] Init');

export const loadUsersSuccess = createAction(
    '[Users/API] Load Users Success',
    props<{ users: User[] }>()
);

export const loadUsersFailure = createAction(
    '[Users/API] Load Users Failure',
    props<{ error: any }>()
);

export const fetchAuthenticatedUser = createAction(
  '[vice] Fetch Authenticated User');

export const fetchAuthenticatedUserSuccess = createAction(
    '[Auth/API] Fetch Authenticated User Success',
    props<{
        authenticatedUser: User
    }>()
);
