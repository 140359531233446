<div class="pagination-wrapper">
  <button class="arrow left" (click)="onPrev()">
    <ion-icon name="caret-back-outline"></ion-icon>
  </button>
  <div class="info-wrapper">
    {{ paginatedResponse.page }} of {{ paginatedResponse.totalPages }}
  </div>
  <button class="arrow right" (click)="onNext()">
    <ion-icon name="caret-forward-outline"></ion-icon>
  </button>
</div>
