<ion-header mode="ios">
  <ion-toolbar>
    <ion-button slot="start" fill="clear" (click)="closeModal()">Back</ion-button>
    <ion-title>qid</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <page-loader #pageLoaderComponent>

    <div *ngIf="share" sxPadding="medium" sxCol sxGap="10px">


      <div sxRow sxAlign="center" sxDistribution="spaceBetween" sxPadding="medium" sxGap="10px">
        <div sxCol sxGap="10px">
          <h2 sxMargin="0" sxTextSize="medium" *ngIf="share.template">{{ share.template?.purpose}}</h2>
          <p sxMargin="0">{{ share.createTime | date : 'medium' }}</p>
        </div>

        <div *ngIf="share.status" sxTextSize="40px">
          <div *ngIf="share.status == 'open'" sxCenterize="col">
            <ion-icon sxColor="warning" name="alert-circle"></ion-icon>
            <p sxMargin="2px 0" sxTextSize="small">Pending</p>
          </div>
          <ion-icon *ngIf="share.status == 'completed'" sxColor="success" name="checkmark-circle"></ion-icon>
          <div *ngIf="share.status == 'cancelled'" sxCenterize="col">
            <ion-icon sxColor="danger" name="close-circle"></ion-icon>
            <p sxMargin="2px 0" sxTextSize="small">Cancelled</p>
          </div>
        </div>

        <ion-icon sxTextSize="40px" *ngIf="!share.status" sxColor="success" name="checkmark-circle"></ion-icon>

      </div>

      <div sxRow
           sxGap="10px"
           sxPadding="15px 22px"
           sxAlign="center"
           sxDistribution="spaceBetween"
           sxRadius="medium"
           sxBackground="base-shade" sxMargin="0 0 15px 0">
        <div sxRow sxGap="10px" sxAlign="center">
          <avatar-round
            [size]="40"
            [imageUrl]="organiser?.account?.displayPictureUrl"
            [title]="organiser?.account?.name"></avatar-round>
          <p sxMargin="0">
            {{ organiser?.account?.name }}
          </p>
        </div>
        <p sxColor="two" sxTextSize="small" sxMargin=" 0">Organiser</p>
      </div>

      <share-checkin-view *ngIf="share?.template?.type == 'check-in'" [(share)]="share"></share-checkin-view>

      <div sxGap="10px" sxCol>

        <div *ngFor="let participant of sharerParticipants">

          <div *ngIf="participant"
               sxBackground="light"
               sxRadius="medium"
               sxPadding="medium 0">

            <div sxRow
                 sxGap="10px"
                 sxPadding="10px 22px"
                 sxAlign="center"
                 sxDistribution="spaceBetween">
              <div sxRow sxGap="10px" sxAlign="center">
                <avatar-round
                  [size]="40"
                  [imageUrl]="participant.account?.displayPictureUrl"
                  [title]="participant.account?.name"></avatar-round>
                <p sxMargin="0" sxCapitalize>
                  {{ participant.account?.name?.toLowerCase() }}
                </p>
              </div>
              <p sxColor="two" sxMargin="0" sxTextSize="small">{{getParticipantRole(participant.roles)}}</p>
            </div>

            <div *ngIf="participant.sharedDocumentsIds">
              <swiper-container navigation="true">
                <swiper-slide *ngFor="let sharedDocumentId of participant.sharedDocumentsIds"
                              sxPadding="medium 22px">
                  <id-card
                    [documentId]="sharedDocumentId"
                    (viewIdModel)="openViewIdPage($event)"
                  ></id-card>
                </swiper-slide>
              </swiper-container>

              <div sxPadding="0 20px 10px" *ngIf="participant.sharedDocumentsIds.length > 1">
                <div sxRow sxRadius="8px" sxBackground="base" sxPadding="10px"
                     sxDistribution="spaceBetween" sxTextSize="small">
                  <p sxMargin="0" sxColor="two">{{ participant.sharedDocumentsIds.length }} Documents</p>
                  <p sxMargin="0" (click)="openViewAllDocumentsPage(participant.sharedDocumentsIds)" sxCursor="pointer">
                    View All</p>
                </div>
              </div>
            </div>

            <div *ngIf="!participant.sharedDocumentsIds" sxPadding="medium">
              <div sxCenterize="row" sxBackground="one" sxRadius="medium" sxHeight="160px">
                <p sxColor="two">No document shared yet</p>
              </div>
            </div>

            <ion-item *ngIf="participant?.accountSnapshot?.phoneNumber">
              <ion-input
                labelPlacement="stacked"
                label="Phone"
                readonly=""
                value="{{ participant?.accountSnapshot?.phoneNumber | formatPhone }}"></ion-input>
              <ion-icon name="logo-whatsapp" slot="end" sxTextSize="24px" sxCursor="pointer" sxColor="#25D366"
                        (click)="openWhatsapp(participant?.accountSnapshot)"
              ></ion-icon>
            </ion-item>
            <ion-item>
              <ion-input labelPlacement="stacked" label="Email" readonly=""
                         value="{{ participant?.accountSnapshot?.email ?? '-' }}"></ion-input>
            </ion-item>

            <ion-item *ngFor="let info of participant.otherFields">
              <ion-input
                labelPlacement="stacked"
                label="{{info.label}}"
                readonly="true"
                value="{{ info.value ? info.value : '-' }}"></ion-input>
            </ion-item>

            <div sxPadding="medium">
              <div *ngIf="participant.facePhoto" sxRow sxPadding="medium" sxBorderWidth="1px"
                   sxBorderColor="one" sxRadius="medium" sxAlign="center" sxGap="10px"
              sxCursor="pointer" (click)="onSelfieClicked(participant.facePhoto.imageUrl)">
                <avatar-round [size]="50" [imageUrl]="participant.facePhoto.imageUrl"></avatar-round>
                <p sxWidth="fill">Selfie Verification</p>
                <div *ngIf="participant.facePhoto.isLive" sxRow sxAlign="center" sxGap="5px">
                  <ion-icon name="shield-checkmark-outline"></ion-icon>
                  <p sxColor="two" sxTextSize="small">Liveness</p>
                </div>
              </div>
            </div>

            <div sxBackground="light"
                 sxPadding="medium"
                 sxRadius="medium"
                 sxRow
                 sxAlign="center"
                 *ngIf="participant.signatureImageUrl">
              <img
                alt="signature"
                sxWidth="100px"
                sxHeight="50px"
                [src]="getImage(participant?.signatureImageUrl)"/>
              <p
                sxMargin="0"
                sxTextSize="small"
                sxWidth="fill">
                <b>I {{ participant?.account?.name }},</b> confirm that the provided Documents and
                information are true and accurate to the best of my
                knowledge.
              </p>
            </div>

          </div>


        </div>

        <div *ngIf="share.otherFields.length>0" sxCol sxGap="10px">
          <div *ngFor="let info of share.otherFields">
            <ion-item *ngIf="!info.sectionBreak && !info.isForEachParticipant">
              <ion-input
                labelPlacement="stacked"
                label="{{info.label ? info.label : info?.name}}"
                readonly="true"
                value="{{ info.value ? info.value : '-' }}"></ion-input>
            </ion-item>
            <div *ngIf="info.sectionBreak" sxCol sxGap="5px" sxPadding="25px 10px 10px">
              <h1 sxMargin="0" sxTextSize="large">{{info.sectionBreak?.title}}</h1>
              <p *ngIf="info?.sectionBreak?.description as description" sxMargin="0" sxTextSize="small"
                 sxColor="two">{{description}}</p>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="share.status == 'completed' && share.agreements.length>0" sxCol sxGap="10px">
        <h2 sxRow sxAlign="center" sxGap="3px"
            sxMargin='medium 0 0 medium' sxTextSize='16px' sxColor='two'>
          <ion-icon name="checkmark-circle"></ion-icon>
          Policies Accepted
        </h2>
        <div sxRadius="medium" sxBackground="light" sxMargin="0">
          <ion-accordion-group
            *ngFor="let agreement of share.agreements"
            sxWidth="fill" sxCursor="pointer" sxBorderColor="one" sxBorderWidth="0 0 1px">
            <ion-accordion>
              <ion-item slot="header">
                <ion-icon name="document-text-outline"></ion-icon>
                <ion-label>{{agreement.title}}</ion-label>
              </ion-item>
              <div slot="content">
                <app-agreement-view [agreement]="agreement" [viewOnly]="true"></app-agreement-view>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </div>
      </div>

      <super-button
        type="outline"
        [action]="printAsPDF"
        iconStart="print-outline"
        background="light"
        textAlign="start"
        hideForPlatform="native"
      >Print/Save
      </super-button>

      <div visibleTo="org" minimumRole="1">
        <super-button
          [permissionModule]="ApiModules.Share"
          [permissionOperation]="ApiOperations.delete"
          #deleteLoader
          type="wrap"
          [isDefaultLoader]="false"
          [action]="deleteShare"
          iconStart="trash-outline"
          background="light"
          color="danger"
          textAlign="start"
        >Delete
        </super-button>
      </div>

    </div>
    <div *ngIf="share?.updateLogs?.length > 0" sxPadding="medium" sxBorderColor="one" sxBorderWidth="5px 0 0 0"
         sxMargin="20px 0">

      <p sxMargin="20px 10px 5px" sxTextWeight="bold">Logs</p>

      <div *ngFor="let _ of share.updateLogs; index as i" sxRow sxAlign="center">

        <div sxWidth="50px" sxHeight="50px" sxCenterize>
          <div sxCol sxWidth="2px" sxHeight="fill" sxBackground="one"></div>
          <div sxCircle="20px" sxBackground="one" sxCol sxAlign="center" sxDistribution="center">
            <div sxCircle="12px" sxBackground="two" sxCol></div>
          </div>
          <div sxCol sxWidth="2px" sxHeight="fill"
               [sxBackground]="i == (share?.updateLogs?.length -1) ? 'transparent' : 'one'"></div>
        </div>

        <div sxCol sxGap="5px" *ngIf="share?.updateLogs[share?.updateLogs?.length -1 - i] as log">
          <p sxMargin="0" sxTextSize="small">{{log.remark}}</p>
          <p sxMargin="0" sxTextSize="10px" sxColor="two">
            <span sxTextStyle="underline">By {{log?.updatedByAccountSnapshot?.name}}</span>
            | {{log.updateTime | date : 'medium'}}
          </p>
        </div>
      </div>
    </div>
    <div sxHeight="100px" sxCenterize sxBorderColor="one" sxBorderWidth="5px 0 0">
      <p sxColor="two" sxTextSize="small">oneqid.com</p>
    </div>
  </page-loader>

  <app-pinch-to-zoom #photoViewer></app-pinch-to-zoom>

</ion-content>
