import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountAttributeFacade} from "../../state/account-attribute.facade";
import {AccountAttribute} from "../../model/account-attribute.model";
import {map, Observable} from 'rxjs';
import {AccountAttributeType} from "../../enums/account-attribute-type.enum";
import {AccountProfileFacade} from "../../../account-profile";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'list-account-attribute',
  templateUrl: './list-account-attribute.component.html',
  styleUrls: ['./list-account-attribute.component.scss'],
})
export class ListAccountAttributeComponent implements OnInit {

  @Input() label: string = null;
  @Input() attributeTypeFilter: AccountAttributeType = null;
  @Output() onAttributeSelected: EventEmitter<AccountAttribute> = new EventEmitter<AccountAttribute>()

  public accountAttribute$: Observable<AccountAttribute[]> = this.accountAttributeFacade.allAttributes$

  public filteredAccountAttribute$: Observable<AccountAttribute[]> = this.accountAttribute$.pipe(
    map((attributes: AccountAttribute[]) =>
      (attributes || [])
        .filter(attribute =>
          !this.attributeTypeFilter || attribute?.attributeType === this.attributeTypeFilter
        )
    )
  );

  constructor(private accountAttributeFacade: AccountAttributeFacade,
              private accountProfileFacade: AccountProfileFacade,
              private modalController: ModalController
  ) {
  }

  ngOnInit() {
    this.accountAttributeFacade.init()
  }

  async onClicked(attribute_id: string) {
    await this.accountProfileFacade.updateAttribute(attribute_id)
  }


  onBackClicked() {
    this.modalController.dismiss()
  }
}
