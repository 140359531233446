import {NgModule} from '@angular/core';
import {HideForOrgDirective} from './directives/hide-for-org.directive';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromOrganisations from './+state/organisations.reducer';
import {OrganisationsEffects} from './+state/organisations.effects';
import {OrganisationDataRepositoryService} from "./+state/organisation-data-repository.service";
import {IndustryModule} from "../../industry";
import {OrganisationRelationshipModule} from "../../organisation-relationship";

@NgModule({
  imports: [
    StoreModule.forFeature(
      fromOrganisations.ORGANISATIONS_FEATURE_KEY,
      fromOrganisations.organisationsReducer
    ),
    EffectsModule.forFeature([OrganisationsEffects]),
    IndustryModule,
    OrganisationRelationshipModule
  ],
  declarations: [HideForOrgDirective],
  providers: [OrganisationDataRepositoryService],
  exports: [HideForOrgDirective],
})
export class OrganisationModule {
}
