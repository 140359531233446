<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button slot='start' fill='clear' color='dark' (click)='close()'>Back</ion-button>
    <ion-title>{{title}}</ion-title>
    <ion-button slot='end' fill='clear' (click)='close(value)'>Done</ion-button>
  </ion-toolbar>
</ion-header>
<ion-content>

  <ion-searchbar [(ngModel)]='searchInput' autofocus (ionInput)="searchInputValue($event)"></ion-searchbar>

  <div>
    <ion-radio-group [(ngModel)]='value' (ionChange)="close(value)">
      <ion-item *ngFor='let option of lazyOptions' [hidden]="checkSearch(option)">
        <ion-radio [value]='option'  sxCapitalize=''>{{getLabel(option)}}</ion-radio>
      </ion-item>
    </ion-radio-group>
  </div>

</ion-content>
