import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {ItemReorderEventDetail} from "@ionic/angular";
import {DisplayService, FieldInputType, ValidationUtil} from '@qid/core';
import {Field} from "@qid/field";
import {CreateSidDto} from "../../dto/create-sid.dto";
import {SID} from "../../models/SID";
import {SidPhoto} from "../../models/SidPhoto";
import {SidRepository} from "../../+state/sid-repository.service";
import {Environment, ENVIRONMENT} from "@qid/env";
import {SidMasterApiService} from "../../services/sid-master-api.service";


@Component({
  selector: 'view-sid',
  templateUrl: './view-sid.component.html',
  styleUrls: ['./view-sid.component.scss'],
})
export class ViewSidComponent implements OnInit {

  @Input() sid: SID = null;
  @Input() getActiveOrgId: string;

  @Output() onClose = new EventEmitter()

  public createSidDto: CreateSidDto = new CreateSidDto();
  inputTypes = Object.values(FieldInputType);

  isMasterApp = this.env.appName == 'Master'

  constructor(
      private displayService: DisplayService,
      private sidRepository: SidRepository,
      private sidMasterApiService: SidMasterApiService,
      @Inject(ENVIRONMENT) private env: Environment
  ) {
  }

  ngOnInit() {
    this.sid = JSON.parse(JSON.stringify(this.sid))
  }


  addPhoto() {
    this.sid.photos.push(new SidPhoto());
  }

  removePhoto(i: number) {
    this.sid.photos.splice(i, 1);
  }

  update = async () => {
    if (ValidationUtil.isStringEmpty(this.sid.name)){
      return this.displayService.toast({message: 'Please enter a valid name.',color:'warning'});
    }

    for (let photo of this.sid?.photos){
      if (ValidationUtil.isStringEmpty(photo.name)){
        return this.displayService.toast({message: 'Please enter photo name.',color:'warning'});
      }
    }

    if (this.isMasterApp) {
      return this.updateMaster()
    }
    const res = await this.sidRepository.updateRecord(this.sid._id, this.sid);
    if (res && res.data) {
      await this.displayService.toast({
        message: this.sid.name + ' Updated',
        color: 'success',
        icon: 'checkmark-outline',
      });
    }
    this.onClose.emit()
  };

  updateMaster = async () => {
    const res = await this.sidMasterApiService.update(this.sid);
    if (res && res.data) {
      await this.displayService.toast({
        message: this.sid.name + ' Updated',
        color: 'success',
        icon: 'checkmark-outline',
      });
      this.sidRepository.refreshGlobalSids()
    }
  }

  addField() {
    if (!this.sid.manualFields) {
      this.sid.manualFields = [];
    }

    this.sid.manualFields.push(new Field<any>());
  }

  removeField(i: number) {
    this.sid.manualFields.splice(i, 1);
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    this.sid.manualFields = ev.detail.complete(this.sid.manualFields);
  }

  delete = async () => {
    await this.sidRepository.removeRecord(this.sid._id)
    await this.displayService.toast({
      message: this.sid.name + ' Deleted',
      color: 'danger',
      icon: 'checkmark-outline',
    });
    this.onClose.emit();
  }

  uploadSidLogo = async (upload: any) => {

    if (this.isMasterApp) {
      return this.updateGlobalSidLogo(upload)
    }

    const res = await this.sidRepository.updateSidLogo(this.sid._id, upload.file);
    if (res && res.data) {
      this.sid = res.data;
    }
  };

  updateGlobalSidLogo = async (upload: any) => {

    const res = await this.sidRepository.updateGlobalSidLogo(this.sid._id, upload.file);
    if (res && res.data) {
      this.sid = res.data;
    }

  }

  closedSID(){
    this.onClose.emit();
  }
}
