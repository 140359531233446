import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {firstValueFrom} from "rxjs";
import {AuthSelectors} from "../modules/authentication";
import {OrganisationUserSelectors} from "../modules/organisation-user";


@Directive({
  standalone : true,
  selector: '[minimumRole]'
})
export class RoleAccessDirective implements OnInit {

  @Input() minimumRole: number;

  constructor(
    private el: ElementRef,
    private store: Store
  ) {
  }

  ngOnInit() {
    this.init()
  }

  async init() {
    const org = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccount))

    if (org == null) {
      return;
    }

    const userRole = await firstValueFrom(this.store.select(OrganisationUserSelectors.selectActiveOrganisationUserRole))

    if (+userRole > this.minimumRole) {
      this.hideElement();
    }
  }


  hideElement() {
    this.el.nativeElement.remove();
  }
}
