<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button slot="start" fill="clear" color="dark" (click)="close()">Cancel</ion-button>
    <ion-title class="title">Create Field</ion-title>
    <super-button
      slot="end"
      background="transparent"
      height="fit"
      padding="0"
      color="primary"
      [action]="onSubmit">Done
    </super-button>
  </ion-toolbar>
</ion-header>
<ion-content>

  <div sxCol sxPadding="medium" sxGap="15px">
    <super-input [(value)]="field.label" label='Field Name' placeholder="Eg. Room No, School,  and etc..."
                 type='text'></super-input>

    <super-input label='Placeholder' [(value)]='field.placeholder'></super-input>

    <super-input type='dropdown' label='Field Type' [(value)]='field.type' [options]='inputTypes'></super-input>

     <ion-accordion-group sxRadius='medium'>
      <ion-accordion value='first'>
        <ion-item slot='header'>
          <ion-label>Advance Options</ion-label>
        </ion-item>
        <div slot='content' sxPadding='medium' sxCol sxGap='10px'>
          <super-input label='ID' placeholder='Assign Unique ID to this field' [(value)]='field.name'></super-input>
          <super-input label='Default Value' [(value)]='field.value'></super-input>
        </div>
      </ion-accordion>
    </ion-accordion-group>


  </div>

</ion-content>
