<div sxRow sxWidth="full" sxHeight="100%" sxMinHeight="full">
  <div sxPadding="medium" sxWidth="fill" sxCol sxGap="10px" sxHeight="full" sxOverflow="scroll">
    <ion-reorder-group
      disabled="false"
      (ionItemReorder)="handleReorder($any($event))">
      <div
        sxRow
        sxRadius="medium"
        sxAlign="center"
        sxWidth="full"
        sxMargin="10px 0"
        *ngFor="let agreement of collectionTemplateAgreements; let i = index">
        <div
          sxPadding="medium"
          sxBackground="light"
          sxRadius="medium"
          sxWidth="fill"
          sxCursor="pointer"
          sxCol sxGap="10px"
          [class.selected]="i === selectedAgreementIndex">
          <h3 sxMargin="5px 0">{{ agreement.title ? agreement.title : 'Add Policy Title' }}</h3>
          <p sxMargin="0">
            {{
            agreement.description
              ? agreement.description
              : 'Write here a short description about your policy'
            }}
          </p>

          <div sxCol>
            <div *ngFor="let bullet of agreement.bullets" sxRow sxGap="10px"
                 sxMargin="0 0 10px"
            >
              <div sxBackground="dark" sxCircle="10px" sxMargin="6px 0"></div>
              <div sxGap="10px">
                <p sxMargin="0" sxColor="three" sxTextSize="medium" *ngIf="bullet.title">{{bullet.title}}</p>
                <p sxMargin="0" sxColor="two" sxTextSize="small" *ngIf="bullet.text">{{bullet.text}}</p>
              </div>
            </div>
          </div>

          <div sxRow sxWrap="wrap" sxGap="10px">
            <div *ngFor="let icon of agreement.icons">
              <img sxWidth="50px" sxHeight="50px" [src]="baseIconUrl + icon.imgName" alt="{{icon.imgName}}">
            </div>
          </div>

          <div sxRow sxAlign="center" sxColor="primary">
            <a>{{
              agreement.url
                ? agreement.urlButtonText
                : 'Add complete policy URL'
              }}</a>
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
          <div
            sxMargin="10px 0 0"
            sxBorderWidth="1px 0 0"
            sxBorderColor="one"
            sxRow
            sxAlign="center"
            sxDistribution="spaceBetween">
            <p>{{ agreement.checkboxTitle }}</p>
            <ion-checkbox></ion-checkbox>
          </div>
        </div>
        <div
          sxCol
          sxDistribution="center"
          sxAlign="center"
          sxWidth="fit"
          sxPadding="small"
          sxGap="10px">
          <ion-reorder></ion-reorder>
          <ion-icon
            (click)="selectAgreement(i)"
            sxCursor="pointer"
            name="create-outline"
            sxBackground="light"
            sxPadding="10px"
            sxRadius="50%"
            sxTextSize="22px"
            sxColor="three"></ion-icon>
          <ion-icon
            (click)="remove(i)"
            sxCursor="pointer"
            name="trash-outline"
            sxBackground="light"
            sxPadding="10px"
            sxRadius="50%"
            sxTextSize="22px"
            sxColor="three"></ion-icon>
        </div>
      </div>
    </ion-reorder-group>
    <super-button
      iconStart="add-outline"
      textAlign="start"
      type="outline"
      (click)="addPolicy()"
    >Add Policy
    </super-button>
  </div>
  <div
    sxWidth="40%"
    sxBorderColor="one"
    sxBorderWidth="0 0 0 1px"
    sxPadding="medium"
    sxHeight="100%" sxOverflow="scroll"
    *ngIf="selectedAgreementIndex !== null">
    <div sxWidth="full" sxCol sxGap="10px">
      <h3 sxMargin="20px 10px 15px">Edit Policy</h3>
      <ion-list>
        <ion-item>
          <ion-label position="stacked">Policy Title</ion-label>
          <ion-input
                  [(ngModel)]="
            collectionTemplateAgreements[selectedAgreementIndex].title
          "></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Description</ion-label>
          <ion-textarea
                  [(ngModel)]="
            collectionTemplateAgreements[selectedAgreementIndex].description
          "></ion-textarea>
        </ion-item>
      </ion-list>

      <ion-list sxBackground="light" sxRadius="10px">

        <h4 sxColor="two" sxMargin="10px 15px 5px">Bullets</h4>

        <div *ngFor="let bullet of collectionTemplateAgreements[selectedAgreementIndex].bullets;  let i = index"
             sxRow sxWidth="full" sxPadding="medium" sxGap="10px" sxBorderWidth="0 0 1px" sxBorderColor="one">
          <div sxBackground="black" sxCircle="10px" sxMargin="8px 0"></div>
          <div sxCol sxWidth="fill" sxGap="5px">
            <input sxBorderWidth="0" sxTextSize="medium" [(ngModel)]="bullet.title" placeholder="Heading">
            <input sxBorderWidth="0" sxTextSize="small" [(ngModel)]="bullet.text" placeholder="Text">
          </div>

          <ion-icon (click)="removeBullet(i)" sxCursor="pointer" name="close-outline"></ion-icon>

        </div>

        <ion-item (click)="addBullet()" sxColor="primary" sxCursor="pointer">
          <ion-icon sxColor="primary" name="add-outline"></ion-icon>
          <ion-label>Add Bullet</ion-label>
        </ion-item>

      </ion-list>

      <ion-list sxBackground="light" sxRadius="10px">

        <h4 sxColor="two" sxMargin="10px 15px 5px">Icons</h4>

        <div sxRow sxWrap="wrap" sxPadding="medium" sxGap="10px">

          <div class="icon-item" *ngFor="let icon of allIcons; let i = index" sxWidth="60px" sxHeight="60px"
               sxRadius="small"
               [class.iconItemSelected]="icon.isSelected" (click)="selectIcon(i)"
          >
            <ion-icon *ngIf="icon.isSelected" name="checkmark-circle"></ion-icon>
            <img [src]="baseIconUrl + icon.imgUrl">

          </div>
        </div>


      </ion-list>


      <ion-list>
        <ion-item>
          <ion-label position="stacked">Policy URL</ion-label>
          <ion-input
                  [(ngModel)]="collectionTemplateAgreements[selectedAgreementIndex].url"
                  placeholder="https://www.company.com/policy"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Policy URL Text</ion-label>
          <ion-input
                  [(ngModel)]="
            collectionTemplateAgreements[selectedAgreementIndex].urlButtonText
          "></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Checkbox Title</ion-label>
          <ion-input
                  [(ngModel)]="
            collectionTemplateAgreements[selectedAgreementIndex].checkboxTitle
          "></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Mandatory to Accept</ion-label>
          <ion-toggle
                  [(ngModel)]="collectionTemplateAgreements[selectedAgreementIndex].mandatory"
                  slot="end"></ion-toggle>
        </ion-item>
      </ion-list>
    </div>
  </div>
</div>
