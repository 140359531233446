import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {FaceIdApi} from "../../modules/face-id";
import {FaceCapture} from '../face-capture/face-capture.component';
import {DisplayService, ImageUtil} from "@qid/core";
import {FaceId} from "../../modules/face-id/model/face-id.model";
import {SID, SidService} from "../../modules/sid";
import {DigilockerComponent} from "../digilocker/digilocker.component";
import {AccountProfileFacade, AccountProfileStore} from "../../modules/account-profile";
import {Store} from '@ngrx/store';
import {DocumentAccessService} from "../../modules/document-access";
import {firstValueFrom} from 'rxjs';
import {AuthSelectors} from "../../modules/authentication";
import {AccountProfileApi} from "../../modules/account-profile/services/account-profile.api";
import {UsersFacade} from "../../modules/user";
import {ID} from "../../models/ID";
import {IdService} from "../../modules/id";


@Component({
  selector: 'verified-signup',
  templateUrl: './verified-signup.component.html',
  styleUrls: ['./verified-signup.component.scss'],
})
export class VerifiedSignupComponent {

  @ViewChild('faceCapture') faceCapture: FaceCapture
  @ViewChild('digilockerComponent') digilockerComponent: DigilockerComponent
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  public faceId: FaceId = null;
  public isFetchingFaceId = true;
  public sid: SID;
  public idVerificationStatus: 'started' | 'success' | 'failed' = null;

  public activeAccount$ = this.store.select(AuthSelectors.selectActiveAccount);
  public profile$ = this.profileFacade.profile$

  public verifiedAadhaar: ID = null

  public isProfileSlide = false;
  public isCreatingProfile = false

  constructor(private faceIdApi: FaceIdApi,
              private sidService: SidService,
              private profileFacade: AccountProfileFacade,
              private store: Store,
              private documentAccessService: DocumentAccessService,
              private profileApi: AccountProfileApi,
              private displayService: DisplayService,
              private userFacade: UsersFacade,
              private documentService: IdService
  ) {
  }

  ionViewWillEnter() {
    this.init()
  }

  async init() {

    const isProfileExists = await this.checkIfProfileExists()

    if (isProfileExists) {
      this.onSubmit.emit();
      return
    }

    this.isFetchingFaceId = true;
    const res = await this.faceIdApi.read();
    this.isFetchingFaceId = false;
    if (res && res.data) {
      this.faceId = res.data;
      this.onFaceIdConfirmed();
    }
  }


  async checkIfProfileExists(): Promise<boolean> {
    await this.profileFacade.init()
    return new Promise((resolve) => {
      this.store.select(AccountProfileStore.selectors.selectActiveAccountAdditionalData)
        .subscribe(data => {
          if (!data.isProfileFetched) {
            return
          }
          return resolve(
            data.profile != null);
        })
    })

  }

  onFaceCaptured(data: {  imageData: string, isLive: boolean }) {
    let image: any = {dataUrl: data.imageData}
    const file = ImageUtil.dataURLtoFile(
      image.dataUrl,
      new Date().getTime() + '.' + image.format
    );

    this.createFaceId(file)
  }

  private async createFaceId(file: File) {
    const res = await this.faceIdApi.create(file)
    if (res && res.data) {
      this.faceId = res.data
    }
    if (this.faceId) {
      this.onFaceIdConfirmed()
    }
  }


  onFaceIdConfirmed() {
    this.goToIdVerification();
  }

  async goToIdVerification() {

    await this.getAadhaarSid()
    this.idVerificationStatus = 'started'
    const ids = await this.documentService.readAll()
    const verifiedAadhaar = ids.find(id => id.sid.keyName == "ADHAR" && id.verificationTime != null)

    if (verifiedAadhaar) {
      this.verifiedAadhaar = verifiedAadhaar;
      return this.createProfile()
    }

    const account = await firstValueFrom(this.activeAccount$)
    this.digilockerComponent.init()
  }

  async getAadhaarSid() {
    this.sid = await this.sidService.readOneByKeyName("ADHAR")
  }

  createProfile = async () => {
    this.isProfileSlide = true;
    this.isCreatingProfile = true;
    const res = await this.profileApi.create()
    this.isCreatingProfile = false;
    if (res && res.data) {
      await this.profileFacade.refresh()
      this.userFacade.refresh()
      this.displayService.toast({
        message: "Verification successful",
        color: 'success',
        position: 'top'
      })
      this.onSubmit.emit();
    }
  }

  onFaceChangeClicked = async () => {
    await this.faceIdApi.delete();
    this.faceId = null;
    this.verifiedAadhaar = null;
  }

  onDigilockerDocumentFetched(document: ID) {
    this.createProfile();
  }

}
