import {Injectable} from "@angular/core";
import {CreateDocumentAccessDto} from "../dto/create-document-access.dto";
import {DocumentAccess} from "../model/document-access.model";
import {RestService} from "../../../services/rest.service";
import {AccountType, PaginatedResponse} from "@qid/core";

@Injectable()
export class DocumentAccessService {

  static readonly ApiEndpoint = 'document-access/'

  constructor(private restService: RestService) {
  }

  public create(dto: CreateDocumentAccessDto) {
    return this.restService.send<DocumentAccess>({
      url: DocumentAccessService.ApiEndpoint,
      method: "POST",
      body: dto
    })
  }

  public grantDocumentAccess(
    access_id: string, grantToAccount_id: string, grantToAccountType: AccountType,) {
    return this.restService.send<DocumentAccess>({
      url: DocumentAccessService.ApiEndpoint + 'grant-access',
      method: "POST",
      query: {
        access_id: access_id,
        grantToAccount_id: grantToAccount_id,
        grantToAccountType: grantToAccountType
      }
    })
  }

  public findByDocumentId(document_id: string) {
    return this.restService.send<DocumentAccess[]>({
      url: DocumentAccessService.ApiEndpoint + `find-by-document-id/${document_id}`,
      method: "GET",
    })
  }

  public findByAccountId(showChildOrg: boolean = true, page = 1) {
    return this.restService.send<PaginatedResponse<DocumentAccess>>({
      url: DocumentAccessService.ApiEndpoint + `find-by-account`,
      method: "GET",
      query: {
        page: page,
        showChildOrg : showChildOrg
      }
    })
  }

  public search(searchInput: string, page = 1) {
    return this.restService.send<DocumentAccess[]>({
      url: DocumentAccessService.ApiEndpoint + `search`,
      method: "GET",
      query: {
        searchInput,
        page
      }
    })
  }

  findAll(sid_id: string, documentOwnerId: string) {
    return this.restService.send<DocumentAccess[]>({
      url: DocumentAccessService.ApiEndpoint + "find",
      method: "GET",
      query: {
        sid_id: sid_id,
        documentOwnerId: documentOwnerId
      }
    })
  }

}

