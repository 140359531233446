import {Field} from '@qid/field';
import {AccountType} from "@qid/core";
import {Participant} from "../model/participant";
import {ShareStatus} from "../enum/share-status.enum";


export class CreateShareDto {

  scan: string;
  participants: Participant[]
  receiverEntityId: string;
  receiverEntityType: AccountType;
  otherFields: Field[];
  status: ShareStatus;
  template: string;
  signature: any;
  templateId: any;
  masterKeyWords: string[];
  masterKeyString: string;

  constructor() {

  }

}
