import {Injectable} from "@angular/core";
import {Signature} from "../model/Signature";
import {RestService} from "../../../services/rest.service";

@Injectable()
export class SignatureService {
  private static readonly ApiEndpoint = "signature/";
  private static readonly EndPointUser = SignatureService.ApiEndpoint + "user/";

  constructor(private restService: RestService) {
  }

  async create(imageFile, memberId: string) {
    let data = new FormData();
    data.append("files", imageFile);
    return this.restService.sendFiles<Signature>({
      url: SignatureService.ApiEndpoint,
      method: "POST",
      body: data,
      query: {
        familyMember_id: memberId
      }
    });
  }

  async findOneByUser() {
    return (
      await this.restService.send<Signature>({
        url: SignatureService.EndPointUser,
        method: "GET",
      })
    ).data;
  }
}
