import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxWrap]'})
export class WrapDirective implements OnInit {

    @Input() sxWrap: 'nowrap' | 'wrap' | 'wrap-reverse' | 'initial' | 'inherit' | string = "nowrap";

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        this.el.nativeElement.style.flexWrap = this.sxWrap
    }

}
