import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {DisplayService, TextUtil} from "@qid/core";
import {OtpService} from "../../services/otp.service";
import {VerifyOtpResponse} from "../../dto/verify-otp-response";
import {OtpVerifier} from "../../model/OtpVerifier";
import {SuperButtonComponent} from "@qid/super";
import {RestResponse} from "../../../../../interface/RestInterface";

@Component({
  selector: 'otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.scss'],
})
export class OtpVerifyComponent implements OnInit {

  @Input() otpVerifier: OtpVerifier;
  @Input() verificationCallback: (otpVerifier: OtpVerifier, otpInput : any) => Promise<boolean>;
  @Input() backText: string = "Back";
  @Input() resend: (any?) => {} = null;
  @Output() onBack: EventEmitter<any> = new EventEmitter();
  public timer: any;
  public otpInput: any
  public otpInputStyles = {width: '60px', height: '60px', fontSize: '25px', fontWeight: 'bold', borderRadius: '10px'}

  constructor(private modalController: ModalController,
              private displayService: DisplayService,
              private otpService: OtpService
  ) {
  }

  ngOnInit() {
    this.startTimer();
  }

  onInputChange($event: string, button: SuperButtonComponent) {
    this.otpInput = $event;
    if ($event.length == 4) {
      button.onClick()
    }

  }

  public onVerifyOtpClicked = async () => {

    if (!this.otpInput || this.otpInput.length < 4) {
      throw "Please enter 4 Digit OTP"
    }

    if (this.verificationCallback) {
      this.otpVerifier.isVerified = await this.verificationCallback(this.otpVerifier,this.otpInput);
    }

    if (!this.verificationCallback) {
      const res: RestResponse<VerifyOtpResponse> = await this.otpService.verify(this.otpVerifier.otpId, this.otpInput);

      if (!res.data) {
        throw res.errorMessage
      }

      this.otpVerifier.isVerified = res.data.isVerified;
    }

    if (this.otpVerifier.isVerified ) {
      await this.modalController.dismiss({verifier: JSON.parse(JSON.stringify(this.otpVerifier))})
    }

  }


  back() {
    this.modalController.dismiss()
  }

  private startTimer() {
    this.timer = 30;
    let timer = setInterval(() => {
      if (this.timer < 1) {
        clearInterval(timer);
        return;
      }
      --this.timer;
    }, 1000);
  }

  onResendClicked = async () => {
    if (this.timer > 0) {
      this.displayService.toast({message: `OTP can be sent after ${this.timer} seconds`})
      return;
    }
    this.startTimer();
    await this.otpService.sendOtp(this.otpVerifier)
  }

  getPhoneFormatted(phone: string) {
    return TextUtil.getFormattedPhoneNumber(phone);
  }
}
