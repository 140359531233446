import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ViewOrganisationUserPageRoutingModule} from './view-organisation-user-routing.module';
import {ViewOrganisationUserPage} from './view-organisation-user.page';
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";
import {CorePipeModule} from "@qid/core";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ViewOrganisationUserPageRoutingModule,
    SexyModule,
    SuperModule,
    CorePipeModule
  ],
  declarations: [ViewOrganisationUserPage]
})
export class ViewOrganisationUserPageModule {
}
