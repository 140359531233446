import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxAlign]'})
export class AlignDirective implements OnInit {
  @Input() sxAlign: 'start' | 'center' | 'end' | 'stretch';

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.setDistributionForCol()
  }

  setDistributionForCol() {
    switch (this.sxAlign) {
      case "start":
        this.el.nativeElement.style.alignItems = 'flex-start';
        break;
      case "end":
        this.el.nativeElement.style.alignItems = 'flex-end';
        break;
      case "center":
        this.el.nativeElement.style.alignItems = 'center';
        break;
      case "stretch":
        this.el.nativeElement.style.alignItems = 'stretch';
        break;
    }
  }


}
