export enum FieldInputType {
  'text' = 'text',
  'textarea' = 'textarea',
  'phone' = 'phone',
  'email' = 'email',
  'number' = 'number',
  'date' = 'date',
  'time' = 'time',
  'country' = 'country',
  'dropdown' = 'dropdown',
  // 'checkbox' = 'checkbox'
}
