import {ShareStatus} from "../enum/share-status.enum";

export class ReadShareQuery {
  keyword?: string;
  template_id?: string;
  startDate?: any;
  endDate?: any;
  paginated?: boolean;
  page?: number;
  status?: ShareStatus

  constructor(
    keyword: string = null,
    template_id: string = null,
    startDate: any = null,
    endDate: any = null,
  ) {
    this.keyword = keyword;
    this.template_id = template_id;
    this.startDate = startDate;
    this.endDate = endDate;
    this.page = 1
  }
}
