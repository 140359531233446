import {Component, Input, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";

@Component({
  selector: "app-super-select",
  templateUrl: "./super-select.component.html",
  styleUrls: ["./super-select.component.scss"],
})
export class SuperSelectComponent implements OnInit {
  @Input() options: string[];
  lazyOptions : string[] = []
  @Input() searchInput: string;
  @Input() title: string = "Select";
  @Input() value: any;
  @Input() labelKey: string;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    setTimeout(()=>{
      this.lazyOptions = this.options
    },200)
  }


  checkSearch(key) {
    if (!this.searchInput || this.searchInput.length < 1) {
      return false;
    }

    if (key.toLowerCase().indexOf(this.searchInput.toLowerCase()) < 0) {
      return true;
    }
  }
  searchInputValue(event) {
    const query = event.target.value.toLowerCase();
    this.lazyOptions = this.options.filter((d) => d.toLowerCase().indexOf(query) > -1);
  }

  close(value = null) {
    this.modalController.dismiss({
      value: value,
    });
  }

  onCheckBoxClicked(option: string) {
    this.value = option;
  }

  getOptions() {
    return this.options;
  }

  getLabel(option: any) {

    if (typeof option === 'string' || option instanceof String) {
      return option;
    }

    if (this.labelKey) {
      return option[this.labelKey]
    }

  }
}
