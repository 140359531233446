import {Injectable} from '@angular/core';
import {Organisation} from '../model/Organisation';
import {AddUserDto} from "../dto/add-user.dto";
import {CreateOrganisationDto} from '../dto/create-organisation.dto';
import {String} from 'typescript-string-operations';
import {UpdateOrganisationDto} from "../dto/update-organisation.dto";
import {ImageUtil} from "@qid/core";
import {Store} from "@ngrx/store";
import {BehaviorSubject} from "rxjs";
import {RestService} from "../../../../services/rest.service";
import {RestResponse} from '../../../../interface/RestInterface';


@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  static readonly NodeMain: string = 'organisation/';
  static readonly NodeId: string = OrganisationService.NodeMain + '{0}/';

  static readonly NodeUser: string = OrganisationService.NodeId + 'user/';
  static readonly NodeUserId: string = OrganisationService.NodeId + 'user/{1}';
  static readonly NodeParent: string = OrganisationService.NodeMain + 'parent?parentOrgId={0}&childOrgId={1}';
  static readonly NodeOrganisationDisplayPic: string =
    OrganisationService.NodeMain + 'display-pic?orgId={0}';

  constructor(private restService: RestService,
              private store: Store
  ) {

  }


  public async getUserOrganisations() {
    const orgs: any = (await this.restService.send({
      method: "GET",
      url: OrganisationService.NodeMain,
    })).data;
    return orgs;
  }


  //----- USER-------------------------------------------------------

  //CREATE
  async addUser(addUserDto: AddUserDto, orgId: string) {
    return (await this.restService.send({
      method: "POST",
      url: String.format(OrganisationService.NodeUser, orgId),
      body: addUserDto
    })).data;
  }


  async create(dto: CreateOrganisationDto) {
    const org: Organisation = (await this.restService.send<Organisation>({
        url: OrganisationService.NodeMain,
        body: dto,
        method: 'POST',
      }
    )).data;

    if (org != null && org._id != null) {
      await this.getUserOrganisations();
    }
    return org;
  }

  async delete(orgId: string) {
    return this.restService.send<{isDeleted : boolean}>(
      {
        url: OrganisationService.NodeMain + orgId,
        method: 'DELETE'
      }
    )
  }

  async updateDisplayPic(file, orgId): Promise<RestResponse<Organisation>> {
    //Compress Image
    const formData = await ImageUtil.getCompressedFormData([file]);

    //UPLOAD
    return this.restService.sendFiles<Organisation>({
      url: String.format(OrganisationService.NodeOrganisationDisplayPic, orgId),
      method: 'POST',
      body: formData
    })
  }

  async update(orgId: string, org: UpdateOrganisationDto) {
    const updateResponse = (await this.restService.send<Organisation>({
        url: String.format(OrganisationService.NodeId, orgId),
        body: org,
        method: 'PUT',
      }
    )).data;

    if (updateResponse != null && updateResponse._id) {
      // this.activeOrganisation.next(updateResponse);
    }
    return updateResponse;
  }

  async getOrgById(orgId: string) {
    return (await this.restService.send<Organisation>({
      url: OrganisationService.NodeMain + 'lite/' + orgId,
      method: "GET"
    })).data
  }

  async getChildOrganisations(parentOrgId: string) {
    return (await this.restService.send<Organisation[]>({
      method: 'GET',
      url: String.format(OrganisationService.NodeId + 'childOrganisations', parentOrgId),
    })).data;
  }

  async setParentOrganisation(parentOrgId: string, childOrgId: string) {
    return (await this.restService.send({
      method: 'POST',
      url: String.format(OrganisationService.NodeParent, parentOrgId, childOrgId),
    })).data;
  }

}
