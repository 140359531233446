import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {DisplayService} from "@qid/core";
import {FormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {SexyModule} from "@qid/sexy";

@Component({
  selector: 'super-button-icon',
  templateUrl: './super-button-icon.component.html',
  styleUrls: ['./super-button-icon.component.scss'],
})
export class SuperButtonIconComponent implements OnInit {
  isLoading: boolean = false;

  @Input() icon: string = 'trash-outline';
  @Input() color: string = 'primary';
  @Input() alertTitle: string = null;
  @Input() action: any = null;
  @Input() subTitle: string = null;
  @Input() params: any;
  @Output() onAction: EventEmitter<any> = new EventEmitter();

  constructor(private displayService: DisplayService) {}

  ngOnInit() {}

  async execute() {
    if (this.isLoading === true || !this.action) {
      return;
    }

    let res = null;
    if (this.alertTitle && this.alertTitle.length > 0) {
      await this.displayService.deleteAlert(
        async () => {
          res = await this.action(this.params);
        },
        this.alertTitle,
        this.subTitle
      );
    } else {
      this.isLoading = true;
      res = await this.action(this.params);
    }
    this.isLoading = false;
    this.onAction.emit(res);
  }
}
