import {NgModule} from "@angular/core";
import {PreloadAllModules, RouterModule, Routes} from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "rt",
    pathMatch: "full"
  },
  {
    path: "rt",
    loadChildren: () =>
      import("@qid/gateway").then(
        (m) => m.GatewayPageModule
      )
  },
  {
    path: "rt/:qr",
    loadChildren: () =>
      import("@qid/gateway").then(
        (m) => m.GatewayPageModule
      )
  },
  {
    path: "view-id",
    loadChildren: () =>
      import("@qid/common").then(
        (m) => m.ViewIdPageModule
      )
  },
  {
    path: "create-id",
    loadChildren: () =>
      import("@qid/common").then(
        (m) => m.CreateIdPageModule
      )
  },
  {
    path: "id-verification",
    loadChildren: () =>
      import("@qid/common").then(
        (m) => m.IdVerificationPageModule
      )
  },
  {
    path: 'name-correction',
    loadChildren: () => import('@qid/common').then(m => m.NameCorrectionPageModule)
  },
  {
    path: 'set-pin',
    loadChildren: () => import('./pages/set-pin/set-pin.module').then(m => m.SetPinPageModule)
  },
  {
    path: 'drawer',
    loadChildren: () => import('./pages/drawer/drawer.module').then(m => m.DrawerPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
