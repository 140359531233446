export class DeviceUtil{

  public static isPowerful(){
    try {
      const canvas = document.createElement("canvas");
      return !!window.WebGLRenderingContext && !!canvas.getContext("webgl");
    } catch (e) {
      return false;
    }
  }

}
