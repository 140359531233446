import {Component, Input} from '@angular/core';
import {AccountProfileUtil} from "../../util/account-profile.util";
import {AccountProfile} from "../../model/account-profile.model";
import {AccountAttributeType} from "../../../account-attribute";
import {Router} from "@angular/router";
import {Store} from '@ngrx/store';
import {AuthSelectors} from "../../../authentication";
import {map, Observable} from 'rxjs';

@Component({
  selector: 'account-profile-view',
  templateUrl: './account-profile-view.component.html',
  styleUrl: './account-profile-view.component.scss',
})
export class AccountProfileViewComponent {

  @Input() profile: AccountProfile = null

  public activeAccount$ = this.store.select(AuthSelectors.selectActiveAccount);

  public isOwner$: Observable<boolean> = this.activeAccount$.pipe(
    map(activeAccount => {
      // Null checks for both profile and activeAccount
      if (!this.profile || !activeAccount) {
        return false;
      }
      return this.profile.account_id === activeAccount._id;
    })
  );

  constructor(private router: Router,
              private store: Store
  ) {
  }

  protected readonly AccountProfileUtil = AccountProfileUtil;
  protected readonly AccountAttributeType = AccountAttributeType;


  async editProfileClicked() {
    this.router.navigateByUrl('edit-account-profile');
    //
    // const modal = await this.modalController.create({
    //   component: VerifiedSignupComponent,
    //   initialBreakpoint: 0.8,
    //   breakpoints: [0, 0.8, 1],
    // });
    //
    // await modal.present();
    // await modal.onDidDismiss();
  }
}
