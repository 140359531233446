import {inject, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {firstValueFrom} from "rxjs";
import {AccountWiseRecords} from "@qid/core";
import {
  CollectionTemplate,
  CollectionTemplateActions,
  CollectionTemplateSelectors,
  CollectionTemplateService,
  CreateCollectionTemplateDto
} from "../index";
import {createAccount} from "./collection-template.reducer";
import {AuthSelectors} from "../../authentication";


@Injectable()
export class CollectionTemplateRepository {

  //Boilerplate Starts
  private readonly store = inject(Store);
  private readonly apiService = inject(CollectionTemplateService)
  private ACTION = CollectionTemplateActions;
  private activeAccountRecords$ = this.store.pipe(select(CollectionTemplateSelectors.selectActiveAccountRecords));
  private activeAccount$ = this.store.pipe(select(CollectionTemplateSelectors.selectActiveAccount));

  async init() {
    const res = await firstValueFrom(this.activeAccount$);
    if (!await firstValueFrom(this.activeAccount$)) {
      await this.addAccount()
    }

  }

  private async addAccount() {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    const accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType));
    const accountBasedEntity: AccountWiseRecords<CollectionTemplate> = createAccount(account_id, accountType);
    this.store.dispatch(this.ACTION.addAccount({accountBasedEntity: accountBasedEntity}))
  }

  public async create(createCollectionTemplateDto: CreateCollectionTemplateDto) {

    const res = await this.apiService.create(createCollectionTemplateDto)

    if (res) {
      const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
      this.store.dispatch(this.ACTION.addRecord({record: res, account_id: account_id}))
    }

    return res
  }

  async updateRecord(record_id: string, updatedRecord) {
    const res = await this.apiService.update(updatedRecord);
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(this.ACTION.updateRecord({
      record_id: record_id,
      account_id: account_id,
      updatedRecord: updatedRecord
    }))
    return res
  };

  public async removeRecord(record_id: string) {
    const deleteRes = await this.apiService.remove(record_id)
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(this.ACTION.removeRecord({record_id: record_id, account_id: account_id}))
    return deleteRes
  }

  get allCollectionTemplates() {
    return this.activeAccountRecords$;
  }

  //Boilerplate Ends

}
