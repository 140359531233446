import {NgModule} from '@angular/core';
import {ShareService} from './services/share.service';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ShareComponentsModule} from './components/share-components.module';
import {ViewSharePageModule} from './pages/view-share/view-share.module';
import {ExportPdfService} from './services/export-pdf.service';
import {ExportExcelService} from './services/export-excell.service';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromShares from './+state/shares.reducer';
import {SharesEffects} from './+state/shares.effects';
import {SharesDataRepository} from './+state/shares-data-repository.service';
import {ShareMasterService} from "./services/share-master.service";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ShareComponentsModule,
    ViewSharePageModule,
    StoreModule.forFeature(
      fromShares.SHARES_FEATURE_KEY,
      fromShares.sharesReducer
    ),
    EffectsModule.forFeature([SharesEffects]),
  ],
  providers: [
    ShareService,
    ShareMasterService,
    ExportPdfService, ExportExcelService,
    SharesDataRepository],
})
export class ShareModule {
}
