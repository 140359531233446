import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxOverlayCenter]'})
export class OverlayCenterDirective implements OnInit {

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        try {
            this.el.nativeElement.style.position = 'absolute';
            this.el.nativeElement.style.top = '0';
            this.el.nativeElement.style.left = '0';
        } catch (e) {

        }
    }

}
