import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AccountProfileApi} from "../../services/account-profile.api";
import {AccountProfile} from "../../model/account-profile.model";
import {AccountProfileUtil} from "../../util/account-profile.util";
import {AccountAttributeType} from "../../../account-attribute";

@Component({
  selector: 'oneqid-nx-view-account-profile',
  templateUrl: './view-account-profile.page.html',
  styleUrls: ['./view-account-profile.page.scss'],
})
export class ViewAccountProfilePage implements OnInit {

  public profile : AccountProfile = null

  constructor(private activatedRoute: ActivatedRoute,
              private profileApi : AccountProfileApi
              ) {
  }

  ngOnInit() {
    this.fetchRouterData()
  }

  async fetchRouterData() {

    this.activatedRoute.params.subscribe(async (data) => {
      if (data && data["username"]) {
        console.log(data["username"])
        return this.fetchProfile(data["username"])
      }
    });
  }

  private async fetchProfile(username : string){
    const res= await this.profileApi.getOpenProfileByUsername(username)
    if(res && res.data){
      this.profile = res.data
    }
  }


  protected readonly AccountProfileUtil = AccountProfileUtil;
  protected readonly AccountAttributeType = AccountAttributeType;
}
