import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as SidActions from './sid.actions';
import * as SidSelectors from './sid.selectors';
import { firstValueFrom } from 'rxjs';
import { AccountWiseRecords } from '@qid/core';
import { createAccount } from './sid.reducer';
import { SID } from '../models/SID';
import { CreateSidDto } from '../dto/create-sid.dto';
import { SidService } from '../services/sid.service';
import { AuthSelectors } from '../../authentication';
import { SidPhoto } from '../models/SidPhoto';

@Injectable()
export class SidRepository {

  //Boilerplate Starts
  private readonly store = inject(Store);
  private readonly apiService = inject(SidService);
  private ACTION = SidActions;
  private activeAccountRecords$ = this.store.select(SidSelectors.selectActiveAccountRecords);
  private activeAccount$ = this.store.select(SidSelectors.selectActiveAccount);

  public allGlobalSid$ = this.store.pipe(select(SidSelectors.selectGlobalSids));


  async init() {
    const globalSids = await firstValueFrom(this.allGlobalSid$);
    if (!globalSids || globalSids.length < 1) {
      const res = await this.apiService.readAll();
      this.store.dispatch(SidActions.setGlobalSids({ sids: res }));
    }

    if (!await firstValueFrom(this.activeAccount$)) {
      await this.addAccount();
    }

  }

  private async addAccount() {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    if (!account_id) {
      return;
    }
    const accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType));
    const accountBasedEntity: AccountWiseRecords<SID> = createAccount(account_id, accountType);
    this.store.dispatch(this.ACTION.addAccount({ accountBasedEntity: accountBasedEntity }));
  }

  public async create(createCollectionTemplateDto: CreateSidDto) {
    createCollectionTemplateDto.photos.push(new SidPhoto());
    const res = await this.apiService.create(createCollectionTemplateDto);

    if (res && res.data) {
      const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
      this.store.dispatch(this.ACTION.addRecord({ record: res.data, account_id: account_id }));
    }

    return res;
  }

  async updateRecord(record_id: string, updatedRecord) {
    const res = await this.apiService.update(updatedRecord);
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(this.ACTION.updateRecord({
      record_id: record_id,
      account_id: account_id,
      updatedRecord: updatedRecord
    }));
    return res;
  };

  public async removeRecord(record_id: string) {
    const deleteRes = await this.apiService.delete(record_id);
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(this.ACTION.removeRecord({ record_id: record_id, account_id: account_id }));
    return deleteRes;
  }

  get allSids$() {
    return this.activeAccountRecords$;
  }

  //Boilerplate Ends

  updateSidLogo = async (sid_id: string, upload: any) => {
    const res = await this.apiService.updateSidLogo(upload, sid_id);
    if (res && res.data) {
      const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
      this.store.dispatch(this.ACTION.updateRecord({
        record_id: sid_id,
        account_id: account_id,
        updatedRecord: res.data
      }));
    }
    return res;
  };


  updateGlobalSidLogo = async (sid_id: string, upload: any) => {
    const res = await this.apiService.updateSidLogo(upload, sid_id);
    if (res && res.data) {
      this.refreshGlobalSids();
    }
    return res;
  };

  public async refreshGlobalSids() {
    const res = await this.apiService.readAll();
    this.store.dispatch(SidActions.setGlobalSids({ sids: res }));
  }
}
