import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Share, ShareParticipantUtil, ShareService} from "@qid/common";
import {ImageUtil} from "@qid/core";

@Component({
  selector: 'gateway-selfie-verification',
  templateUrl: './gateway-selfie-verification.component.html',
  styleUrls: ['./gateway-selfie-verification.component.scss'],
})
export class GatewaySelfieVerificationComponent {

  @Input() share: Share
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>()
  public mainParticipant: any;

  constructor(private shareService: ShareService) {
  }

  public init() {
    this.mainParticipant = ShareParticipantUtil.findMainParticipant(this.share?.participants)?.account;
  }

  onCaptured({imageData, isLive}) {

    let image: any = {dataUrl: imageData}

    const file = ImageUtil.dataURLtoFile(
      image.dataUrl,
      new Date().getTime() + '.' + image.format
    );

    this.uploadPhoto(file, isLive)
  }

  async uploadPhoto(file, isLive : boolean) {
    const res = await this.shareService.uploadFacePhoto(file, null)
    this.share.participants[1].facePhoto = {
      isLive: isLive,
      imageUrl: res.data.image
    }
    await this.shareService.update(this.share._id, {
      participants: this.share.participants,
      updateRemark: "Selfie Verification Submitted",
    })
    this.onSubmit.emit()
  }

}
