import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {IndustryService} from './services/industry.service';
import {IndustryComponentsModule} from "./components/industry-components.module";

@NgModule({
    imports: [IonicModule, CommonModule, IndustryComponentsModule],

    providers: [IndustryService],
})
export class IndustryModule {
}
