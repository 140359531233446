<ion-header>
    <ion-toolbar>
        <ion-button fill="clear" slot="start" (click)="close()">
            Cancel
        </ion-button>
        <ion-title>Send Invitation</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div
            sxRow
            sxWidth="full"
            sxDistribution="spaceBetween"
            sxPadding="medium"
            sxGap="10px">
        <a
                sxCol
                sxWidth="fill"
                sxBackground="light"
                sxRadius="medium"
                sxPadding="medium"
                sxAlign="center"
                sxDistribution="center"
                [href]="getSMSUrl()">
            <ion-icon sxTextSize="40px" slot="start" name="mail-outline"></ion-icon>
            <p sxTextSize="small" sxMargin="small 0">SMS</p>
        </a>
        <a
                sxCol
                sxWidth="fill"
                sxBackground="light"
                sxRadius="medium"
                sxPadding="medium"
                sxAlign="center"
                sxDistribution="center"
                (click)="getWhatsAppURL()">
            <ion-icon sxTextSize="40px" slot="start" name="logo-whatsapp"></ion-icon>
            <p sxTextSize="small">Whatsapp</p>
        </a>
        <button
                sxWidth="fill"
                sxBackground="light"
                sxRadius="medium"
                sxPadding="medium"
                (click)="copyToClipboard()">
            <ion-icon
                    sxTextSize="40px"
                    slot="start"
                    name="clipboard-outline"></ion-icon>
            <p sxTextSize="small">Copy Link</p>
        </button>
    </div>
</ion-content>
