import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";


@NgModule({
  imports: [IonicModule, BrowserModule, CommonModule, FormsModule, SexyModule, SuperModule],
})

export class FieldModule {

}
