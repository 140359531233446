import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {AbsoluteValue} from "../util/AbsoluteValue";

@Directive({selector: '[sxTextSize]'})
export class TextSizeDirective implements OnInit {
  @Input() sxTextSize: 'small' | 'medium' | 'large' | string;

  static readonly themeValues = {
    verySmall: "10px",
    small: "14px",
    medium: "18px",
    large: "20px"
  }

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    try {
      let fontSize = TextSizeDirective.themeValues[this.sxTextSize];
      if (!fontSize) {
        fontSize = this.sxTextSize
      }
      this.el.nativeElement.style.fontSize = fontSize
    } catch (e) {
      console.error("SX PADDING PROPERTY", e)
    }
  }


}
