import {AccountType} from "@qid/core";
import { SidPhoto } from '../models/SidPhoto';

export class CreateSidDto {
  name: string;
  issuer: string;
  isBackPhoto: boolean = false;
  account_id: string;
  accountType: AccountType;
  account: any;
  photos: SidPhoto[] = [];

  constructor() {
  }
}

