import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as SharesActions from './shares.actions';
import * as SharesSelectors from './shares.selectors';
import {firstValueFrom} from "rxjs";
import {AccountWiseRecords, DisplayService, PaginatedResponse, ValidationUtil} from "@qid/core";
import {Share} from "../model/Share";
import {createAccount} from "./shares.reducer";
import {ShareService} from "../services/share.service";
import {ReadShareQuery} from "../model/ReadShareQuery";
import {Browser} from "@capacitor/browser";
import {AlertController, LoadingController} from "@ionic/angular";
import { PlanPurchaseService } from '../../plan-purchase';
import { AuthSelectors } from '../../authentication';


@Injectable()
export class SharesDataRepository {

  private readonly store = inject(Store);
  private readonly apiService = inject(ShareService)
  private readonly loadingController = inject(LoadingController)
  private readonly displayService = inject(DisplayService)
  private readonly alertController = inject(AlertController)
  private readonly planPurchaseService = inject(PlanPurchaseService)

  allShares$ = this.store.select(SharesSelectors.selectActiveAccountShares);
  activeAccount$ = this.store.select(SharesSelectors.selectActiveAccount);
  pagination$ = this.store.select(SharesSelectors.selectActiveAccountPagination)

  async init() {

    if (!await firstValueFrom(this.activeAccount$)) {
      await this.addAccount()
    }

  }

  async fetchNextPage(query : ReadShareQuery = new ReadShareQuery()) {

    const pagination = await firstValueFrom(this.pagination$)
    if (!pagination.hasNext) {
      return;
    }

    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    const accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType));

    query.paginated = true;
    query.page = +pagination.page + 1;

    const res: PaginatedResponse<Share> = await this.apiService.readAll(query, account_id, accountType)

    if (res && res.docs) {
      this.store.dispatch(SharesActions.addNextPageRecords({
        account_id: account_id, accountType: accountType, records: res.docs, pagination: {...res, docs: null}
      }))
    }


  }

  async refresh(query : ReadShareQuery = new ReadShareQuery()) {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    const accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType));
    this.store.dispatch(SharesActions.loadSharesForAccount({account_id: account_id, accountType: accountType, query}))
  }

  private async addAccount() {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    if (!account_id) {
      return
    }
    const accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType));
    const accountBasedEntity: AccountWiseRecords<Share> = createAccount(account_id, accountType);
    this.store.dispatch(SharesActions.addAccount({accountBasedEntity: accountBasedEntity}))
  }

  public async delete(share_id: string) {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    const res = await this.apiService.delete(share_id)
    if (res.data) {
      this.store.dispatch(SharesActions.deleteShare({share_id: share_id, account_id: account_id}))
    }

    if (res.errorMessage) {
      this.displayService.toast({
        message: res.errorMessage,
        color: "danger",
        position: "bottom"
      })
    }
  }


  async addRecord(newRecord: Share) {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(SharesActions.addRecord({
      record_id: newRecord._id,
      account_id: account_id,
      record: newRecord
    }))
  }

  async update(updatedRecord: Share) {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(SharesActions.updateRecord({
      record_id: updatedRecord._id,
      account_id: account_id,
      updatedRecord: updatedRecord
    }))
  }


  public async handleQuotaLockedShareClick(share: Share, callback: (share: Share) => void) {
    const loader = await this.loadingController.create({
      message: 'Checking Quota to unlock this Collection',
    });
    loader.present();
    const res: Share = await this.apiService.unlock(share._id);
    loader.dismiss();
    if (res && !ValidationUtil.isTrue(res.isQuotaLocked)) {
      this.displayService.toast({
        header: 'Unlocked'
      });
      this.update({...share, isQuotaLocked: res.isQuotaLocked})
      return callback(share);
    }
    const alert = await this.alertController.create({
      header: 'Usage Quota Exceeded',
      subHeader: 'This ID exceeds the collection limit.',
      buttons: [
        {
          text: 'Upgrade Plan',
          handler: () => {
            this.planPurchaseService.openPlanUpgrade();
          },
        },
        {
          text: 'Contact Support',
          handler: () => {
            Browser.open({
              url: 'https://oneqid.com/support/',
              toolbarColor: '#f1f5f5',
              presentationStyle: 'popover',
            });
          },
        },
        {
          text: 'Cancel',
          handler: () => {
          },
        },
      ],
    });
    return alert.present();
  }

}
