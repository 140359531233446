import {User} from './User';
import {OtpChannel} from "../enum/OtpChannel";


export class OtpVerifier {
  receiverId: string;
  channel: OtpChannel;
  otpId?: string;
  otp?: string;
  user?: User;
  token?: string;
  returnToken?: boolean
  isVerified?: boolean;

  constructor(channel: OtpChannel, receiverId: string = null) {
    this.channel = channel
    this.receiverId = receiverId;
  }

}
