import {createFeatureSelector, createSelector} from '@ngrx/store';
import {accountAdapter, recordAdapter, SID_FEATURE_KEY, SidState} from './sid.reducer';
import { AuthSelectors } from '../../authentication';

// Lookup the 'Sid' feature state managed by NgRx
export const selectSidState = createFeatureSelector<SidState>(SID_FEATURE_KEY);

const {selectAll, selectEntities} = accountAdapter.getSelectors();
const recordsSelector = recordAdapter.getSelectors();

export const selectAllSid = createSelector(selectSidState, (state: SidState) =>
  selectAll(state)
);

export const selectGlobalSids = createSelector(
  selectSidState,
  (state: SidState) => state.globalSIDs
);

export const selectSidEntities = createSelector(
  selectSidState,
  (state: SidState) => selectEntities(state)
);

export const selectActiveAccount = createSelector(
  selectSidEntities,
  AuthSelectors.selectActiveAccountId,
  (entities, activeAccountId) => (activeAccountId ? entities[activeAccountId] : undefined)
);

export const selectActiveAccountRecords = createSelector(
  selectActiveAccount,
  (selectedAccount) => {
    return (selectedAccount ? recordsSelector.selectAll(selectedAccount.records) : undefined)
  }
);
