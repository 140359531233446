import {ValidationUtil} from "@qid/core";
import {DocumentData} from "../../models/document-data.model";
import { SID } from "../../../sid";


export class CreateIdPageUtil {


  static validateAndGetAllPhotosFile(sid: SID) {

    let files: any[] = [];

    let errorCount = 0;
    for (let photo of sid.photos) {
      if (!photo.file) {
        photo.errorMessage = photo.name + " is required";
        errorCount++;
        continue;
      }
      files.push(photo.file.file);
    }

    if (errorCount > 0) {
      throw new Error("Please upload the required images")
    }

    return files;
  }

  static validateManualFields(sid: SID, documentData: DocumentData) {
    let errorCount = 0;

    if (sid.manualFields) {
      for (let field of documentData.manualFields) {
        if (field.isMandatory && ValidationUtil.isStringEmpty(field.value)) {
          field.errorMessage = "Required";
        }
        if (field.errorMessage && field.errorMessage.length > 0) {
          errorCount++;
        }
      }
    }

    if (errorCount > 0) {
      throw "Please fill the required details";
    }
  }

  static autofillFieldsValue(extractedFields: any[], documentData: DocumentData) {
    if (!extractedFields || !documentData) {
      return;
    }
    for (let field of documentData.manualFields) {
      if (extractedFields[field.name]) {
        field.value = extractedFields[field.name];
        field.errorMessage = null
      }
    }
  }

  static maskIdNumber(idNumber: string) {

    if (!idNumber) {
      return null
    }

    let maskedIdNumber = idNumber
      .split('')
      .map((char, index) => {
        if (char === ' ' || index < 2 || index >= idNumber.length - 4) {
          return char;
        } else {
          return 'X';
        }
      })
      .join('');

    return maskedIdNumber;


  }


}
