<div class="custom-field-view" *ngIf="field">

  <button
    class="set-name-button"
    [class.placeholder]="!field.label || field.label.length < 2"
    (click)="onInput()">
    {{ field.label && field.label.length > 0 ? field.label : placeholder }}
  </button>


  <div class="options">
    <div class="mandatory-checkmark" *ngIf="field?.isEnabled && field.label && showMandatory">
      <p>Mandatory</p>
      <ion-checkbox [(ngModel)]="field.isMandatory"></ion-checkbox>
    </div>
    <ion-icon
      *ngIf="isRemove"
      name="close-circle-outline"
      (click)="remove()"></ion-icon>
    <ion-toggle *ngIf="isToggle" [(ngModel)]="field.isEnabled"></ion-toggle>
  </div>
</div>
