<div sxPadding="medium" *ngIf="profile" sxCol sxAlign="center" sxGap="10px">


  <div *ngIf="profile.name?.verificationMethod"
       sxRadius="50px" sxBorderWidth="1px" sxBorderColor="one" sxPadding="small medium">
    <p sxMargin="0" sxColor="two" sxTextSize="small">{{profile?.name?.verificationMethod}}</p>
  </div>

  <avatar-round
    [imageUrl]="profile.displayPictureUrl"></avatar-round>

  <div sxRow sxAlign="center" sxGap="5px">
    <div sxWidth="15px"></div>
    <h1 sxMargin="0" sxTextSize="large">{{profile?.name?.value}}</h1>
    <img sxWidth="25px" sxHeight="25px" src="assets/blue-tick.png">
  </div>

  <p sxTextSize="small" sxColor="two" sxTextAlign="center">{{profile.bio}}</p>


  <div *ngIf="!(isOwner$ | async)">
    <div sxRow sxGap="10px">
      <super-button size="v-small" type="outline">Save Contact</super-button>
      <super-button size="v-small">Connect</super-button>
    </div>
  </div>

  <div *ngIf="isOwner$ | async">
    <div sxMargin="0 0 20px" sxRow sxGap="8px">
      <button class="profile-button" (click)="editProfileClicked()">Edit</button>
      <button class="profile-button" sxCircle="40px" sxCenterize="col">
        <ion-icon sxTextSize="22px" name="share-outline"></ion-icon>
      </button>
      <button class="profile-button" sxCircle="40px" sxCenterize="col">
        <ion-icon sxTextSize="22px" name="qr-code-outline"></ion-icon>
      </button>
    </div>
  </div>


</div>
