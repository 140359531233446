import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DisplayService, FieldInputType, ValidationUtil} from '@qid/core';

@Component({
  selector: 'super-input-modal',
  templateUrl: './super-input-modal.component.html',
  styleUrls: ['./super-input-modal.component.scss'],
})
export class SuperInputModalComponent implements OnInit {

  @Input() title: string = 'Enter';
  @Input() inputValue: any;
  @Input() placeholder = 'Type Here';
  @Input() remark;
  @Input() isType: boolean = false;
  @Input() type: string = 'text';
  @Input() action: (value: any) => {} = null;

  inputTypes = Object.values(FieldInputType)
  @Input() selectedType = 'text';

  constructor(private modalController: ModalController,
              private displayService: DisplayService) {
  }

  ngOnInit() {
    // setTimeout(() => {
    //   if (this.input) {
    //     this.input.setFocus();
    //   }
    // }, 100);
  }

  ionViewDidEnter() {
  }

  close(data = null) {
    this.modalController.dismiss({
      data: data,
      type: this.selectedType,
    });
  }

  onSubmit = async () => {
    if (ValidationUtil.isStringEmpty(this.inputValue)) {
      return this.displayService.toast({message: `Enter ${this.title} value`, color: 'warning'});
    }
    if (this.action == null) {
      this.close(this.inputValue);
      return;
    }
    this.action(this.inputValue);
    this.close(this.inputValue);
  };

}
