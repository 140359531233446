export class SidPhoto {
  name: string;
  isMandatoryWithNoVerificationUpload: boolean;
  isMandatoryForVerification: boolean;
  file: any
  errorMessage: string = null;
  faceCheck: SidPhotoFaceCheck;
  testKeywords: string;
  testKeywordsForbidden : string
  constructor(
    name: string = 'photo'
  ) {
    this.name = name;
  }

}

export enum SidPhotoFaceCheck {
  no = 0,
  mayBe = 1,
  mustBe = 2
}
