import {NgModule} from '@angular/core';
import {RolePipe} from './role.pipe';

@NgModule({
    declarations: [RolePipe],
    providers: [],
    exports: [RolePipe],
})
export class CorePipeModule {
}
