import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {DisplayService} from "@qid/core";
import { SID } from '../../modules/sid';
import { DocumentAccess } from '../../modules/document-access';

@Component({
  selector: 'oneqid-nx-view-document-options',
  templateUrl: './view-document-options.page.html',
  styleUrls: ['./view-document-options.page.scss'],
})
export class ViewDocumentOptionsPage implements OnInit {

  @Input() sid : SID = null
  @Input() documentAccesses : DocumentAccess[] = []
  @Input() selectedAccess: DocumentAccess = null;
  @Input() onCreateDocument : ()=>{};



  constructor(private modalController : ModalController,
              private displayService : DisplayService
              ) { }

  ngOnInit() {
  }

  onAddNewClicked(){
    this.onCreateDocument()
    this.closeModal()
  }

  closeModal() {
    this.modalController.dismiss()
  }

  onDocSelected(access: any) {
    this.selectedAccess = access
    this.modalController.dismiss({
      selectedAccess : access
    })
  }
}
