import {Component} from '@angular/core';
import {OrganisationRelationship} from "../../model/organisation-relationship.model";
import {OrganisationRelationshipService} from "../../services/organisation-relationship.service";
import { AlertController, ModalController } from '@ionic/angular';


@Component({
  selector: 'edit-organisation-relationship-page',
  templateUrl: './edit-organisation-relationship-page.component.html',
  styleUrls: ['./edit-organisation-relationship-page.component.scss'],
})
export class EditOrganisationRelationshipPage {

  orgRelationship: OrganisationRelationship;

  constructor(private orgRelationshipService: OrganisationRelationshipService,
              private modalController: ModalController,
              private alertController: AlertController,
  ) {

  }

  showAlert = () => {
    this.alertController.create({
      header: 'Alert',
      message: `Are you sure you want to remove relationship from ${this.orgRelationship?.parentOrg?.name} ?` ,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'OK',
          handler: () => {
            this.removeRelation();
          },
        },
      ],
    }).then(alert => {
      alert.present();
    });
  };


  removeRelation = async () => {
    await this.orgRelationshipService.delete(this.orgRelationship._id)
    this.modalController.dismiss({deleted: true})
  }

}
