import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxBgSize]'})
export class BackgroundImageSize implements OnInit {

  @Input() sxBgSize: "cover" | "fit" | string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.style.backgroundSize = this.sxBgSize;
  }

}
