<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" slot="start" (click)="close()">
      Cancel
    </ion-button>
    <ion-title> {{ user?.email ? 'Update' : 'Add' }} Email</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div sxCol sxGap="10px" sxPadding="medium">
    <super-input
      sxWidth="full"
      type="email"
      iconStart="mail-outline"
      [(value)]="emailVerifier.receiverId"
      placeholder="Enter Email"></super-input>
    <super-button [action]="sendEmailOtp" [showError]="true">Send OTP</super-button>
  </div>



</ion-content>
