import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ViewAccountProfilePageRoutingModule } from './view-account-profile-routing.module';

import { ViewAccountProfilePage } from './view-account-profile.page';
import {SuperModule} from "@qid/super";
import {SexyModule} from "@qid/sexy";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ViewAccountProfilePageRoutingModule,
    SuperModule,
    SexyModule
  ],
  declarations: [ViewAccountProfilePage]
})
export class ViewAccountProfilePageModule {}
