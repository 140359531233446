import {inject, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {firstValueFrom} from "rxjs";
import {AccountWiseRecords} from "@qid/core";
import {IdService} from "../services/id.service";
import {createAccount} from "./documents.reducer";
import {DocumentsActions, DocumentsSelectors} from '..';
import {AuthSelectors} from "../../authentication";
import {ID} from "../../../models/ID";

@Injectable()
export class DocumentsRepository {

    //Boilerplate Starts
    private readonly store = inject(Store);
    private readonly apiService = inject(IdService)
    private ACTION = DocumentsActions;
    public activeAccountRecords$ = this.store.pipe(select(DocumentsSelectors.selectActiveAccountRecords));
    private activeAccount$ = this.store.pipe(select(DocumentsSelectors.selectActiveAccount));

    async init() {
        const res = await firstValueFrom(this.activeAccount$);
        if (!await firstValueFrom(this.activeAccount$)) {
            await this.addAccount()
        }

    }

    private async addAccount() {
        const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
        if (!account_id) {
            return
        }
        const accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType));
        const accountBasedEntity: AccountWiseRecords<ID> = createAccount(account_id, accountType);
        this.store.dispatch(this.ACTION.addAccount({accountBasedEntity: accountBasedEntity}))
    }

    async refresh() {
        const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
        const accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType));
        if (account_id) {
            this.store.dispatch(DocumentsActions.loadRecordsForAccount({account_id: account_id, accountType: accountType}))
        }
    }

    // public async create(createCollectionTemplateDto: CreateCollectionTemplateDto) {
    //
    //     const res = await this.apiService.create(createCollectionTemplateDto)
    //
    //     if (res) {
    //         const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    //         this.store.dispatch(this.ACTION.addRecord({record: res, account_id: account_id}))
    //     }
    //
    //     return res
    // }

    // public async removeRecord(record_id: string) {
    //     const deleteRes = await this.apiService.remove(record_id)
    //     const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    //     this.store.dispatch(this.ACTION.removeRecord({record_id: record_id, account_id: account_id}))
    //     return deleteRes
    // }

    //Boilerplate Ends


}
