import {Injectable} from '@angular/core';
import {OtpVerifier} from "../model/OtpVerifier";
import {ModalController} from "@ionic/angular";
import {HttpClient} from "@angular/common/http";
import { OtpUtil } from '../../../otp/utils/otp.util';
import {RestResponse} from "../../../../interface/RestInterface";

@Injectable()
export class OtpService {

  constructor(private modalController: ModalController,
              private http: HttpClient
  ) {
  }

  public async sendOtp(otpVerifier: OtpVerifier): Promise<RestResponse<any>> {
    return OtpUtil.sendOtp(otpVerifier, this.http)
  }

  public async verify(otpId: string, otp: string) {
    return OtpUtil.verify(otpId, otp, this.http)
  }

  public async verifyWithModal(otpVerifier: OtpVerifier, verificationCallback: (otpVerifier: OtpVerifier, otpInput: string) => Promise<boolean> = null) {
    return OtpUtil.verifyWithModal(otpVerifier, this.modalController, verificationCallback)
  }

  public async sendAndVerify(otpVerifier: OtpVerifier, verificationCallback: (otpVerifier: OtpVerifier, otpInput: string) => Promise<boolean> = null): Promise<OtpVerifier> {
    return OtpUtil.sendAndVerify(otpVerifier, this.http, this.modalController, verificationCallback)
  }


}
