import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import '@tensorflow/tfjs';
import { IonSelect, ModalController } from '@ionic/angular';
import { CropperComponent } from 'angular-cropperjs';
import { ImageCropperComponent } from '../image-croppper/image-cropper.component';
import { log } from '@tensorflow/tfjs';
import { DisplayService } from '@qid/core';


@Component({
  selector: 'document-capture',
  templateUrl: './super-document-capture.component.html',
  styleUrls: ['./super-document-capture.component.scss']
})
export class SuperDocumentCaptureComponent {
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('rotate', { static: false }) rotate: ElementRef<HTMLCanvasElement>;
  rotation = 0;
  image: string | ArrayBuffer = null;

  @ViewChild('imageCropperComponent') imageCropperComponent: ImageCropperComponent;

  @ViewChild('video')
  public video: ElementRef;

  @ViewChild('cameraResultCanvas')
  public cameraResultCanvas: ElementRef;

  @ViewChild('cameraSourceSelect')
  public cameraSourceSelect: IonSelect;

  @ViewChild('angularCropper')
  public angularCropper: CropperComponent;

  @Input()
  public imageTitle: string = null;

  cameraStarted:boolean = false;
  public devices: any[] = [];
  public selectedDevice: any = { label: 'Default Camera' };
  public cameraCapturedImageData = '';
  public resultImage: string = null;


  public cropperConfig = {
    initialAspectRatio: 4 / 3,
    cropBoxMovable: false
  };

  public slides = {
    camera: 'camera',
    crop: 'crop',
    preview: 'preview'
  };

  public activeSlide = this.slides.camera;

  constructor(private modalController: ModalController,
              private displayService: DisplayService,) {
  }

  async ngAfterViewInit() {
    await this.setupDevices();
  }

  rotateImage() {
    const radians = this.rotation * (Math.PI / 180);
    const offscreenCanvas = document.createElement('canvas');
    const offscreenCtx = offscreenCanvas.getContext('2d');

    return new Promise<string>((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        offscreenCanvas.width = image.width;
        offscreenCanvas.height = image.height;

        offscreenCtx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);

        offscreenCtx.translate(offscreenCanvas.width / 2, offscreenCanvas.height / 2);
        offscreenCtx.rotate(radians);
        offscreenCtx.translate(-image.width / 2, -image.height / 2);
        offscreenCtx.drawImage(image, 0, 0);
        const rotatedDataUrl = offscreenCanvas.toDataURL('image/png');
        resolve(rotatedDataUrl);
      };

      image.onerror = (error) => {
        reject(error);
      };
      image.src = this.resultImage;
    });
  }

  handleRotate() {
    this.rotation = (this.rotation + 90) % 360;
    this.rotateImage().then(r => this.resultImage = r);
  }


  async setupDevices() {

    await navigator.mediaDevices.getUserMedia({ audio: false, video: true });

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {

        this.devices = await navigator.mediaDevices.enumerateDevices();

        this.selectedDevice = this.devices.find(device => device.deviceId === localStorage.getItem('currentCam'));

        if (!this.selectedDevice) {
          this.selectedDevice = this.devices[1];
        }

        return this.startCamera();

      } catch (e) {
        console.log('QID | Error Starting Webcam', e);
      }
    }
  }

  onDeviceChanges() {
    localStorage.setItem('currentCam', this.selectedDevice.deviceId);
    return this.startCamera();
  }

  startCamera = async () => {

    let context: any = {
      video: this.selectedDevice?.deviceId ?
        { deviceId: this.selectedDevice.deviceId } : true
    };

    navigator.mediaDevices.getUserMedia(context).then((stream) => {
      this.cameraStarted = true
      this.video.nativeElement.srcObject = stream;
      this.video.nativeElement.play();
    });
  };


  onCaptureClicked() {
    if (this.cameraStarted) {
      this.takePicture(this.video.nativeElement);
      this.goToSlide(this.slides.crop);
    }else{
      this.displayService.toast({message:'Wait for camera to turn ON' , color:'warning'})
    }
  }

  takePicture(videoElement: any) {

    try {
      const width = videoElement.videoWidth;
      const height = videoElement.videoHeight;
      const context = this.cameraResultCanvas.nativeElement.getContext('2d');

      this.cameraResultCanvas.nativeElement.width = width;
      this.cameraResultCanvas.nativeElement.height = height;
      context.drawImage(this.video.nativeElement, 0, 0, width, height);
      this.image = videoElement;
      this.cameraCapturedImageData = this.cameraResultCanvas.nativeElement.toDataURL('image/png');

    } catch (e) {
      console.log('QID | Error Capturing Image', e);
    }

  }

  async onCropClicked() {
    this.imageCropperComponent.cropImage();
    this.goToSlide(this.slides.preview);
  }

  setCroppedImageUrl(e: string) {
    this.resultImage = e;
  }

  onCameraSourceChangeClicked() {
    return this.cameraSourceSelect.open();
  }

  onRetakeClicked() {
    this.resultImage = null;
    this.cameraCapturedImageData = null;
    this.goToSlide(this.slides.camera);
    return this.startCamera();
  }

  onSaveImageClicked() {
    return this.modalController.dismiss({
      imageData: this.resultImage
    });
  }

  goToSlide(slide: string) {
    this.activeSlide = slide;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Space') {
      this.onCaptureClicked();
    }
  }

  async close() {
    await this.modalController.dismiss();
  }
}
