<ion-header>
  <ion-toolbar>
    <ion-button slot="start" color="dark" fill="clear" (click)="closeModal()">Cancel</ion-button>
    <ion-title>Change Document</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div sxPadding="medium" sxCol sxGap="10px">

    <h1 sxPadding="15px 10px 0" sxMargin="0"
        sxTextWeight="normal" sxTextSize="small" sxColor="two">Saved {{sid.name}}</h1>
    <div *ngFor="let access of documentAccesses"
    sxRow sxAlign="center" sxDistribution="spaceBetween" sxBackground="light" sxPadding="medium" sxRadius="medium" sxBorderColor="one"
    sxBorderWidth="1px">
      <div sxRow sxAlign="center" sxGap="10px">
        <div sxWidth="60px" sxHeight="60px" sxRadius="medium" [sxBackgroundImage]="access.documentData.scannedImages[0].imageUrl" [sxBackgroundImageAutoUrl]="true"
             sxBgPosition="center" sxBgSize="cover"></div>
        <div sxCol sxGap="5px">
          <p sxMargin="0" sxTextSize="14px" sxColor="three">{{access.documentData.idNumber || "XXXX XXXX XXXX"}}</p>
          <p sxMargin="0" sxTextSize="small" sxColor="two">Saved on {{access.createTime | date}}</p>
        </div>
      </div>
      <ion-checkbox [checked]="access?._id == selectedAccess?._id" (ionChange)="onDocSelected(access)" ></ion-checkbox>
    </div>

    <div class="or-label"><p>OR</p></div>

    <super-button
      (click)="onAddNewClicked()"
      sxMargin="10px 0"
      iconEnd="add-circle" textAlign="start" color="dark" background="light">
      Add New {{sid.name}}</super-button>

  </div>

</ion-content>
