<div sxBackground="light" sxRadius="medium" sxPadding="medium" sxRow sxDistribution="spaceBetween" sxAlign="center"
     sxBorderColor="one" sxBorderWidth="1px">
  <div sxCol sxGap="5px">
    <p sxMargin="0" sxTextSize="small" sxColor="two">{{label}}</p>
    <p sxMargin="0">{{attribute?.value || '-'}}</p>

  </div>
  <div sxRow sxAlign="center">
    <div *ngIf="attribute && attribute.verificationMethod" sxBorderColor="one" sxBorderWidth="1px"
         sxColor="two" sxRadius="medium" sxPadding="small 8px" sxRow sxGap="3px">
      <ion-icon name="shield-checkmark-outline"></ion-icon>
      <p sxTextSize="small" sxMargin="0">{{attribute?.verificationMethod}}</p>
    </div>
    <div *ngIf="!attribute || !attribute?.value" sxBorderColor="primary" sxBorderWidth="1px"
         sxColor="primary" sxRadius="medium" sxPadding="small 8px" sxRow sxGap="3px">
      <ion-icon name="add-outline"></ion-icon>
      <p sxTextSize="small" sxMargin="0">Add</p>
    </div>
  </div>

</div>
