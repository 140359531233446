import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxTextDecoration]'})
export class TextDecorationDirective implements OnInit {

  @Input() sxTextDecoration: string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    try {
      this.el.nativeElement.style.textDecoration = this.sxTextDecoration
    } catch (e) {
      console.error("SX TEXT DECORATION PROPERTY", e)
    }
  }


}
