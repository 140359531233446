<div *ngIf="id && id.sid?.keyName" sxPadding="medium">

  <div *ngIf="id.sid.keyName == sidKeys.pan">
    <div sxBackgroundImage="assets/imgs/pan-bg.png" sxBgPosition="top" sxBgSize="cover" sxRadius="medium">
      <div sxHeight="60px"></div>
      <p sxWidth="full" sxTextAlign="center" sxTextSize="10px" sxTextWeight="bold">
        Permanent Account Card Number <br> <span sxTextSize="20px">{{id.documentData.idNumber}}</span></p>
      <p sxTextSize="10px" sxTextWeight="bold" sxMargin="small medium">Name <br> <span
        sxTextSize="14px">{{id?.documentData?.fullName}}</span></p>
      <p sxTextSize="10px" sxTextWeight="bold" sxMargin="small medium">Date of Birth <br> <span
        sxTextSize="14px">{{id?.documentData?.dob}}</span></p>
      <div sxHeight="30px"></div>
    </div>
  </div>

  <div *ngIf="id.sid.keyName == sidKeys.aadhaar">
    <div sxBackground="light" sxCol sxRadius="medium" class="ad-wrapper" sxPadding="medium">
      <img src="assets/imgs/ad-header_1.png">
      <div sxRow>
        <img sxHeight="120px" src="data:image/png;base64,{{id?.documentData?.userPhotoURI}}">
        <div sxCol sxDistribution="start" sxAlign="start" sxWidth="fill" sxPadding="medium">
          <p sxMargin="0">{{id?.documentData?.fullName}}</p>
          <p sxMargin="0">DOB: {{id?.documentData?.dob}}</p>
          <p sxMargin="0">{{id?.documentData?.gender}}</p>
          <p sxMargin="5px 0 0 0" sxTextSize="12px">{{id?.documentData?.careOf}}</p>
          <p sxMargin="0" sxTextSize="12px">{{getAddressLine()}}</p>
        </div>
      </div>
      <div>
        <h1 sxWidth="full" sxTextSize="24px" sxTextAlign="center">{{getStyledIdNumber(id?.documentData?.idNumber)}}</h1>
      </div>
      <img sxWidth="full" src="assets/imgs/img.png">
    </div>
  </div>

  <div *ngIf="id.sid.keyName == sidKeys.dl">
    <div sxBackgroundImage="assets/imgs/dl-bg.png" sxBgPosition="top" sxBgSize="cover" sxCol sxAlign="stretch"
         sxRadius="medium" class="ad-wrapper"
         sxPadding="medium">

      <div sxCol sxAlign="stretch">
        <h2 sxTextAlign="center" sxTextSize="16px"><span sxMargin=" 0 5px 0 0" sxTextSize="12px">UNION OF INDIA</span>Driving
          License</h2>
        <h3 sxMargin="5px 0" sxTextWeight="bold" sxTextSize="18px"
            sxTextAlign="center">{{id.verifiedIdData.idNumber}}</h3>
      </div>

      <div sxRow sxDistribution="spaceBetween" sxAlign="center">
        <div sxCol>
          <img sxWidth="70px" src="assets/imgs/card-chip.png">
        </div>
        <div sxWidth="fill" sxRow sxPadding="0 15px">
          <div sxCol sxWidth="fill">
            <div>
              <p sxMargin="2px 0" sxTextSize="10px">Date of Issue</p>
              <h2 sxTextSize="12px">{{id.verifiedIdData.extra.doi}}</h2>
            </div>
            <div>
              <p sxMargin="2px 0" sxTextSize="10px">Date of Birth</p>
              <h2 sxTextSize="12px">{{id.verifiedIdData.dob}}</h2>
            </div>
          </div>
          <div sxCol sxWidth="fill">
            <div>
              <p sxMargin="2px 0" sxTextSize="10px">Validity</p>
              <h2 sxTextSize="12px">{{id.verifiedIdData.expiry}}</h2>
            </div>
            <div>
              <p sxMargin="2px 0" sxTextSize="10px">Blood Group</p>
              <h2 sxTextSize="12px">{{id.verifiedIdData.extra.blood_group}}</h2>
            </div>
          </div>
        </div>
        <img sxHeight="100px" src="data:image/png;base64,{{id?.verifiedIdData?.userPhotoURI}}">
      </div>

      <div sxRow>
        <div sxWidth="fill">
          <p sxMargin="2px 0" sxTextSize="10px">Name</p>
          <h2 sxTextSize="12px">{{id.documentData.fullName}}</h2>
        </div>
        <div sxWidth="fill">
          <p sxMargin="2px 0" sxTextSize="10px">Son/Daughter/Wife of</p>
          <h2 sxTextSize="12px">{{id.documentData.careOf}}</h2>
        </div>
      </div>

      <div sxRow sxGap="10px" sxAlign="end">
        <p sxMargin="0" sxTextSize="10px">Vehicle Classes:</p>
        <p sxMargin="0" sxTextSize="12px" sxTextWeight="bold"
           *ngFor="let vc of id.documentData.extra.vehicle_classes">{{vc}}</p>
      </div>
      <div sxWidth="fill">
        <p sxMargin="2px 0" sxTextSize="10px">Address</p>
        <h2 sxTextSize="12px">{{id.documentData.address.addressLine}}</h2>
      </div>


    </div>
  </div>


</div>
