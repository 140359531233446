import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {AbsoluteValue} from "../util/AbsoluteValue";

@Directive({selector: '[sxRadius]'})
export class RadiusDirective implements OnInit {

  @Input() sxRadius: 'xSmall' | 'small' | 'medium' | 'large' | string;

  static readonly themeValues = {
    xSmall : "5px",
    small: "12px",
    medium: "18px",
    large: "20px"
  }


  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    try {
      this.el.nativeElement.style.overflow = 'hidden'
      this.el.nativeElement.style.borderRadius = AbsoluteValue.getSplitValues(this.sxRadius, RadiusDirective.themeValues);
    } catch (e) {
      console.error("SX RADIUS PROPERTY", e)
    }
  }


}
