import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ListChildOrgsPage} from './list-child-orgs.page';

const routes: Routes = [
  {
    path: '',
    component: ListChildOrgsPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ListChildOrgsPageRoutingModule {
}
