import {ScannerTemplateOptions} from "../../../models/scanner-template-options";

export class CreateScannerDto {
  qr: string;
  scannerTemplateOptions : ScannerTemplateOptions[]
  commonTitle: string;
  org : string;
  constructor(qr: string, scannerTemplateOptions: ScannerTemplateOptions[], commonTitle: string) {
    this.qr = qr;
    this.scannerTemplateOptions = scannerTemplateOptions;
    this.commonTitle = commonTitle;
  }
}
