import {DateRange} from "../enums/DateRange";
import parser from 'any-date-parser';
import * as dayjs from 'dayjs';
export class DateUtil {

  static getPreviousMonthStart(previous) {
    let date = new Date();
    let month = date.getMonth() - previous;
    let year = date.getFullYear();

    if (month < 0) {
      month = 12 + month;
      year = year - 1;
    }

    date.setDate(1);
    date.setMonth(month);
    date.setFullYear(year);

    return date;
  }

  static getSevenDaysBefore(today = new Date()) {
    let date = new Date(today);
    date.setDate(date.getDate() - 7);  // Subtract 7 days
    date.setHours(0, 0, 0, 0);  // Set time to the start of the day (00:00:00.000)
    return date;
  }

  static getPreviousMonthEnd(previous = 1) {
    let date = new Date();
    let month = date.getMonth() - previous;
    let year = date.getFullYear();

    if (month < 0) {
      month = 12 + month;
      year = year - 1;
    }

    date.setDate(0);
    date.setMonth(month);
    date.setFullYear(year);

    return date;
  }

  public static getStartOfPeriod = (period : 'day' | 'week' | 'month' | 'year', date = new Date()) => {
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    switch (period.toLowerCase()) {
      case 'day':
        return startOfDay;

      case 'week': {
        const startOfWeek = new Date(startOfDay);
        const dayOfWeek = startOfWeek.getDay(); // 0 = Sunday
        startOfWeek.setDate(startOfWeek.getDate() - dayOfWeek); // Adjust to Sunday (start of the week)
        return startOfWeek;
      }

      case 'month':
        return new Date(date.getFullYear(), date.getMonth(), 1);

      case 'year':
        return new Date(date.getFullYear(), 0, 1);

      default:
        throw new Error('Invalid period. Choose "day", "week", "month", or "year".');
    }
  };

  static getStartOfThisYear() {
    let date = new Date();
    date.setMonth(0);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  static getDateRange(selectedRangeOption) {
    let startDate = new Date();
    let endDate = new Date();
    switch (selectedRangeOption) {
      case DateRange.THIS_MONTH: {
        startDate.setDate(1);
        break;
      }
      case DateRange.WEEK: {
        startDate = DateUtil.getSevenDaysBefore();
        break;
      }
      case DateRange.PREVIOUS_MONTH: {
        startDate = DateUtil.getPreviousMonthStart(1);
        endDate = DateUtil.getPreviousMonthEnd();
        break;
      }
      case DateRange.LAST_3_MONTH: {
        startDate = DateUtil.getPreviousMonthStart(3);
        break;
      }

      case DateRange.CURRENT_YEAR: {
        startDate = DateUtil.getStartOfThisYear();
        break;
      }

      case DateRange.CUSTOM: {
      }
    }

    return {
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
    };
  }
  static getCurrentFirestoreTimestamp(): { seconds: number, nanoseconds: number } {
    const now = new Date();
    const seconds = Math.floor(now.getTime() / 1000);
    const nanoseconds = (now.getTime() % 1000) * 1000000;
    return { seconds, nanoseconds };
  }

  static convertAndFormatTimestamp(timestamp: { seconds: number, nanoseconds: number }): string {
    const { seconds, nanoseconds } = timestamp;
    const date = new Date(seconds * 1000 + nanoseconds / 1000000);
    return DateUtil.formatDate(date);
  }

  static formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };
    return date.toLocaleString('en-US', options);
  }

  public static getCurrentPreciseTime() {
    const date = new Date();
    return (
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds() +
      ':' +
      date.getMilliseconds()
    );
  }
  public static getCurrentTime() {
    const date = new Date();
    return (
      date.getHours() +
      ':' +
      ('0' + date.getMinutes()).slice(-2)
    );
  }

  public static getCurrentTime24() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');  // Get hours and pad with zero if necessary
    const minutes = String(now.getMinutes()).padStart(2, '0');  // Get minutes and pad with zero if necessary
    return `${hours}:${minutes}`;
  }

  public static getTimeDifferenceInSeconds(startTime: Date, endTime = new Date()) {
    return Math.abs((endTime.getTime() - startTime.getTime()) / 1000);
  }

  public static getTimeDifferenceInDays(startTime: Date, endTime = new Date()): number {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    return Math.abs((endTime.getTime() - startTime.getTime()) / millisecondsPerDay);
  }

  public static getAge(dob : any) {
    const parsedData = parser.fromString(dob);
    if(!parsedData.isValid()) {
      return null;
    }
    const currentYear = new Date().getFullYear();
    return currentYear - parsedData.getFullYear();
  }

  static getAgeByBirthYear(yearOfBirth: number) {
    if(!yearOfBirth) {
      return null;
    }
    const currentYear = new Date().getFullYear();
    return currentYear - yearOfBirth;
  }
}
