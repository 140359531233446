import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import { DisplayService, OrganisationUserRoles, ValidationUtil } from '@qid/core';
import {CreateOrganisationUserDto} from "../../dto/create-organisation-user.dto";
import {Store} from "@ngrx/store";
import {firstValueFrom} from "rxjs";
import {User, UserService} from "../../../user";
import {OrganisationUserDataRepository} from "../../+state/organisation-user-data-repository.service";
import {OrganisationUserSelectors} from "../../index";

@Component({
  selector: 'app-add-org-user',
  templateUrl: './add-org-user.page.html',
  styleUrls: ['./add-org-user.page.scss'],
})
export class AddOrgUserPage implements OnInit {
  roles = OrganisationUserRoles;
  addUserDto: CreateOrganisationUserDto = new CreateOrganisationUserDto();
  slides = {
    inputEmail: 'inputEmail',
    inputName: 'inputName',
    inputRole: 'inputRole'
  }
  activeSlide: any = this.slides.inputEmail;
  userToBeAdded: User = null;

  constructor(
    private organisationUserRepository: OrganisationUserDataRepository,
    private modalController: ModalController,
    private displayService: DisplayService,
    private userService: UserService,
    private store: Store
  ) {
  }

  ngOnInit() {
  }

  async isUserAlreadyAMember(email: string) {
    const organisationUsers = await firstValueFrom(this.store.select(OrganisationUserSelectors.selectActiveAccountOrgUsers))
    for (let orgUser of organisationUsers) {
      if (orgUser.user?.email == email) {
        return true;
      }
    }
    return false;
  };

  fetchName = async () => {

    ValidationUtil.validateEmail(this.addUserDto.email)

    if (await this.isUserAlreadyAMember(this.addUserDto.email)) {
      throw `${this.addUserDto.email} is already an added Member`
    }

    const user = await this.userService.checkEmail(this.addUserDto.email);
    this.userToBeAdded = null;
    if (user) {
      this.userToBeAdded = user;
      this.addUserDto.name = user.name;
    }
    this.goToSlide(this.slides.inputName);
  };

  addUser = async () => {
    if (!this.addUserDto.role){
      return this.displayService.toast({message:'user role required',color:'warning'})
    }
    const res = await this.organisationUserRepository.create(this.addUserDto);
    if (res && res.data) {
      this.dismiss();
    }
    else {
      this.displayService.toast({
        message: 'Your plan does not support more members. Please Upgrade your plan.',
        color: 'danger'
      });
      this.dismiss();
    }
  };

  dismiss() {
    this.modalController.dismiss();
  }

  goToSlide(slide: string) {
    if (this.activeSlide === this.slides.inputName){
      if (this.userToBeAdded === null && ValidationUtil.isStringEmpty(this.addUserDto.name)){
        return this.displayService.toast({message:'user name required',color:'warning'})
      }
    }
    this.activeSlide = slide;
  }
}
