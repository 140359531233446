import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {SidViewComponent} from "./sid-view/sid-view.component";
import {IdService} from "../services/id.service";
import {SexyModule} from "@qid/sexy";
import {IdCardComponent} from "./id-card/id-card.component";
import {IdUploadComponent} from "./id-upload/id-upload.component";
import {IdImagePickerComponent} from "./id-upload-input/id-image-picker.component";
import {SuperModule} from "@qid/super";


@NgModule({
    declarations: [
        IdCardComponent,
        IdUploadComponent,
        SidViewComponent,
        IdImagePickerComponent,
    ],
  imports: [IonicModule, CommonModule, SexyModule, SuperModule],
    exports: [
        IdCardComponent,
        IdUploadComponent,
        SidViewComponent,
        IdImagePickerComponent,
    ],
  providers: [IdService, DatePipe],
})
export class IdComponentsModule {
}
