import {EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {AccountWiseRecords, createAccountWiseEntityAdapters} from "@qid/core";
import {CollectionTemplate} from "../models/CollectionTemplate";
import {CollectionTemplateActions} from '../index';


export const COLLECTION_TEMPLATE_FEATURE_KEY = 'collectionTemplate';

export interface CollectionTemplateState extends EntityState<AccountWiseRecords<CollectionTemplate>> {

}

export const {
  accountAdapter,
  recordAdapter,
  createAccount
} = createAccountWiseEntityAdapters<CollectionTemplate>(COLLECTION_TEMPLATE_FEATURE_KEY)

const recordSelectors = recordAdapter.getSelectors();

export const initialCollectionTemplateState: CollectionTemplateState =
  accountAdapter.getInitialState({});

const reducer = createReducer(
  initialCollectionTemplateState,

  // Boilerplate Starts
  on(CollectionTemplateActions.addAccount, (state, {accountBasedEntity}) => {
    return accountAdapter.addOne(accountBasedEntity, state)
  }),
  on(CollectionTemplateActions.loadRecordsForAccountSuccess, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.setAll(action.records, state.entities[action.account_id].records)
      }
    }, state)
  }),
  on(CollectionTemplateActions.addRecord, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.setAll([action.record, ...recordSelectors.selectAll(state.entities[action.account_id].records)], state.entities[action.account_id].records)
      }
    }, state)
  }),
  on(CollectionTemplateActions.updateRecord, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.updateOne({
          id: action.record_id,
          changes: {
            ...action.updatedRecord
          }
        }, state.entities[action.account_id].records)
      }
    }, state)
  }),
  on(CollectionTemplateActions.removeRecord, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.removeOne(action.record_id, state.entities[action.account_id].records)
      }
    }, state)
  })
  // Boilerplate Ends

);

export function collectionTemplateReducer(
  state: CollectionTemplateState | undefined,
  action: Action
) {
  return reducer(state, action);
}
