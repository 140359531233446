export enum ApiModules {
  SID = 'SID',
  Organisation = 'Organisation',
  Plan = 'Plan',
  PlanPurchase = 'PlanPurchase',
  Feature = 'Feature',
  FeatureGroup = 'FeatureGroup',
  CollectionTemplates = 'CollectionTemplates',
  MasterTemplates = 'MasterTemplates',
  Industries = 'Industries',
  Scanner = 'Scanner',
  OrganisationUser = 'OrganisationUser',
  Share = 'Share',
  User = 'User',
  VMS = "VMS",
  Document = 'Document',
  AuthKey = 'AuthKey'
}
