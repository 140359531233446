import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot,} from '@angular/router';
import {ReplaySubject, takeWhile} from 'rxjs';
import {Store} from "@ngrx/store";
import {fetchPreviousAuthToken, selectAuthState} from "@qid/common";


@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  public loading: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(
    private router: Router,
    private store: Store
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {

    let isActionDispatched = false;

    return new Promise((resolve) => {

      this.store.select(selectAuthState)
        .pipe(takeWhile(authState => {
          if (authState.authToken) {
            resolve(true);
            return false
          }
          if (authState.previousAuthTokenChecked) {
            this.router.navigateByUrl('starter')
            resolve(false)
            return false
          }
          return true
        }))
        .subscribe((authState) => {
          if (!isActionDispatched) {
            isActionDispatched = true
            this.store.dispatch(fetchPreviousAuthToken())
          }

        })

    })


  }

  showLoader() {
    this.loading.next(true);
  }

  hideLoader() {
    this.loading.next(false);
  }
}
