import {Injectable} from '@angular/core';
import {String} from 'typescript-string-operations';
import {ImageUtil} from '@qid/core';
import {SID} from '../models/SID';
import {CreateSidDto} from '../dto/create-sid.dto';
import {RestService} from "../../../services/rest.service";


@Injectable({
  providedIn: 'root',
})
export class SidMasterApiService {
  static readonly ApiEndpoint: string = 'master/sid/';
  static readonly nodeSidId: string = 'sid/{0}';
  static readonly NodeLogo: string = SidMasterApiService.ApiEndpoint + 'logo/{0}';

  constructor(private restService: RestService) {
  }



  async create(createSidDto: CreateSidDto) {
    return this.restService.send<SID>({
      url: SidMasterApiService.ApiEndpoint,
      method: 'POST',
      body: createSidDto,
    });
  }

  async update(sid: SID) {
    return this.restService.send<SID>({
      url: SidMasterApiService.ApiEndpoint + sid._id,
      method: 'PUT',
      body: sid,
    });
  }

  async updateSidLogo(imageFile, sidId: string) {

    const formData = await ImageUtil.getCompressedFormData([imageFile]);

    return this.restService.sendFiles<SID>({
      url: String.format(SidMasterApiService.NodeLogo, sidId),
      body: formData,
      method: "POST"
    })

  }

  async delete(_id: string) {
    return this.restService.send({
      url: SidMasterApiService.ApiEndpoint + _id,
      method: 'DELETE',
    });
  }
}
