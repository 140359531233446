import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Directive({
  selector: '[sxFlexDirection]'
})
export class FlexDirectionDirective implements OnInit {

  @Input() sxFlexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse'

  @Input() sxFlexDirectionMobile: 'row' | 'column' | 'row-reverse' | 'column-reverse' = null

  constructor(private el: ElementRef,
              private breakpointObserver: BreakpointObserver
  ) {
  }

  ngOnInit() {

    this.el.nativeElement.style.display = "flex"

    if (!this.sxFlexDirectionMobile) {
      this.el.nativeElement.style.flexDirection = this.sxFlexDirection
    } else if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.el.nativeElement.style.flexDirection = this.sxFlexDirectionMobile
    }

  }

}
