import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import * as UsersActions from './users.actions';
import {User} from '../model/User';


export const USERS_FEATURE_KEY = 'users';

export interface UsersState extends EntityState<User> {
  selectedId?: string | number; // which Users record has been selected
  loaded: boolean; // has the Users list been loaded
  error?: string | null; // last known error (if any)
  authenticatedUser?: User
}

export interface UsersPartialState {
  readonly [USERS_FEATURE_KEY]: UsersState;
}

export const usersAdapter: EntityAdapter<User> =
  createEntityAdapter<User>();

export const initialUsersState: UsersState = usersAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const reducer = createReducer(
  initialUsersState,
  on(UsersActions.initUsers, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(UsersActions.loadUsersSuccess, (state, {users}) =>
    usersAdapter.setAll(users, {...state, loaded: true})
  ),
  on(UsersActions.loadUsersFailure, (state, {error}) => ({...state, error})),
  on(UsersActions.fetchAuthenticatedUser, (state: UsersState) =>
    ({
      ...state
    })
  ),

  on(UsersActions.fetchAuthenticatedUserSuccess, (state: UsersState, {authenticatedUser: User}) => (
    {
      ...state,
      authenticatedUser: User
    }
  )),
  on(UsersActions.refreshAuthenticatedUserSuccess, (state: UsersState, {authenticatedUser: User}) => (
    {
      ...state,
      authenticatedUser: User
    }
  )),
);

export function usersReducer(state: UsersState | undefined, action: Action) {
  return reducer(state, action);
}
