import {EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import * as SidActions from './sid.actions';
import {SID} from "../models/SID";
import {AccountWiseRecords, createAccountWiseEntityAdapters} from "@qid/core";


export const SID_FEATURE_KEY = 'sid';

export interface SidState extends EntityState<AccountWiseRecords<SID>> {
  globalSIDs: SID[]
}

export const {
  accountAdapter,
  recordAdapter,
  createAccount
} = createAccountWiseEntityAdapters<SID>(SID_FEATURE_KEY)

export const initialState: SidState =
  accountAdapter.getInitialState({
    globalSIDs: []
  });

const recordSelectors = recordAdapter.getSelectors();

const reducer = createReducer(
  initialState,

  on(SidActions.setGlobalSids, (state, props) => {
    return {
      ...state,
      globalSIDs: props.sids
    }
  }),

  // Boilerplate Starts
  on(SidActions.addAccount, (state, {accountBasedEntity}) => {
    return accountAdapter.addOne(accountBasedEntity, state)
  }),
  on(SidActions.loadRecordsForAccountSuccess, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.setAll(action.records, state.entities[action.account_id].records)
      }
    }, state)
  }),
  on(SidActions.addRecord, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.setAll([action.record, ...recordSelectors.selectAll(state.entities[action.account_id].records)], state.entities[action.account_id].records)
      }
    }, state)
  }),
  on(SidActions.updateRecord, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.updateOne({
          id: action.record_id,
          changes: {
            ...action.updatedRecord
          }
        }, state.entities[action.account_id].records)
      }
    }, state)
  }),
  on(SidActions.removeRecord, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.removeOne(action.record_id, state.entities[action.account_id].records)
      }
    }, state)
  })
// Boilerplate Ends

);


export function sidReducer(state: SidState | undefined, action: Action) {
  return reducer(state, action);
}
