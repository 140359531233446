import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {exhaustMap, from, map, of} from "rxjs";
import {CollectionTemplateService} from '../services/collection-template.service';
import {CollectionTemplateActions} from '../index';


@Injectable()
export class CollectionTemplateEffects {

  private actions$ = inject(Actions);
  private apiService = inject(CollectionTemplateService);
  private ACTIONS = CollectionTemplateActions;

  // Boilerplate Starts

  addAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.ACTIONS.addAccount),
      exhaustMap((props) =>
        of(this.ACTIONS.loadRecordsForAccount({
          account_id: props.accountBasedEntity.account_id,
          accountType: props.accountBasedEntity.accountType
        }))
      )
    )
  );

  loadSharesForAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.ACTIONS.loadRecordsForAccount),
      exhaustMap((props) => {
          return from(this.apiService.readAll(1)).pipe(
            map(paginatedResponse => {
              const records = paginatedResponse?.docs ? paginatedResponse.docs : []
              return this.ACTIONS.loadRecordsForAccountSuccess({
                records: records,
                account_id: props.account_id
              })
            })
          )
        }
      )
    )
  );

  // Boilerplate Ends

}
