import {Injectable} from "@angular/core";
import {String} from "typescript-string-operations";
import {CollectionTemplate} from "../models/CollectionTemplate";
import {CreateCollectionTemplateDto} from "../dto/create-collection-template.dto";
import {Store} from "@ngrx/store";
import {firstValueFrom} from "rxjs";
import {AuthSelectors} from "../../authentication";
import {RestService} from "../../../services/rest.service";
import {PaginatedResponse} from "../../../interface/PaginatedResponse";
import {RestResponse} from "../../../interface/RestInterface";


@Injectable({
    providedIn: "root"
})
export class CollectionTemplateService {
    public static readonly NodeCollectionTemplate = "collection-template/";
    public static readonly NodeId =
        CollectionTemplateService.NodeCollectionTemplate + "{0}/";
    public static readonly NodeOrg =
        CollectionTemplateService.NodeCollectionTemplate + "?orgId={0}&";

  public activeOrganisation$ = this.store.select(AuthSelectors.selectActiveAccount);

    constructor(
        private restService: RestService,
        private store: Store
    ) {
    }

    async create(createCollectionTemplateDto: CreateCollectionTemplateDto) {
        //SET LAST-UPDATE TIME

        //SET ORGANISATION
        createCollectionTemplateDto.org = (await firstValueFrom(this.activeOrganisation$))?._id;

        delete createCollectionTemplateDto._id;

        return (await this.restService.send<CollectionTemplate>({
            url: CollectionTemplateService.NodeCollectionTemplate,
            method: "POST",
            body: createCollectionTemplateDto
        })).data;
    }

    async read(templateId: string): Promise<CollectionTemplate> {
        const url = String.Format(CollectionTemplateService.NodeId, templateId);
        return (
            await this.restService.send<CollectionTemplate>({
                url: url,
                method: "GET"
            })
        ).data;
    }

    async readAll(page: number = 1) {
        const url = String.format(
            CollectionTemplateService.NodeOrg,
            (await firstValueFrom(this.activeOrganisation$))._id
        );
        return (
            await this.restService.send<PaginatedResponse<CollectionTemplate>>({
                url: url,
                method: "GET",
                query: {
                    page: page
                }
            })
        ).data;
    }

    async update(template: any): Promise<RestResponse<CollectionTemplate>> {
        return this.restService.send<CollectionTemplate>({
            method: "PUT",
            url: String.format(CollectionTemplateService.NodeId, template._id),
            body: template
        })
    }

  async remove(template_id: string) {
        return (await this.restService.send<any>({
            method: "DELETE",
          url: CollectionTemplateService.NodeCollectionTemplate + template_id
        })).data;
    }

    async unlockTemplate(templateId: string) {
        return (await this.restService.send<CollectionTemplate>({
            url: CollectionTemplateService.NodeCollectionTemplate + 'unlock/' + templateId,
            method: "PUT"
        })).data
    }

}
