import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {RestRequest, RestResponse} from "../interface/RestInterface";
import {AccountType, DisplayService} from "@qid/core";
import {AuthenticationService} from "../modules/authentication";


@Injectable({
  providedIn: 'root',
})
export class RestUtil {

  public static AuthToken = null;
  public static Account_id: string = null;
  public static AccountType: AccountType = null;
  public static TokenId: string = null;
  public static BaseUrl: string

  public static async send<T>(
    request: RestRequest,
    http: HttpClient,
    displayService: DisplayService = null,
    authenticationService: AuthenticationService = null,
    tokenRefreshCount = 0): Promise<RestResponse<T>> {

    let url = RestUtil.BaseUrl + request.url;

    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .append('cache-control', 'no-cache')

    headers.append('Content-type', request.contentType || 'application/json');

    if (!request.isNoAuth) {
      headers = headers.append('Authorization', 'Bearer ' + RestUtil.AuthToken);
    }

    if (RestUtil.Account_id) {
      headers = headers.append('account_id', RestUtil.Account_id);
    }
    if (RestUtil.AccountType) {
      headers = headers.append('accountType', RestUtil.AccountType);
    }
    if (RestUtil.TokenId) {
      headers = headers.append('tokenId', RestUtil.TokenId);
    }

    if (request.query) {
      let keys = Object.keys(request.query)
      url += '?'
      keys.forEach((key, index) => {
        if (!request.query[key]) {
          return
        }
        url = `${url}${key}=${request.query[key]}`
        url += index < keys.length - 1 ? "&" : ""
      })
    }

    const req = new HttpRequest(request.method, url, request.body, {headers: headers})

    let result: RestResponse<T> = {data: null , httpStatusCode: null, errorMessage: null};

    try {
      const res: any = await http.request(req).toPromise()
      if (res?.body) {
        result.data = res.body;
      }
    } catch (e) {
      if (e?.error) {
        result.errorMessage = e.error.message;
        result.httpStatusCode = e.error.statusCode
      }
      if (request.throwError) {
        throw e.error?.message;
      }
      if (request.showErrorToast && displayService) {
        displayService.toast(e.error?.message)
      }
    }

    if (result?.errorMessage?.indexOf('Token Expired') > -1 && tokenRefreshCount == 0) {
      return await RestUtil.refreshAuthTokenAndRetry<T>(request, http, displayService, authenticationService)
    }

    if (result?.errorMessage?.indexOf('login again') > -1 && tokenRefreshCount == 0) {
      return await RestUtil.refreshAuthTokenAndRetry<T>(request, http, displayService, authenticationService)
    }

    if (result?.errorMessage?.indexOf('#121') > -1 && tokenRefreshCount == 0 && authenticationService) {
      await authenticationService.signOut()
    }

    return result

  }

  public static async refreshAuthTokenAndRetry<T>(request: RestRequest, http: HttpClient,
                                                  displayService: DisplayService, authenticationService: AuthenticationService) {
    if (authenticationService) {
      await authenticationService.refreshToken()
    }
    return RestUtil.send<T>(request, http, displayService, authenticationService, 1)
  }

  public static async sendFiles<T>(request: RestRequest): Promise<RestResponse<T>> {
    return new Promise((resolve, reject) => {
      const xhttp = new XMLHttpRequest();

      if (request.query) {
        let keys = Object.keys(request.query)
        request.url += '?'
        keys.forEach((key, index) => {
          if (!request.query[key]) {
            return
          }
          request.url = `${request.url}${key}=${request.query[key]}`
          request.url += index < keys.length - 1 ? "&" : ""
        })
      }

      //Set Response Listener
      xhttp.onreadystatechange = function () {
        if (this.readyState != 4) {
          return;
        }

        if (this.status >= 200 && this.status <= 299) {
          resolve({
            data: RestUtil.returnValidJSON(this.response),
            errorMessage: null,
            httpStatusCode: this.status,
          });
          return;
        }

        if (!this.response) {
          reject({
            data: null,
            errorMessage: null,
            httpStatusCode: this.status,
          });
          return;
        }
        const jsonRes = JSON.parse(this.response);

        if (jsonRes && jsonRes.message) {
          resolve({
            data: null,
            errorMessage: jsonRes.message.toString(),
            httpStatusCode: this.status,
          });
          return;
        }

        reject(jsonRes);
      };

      //Create Request
      xhttp.open(request.method, RestUtil.BaseUrl + request.url, true);

      //Set Headers

      xhttp.setRequestHeader('Access-Control-Allow-Origin', '*');

      xhttp.setRequestHeader('cache-control', 'no-cache');

      if (!request.isNoAuth) {
        xhttp.setRequestHeader(
          'Authorization',
          'Bearer ' + RestUtil.AuthToken
        );
      }

      if (RestUtil.Account_id) {
        xhttp.setRequestHeader('account_id', RestUtil.Account_id);
      }
      if (RestUtil.AccountType) {
        xhttp.setRequestHeader('accountType', RestUtil.AccountType);
      }
      xhttp.send(request.body);
    });
  }


  static returnValidJSON(res: any) {
    try {
      return JSON.parse(res);
    } catch (e) {
      if (res != null && res.length > 0) {
        return res;
      }
      return null;
    }
  }

}
