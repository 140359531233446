import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {AccountType} from "@qid/core";
import {AuthSelectors} from "../../../authentication";


@Directive({selector: '[visibleTo]'})
export class HideForOrgDirective implements OnInit {
  @Input() visibleTo: 'org' | 'user';

  constructor(
    private el: ElementRef,
    private store: Store
  ) {
  }

  ngOnInit() {
    this.store.select(AuthSelectors.selectActiveAccountType).subscribe((accountType: AccountType) => {
      this.hideElement();
      if (this.visibleTo == 'org' && accountType == AccountType.organisation) {
        this.showElement();
      }
      if (this.visibleTo == 'user' && accountType == AccountType.user) {
        this.showElement();
      }
    });
  }

  hideElement() {
    this.el.nativeElement.hidden = true;
  }

  showElement() {
    this.el.nativeElement.hidden = false;
  }
}
