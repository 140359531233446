import {NgModule} from "@angular/core";
import {TemplateSelectorComponent} from "./template-selector/template-selector.component";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {SexyModule} from "@qid/sexy";
import {WebcamModule} from 'ngx-webcam';
import {SuperModule} from "@qid/super";
import {FormsModule} from "@angular/forms";
import {VerifiedSignupComponent} from "./verified-signup/verified-signup.component";
import {FaceCapture} from "./face-capture/face-capture.component";
import {DigilockerComponent} from "./digilocker/digilocker.component";
import {IdComponentsModule} from "../modules/id";


@NgModule({
    imports: [IonicModule, CommonModule, SexyModule,
        WebcamModule, SuperModule, FormsModule, IdComponentsModule
    ],
  declarations: [TemplateSelectorComponent,
    FaceCapture, VerifiedSignupComponent, DigilockerComponent],
  exports: [TemplateSelectorComponent,
    FaceCapture, VerifiedSignupComponent, DigilockerComponent]
})
export class CommonComponentsModule {

}
