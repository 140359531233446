import {Component, Input, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {FamilyMemberService} from "../../services/family-member.service";
import {CreateFamilyMemberDto} from "../../dto/create-family-member.dto";
import {FamilyMember} from "../../models/family.member";
import {DisplayService, ValidationUtil} from "@qid/core";

@Component({
  selector: "add-family-member",
  templateUrl: "./add-family-member.component.html",
  styleUrls: ["./add-family-member.component.scss"]
})
export class AddFamilyMemberComponent implements OnInit {

  @Input() isEmailRequired: boolean = false;
  @Input() isPhoneRequired: boolean = false;

  public createFamilyMemberDto: CreateFamilyMemberDto = new CreateFamilyMemberDto();

  public familyMembers: FamilyMember[] = [];


  constructor(private modalController: ModalController,
              private familyMemberService: FamilyMemberService,
              private displayService: DisplayService
  ) {
  }

  ngOnInit() {
    this.fetchMembers();
  }

  close() {
    this.modalController.dismiss();
  }

  async fetchMembers() {
    const res = await this.familyMemberService.readALl();
    if (res) {
      this.familyMembers = res;
    }
  }

  addParticipant = async () => {
    if (ValidationUtil.isStringEmpty(this.createFamilyMemberDto.name)) {
      throw "Name is required";
    }
    if (this.isEmailRequired && !ValidationUtil.isEmailValid(this.createFamilyMemberDto.email)) {
      throw "Email is required";
    }
    if (this.isPhoneRequired && !ValidationUtil.isValidPhone(this.createFamilyMemberDto.phoneNumber)) {
      throw "Phone is required";
    }
    const res = await this.familyMemberService.create(this.createFamilyMemberDto);

    if (res) {
      this.selectMember(res);
        this.displayService.toast({message: "Member Added"});
    }
  };

  selectMember(member: FamilyMember) {
    this.modalController.dismiss({
      member: member
    });
  }

  deleteMember = async (index: number) => {
    let res = await this.familyMemberService.delete(this.familyMembers[index]._id);
    // if (ValidationUtil.isTrue(res)) {
      this.familyMembers.splice(index, 1);
    // }
      await this.displayService.toast({ message: "Deleted" });
  };

  showInviteMessage(name: string) {
    this.displayService.alert("Invite Pending", `${name} is already registered on Qid. Once they accept your invitation to become a family member, you will be able to share and access their documents.`);
  }
}
