import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {SuperModule} from "@qid/super";
import {SexyModule} from "@qid/sexy";
import {QuickSignupComponent} from "./quick-signup/quick-signup.component";

@NgModule({
  declarations: [QuickSignupComponent],
  imports: [
    CommonModule,
    IonicModule,
    SuperModule,
    SexyModule,
  ],
    exports: [QuickSignupComponent]
})
export class AuthenticationComponentsModule {
}
