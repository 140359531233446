import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Share} from '../../model/Share';
import {ShareService} from '../../services/share.service';
import {AlertController, LoadingController, ModalController} from '@ionic/angular';
import {AccountType, DisplayService, ValidationUtil} from "@qid/core";
import {ViewSharePage} from '../../pages/view-share/view-share.page';
import {ExportPdfService} from "../../services/export-pdf.service";
import {ParticipantRoles} from "../../enum/participant-roles.enum";
import {firstValueFrom} from "rxjs";
import {Store} from "@ngrx/store";
import {ShareParticipantUtil} from "../../utils/share-participant.util";
import {ShareStatus} from '../../enum/share-status.enum';
import { User } from '../../../user';
import { Organisation } from '../../../organisation';
import { AuthSelectors } from '../../../authentication';
import { PlanPurchaseService } from '../../../plan-purchase';


@Component({
  selector: 'share-log-view',
  templateUrl: './share-log-view.component.html',
  styleUrls: ['./share-log-view.component.scss'],
})
export class ShareLogViewComponent implements OnInit {

  @Input() share: Share = null;
  @Input() user: User = null;
  @Input() organisation: Organisation
  @Input() idCard: boolean = false;
  @Input() menuIcon: boolean = true;
  @Input() viewSize: 'small' | 'large' | 'self-collect' = 'large';
  @Input() skeletonOnly: boolean = false;
  @Input() headerOnly : boolean = false;
  @Output() continueClicked = new EventEmitter();

  public sharerCount: number = null;

  public accountToDisplay: User | Organisation | any;
  public accountType: AccountType;

  public activeAccountType$ = this.store.select(AuthSelectors.selectActiveAccountType)

  constructor(
    private shareService: ShareService,
    private modalController: ModalController,
    private exportPdfService: ExportPdfService,
    private displayService: DisplayService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private store: Store,
    private planPurchaseService: PlanPurchaseService
  ) {
  }

  ngOnInit() {
    if (!this.skeletonOnly) {
      this.setRecipient()
    }
    this.share = JSON.parse(JSON.stringify(this.share));
  }

  async openViewSharePage() {

    const activeAccountType = await firstValueFrom(this.activeAccountType$)

    if (ValidationUtil.isTrue(this.share.isQuotaLocked) && activeAccountType == AccountType.organisation) {
      return this.onLockedShareClicked();
    }

    const modal = await this.modalController.create({
      component: ViewSharePage,
      componentProps: {
        shareId: this.share._id,
      },
    });
    await modal.present();
  }

  printAsPDF = async () => {
    if (this.share.isQuotaLocked) {
      await this.lockAlert();
      return;
    }
    await this.exportPdfService.exportIndividualPdf(this.share);
  };

  copyTransactionId(event: Event) {
    event.stopPropagation();
    if (this.share.isQuotaLocked) {
      this.lockAlert();
      return;
    }
    const el = document.createElement('textarea');
    el.value = this.share._id;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.displayService.toast({message: 'Transaction ID copied to clipboard!'});
  }

  lockAlert = async () => {
    const alert = await this.alertController.create({
      header: 'Usage Quota Exceeded',
      message: 'This ID exceeds the collection limit.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Upgrade Plan',
          handler: async () => {
            this.planPurchaseService.openPlanUpgrade();          },
        },
      ],
    });
    await alert.present();
    await alert.onDidDismiss();
  };


  public async onLockedShareClicked() {
    const loader = await this.loadingController.create({
      message: 'Checking Quota to unlock this Collection',
    });
    loader.present();
    const res: Share = await this.shareService.unlock(this.share._id);
    loader.dismiss();
    if (res && !ValidationUtil.isTrue(res.isQuotaLocked)) {
      this.share = res;
      this.displayService.toast({
        message: "Unlocked",
        duration: 1000
      });
      return this.openViewSharePage()
    }
    const alert = await this.alertController.create({
      header: 'Usage Quota Exceeded',
      subHeader: 'This ID exceeds the collection limit.',
      buttons: [
        {
          text: 'Upgrade Plan',
          handler: () => {
            this.planPurchaseService.openPlanUpgrade();          },
        },
        {
          text: 'Cancel',
          handler: () => {
          },
        },
      ],
    });
    return alert.present();
  }

  async setRecipient() {

    if (!this.share) {
      return
    }

    this.accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType))

    if (this.share.participants) {
      const participant = this.accountType == AccountType.organisation ?
        ShareParticipantUtil.findMainParticipant(this.share.participants) :
        ShareParticipantUtil.findOrganisingParticipant(this.share.participants)
      this.accountToDisplay = participant?.accountSnapshot
    }

  }


  getParticipantsCount() {
    if (!this.share || !this.share.participants) {
      return null;
    }
    this.sharerCount = 0;
    for (let participant of this.share.participants) {
      if (!participant.roles) {
        this.sharerCount++;
        continue;
      }
      if (participant.roles.indexOf(ParticipantRoles.sharer) > -1) {
        this.sharerCount++
      }
    }
    return this.sharerCount;
  }


  getShareSummary() {
    switch (this.accountType) {
      case AccountType.user :
        return "You shared a document";
      case AccountType.organisation :
        return this.share?.template?.purpose;
    }
  }
  getShareStatus(){
    switch (this.share.status){
      case ShareStatus.completed:
        return "Completed";
      case ShareStatus.open:
        return "Pending";
      case ShareStatus.cancelled:
        return "Cancelled";
    }
  }

  getShareStatusIcon(){
    switch (this.share.status){
      case ShareStatus.completed:
        return "checkmark-circle";
      case ShareStatus.open:
        return "alert-circle";
      case ShareStatus.cancelled:
        return "close-circle";
    }
  }

  getShareStatusColor(){
    switch (this.share.status){
      case ShareStatus.completed:
        return "success";
      case ShareStatus.open:
        return "warning";
      case ShareStatus.cancelled:
        return "danger";
    }
  }

  protected readonly ShareStatus = ShareStatus;

  isShowCFormButton(purpose: string | undefined) {
    return purpose?.toLowerCase()?.indexOf('foreign') > -1
  }
}
