import {EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import * as OrganisationUserActions from './organisation-user.actions';
import {AccountWiseRecords, createAccountWiseEntityAdapters} from "@qid/core";
import {OrganisationUser} from '../models/organisation-user.model';

export const ORGANISATION_USER_FEATURE_KEY = 'organisationUser';

export interface OrganisationUserState extends EntityState<AccountWiseRecords<OrganisationUser>> {
  selectedId?: any;
  authenticatedUserOrganisations: OrganisationUser[],
  fetchingUserOrganisations?: boolean,
  activeOrganisationUser: OrganisationUser
}

export const {
  accountAdapter,
  recordAdapter,
  createAccount
} = createAccountWiseEntityAdapters<OrganisationUser>(ORGANISATION_USER_FEATURE_KEY)


export const initialOrganisationUserState: OrganisationUserState =
  accountAdapter.getInitialState({
    authenticatedUserOrganisations: null,
    fetchingUserOrganisations: false,
    activeOrganisationUser: null
  });

const recordSelectors = recordAdapter.getSelectors()

const reducer = createReducer(
  initialOrganisationUserState,
  on(OrganisationUserActions.addAccount, (state, {accountBasedEntity}) => {
    return accountAdapter.addOne(accountBasedEntity, state)
  }),
  on(OrganisationUserActions.fetchUserOrganisationsSuccess, (state, {organisations}) => ({
    ...state,
    authenticatedUserOrganisations: organisations
  })),

  on(OrganisationUserActions.switchUserOrganisation, (state, {organisation}) => ({
    ...state,
    activeOrganisationUser: organisation ? state.authenticatedUserOrganisations.find(orgUser => orgUser.organisation._id == organisation._id) : null
  })),

  on(OrganisationUserActions.updateActiveOrganisationUser, (state, {updatedRecord}) => ({
    ...state,
    activeOrganisationUser: updatedRecord
  })),

  on(OrganisationUserActions.loadOrganisationUsersForAccountSuccess, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.setAll(action.organisationUsers, state.entities[action.account_id].records)
      }
    }, state)
  }),
  on(OrganisationUserActions.addRecord, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.setAll([action.record, ...recordSelectors.selectAll(state.entities[action.account_id].records)], state.entities[action.account_id].records)
      }
    }, state)
  }),

  on(OrganisationUserActions.updateRecord, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.updateOne({
          id: action.record_id,
          changes: {
            ...action.updatedRecord
          }
        }, state.entities[action.account_id].records)
      }
    }, state)
  }),

  on(OrganisationUserActions.removeRecord, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.removeOne(action.record_id, state.entities[action.account_id].records)
      }
    }, state)
  })
);

export function organisationUserReducer(
  state: OrganisationUserState | undefined,
  action: Action
) {
  return reducer(state, action);
}
