<div *ngIf="config && config?.isLoading$ | async" [sxPadding]="padding"
     sxCol [sxGap]="gap">
  <div *ngFor="let item of iterations">
    <ng-container *ngComponentOutlet="config?.component; inputs : {'skeletonOnly' : true}"></ng-container>
  </div>
</div>

<div *ngIf="config?.entities$ && config.component" [sxPadding]="padding" sxCol [sxGap]="gap">
  <div *ngFor="let entity of config.entities$ | async" (click)="onEntityClick(entity)">
    <ng-container *ngComponentOutlet="config.component; inputs : getComponentProps(entity);"></ng-container>
  </div>
</div>

