import {CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IdService} from './services/id.service';
import {ENVIRONMENT, Environment} from '@qid/env';
import {IonicModule} from '@ionic/angular';
import {CreateIdPageModule} from './pages/create-id/create-id.module';
import {IdVerificationPageModule} from './pages/id-verification/id-verification.module';
import {NameCorrectionPageModule} from './pages/name-correction/name-correction.module';
import {ViewIdPageModule} from './pages/view-id/view-id.module';
import {SocialSharing} from '@awesome-cordova-plugins/social-sharing/ngx';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromDocuments from './+state/documents.reducer';
import {DocumentsEffects} from './+state/documents.effects';
import {DocumentsRepository} from './+state/documents-repository.service';
import {DocumentDiService} from './services/document-di.service';
import {ViewMultipleDocumentsPageModule} from "./pages/view-multiple-documents/view-multiple-documents.module";

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    CreateIdPageModule,
    IdVerificationPageModule,
    NameCorrectionPageModule,
    ViewIdPageModule,
    ViewMultipleDocumentsPageModule,
    StoreModule.forFeature(
      fromDocuments.DOCUMENTS_FEATURE_KEY,
      fromDocuments.documentsReducer
    ),
    EffectsModule.forFeature([DocumentsEffects]),
  ],
  providers: [IdService, SocialSharing, DocumentsRepository, DocumentDiService]
})
export class IdModule {
  public static environment: Environment = null;

  constructor(@Inject(ENVIRONMENT) private env: Environment) {
    if (env) {
      IdModule.environment = env;
    }
  }
}
