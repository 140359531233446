import { Component, Input, Output, EventEmitter } from '@angular/core';
import {PaginatedResponse} from '@qid/core';

@Component({
  selector: 'pagination-box',
  templateUrl: './pagination-box.component.html',
  styleUrls: ['./pagination-box.component.scss'],
})
export class PaginationBoxComponent {

  @Input() paginatedResponse: PaginatedResponse<any>;
  @Output() changePage: EventEmitter<number> = new EventEmitter();

  visiblePageCount = 8; // Update the visiblePageCount to the desired value
  activePageNumber= 1;

  public nextPage = async () => {
    if (this.paginatedResponse.page < this.paginatedResponse.totalPages) {
      await this.goToPage(++this.paginatedResponse.page);
    }
  };

  public previousPage = async () => {
    if (this.paginatedResponse.page > 1) {
      await this.goToPage(--this.paginatedResponse.page);
    }
  };

  getVisiblePageNumbers(): number[] {
    const startingPage = this.getStartingPageNumber();
    const maxVisiblePages = this.visiblePageCount;
    const visiblePageNumbers = [];

    for (let i = 0; i < maxVisiblePages; i++) {
      const pageNumber = startingPage + i;

      if (pageNumber <= this.paginatedResponse.totalPages) {
        visiblePageNumbers.push(pageNumber);
      }
    }

    return visiblePageNumbers;
  }

  getStartingPageNumber(): number {
    let startingPage = this.paginatedResponse.page;

    if (startingPage - this.visiblePageCount < 1) {
      startingPage = 1;
    } else if (startingPage + this.visiblePageCount > this.paginatedResponse.totalPages) {
      startingPage = this.paginatedResponse.totalPages - this.visiblePageCount + 1;
      if (startingPage < 1) {
        startingPage = 1;
      }
    } else {
      startingPage -= this.visiblePageCount;
    }
    return startingPage;
  }

  goToPage(pageNumber: number): void {
    if (pageNumber >= 1 && pageNumber <= this.paginatedResponse.totalPages) {
      this.changePage.emit(pageNumber);
      this.activePageNumber = pageNumber;
      this.visiblePageCount = 8; // Update the visiblePageCount to the desired value
    }
  }


  getStartNumber(){
    const startNumber = ((this.paginatedResponse.page - 1) * 30) + 1
    return startNumber == 0 ? 1 : startNumber
  }
}





