import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as AuthActions from './auth.actions'
import {exhaustMap, from, map} from "rxjs";
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class AuthEffects {

  private actions$ = inject(Actions);

  constructor(private authService: AuthenticationService
  ) {
  }

  fetchPreviousAuthToken$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(AuthActions.fetchPreviousAuthToken),
      exhaustMap(() =>
        from(this.authService.checkPreviousAuthenticatedUser()).pipe(
          map(token => {
            return AuthActions.fetchAuthTokenSuccess({token: token})
          })
        )
      )
    )

  })


}
