import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxMinHeight]'})
export class MinHeightDirective implements OnInit {

  @Input() sxMinHeight: string | 'full' | 'fit' | "fill";

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.setValue()
  }

  setValue() {
    switch (this.sxMinHeight) {
      case "full" :
        return this.el.nativeElement.style.height = "100%";
      case "fill" :
        return this.el.nativeElement.style.flex = "1 0 0px"
      default :
        return this.el.nativeElement.style.minHeight = this.sxMinHeight;
    }

  }
}
