import {createAction, props} from '@ngrx/store';
import {SID} from "../models/SID";
import {AccountType, AccountWiseRecords} from "@qid/core";


export const setGlobalSids = createAction(
  '[Sid/API] Set Global SIDs',
  props<{ sids: SID[] }>()
);


//Boilerplate Starts

const FEATURE_KEY = 'SID'
const ENTITY_MODEL: SID = null;

export const addAccount = createAction(
  `[${FEATURE_KEY}] Add new account`,
  props<{ accountBasedEntity: AccountWiseRecords<typeof ENTITY_MODEL> }>()
)

export const loadRecordsForAccount = createAction(
  `[${FEATURE_KEY}] Load records for account`,
  props<{ account_id: string, accountType: AccountType }>()
);


export const loadRecordsForAccountSuccess = createAction(
  `[${FEATURE_KEY}] Load Shares for Account Success`,
  props<{ records: typeof ENTITY_MODEL[], account_id: string }>()
);

export const addRecord = createAction(
  `[${FEATURE_KEY}] Add Record`,
  props<{ record: typeof ENTITY_MODEL, account_id: string }>()
);

export const updateRecord = createAction(
  `[${FEATURE_KEY}] Update Record`,
  props<{ record_id: string, account_id: string, updatedRecord: typeof ENTITY_MODEL }>()
);

export const removeRecord = createAction(
  `[${FEATURE_KEY}] Remove Record`,
  props<{ record_id: string, account_id: string }>()
);


// Boilerplate Ends
