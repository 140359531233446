<div sxPadding="medium" sxCol sxAlign="center" sxGap="10px" *ngIf="activeSlide == slides.start">

  <div sxBackground="light" sxRadius="medium" sxBorderColor="one" sxBorderWidth="1px"
       sxPadding="20px medium" sxCenterize="col">

    <div sxRow sxGap="10px" sxAlign="center" sxWidth="full">
      <ion-icon sxTextSize="28px" name="id-card-outline"></ion-icon>
      <h1 sxMargin="0" sxTextSize="large">Verify Identity</h1>
    </div>

    <div sxCol sxMargin="10px 0" sxGap="10px" sxPadding="15px 0 10px" sxBorderColor="one" sxBorderWidth="1px 0 0">
      <div sxRow sxAlign="center" sxDistribution="spaceBetween" sxWidth="full">
        <h1 sxMargin="0" sxTextWeight="normal" sxTextSize="medium">Add Aadhaar</h1>
        <img class="aadhaar-logo" src="assets/aadhaar-logo.png"/>
      </div>

      <p sxTextSize="small" sxTextAlign="justify" sxWidth="fill" sxColor="two" sxMargin="0">I provide my consent to
        share my Aadhaar Number, Date of Birth and Name from my Aadhaar eKYC information with the Qid for the purpose of
        fetching my
        Certificate Name into DigiLocker</p>
    </div>

    <super-button sxWidth="full" iconEnd="chevron-forward" [action]="onVerifyAadhaarClicked">Agree & Continue
    </super-button>
  </div>

</div>

<div *ngIf="activeSlide == slides.digilockerAuth" sxCenterize="col" sxPadding="medium" sxGap="10px">


  <div *ngIf="digilockerRequest" sxCol sxWidth="full" sxBackground="light"
       sxBorderWidth="1px" sxRadius="medium" sxBorderColor="one" sxPadding="medium">
    <h1 sxTextSize="large">Add Aadhaar</h1>
    <div class="digilocker-progress-card">
      <p>{{digilockerRequest.authResponse ? "Authorized" : "Authorizing"}}</p>
      <ion-spinner *ngIf="!digilockerRequest.authResponse"></ion-spinner>
      <ion-icon *ngIf="digilockerRequest.authResponse" name="checkmark-circle"></ion-icon>
    </div>
    <div *ngIf="digilockerRequest.requestedDoc.documentFetch" class="digilocker-progress-card">
      <p>Fetch Document</p>
      <ion-spinner *ngIf="digilockerRequest.requestedDoc.documentFetch == 'started'"></ion-spinner>
      <ion-icon *ngIf="digilockerRequest.requestedDoc.documentFetch == 'success'" name="checkmark-circle"></ion-icon>
    </div>
    <div *ngIf="digilockerRequest.requestedDoc.faceVerification" class="digilocker-progress-card">
      <p>Face Verification</p>
      <ion-spinner *ngIf="digilockerRequest.requestedDoc.faceVerification == 'started'"></ion-spinner>
      <ion-icon *ngIf="digilockerRequest.requestedDoc.faceVerification == 'success'" name="checkmark-circle"></ion-icon>
    </div>
    <div *ngIf="digilockerRequest.requestedDoc.documentCreation" class="digilocker-progress-card">
      <p>Create Document</p>
      <ion-spinner *ngIf="digilockerRequest.requestedDoc.documentCreation == 'started'"></ion-spinner>
      <ion-icon *ngIf="digilockerRequest.requestedDoc.documentCreation == 'success'" name="checkmark-circle"></ion-icon>
    </div>
    <div class="digilocker-brand-wrapper" sxMargin="20px 0 0" sxWidth="full" sxBorderColor="one" sxBorderWidth="1px 0 0"
         sxPadding="medium" sxCenterize="row" sxGap="10px">
      <img sxWidth="100px" src="assets/digilocker-logo.webp"/>
    </div>
  </div>

</div>

<div *ngIf="createDocument" sxPadding="medium" sxWidth="full">
  <id-card [document]="createDocument"></id-card>
</div>


