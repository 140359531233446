<div>

  <div sxPadding="large medium 0">
    <h1 sxMargin="0 10px" sxTextWeight="normal">Setup</h1>
    <h1 sxMargin="0 10px">Verified Profile</h1>
  </div>

  <div *ngIf="!faceId && !isFetchingFaceId" sxPadding="medium">
    <div sxWidth="full" sxBackground="light" sxRadius="medium">
      <face-capture #faceCapture (onCapture)="onFaceCaptured($event)"></face-capture>
    </div>
  </div>

  <div *ngIf="faceId && !isFetchingFaceId" sxPadding="medium">
    <div sxPadding="medium" sxRow sxAlign="center" sxBackground="light" sxDistribution="spaceBetween"
         sxBorderWidth="1px" sxRadius="medium" sxBorderColor="one">
      <div sxCenterize="row" sxGap="10px">
        <avatar-round [size]="50" [imageUrl]="faceId.imageUrl"></avatar-round>
        <p>Face Verified</p>
      </div>
      <ion-icon sxTextSize="28px" sxColor="success" name="shield-checkmark"></ion-icon>
    </div>
  </div>


  <digilocker [hidden]="!idVerificationStatus" #digilockerComponent [sid]="sid"></digilocker>

</div>
