import {Field} from "@qid/field";
import {ParticipantRoles} from "../enum/participant-roles.enum";
import {AccountType} from "@qid/core";
import { User } from "../../user";
import { Organisation } from "../../organisation";
import { Signature } from "../../signature";
import { IdField } from "../../id";
import { DocumentAccess } from "../../document-access";

export class Participant {

  account_id: string;
  accountType: AccountType;
  account: User | Organisation | any;
  accountSnapshot: User | Organisation | any;

  memberId: string;

  sharedDocumentsIds: string[];
  signature: Signature;
  signatureImageUrl: string;
  otherFields: Field[];
  optionalLabel: string

  roles: ParticipantRoles[];

  name: string;

  mandatoryIds: Field<IdField>[] = [];
  optionalIds: Field<IdField>[] = [];
  documentsAccess : DocumentAccess[] = [];

  facePhoto : {
    isLive : boolean;
    imageUrl : string;
  }

  constructor() {

  }
}
