import {Injectable} from "@angular/core";
import { RestService } from "../../../services/rest.service";
import {ImageUtil} from "@qid/core";
import {FaceId} from "../model/face-id.model";

@Injectable()
export class FaceIdApi{

  static ApiEndpoint = 'face-id'

  constructor(private restService : RestService) {
  }

  async create(imageFile : File){
    const data = await ImageUtil.getCompressedFormData([imageFile])
    return this.restService.sendFiles<FaceId>({
      url: FaceIdApi.ApiEndpoint,
      body: data,
      method: 'POST',
      isFile: true,
    });
  }

  async read(){
    return this.restService.send<FaceId>({
      url : FaceIdApi.ApiEndpoint,
      method : "GET"
    })
  }

  async delete(){
    return this.restService.send<FaceId>({
      url : FaceIdApi.ApiEndpoint,
      method : "DELETE"
    })
  }

}
