import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {AddOrgUserPageRoutingModule} from './add-org-user-routing.module';
import {AddOrgUserPage} from './add-org-user.page';
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        AddOrgUserPageRoutingModule,
        SexyModule,
        SuperModule,
    ],
    declarations: [AddOrgUserPage],
})
export class AddOrgUserPageModule {
}
