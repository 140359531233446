export class AbsoluteValue {

  public static get(selection, values) {
    switch (selection) {
      case "small" :
        return values.small
      case "medium" :
        return values.medium
      case "large" :
        return values.large
      default :
        return selection
    }
  }

  public static getSplitValues(selection, themeValues) {

    const valueList = selection.split(' ');

    valueList.map((value, index) => {
      valueList[index] = AbsoluteValue.get(value, themeValues)
    })

    if (valueList.length == 1) {
      return `${valueList[0]} ${valueList[0]} ${valueList[0]} ${valueList[0]}`;
    }

    if (valueList.length == 2) {
      return `${valueList[0]} ${valueList[1]} ${valueList[0]} ${valueList[1]}`;
    }

    if (valueList.length == 3) {
      return `${valueList[0]} ${valueList[1]} ${valueList[2]} ${valueList[1]}`;
    }

    if (valueList.length == 4) {
      return `${valueList[0]} ${valueList[1]} ${valueList[2]} ${valueList[3]}`;

    }
  }
}
