<div
  class="avatar-wrapper"
  [style.width]="size + 'px'"
  [style.height]="size + 'px'">
  <div class="logo" [sxBackground]="'white'" [style.background-image]="'url(' + getUrl() + ')'">
    <div class="initial-wrapper" [sxBackground]="backgroundColor" *ngIf="title && !imageUrl">
      <p [style.font-size]="getInitialsFontSize()">{{getInitials(title)}}</p>
    </div>

    <div class="lock" *ngIf="lock">
      <ion-icon size="small" name="lock-closed-outline"></ion-icon>
    </div>
  </div>
  <div
    *ngIf="isEditMode"
    class="bubble"
    [class.filled]="bubbleFilled"
    (click)="onCameraClicked()"
    [style.width]="bubbleSize + 'px'"
    [style.height]="bubbleSize + 'px'"
    [style.top]="getBubbleTop() + 'px'"
    [style.left]="getBubbleLeft() + 'px'">
    <ion-icon *ngIf="!isOnAction" name="camera-outline"></ion-icon>
    <ion-spinner *ngIf="isOnAction"></ion-spinner>
  </div>
</div>

<app-upload-image-button
  #uploadImageButtonComponent
  hidden
  (onImageUpload)="onImageLoad($event)"></app-upload-image-button>
