import {Pipe, PipeTransform} from '@angular/core';
import {OrganisationUserRoles} from "../enums/organisation-user-roles.enum";

@Pipe({
  name: 'roleTitle',
})
export class RolePipe implements PipeTransform {
  transform(value: any): string {
    switch (value) {
      case OrganisationUserRoles.SuperAdmin:
        return 'Super Admin';
      case OrganisationUserRoles.ADMIN:
        return 'Admin';
      case OrganisationUserRoles.EDITOR:
        return 'Editor';
      case OrganisationUserRoles.VIEWER:
        return 'Viewer';
    }

    return;
  }
}
