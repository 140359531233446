import {Injectable} from "@angular/core";
import {User} from "../model/User";
import {RestService} from "../../../../services/rest.service";
import {PaginatedResponse} from "../../../../interface/PaginatedResponse";

@Injectable({
  providedIn: "root"
})
export class UserMasterApiService {

  static readonly ApiUrl: string = "master/user/";

  constructor(
    private restService: RestService,
  ) {
  }

  async readAll(pageNumber: number) {
    return (await this.restService.send<PaginatedResponse<User>>({
      url: UserMasterApiService.ApiUrl,
      method: 'GET',
      query: {
        page: pageNumber
      }
    })).data;
  }

  public getAnalytics(startDate: number = null, endDate: number = null) {
    return this.restService.send<number>({
      url: UserMasterApiService.ApiUrl + 'analytics',
      method: 'GET',
      query: {
        startDate: startDate,
        endDate: endDate
      }
    })
  }


}
