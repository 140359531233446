import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxDistribution]'})
export class DistributionDirective implements OnInit {
  @Input() sxDistribution: 'start' | 'center' | 'end' | 'spaceBetween' | 'spaceAround' | 'spaceEvenly';

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.setDistribution()
  }

  setDistribution() {
    switch (this.sxDistribution) {
      case "start":
        this.el.nativeElement.style.justifyContent = 'flex-start';
        break;
      case "end":
        this.el.nativeElement.style.justifyContent = 'flex-end';
        break;
      case "center":
        this.el.nativeElement.style.justifyContent = 'center';
        break;
      case "spaceAround":
        this.el.nativeElement.style.justifyContent = 'space-around';
        break;
      case "spaceBetween":
        this.el.nativeElement.style.justifyContent = 'space-between';
        break;
      case "spaceEvenly":
        this.el.nativeElement.style.justifyContent = 'space-evenly';
        break;
    }
  }


}
