import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxBgPosition]'})
export class BackgroundPositionDirective implements OnInit {

  @Input() sxBgPosition: "center" | "top" | "bottom" | string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.style.backgroundPosition = this.sxBgPosition;
  }

}
