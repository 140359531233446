import {CryptoUtil} from "./CryptoUtil";
import {RsaUtil} from "./RsaUtil";

export class AesUtil {

  public static Algorithm = "AES-GCM"
  public static KeyLength = 256

  public static async generateKey(): Promise<CryptoKey> {
    return await window.crypto.subtle.generateKey(
      {
        name: AesUtil.Algorithm,
        length: AesUtil.KeyLength
      },
      true, // Key can be used for encryption
      ["encrypt", "decrypt"] // Key usages
    );
  }


  public static async encryptJsonWithAESKey(aesKey: CryptoKey, jsonData: any):
    Promise<{ ivB64: string, encryptedDataB64: string }> {

    const jsonStr: string = JSON.stringify(jsonData);
    const encodedData: Uint8Array = CryptoUtil.encode(jsonStr);

    const iv: Uint8Array = window.crypto.getRandomValues(new Uint8Array(16)); // Initialization Vector

    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: AesUtil.Algorithm,
        iv: iv
      },
      aesKey,
      encodedData
    );

    return {
      ivB64: CryptoUtil.unit8ArrayToBase64(iv),
      encryptedDataB64: CryptoUtil.arrayBufferToBase64(encryptedData)
    };
  }


  public static async decryptJsonWithAESKey(key: CryptoKey, encryptedData: Uint8Array, iv: Uint8Array) {

      const decryptedData = await window.crypto.subtle.decrypt(
        {
          name: AesUtil.Algorithm,
          iv: iv
        },
        key,
        encryptedData
      );

      const decryptedString = CryptoUtil.decode(decryptedData);
      return JSON.parse(decryptedString);

  }


  public static async decryptAesKeyWithRsaPrivateKey(privateKeyBase46: string, rsaEncryptedAesKey: string): Promise<CryptoKey> {

      const aesString: string = await RsaUtil.decryptData(privateKeyBase46, rsaEncryptedAesKey);
      let aes: any[] = aesString.split(",");
      aes = aes.map((value) => parseInt(value, 10));

      return await CryptoUtil.importRawKeyFromArrayBuffer(new Uint8Array(aes));

  }

}
