import {Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {map} from 'rxjs';
import {AccountProfileFacade} from "../../modules/account-profile";
import {UsersSelectors} from "../../modules/user";

@Component({
  selector: 'display-name',
  templateUrl: './display-name.component.html',
  styleUrl: './display-name.component.scss',
})
export class DisplayNameComponent {

  @Input() textSize: string = 'medium';
  @Input() iconSize: string = '22px';
  @Input() textWeight: string = 'bold'

  public authenticatedUser$ = this.store.select(UsersSelectors.selectAuthenticatedUser);
  public authenticatedUserName$ = this.authenticatedUser$.pipe(
    map(user => user?.name))
  public accountProfile$ = this.accountProfileFacade.profile$

  constructor(private store: Store,
              private accountProfileFacade: AccountProfileFacade) {
  }


}
