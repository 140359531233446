<ion-header>
  <ion-toolbar>
    <ion-button fill="clear" (click)="close()">Back</ion-button>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div sxPadding="medium" sxCol sxGap="10px">
    <h2 sxMargin="20px 20px 0">Choose the correct name</h2>

    <div sxBackground="light" sxPadding="medium" sxCol sxGap="10px"
         sxRadius="medium">

      <div sxRow (click)="onNameSelected('user-entered')" sxCursor="pointer">
        <div sxCenterize="row" sxWidth="40px">
          <div sxRadius="50%" sxBorderWidth="2px" sxBorderColor="one" sxWidth="30px" sxHeight="30px"
               sxCenterize="row" sxPadding="0">
            <ion-icon sxTextSize="35px" sxColor="primary" *ngIf="selectedNameType=='user-entered'"
                      name="checkmark-circle"></ion-icon>
          </div>
        </div>
        <div sxBorderColor="one" sxBorderWidth="0 0 1px 0" sxCol sxGap="5px" sxPadding="10px small" sxWidth="fill">
          <p sxMargin="0" sxTextSize="small" sxColor="two">Name you entered</p>
          <p sxMargin="0 0 10px">{{userInput}}</p>
        </div>
      </div>


      <div sxRow (click)="onNameSelected('system-generated')" sxCursor="pointer">
        <div sxCenterize="row" sxWidth="40px">
          <div sxRadius="50%" sxBorderWidth="2px" sxBorderColor="one" sxWidth="30px" sxHeight="30px"
               sxCenterize="row" sxPadding="0">
            <ion-icon sxTextSize="35px" sxColor="primary" *ngIf="selectedNameType=='system-generated'"
                      name="checkmark-circle"></ion-icon>
          </div>
        </div>
        <div sxCol sxGap="5px" sxPadding="10px small" sxWidth="fill">
          <p sxMargin="0" sxTextSize="small" sxColor="two">Name Found on ID</p>
          <p sxMargin="0" sxTextWeight="bold">{{documentImageScanResult.extractedNameOnId}}</p>
        </div>

      </div>


      <super-button sxWidth="fill" iconEnd="chevron-forward-outline"
                    [action]="updateNameAsPerOcr"
      >Continue with this Name
      </super-button>


    </div>

    <div sxBackground="light" sxPadding="medium" sxCol sxGap="10px"
         sxRadius="medium">
      <p><b>Uploaded wrong pictures by mistake?</b><br>Use clear and good quality pictures for accurate name check.</p>
      <div sxHeight="100px" sxWidth="full" sxRow sxGap="10px">
        <div *ngFor="let image of documentImageScanResult?.scannedImages" [sxBackgroundImage]="image.imageUrl" sxWidth="fill"
             sxHeight="full" sxBgSize="cover" sxBgPosition="center" (click)="viewIdImage(image)">

        </div>
      </div>
      <super-button iconEnd="chevron-outline" type="outline" (click)="reUploadImages()">Re-Upload Pictures
      </super-button>
    </div>

  </div>

</ion-content>
