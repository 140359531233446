import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {firstValueFrom, of, switchMap} from 'rxjs';
import {createFeatureStore} from '../../../utils/feature-store.util';
import {AccountType, AccountWiseRecords} from '@qid/core';
import {EntityState} from '@ngrx/entity';
import {AuthSelectors, setActiveAccount} from '../../authentication';
import {AccountProfile} from "../model/account-profile.model";
import {AccountProfileApi} from "../services/account-profile.api";

interface AdditionalAccountWiseData {
  isProfileFetched: boolean;
  profile: AccountProfile;
}

const initialAccountWiseDataState = {
  isProfileFetched: false,
  profile: null
};

export const AccountProfileStore =
  createFeatureStore<AccountProfile, EntityState<AccountWiseRecords<AccountProfile, AdditionalAccountWiseData>>>('account-profile');

@Injectable()
export class AccountProfileFacade {

  private readonly apiService = inject(AccountProfileApi);

  public activeAccount$ = this.store.select(AccountProfileStore.selectors.selectActiveAccount);

  public profile$ = this.store.select(AccountProfileStore.selectors.selectActiveAccountAdditionalData).pipe(
    switchMap((data: AdditionalAccountWiseData) => {
      if (data) {
        return of(data.profile);
      }
      return of(null);
    })
  );

  constructor(private store: Store) {
    this.setAccountChangeListener();
  }

  setAccountChangeListener() {
    this.store.select(AuthSelectors.selectActiveAccount).subscribe(account => {
      if(account){
        this.init()
      }
    })
  }

  async init() {
    if (!await firstValueFrom(this.activeAccount$)) {
      await this.addAccount();
    }
  }

  private async addAccount() {

    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    if (!account_id) {
      return;
    }
    const accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType));

    const accountBasedEntity: AccountWiseRecords<AccountProfile> = AccountProfileStore.createAccount(account_id, accountType, initialAccountWiseDataState);

    this.store.dispatch(AccountProfileStore.actions.addAccount({accountBasedEntity: accountBasedEntity}));

  }

  async refresh() {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(AccountProfileStore.actions.loadRecordsForAccount({
      account_id: account_id, accountType: AccountType.organisation
    }));
  }


  async updateAttribute(attribute_id: string) {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    const profile = await this.apiService.updateDefaultAttribute(attribute_id);
    return this.store.dispatch(AccountProfileStore.actions.loadAdditionalDataAccountSuccess({
      data: {
        profile: profile
      },
      account_id: account_id
    }))

  }

  async updateDisplayPicture(file: string) {

    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));

    const accountAttributeRestResponse = await this.apiService.updateDisplayPicture(file);

    if (!accountAttributeRestResponse.data) {
      throw accountAttributeRestResponse.errorMessage
    }

    const currentProfile = await firstValueFrom(this.profile$);

    return this.store.dispatch(AccountProfileStore.actions.loadAdditionalDataAccountSuccess({
      data: {
        profile: {
          ...currentProfile,
          displayPictureUrl: accountAttributeRestResponse.data
        }
      },
      account_id: account_id
    }))

  }


}
