import {Component, OnInit} from '@angular/core';
import {Industry} from "../../model/industry.model";
import {ModalController} from "@ionic/angular";
import {IndustryService} from "../../services/industry.service";

@Component({
    selector: 'app-select-industry',
    templateUrl: './select-industry.component.html',
    styleUrls: ['./select-industry.component.scss'],
})
export class SelectIndustryComponent implements OnInit {

    orgName: string = '';
    isMandatory: boolean = false;
    industries: Industry[] = [];
    subIndustries: Industry[] = [];
    activeSlide = 1;
    isCreatingOrg: boolean = false;

    constructor(
        private modalController: ModalController,
        private industryService: IndustryService
    ) {
    }

    ngOnInit() {
        this.fetchIndustries();
    }

    async fetchIndustries() {
        this.industries = await this.industryService.readAll(null);
    }

    async onIndustrySelected(industry: Industry) {
        this.subIndustries = await this.industryService.readAll(industry._id);
        if (!this.subIndustries || this.subIndustries.length < 1) {
            return this.modalController.dismiss({industryId: industry._id,name:industry.name});
        }
        this.activeSlide = 2;
    }

    cancel() {
        this.modalController.dismiss({industryId: null});
    }


}

