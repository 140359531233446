import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxBottom]'})
export class BottomDirective implements OnInit {
  @Input() sxBottom: string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.style.bottom = this.sxBottom;
  }

}
