import {NgModule} from "@angular/core";
import {ListAccountAttributeComponent} from "./list-account-attribute/list-account-attribute.component";
import {CommonModule} from "@angular/common";
import {AccountAttributeViewComponent} from "./attribute-view/account-attribute-view.component";
import {SexyModule} from "@qid/sexy";
import {IonicModule} from "@ionic/angular";

@NgModule({
  declarations: [
    ListAccountAttributeComponent,
    AccountAttributeViewComponent],
  imports: [CommonModule, IonicModule, SexyModule],
  providers: [],
  exports: [ListAccountAttributeComponent,
    AccountAttributeViewComponent]
})
export class AccountAttributeComponentsModule {

}
