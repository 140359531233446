<page-loader #pageLoaderComponent>

    <div *ngIf="pendingShares" sxPadding="medium" sxCol sxGap="10px">

        <div sxPadding="medium" sxRadius="medium" sxBackground="light"
             sxCol sxAlign="center">
            <ion-icon sxTextSize="50px" name="reload-outline"></ion-icon>
            <h1 sxTextSize="large" sxWidth="80%" sxTextAlign="center">Resume previous submission</h1>
            <p sxColor="two" sxTextSize="small" sxMargin="5px 0" sxTextAlign="center">
                We've saved your recent incomplete transaction as draft. You can continue with the draft or choose to
                Start
                New</p>
            <div *ngIf="pendingShares?.length > 0 && pendingShares[0] != null" sxWidth="full" sxCol sxGap="10px">
                <h2 sxTextSize="medium" sxMargin="10px 5px 0">{{pendingShares[0].template?.purpose}}</h2>
                <p sxMargin="5px 5px 15px">{{pendingShares[0].createTime | date : 'MMM d, y, h:mm a'}}</p>
                <super-button iconEnd="chevron-forward"
                              [action]="onDraftShareSelected.bind(this,pendingShares[0])">Continue
                </super-button>
                <super-button type="outline" [action]="removeDraft.bind(this, pendingShares[0], 0)">
                  Start New
                </super-button>


            </div>
        </div>


    </div>

</page-loader>
