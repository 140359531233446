import {HttpClient} from "@angular/common/http";
import {ValidationUtil} from "@qid/core";
import {ModalController} from "@ionic/angular";
import {OtpChannel, OtpVerifier, OtpVerifyComponent, SendOtpDto, VerifyOtpResponse} from "../../user";
import {RestResponse} from "../../../interface/RestInterface";
import {RestUtil} from "../../../services/rest.util";

export class OtpUtil {

  static ApiEndpoint = 'otp/';

  public static async sendOtp(otpVerifier: OtpVerifier, http: HttpClient): Promise<RestResponse<any>> {

    if (otpVerifier.channel == OtpChannel.phone && ValidationUtil.isValidPhone(otpVerifier.receiverId) != true) {
      throw 'Please Enter a Valid 10-Digit Phone Number'
    }

    if (otpVerifier.channel == OtpChannel.email && !ValidationUtil.isEmailValid(otpVerifier.receiverId)) {
      throw "Please enter a valid Email"
    }

    let otpReq = new SendOtpDto(otpVerifier.channel, otpVerifier.receiverId);
    return RestUtil.send<string>({
      url: OtpUtil.ApiEndpoint + 'send',
      method: 'POST',
      body: otpReq,
    }, http)
  }


  public static async verify(otpId: string, otp: string, http: HttpClient) {
    return RestUtil.send<VerifyOtpResponse>({
      url : OtpUtil.ApiEndpoint + `verify/${otpId}?otp=${otp}`,
      method: 'GET',
    }, http);
  }

  public static async verifyWithModal(
    otpVerifier: OtpVerifier,
    modalController: ModalController,
    verificationCallback: (otpVerifier: OtpVerifier, otpInput: string) => Promise<boolean> = null) {
    const modal = await modalController.create({
      component: OtpVerifyComponent,
      componentProps: {
        otpVerifier: otpVerifier,
        verificationCallback: verificationCallback
      },
      animated: false
    })
    await modal.present()
    const modalRes = await modal.onDidDismiss();
    if (!modalRes.data || !modalRes.data.verifier) {
      return null
    }
    return modalRes.data.verifier
  }

  public static async sendAndVerify(otpVerifier: OtpVerifier,
                                    httpClient: HttpClient,
                                    modalController: ModalController,
                                    verificationCallback: (otpVerifier: OtpVerifier, otpInput: string) => Promise<boolean> = null): Promise<OtpVerifier> {

    const sendOtpRes = await OtpUtil.sendOtp(otpVerifier, httpClient);

    if (!sendOtpRes.data) {
      throw sendOtpRes.errorMessage
    }
    otpVerifier.otpId = sendOtpRes.data.otpId;

    if (!otpVerifier.otpId) {
      throw "Some problem occurred sending OTP"
    }
    return OtpUtil.verifyWithModal(otpVerifier, modalController, verificationCallback);
  }


}
