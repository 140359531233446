import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CollectionTemplate, CollectionTemplateRepository} from "../../modules/collection-template";
import {OrganisationRelationshipFacade} from '../../modules/organisation-relationship';
import {ScannerTemplateOptions} from "../../models/scanner-template-options";


@Component({
  selector: 'template-selector',
  templateUrl: './template-selector.component.html',
  styleUrls: ['./template-selector.component.scss'],
})
export class TemplateSelectorComponent implements OnInit {

  templates$ = this.collectionTemplateRepository.allCollectionTemplates;
  sharedTemplates$ = this.orgRelationshipFacade.parentCollectionTemplates$;

  @Input() isMultiSelection: boolean = true;
  @Input() isShowSelectAll: boolean = false;

  @Input() scannerTemplateOptions: ScannerTemplateOptions[] = [];
  @Output() scannerTemplateOptionsChange: EventEmitter<ScannerTemplateOptions[]> = new EventEmitter<ScannerTemplateOptions[]>()

  @Input() isAllSelected : boolean = false;
  @Output() isAllSelectedChange : EventEmitter<boolean> = new EventEmitter<boolean>()

  @Output() onTemplateSelected: EventEmitter<CollectionTemplate> = new EventEmitter<CollectionTemplate>()

  constructor(private collectionTemplateRepository: CollectionTemplateRepository,
              private orgRelationshipFacade: OrganisationRelationshipFacade
  ) {
  }

  ngOnInit() {
    this.collectionTemplateRepository.init()
    this.orgRelationshipFacade.initSharedTemplates()
  }

  onCheckboxClick(template: CollectionTemplate, event: any) {
    this.isAllSelected = false;
    this.scannerTemplateOptions = [...this.scannerTemplateOptions];
    let index = -1;
    let i = 0
    for (let option of this.scannerTemplateOptions) {
      if (option.template._id == template._id) {
        index = i;
      }
      i++
    }

    if (!event.detail.checked && index > -1) {
      this.scannerTemplateOptions.splice(index, 1)
    }

    if (event.detail.checked && index == -1) {
      this.scannerTemplateOptions.push(new ScannerTemplateOptions(template.purpose, {_id: template._id}))
    }

    this.updateScannerTemplateOptions();

  }

  updateScannerTemplateOptions() {
    this.scannerTemplateOptionsChange.emit(this.scannerTemplateOptions)
  }

  isChecked(template: CollectionTemplate) {
    for (let option of this.scannerTemplateOptions) {
      if (option.template._id == template._id) {
        return true
      }
    }
    return false
  }

  onTemplateClicked(template: any) {
    this.onTemplateSelected.emit({ ...template })
  }

  selectAllToggle(){
    if(!this.isAllSelected){
      this.scannerTemplateOptions = [];
    }
  }
}
