import {Directive, ElementRef, Input, OnInit} from "@angular/core";

const style = getComputedStyle(document.body)

@Directive({selector: '[sxColor]'})
export class ColorDirective implements OnInit {

  @Input() sxColor: 'two'| 'one' | 'three' | 'primary' | 'dark' | 'light' | 'light-two' | 'success' | 'danger' | 'darkGradient' | 'lightGradient' | 'warning' | string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    switch (this.sxColor) {
      case "dark" :
      case "light" :
      case "light-two" :
      case "success" :
      case "danger" :
      case "one" :
      case "two" :
      case "three" :
      case "warning" :
      case "primary" :
        return this.el.nativeElement.style.color = style.getPropertyValue(`--ion-color-${this.sxColor}`)
      default :
        return this.el.nativeElement.style.color = this.sxColor
    }

  }

}
