import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestRequest, RestResponse} from "../interface/RestInterface";
import {AccountType, DisplayService} from "@qid/core";
import {Environment, ENVIRONMENT} from "@qid/env";
import {RestUtil} from "./rest.util";
import {AuthenticationService} from "../modules/authentication";


@Injectable({
  providedIn: 'root',
})
export class RestService {

  constructor(private http: HttpClient,
              private displayService: DisplayService,
              private authenticationService: AuthenticationService,
              @Inject(ENVIRONMENT) private env: Environment,
  ) {
    RestUtil.BaseUrl = env.url;
  }

  public async send<T>(request: RestRequest, tokenRefreshCount = 0): Promise<RestResponse<T>> {
    return RestUtil.send<T>(request, this.http, this.displayService, this.authenticationService, tokenRefreshCount)
  }

  public async sendFiles<T>(request: RestRequest, tokenRefreshCount = 0): Promise<RestResponse<T>> {
    request.contentType = 'multipart/form-data';
    return RestUtil.send(request, this.http, this.displayService, this.authenticationService, tokenRefreshCount)
  }


  public static setAuthToken(authToken: string) {
    RestUtil.AuthToken = authToken;
  }

  public static setAccount(account_id: string, accountType: AccountType) {
    RestUtil.Account_id = account_id;
    RestUtil.AccountType = accountType;
  }

  public static setDeveloperKeys(authToken: string, tokenId: string) {
    RestUtil.AuthToken = authToken;
    RestUtil.TokenId = tokenId;
  }

}
