import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EditOrganisationRelationshipPage} from "./edit-organisation-relationship-page.component";


const routes: Routes = [
    {
        path: '',
        component: EditOrganisationRelationshipPage,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class EditOrganisationRelationshipPageRoutingModule {
}
