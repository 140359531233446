export class CreateFamilyMemberDto {

  name: string;
  email: string;
  phoneNumber: string;
  countryCode: string = "+91";


  constructor(name: string = null, email: string = null, phoneNumber: string = null) {
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
  }
}