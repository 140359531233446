import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {AbsoluteValue} from "../util/AbsoluteValue";

@Directive({selector: '[sxBorderWidth]'})
export class BorderWidthDirective implements OnInit {

  @Input() sxBorderWidth: 'small' | 'medium' | 'large' | string;

  static readonly themeValues = {
    small: "1px",
    medium: "2px",
    large: "5px"
  }

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    try {
      this.el.nativeElement.style.borderWidth = AbsoluteValue.getSplitValues(this.sxBorderWidth, BorderWidthDirective.themeValues);
    } catch (e) {
      console.error("SX Border width PROPERTY", e)
    }
  }



}
