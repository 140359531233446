<ion-header>
  <ion-toolbar>
    <ion-button fill="clear" slot="start" (click)="onBackClicked()">Back</ion-button>
  </ion-toolbar>
</ion-header>
<ion-content>

  <div sxPadding="medium large">
    <h1>{{label}}</h1>
    <p>You can set your {{label}} from the following verified sources.</p>
  </div>

  <div sxCol sxGap="10px" sxPadding="medium">

    <account-attribute-view *ngFor="let attribute of filteredAccountAttribute$ | async"
                            [attribute]="attribute" (click)="onClicked(attribute._id)"
    ></account-attribute-view>

  </div>

</ion-content>
