import {register} from 'swiper/element/bundle';
import {Component} from "@angular/core";
import {AuthGuard} from "./services/guards/auth.guard";
import {AppVmsService} from "@qid/entities/app-vms";

register();

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  public loadingGuard: boolean = false;

  constructor(
      private authGuard: AuthGuard,
      private vmsService: AppVmsService,
  ) {
    this.initialize();
  }

  private async initialize() {
    if (!(await this.vmsService.checkEntry())) {
      this.loadingGuard = true;
      return;
    }

    this.authGuard.loading.subscribe((val) => {
      this.loadingGuard = val;
      if (!this.loadingGuard) {
      }
    });
  }


}
