import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";
import {IdComponentsModule} from '../../compenents/id-components.module';
import {ViewMultipleDocumentsPageRoutingModule} from "./view-multiple-documents-routing.module";
import {ViewMultipleDocumentsPage} from "./view-multiple-documents.page";
import {HideForPlatformDirective} from "@qid/core";



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ViewMultipleDocumentsPageRoutingModule,
    SexyModule,
    IdComponentsModule,
    SuperModule,
    HideForPlatformDirective
  ],
  declarations: [ViewMultipleDocumentsPage]
})
export class ViewMultipleDocumentsPageModule {
}
