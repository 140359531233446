<div sxCol sxBackground="base" sxPadding="medium"
     sxAlign="center" sxMatchParent sxGap="10px"
>

    <h1>Your data is encrypted</h1>
    <p>Enter password to unlock your data</p>
    <input type="password" [(ngModel)]="passwordInput">
    <super-button [action]="unlock">Unlock</super-button>

</div>
