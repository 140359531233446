<ion-header *ngIf="pageMode">
  <ion-toolbar>
    <ion-button slot="start" fill="clear" (click)="close()">Cancel</ion-button>
    <ion-title>Add Documents</ion-title>
    <ion-button slot="end" fill="clear" (click)="save()">Done</ion-button>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div
    sxPadding="medium"
    id="sid-selector-panel"
  >
    <div class="skeleton" *ngIf="(sids$ | async)?.length < 1">
      <ion-skeleton-text animated="true"></ion-skeleton-text>
      <ion-skeleton-text animated="true"></ion-skeleton-text>
      <ion-skeleton-text animated="true"></ion-skeleton-text>
    </div>

    <div sxCol sxGap="10px" *ngIf="sids$ | async as sids">
      <p *ngIf="sids?.length > 0" sxMargin="10px 10px 5px" sxTextSize="small" sxColor="two">Global Documents</p>
      <div
        *ngFor="let sid of sids"
        sxRow
        sxDistribution="spaceBetween"
        sxAlign="center"
        sxBackground="light"
        sxRadius="10px"
        sxPadding="medium">
        <p sxMargin="0" sxTextEllipse sxWidth="fill">{{ sid.name }}</p>
        <button
          *ngIf="!isSidSelected(sid, selectedSids)"
          sxColor="primary"
          sxPadding="8px"
          sxRadius="small"
          sxTextSize="14px"
          sxWidth="60px"
          sxBorderWidth="1px"
          sxBorderColor="one"
          sxBackground="light"
          sxRow
          sxDistribution="center"
          sxAlign="center"
          sxGap="5px"
          (click)="addSid(sid)">
          Add
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </button>
        <button
          *ngIf="isSidSelected(sid,selectedSids)"
          sxBackground="one"
          sxColor="two"
          sxPadding="8px"
          sxRadius="small"
          sxTextSize="14px">
          Added
        </button>
      </div>
    </div>

    <div sxCol sxGap="10px" *ngIf="organisationSID$ | async as organisationSids">
      <p sxMargin="25px 10px 5px" sxTextSize="small" sxColor="two">Your Custom Documents Type</p>
      <div
        *ngFor="let customSid of organisationSids"
        sxRow
        sxDistribution="spaceBetween"
        sxAlign="center"
        sxBackground="light"
        sxRadius="10px"
        sxPadding="medium">
        <p sxMargin="0" sxTextEllipse sxWidth="fill">{{ customSid.name }}</p>
        <button
          *ngIf="!isSidSelected(customSid, selectedSids)"
          sxColor="primary"
          sxPadding="8px"
          sxRadius="small"
          sxTextSize="14px"
          sxWidth="60px"
          sxBorderWidth="1px"
          sxBorderColor="one"
          sxBackground="light"
          sxRow
          sxDistribution="center"
          sxAlign="center"
          sxGap="5px"
          (click)="addSid(customSid)">
          Add
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </button>
        <button
          *ngIf="isSidSelected(customSid, selectedSids)"
          sxBackground="one"
          sxColor="two"
          sxPadding="8px"
          sxRadius="small"
          sxTextSize="14px">
          Added
        </button>
      </div>
    </div>
    <div
      sxRow
      sxDistribution="spaceBetween"
      sxAlign="center"
      sxRadius="10px"
      sxBorderWidth="1px"
      sxBorderColor="one"
      sxMargin="15px 0 0"
      (click)="createSid()"
      sxPadding="medium">
      <p sxMargin="0" sxTextEllipse sxWidth="fill">Create Doc</p>
      <button
        sxColor="primary"
        sxPadding="8px"
        sxRadius="small"
        sxTextSize="14px"
        sxWidth="60px"
        sxBorderWidth="1px"
        sxBorderColor="one"
        sxBackground="light"
        sxRow
        sxDistribution="center"
        sxAlign="center"
        sxGap="5px">
        <ion-icon name="add-outline"></ion-icon>
      </button>
    </div>

    <!--    <p sxMargin="20px 5px 10px">Your custom documents</p>-->
    <!--    <super-button-->
    <!--      iconStart="add-circle-outline"-->
    <!--      borderRadius="small"-->
    <!--      textAlign="start"-->
    <!--      background="light"-->
    <!--      (click)="addCustomId()">-->
    <!--      Create Document-->
    <!--    </super-button>-->
  </div>

</ion-content>
