import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateUtil, DisplayService, FieldInputType, TextUtil, ValidationUtil } from '@qid/core';
import { ModalController } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { SuperSelectComponent } from '../super-select/super-select.component';
import * as country from 'country-list-with-dial-code-and-flag';
import { SuperInputModalComponent } from '../super-input-modal/super-input-modal.component';
import { ENVIRONMENT, Environment } from '@qid/env';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

@Component({
  selector: 'super-input',
  templateUrl: './super-input.component.html',
  styleUrls: ['./super-input.component.scss']
})
export class SuperInputComponent implements OnInit {
  @ViewChild('inputField') inputField: HTMLInputElement;

  @Input() label: string;
  @Input() iconStart: string;
  @Input() readonly: boolean = false;
  @Input() checkBoxRequired = false;
  @Input() helpNote: string = null;
  @Input() placeholder: string = '';
  @Input() type: any = 'text';
  @Input() options: any;
  @Input() dropDownLabelKey: string;
  @Input() required: boolean = false;
  @Input() validate: boolean = true;
  @Input() errorNote: string = null;
  @Input() showCountrySelector: boolean = false;
  @Input() fixedCountry: string = null;
  @Input() fieldCallback: (value) => {} = null;
  @Output() errorNoteChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() countryCode: string = '+91';
  @Output() countryCodeChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  selectedInputType = 'input';
  isFocused: boolean = false;

  phoneInput: string = '';

  constructor(
    private displayService: DisplayService,
    private modalController: ModalController,
    private datePipe: DatePipe,
    @Inject(ENVIRONMENT) public env: Environment
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.setType();
      if (this.type == FieldInputType.date) {
        const date = ValidationUtil.isStringEmpty(this.value)
          ? new Date()
          : new Date(this.value);
        this.setDate(this.datePipe.transform(date, 'y-MM-dd'));
      }
    }, 10);
  }

  setType() {
    if (this.type == FieldInputType.textarea) {
      return this.selectedInputType = 'textarea';
    }

    if (this.type == FieldInputType.country || this.type == FieldInputType.dropdown) {
      return this.selectedInputType = 'dropdown';
    }

    if (this.type == FieldInputType.phone) {
      this.splitPhoneNumber();
      return this.selectedInputType = 'phone';
    }

    if (this.type == FieldInputType.time) {
      this.value = this.value || DateUtil.getCurrentTime24();
      this.onValueChange();
      this.doValidation();
      return this.selectedInputType = 'time';
    }

    return this.selectedInputType = 'input';
  }

  async openCountryCodeSelector() {
    if (!ValidationUtil.isStringEmpty(this.fixedCountry)) {
      return this.showCountryUnavailable();
    }

    const modal = await this.modalController.create({
      component: SuperSelectComponent,
      componentProps: {
        options: this.getOptions(),
        title: this.label
      }
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    if (res?.data?.value) {
      this.countryCode = res.data.value;
      this.countryCodeChange.emit(this.countryCode);
    }
  }

  showCountryUnavailable() {
    this.displayService.alert(
      `Only Phone Numbers issued by ${this.fixedCountry} are supported`,
      'You can continue with your email address'
    );
  }

  onValueChange(value?: string) {
      this.valueChange.emit(value ?? this.value);
  }

  doValidation() {
    if (!this.validate) {
      return;
    }
    this.publishError(null);

    if (this.required && ValidationUtil.isStringEmpty(this.value)) {
      this.publishError('Required');
      return;
    }

    if (ValidationUtil.isStringEmpty(this.value)) {
      return;
    }

    switch (this.type) {
      case FieldInputType.phone:
        return ValidationUtil.isValidPhone(this.countryCode + this.phoneInput)
          ? null
          : this.publishError('Please enter a valid phone number');
      case FieldInputType.email:
        this.value = TextUtil.removeAllSpaces(this.value?.toString());
        this.onValueChange();
        return ValidationUtil.isEmailValid(this.value)
          ? null
          : this.publishError('Please enter a valid email');
    }
  }

  private publishError(error) {
    this.errorNote = error;
    this.errorNoteChange.emit(this.errorNote);
  }

  isShowCountrySelector() {
    return (
      this.type?.toLowerCase() == 'phone' || this.type?.toLowerCase() == 'tel'
    );
  }

  getOptions() {
    if (this.type == FieldInputType.country) {
      return TextUtil.getCountryNameList();
    }
    if (this.type == FieldInputType.phone) {
      return country.map((data) => {
        return data.dial_code;
      });
    }
    if (!this.options) {
      return [];
    }
    if (Array.isArray(this.options)) {
      return this.options;
    }

    let o: string[] = this.options.split(',');
    return o.map((x) => {
      return x.replace(/^\s+/g, '');
    });
  }

  isShowDropDown() {
    return (
      (this.type == FieldInputType.country ||
        this.type == FieldInputType.dropdown) && !this.checkBoxRequired
    );
  }

  getLabel(option: any) {

    if (typeof option === 'string' || option instanceof String) {
      return option;
    }

    if (this.dropDownLabelKey) {
      return option[this.dropDownLabelKey];
    }

  }

  async updateWithModal() {
    const modal = await this.modalController.create({
      component: SuperInputModalComponent,
      componentProps: {
        title: this.label,
        action: (value) => {
          this.fieldCallback(value);
        },
        inputValue: this.value,
        placeholder: this.placeholder
      },
      keyboardClose: false,
      animated: false
    });
    modal.present();
  }

  onFieldClicked() {
    if (this.fieldCallback) {
      this.updateWithModal();
    }
  }

  async openSuperSelect() {
    if (this.readonly) {
      return;
    }
    const modal = await this.modalController.create({
      component: SuperSelectComponent,
      componentProps: {
        options: this.getOptions(),
        title: this.label,
        labelKey: this.dropDownLabelKey
      }
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    if (res?.data?.value) {
      this.setDate(res.data.value);
    }
  }

  private setDate(date) {
    this.value = date;
    this.onValueChange();
    this.doValidation();
  }

  getDropdownSelection(value: any) {
    if (!value) {
      return;
    }
    if (ValidationUtil.isStringEmpty(this.dropDownLabelKey)) {
      return value;
    }
    return value[this.dropDownLabelKey];
  }

  public onFocusChanges(isFocused: boolean) {
    this.isFocused = isFocused;
  }

  splitPhoneNumber(): void {
    const phone = parsePhoneNumberFromString('+' + this.value);
    if (phone) {
      this.countryCode = '+' + phone.countryCallingCode;
      this.phoneInput = phone.nationalNumber;
    }
  }

  onPhoneInputChange() {
    this.doValidation();
    const formattedPhoneNumber = this.countryCode.replace('+', '') + this.phoneInput;
    this.valueChange.emit(formattedPhoneNumber);
  }
}
