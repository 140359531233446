import {Injectable} from "@angular/core";
import {User} from "../model/User";
import {String} from "typescript-string-operations";
import {CreateUserDto} from "../dto/create-user.dto";
import {OtpVerifier} from "../model/OtpVerifier";
import {AccountType, ImageUtil, ValidationUtil} from "@qid/core";
import {Store} from "@ngrx/store";
import {UsersActions, UsersSelectors} from "../../index";
import {BehaviorSubject, firstValueFrom} from "rxjs";
import {RestService} from "../../../../services/rest.service";
import {AuthActions, AuthSelectors, selectAuthToken, signOut} from "../../../authentication";

@Injectable({
  providedIn: "root"
})
export class UserService {
  static readonly nodeUser: string = "user/";

  static readonly NodeUserDisplayPic: string =
    UserService.nodeUser + "display-pic";
  static readonly nodeUserEmail: string =
    UserService.nodeUser + "isEmail?email={0}";
  static readonly nodeUserFCM: string = "user/fcm?token={0}";
  static readonly nodeSearch: string =
    UserService.nodeUser + "search?query={0}";
  static readonly nodeCreateAndInvite: string =
    UserService.nodeUser + "create-invite";
  static readonly nodeAnalytics: string =
    UserService.nodeUser + 'analytics?startDate={0}&endDate={1}';

  public authenticatedUser: BehaviorSubject<User> =
    new BehaviorSubject<User>(null);

  constructor(
    private restService: RestService,
    private store: Store
  ) {

    this.init();

  }


  init() {
    this.store.select(selectAuthToken).subscribe(authToken => {
      if (!ValidationUtil.isNull(authToken)) {
        this.store.dispatch(UsersActions.fetchAuthenticatedUser())
      }
    })
  }

  async logLogin() {
    return this.restService.send({
      url: UserService.nodeUser + "login",
      method: "POST"
    });
  }


  public getAnalytics(startDate: number = null, endDate: number = null) {
    return this.restService.send({
      url: String.format(UserService.nodeAnalytics, startDate, endDate),
      method: 'GET',
    });
  }

  createUser(email: string, password: string, name: string) {
    let body = {email: email, name: name, password: password};
    return this.restService.send({
      url: UserService.nodeUser,
      body: body,
      method: 'POST'
    });
  }


  async updateUser(user: User) {
    return this.restService.send<User>({
      url: UserService.nodeUser,
      body: user,
      method: "PUT",
      query: {
        userId: user._id
      }
    })
  }


  async checkEmail(email: any) {
    return (
      await this.restService.send<User>({
        url: String.format(UserService.nodeUserEmail, email),
        method: "GET"
      })
    ).data;
  }

  async isPhoneRegistered(phone: any) {
    if (!phone) {
      return null
    }
    return (
      await this.restService.send<{ name: string, isPhone: boolean }>({
        url: UserService.nodeUser + 'is-phone-registered',
        method: "GET",
        query: {
          phone: phone
        }
      })
    ).data;
  }

  async isEmailNotAssociatedWithPhone(email: any) {
    return (
      await this.restService.send({
        url: String.format(UserService.nodeUserEmail, email),
        method: "GET"
      })
    ).data;
  }

  async setUserDefaultOrganisation(orgId: string) {
    const user = await firstValueFrom(this.store.select(UsersSelectors.selectAuthenticatedUser));
    const res = await this.updateUser({...user, defaultOrgId: orgId});
    if (res && res.data) {
      this.store.dispatch(UsersActions.fetchAuthenticatedUserSuccess({authenticatedUser: res.data}))
    }
  }


  public async createAndInvite(createUserDto: CreateUserDto) {
    return (
      await this.restService.send<User>({
        url: UserService.nodeCreateAndInvite,
        method: "POST",
        body: createUserDto
      })
    ).data;
  }

  //READ USER ----------------------------------------------------------->

  async getUserById(userId: any) {
    return (
      await this.restService.send<User>({
        url: UserService.nodeUser + userId,
        method: "GET"
      })
    ).data;
  }


  public async getUserByToken() {
    return (
      await this.restService.send<User>({
        url: UserService.nodeUser,
        method: "GET"
      })
    ).data;
  }


  // UPDATE USER -------------------------------------------------------->
  public async updateUserPhone(phoneVerifier: OtpVerifier) {
    const url = UserService.nodeUser + "update-phone";
    const updatedUser = (
      await this.restService.send<User>({
        url: url,
        method: "PUT",
        body: phoneVerifier,
        throwError: true
      })
    ).data;
    if (updatedUser && updatedUser._id) {
      this.store.dispatch(UsersActions.fetchAuthenticatedUserSuccess({authenticatedUser: updatedUser}))
    }
    return updatedUser;
  }

  public async updateUserEmail(emailVerifier: OtpVerifier) {
    return this.restService.send<User>({
      url: UserService.nodeUser + "update-email",
      method: "PUT",
      body: emailVerifier
    })
  }

  public async updateDisplayPic(file) {
    //Compress Image
    const formData = await ImageUtil.getCompressedFormData([file]);
    //UPLOAD
    const newUser = (
      await this.restService.sendFiles<any>({
        url: UserService.NodeUserDisplayPic,
        method: "POST",
        body: formData
      })
    ).data;
    this.store.dispatch(UsersActions.fetchAuthenticatedUserSuccess({authenticatedUser: newUser}))
    const activeAccount_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    if (activeAccount_id == newUser._id) {
      this.store.dispatch(AuthActions.setActiveAccount({account: newUser, accountType: AccountType.user}))
    }
    return;
  }

  // NOTIFICATION AND FCM ------------------------------------------------------>

  public async registerFCM(fcmToken) {
    const url = String.format(UserService.nodeUserFCM, fcmToken);
    await (
      await this.restService.send({
        url: url,
        method: "POST"
      })
    ).data;
  }

  // DELETE USER ---------------------------------------------------------------->

  public async delete(emailVerifier: OtpVerifier) {
    const res = await this.restService.send<User>({
      url: UserService.nodeUser,
      method: "DELETE",
      body: emailVerifier
    });
    this.store.dispatch(signOut())
    return res.data;
  }


}
