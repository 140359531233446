import {EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import * as ScannersActions from './scanners.actions';
import {Scanner} from "../models/scanner";
import {AccountWiseRecords, createAccountWiseEntityAdapters} from "@qid/core";


export const SCANNERS_FEATURE_KEY = 'scanners';

export const {
  accountAdapter,
  recordAdapter,
  createAccount
} = createAccountWiseEntityAdapters<Scanner>(SCANNERS_FEATURE_KEY)

const recordSelectors = recordAdapter.getSelectors()

export interface AccountWiseScannersState extends EntityState<AccountWiseRecords<Scanner>> {

}

export const initialScannersState: AccountWiseScannersState =
  accountAdapter.getInitialState({});

const reducer = createReducer(
  initialScannersState,

  on(ScannersActions.addAccount, (state, {accountBasedEntity}) => {
    return accountAdapter.addOne(accountBasedEntity, state)
  }),

  on(ScannersActions.loadScannersForAccount, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        isLoading: true
      }
    }, state)
  }),

  on(ScannersActions.loadScannersSuccess, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        isLoading: false,
        records: recordAdapter.setAll(action.scanners, state.entities[action.account_id].records)
      }
    }, state)
  }),
  on(ScannersActions.addScanner, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.setAll([action.scanner, ...recordSelectors.selectAll(state.entities[action.account_id].records)], state.entities[action.account_id].records)
      }
    }, state)
  }),
  on(ScannersActions.removeScanner, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.removeOne(action.scanner_id, state.entities[action.account_id].records)
      }
    }, state)
  }),
  on(ScannersActions.updateRecord, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.updateOne({
          id: action.record_id,
          changes: {
            ...action.updatedRecord
          }
        }, state.entities[action.account_id].records)
      }
    }, state)
  }),
);

export function scannersReducer(
  state: AccountWiseScannersState | undefined,
  action: Action
) {
  return reducer(state, action);
}



