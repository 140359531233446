import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MasterTemplateService} from "../services/master-template.service";
import {MasterTemplate} from "../../models/MasterTemplate";
import {AlertController, LoadingController} from "@ionic/angular";
import {DisplayService, ValidationUtil} from "@qid/core";
import {Store} from "@ngrx/store";
import {firstValueFrom} from "rxjs";
import {
  CollectionTemplate,
  CollectionTemplateActions,
  CollectionTemplateRepository, CreateCollectionTemplateDto
} from "../../../collection-template";
import {AuthSelectors} from "../../../authentication";

@Component({
  selector: 'browse-master-templates',
  templateUrl: './browse-master-templates.component.html',
  styleUrls: ['./browse-master-templates.component.scss'],
})
export class BrowseMasterTemplatesComponent implements OnInit {

  public masterTemplates: MasterTemplate[] = [];

  @Output() onCreate: EventEmitter<CollectionTemplate> = new EventEmitter<CollectionTemplate>()

  constructor(private masterTemplateService: MasterTemplateService,
              private alertController: AlertController,
              private collectionTemplateRepository: CollectionTemplateRepository,
              private displayService: DisplayService,
              private loadingController: LoadingController,
              private store: Store
  ) {
  }

  ngOnInit() {
    this.fetchMasterTemplates();
  }

  async fetchMasterTemplates() {
    this.masterTemplates = await this.masterTemplateService.readAll(null);
  }

  createTemplate = async (masterTemplateId: string) => {
    const template: CollectionTemplate = await this.masterTemplateService.clone(masterTemplateId)
    if (!template || !template._id) {
      await this.displayService.toast({
        message: "Form creation failed. Upgrade your plan to access more forms.",
        color: "danger"
      })
      return
    }
    await this.displayService.toast({
      message: 'Form Created',
      color: 'success',
      icon: 'checkmark-outline',
    });
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(CollectionTemplateActions.addRecord({record: template, account_id: account_id}))
    this.onCreate.emit(template)
  }

  createCollectionTemplate = async () => {

    const alert = await this.alertController.create({
      header: "Create New Form",
      subHeader: 'Please enter a name for your new form.',
      inputs: [
        {
          label: "Form Name",
          placeholder: 'Form Name',
          name: 'formName'
        }
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel"
        },
        {
          text: 'Create',
          handler: async (data) => {
            if (ValidationUtil.isStringEmpty(data.formName) || ValidationUtil.isOnlyWhitespace(data.formName)) {
              this.displayService.toast({message: "Form name cannot be empty"})
              return false
            }
            let dto = new CreateCollectionTemplateDto();
            dto.purpose = data.formName;
            const loader = await this.loadingController.create({message: "Creating Form"});
            loader.present();
            const template = await this.collectionTemplateRepository.create(dto);
            if (template) {
              loader.dismiss();
              this.displayService.toast({message: 'Form Created', color: "success"});
              this.onCreate.emit(template);
            } else {
              loader.dismiss();
              this.displayService.toast({message: "Template not created", color: "warning"});
            }

          }
        }
      ]
    })

    alert.present();

  }


}
