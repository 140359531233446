import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CreateShareDto, Participant, ParticipantRoles, Share, ShareService, ShareStatus} from "@qid/common";
import {CollectionTemplate, Scanner, UsersSelectors} from "@qid/common";
import {AccountType, ValidationUtil} from "@qid/core";
import {PageLoaderComponent} from "@qid/super";
import {Store} from "@ngrx/store";
import {firstValueFrom} from "rxjs";
import {AccountUtil} from "@qid/common";

@Component({
  selector: 'gateway-continue-previous',
  templateUrl: './gateway-continue-previous.component.html',
  styleUrl: './gateway-continue-previous.component.scss',
})
export class GatewayContinuePreviousComponent {

  @ViewChild('pageLoaderComponent') pageLoaderComponent: PageLoaderComponent;

  @Input() template: CollectionTemplate;
  @Input() scanner: Scanner;
  @Output() onSubmit: EventEmitter<Share> = new EventEmitter<Share>();

  public pendingShares: Share[] = null;

  authenticatedUser$ = this.store.select(UsersSelectors.selectAuthenticatedUser);

  constructor(private shareService: ShareService,
              private store: Store
  ) {
  }

  async initialize(templateId: string, scanner: Scanner) {
    this.pendingShares = await this.shareService.findMyOpenShareByTemplateId(templateId, scanner.org?._id);
    if (!this.pendingShares || this.pendingShares.length < 1) {
      return this.createShare();
    }
    this.pageLoaderComponent.hideLoader();
  }

  onDraftShareSelected = async (share: Share) => {

    this.setSavedValueOfOtherFormFields(share)

    this.onSubmit.emit(share)
  }

  setSavedValueOfOtherFormFields(share: Share) {

    if (!this.template.otherInfo) {
      return
    }

    const commonFields = [...this.template.otherInfo?.filter(field => !field.isForEachParticipant)]

    for (const field of commonFields) {
      const matchingField = share.otherFields.find(prevField => {
        let field1 = prevField.name?.length > 0 || prevField.label
        let field2 = field.name?.length > 0 || field.label
        return field1 === field2
      });
      if (matchingField) {
        field.value = matchingField.value;
      }
    }
    share.otherFields = commonFields

    for (let participant of share.participants) {

      if (participant.roles.indexOf(ParticipantRoles.sharer) < 0) {
        continue;
      }
      const participantFields = this.template.otherInfo?.filter(field => ValidationUtil.isTrue(field.isForEachParticipant))

      for (const field of participantFields) {
        const matchingField = participant.otherFields.find(prevField => {
          let field1 = prevField.name?.length > 0 || prevField.label
          let field2 = field.name?.length > 0 || field.label
          return field1 === field2
        });

        if (matchingField) {
          field.value = matchingField.value;
        }
      }

      participant.otherFields = [...participantFields]

    }
  }

  createShare = async () => {

    let createShareDto = new CreateShareDto()
    createShareDto.participants = [];

    let orgParticipant = new Participant()
    orgParticipant.account_id = this.scanner?.org?._id;
    orgParticipant.accountType = AccountType.organisation
    orgParticipant.accountSnapshot = AccountUtil.getAccountSnapshot(this.scanner.org)
    orgParticipant.roles = [ParticipantRoles.organiser, ParticipantRoles.admin]
    createShareDto.participants.push(orgParticipant)

    const authenticatedUser = await firstValueFrom(this.authenticatedUser$)

    let userParticipant = new Participant()
    userParticipant.account_id = authenticatedUser?._id;
    userParticipant.accountType = AccountType.user
    userParticipant.accountSnapshot = AccountUtil.getAccountSnapshot(authenticatedUser)
    userParticipant.roles = [ParticipantRoles.sharer, ParticipantRoles.admin]
    userParticipant.otherFields = this.template.otherInfo?.filter(field => ValidationUtil.isTrue(field.isForEachParticipant));
    createShareDto.participants.push(userParticipant)
    createShareDto.templateId = this.template._id
    createShareDto.otherFields = this.template.otherInfo?.filter(field => !field.isForEachParticipant);
    createShareDto.status = ShareStatus.open;

    const share = await this.shareService.createShare(createShareDto);
    this.pageLoaderComponent.hideLoader();
    return share;

  }

  removeDraft = async (share: Share, i: number) => {
    await this.shareService.update(share._id, {status: ShareStatus.cancelled})
    this.pendingShares.splice(i, 1)
    if (this.pendingShares.length < 1) {
      const share = await this.createShare();
      this.onDraftShareSelected(share)
    }
  }
}
