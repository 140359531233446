<div sxPadding="medium" sxCol sxGap="10px"
     *ngIf="share && share.participants && share.participants[activeParticipantIndex]">

    <div sxRow sxAlign="center" sxGap="10px" sxPadding="0 20px">
        <avatar-round [imageUrl]="share.participants[activeParticipantIndex]?.account?.displayPictureUrl"
                      [title]="share.participants[activeParticipantIndex]?.account?.name"
                      [size]="30"></avatar-round>
        <p>{{share.participants[activeParticipantIndex]?.account?.name}}</p>
    </div>
    <view-signature
            [signatoryName]="share.participants[activeParticipantIndex].account?.name"
            [(signature)]="share.participants[activeParticipantIndex].signature"
            [familyMember_id]="share.participants[activeParticipantIndex].memberId"
            [onSubmit]="onSignatureSubmit"></view-signature>


</div>
