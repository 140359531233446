<div *ngIf="accountProfile$ | async as profile">
  <div *ngIf="profile?.name?.value" sxRow sxAlign="center" sxGap="5px">
    <h1 sxMargin="0" [sxTextWeight]="textWeight" [sxTextSize]="textSize">{{ profile?.name?.value }}</h1>
    <img [sxWidth]="iconSize" [sxHeight]="iconSize" src="assets/blue-tick.png">
  </div>
  <div *ngIf="!profile?.name">
    <h1 sxMargin="0" [sxTextSize]="textSize">{{ authenticatedUserName$ | async }}</h1>
  </div>
</div>
<div *ngIf="!(accountProfile$ | async)">
  <h1 sxMargin="0" [sxTextSize]="textSize">{{ authenticatedUserName$ | async }}</h1>
</div>
