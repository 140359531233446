import {Injectable} from "@angular/core";
import {AccountAttribute} from "../model/account-attribute.model";
import { RestService } from "../../../services/rest.service";

@Injectable()
export class AccountAttributeApi {
  public static readonly ApiEndpoint = 'account-attribute'

  constructor(private restService: RestService) {
  }

  create() {

  }

  read() {

  }

  readAll() {
    return this.restService.send<AccountAttribute[]>({
      url: AccountAttributeApi.ApiEndpoint,
      method: "GET"
    })
  }

  update() {

  }

  delete() {

  }
}
