<div sxPadding="0 medium">
  <h1 sxPadding="0 10px" sxTextSize="large" sxColor="three">Form Templates</h1>
</div>
<div sxHorizontalScroll sxPadding="0 0 medium medium" sxGap="10px">
  <div
    sxBackground="light"
    sxRadius="medium"
    sxPadding="medium"
    sxWidth="150px"
    sxTextAlign="center"
    sxFlexShrink="0"
    sxAlign="center"
    sxDistribution="center">
    <ion-icon
      name="add-outline"
      sxColor="primary"
      sxTextSize="5rem"
      sxAlign="center"
      (click)="createCollectionTemplate()"></ion-icon>
    <super-button
      type="outline"
      padding="5px"
      height="40px"
      color="primary"
      [action]="createCollectionTemplate"
    >Blank
    </super-button>
  </div>
  <div
    *ngFor="let mt of masterTemplates"
    sxBackground="light"
    sxRadius="medium"
    sxPadding="medium"
    sxWidth="150px"
    sxFlexShrink="0"
    sxCol
    sxDistribution="spaceBetween">
    <p sxCapitalize="">{{ mt.template.purpose }}</p>
    <super-button
      type="outline"
      padding="5px"
      height="40px"
      color="primary"
      [action]="createTemplate.bind(this, mt._id)"
    >Create
    </super-button>
  </div>
  <div sxWidth="10px" sxFlexShrink="0"></div>
</div>
