import {NgModule} from '@angular/core';
import {SignatureComponentsModule} from './components/signature-components.module';
import {SignatureService} from './services/signature.service';

@NgModule({
  imports: [
    SignatureComponentsModule,
  ],
  providers :[
    SignatureService
  ]
})

export class SignatureModule {

}
