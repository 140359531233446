import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {CreatePlanPurchasePageRoutingModule} from './create-plan-purchase-routing.module';
import {CreatePlanPurchasePage} from './create-plan-purchase.page';
import {SuperModule} from '@qid/super';
import {SexyModule} from '@qid/sexy';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        CreatePlanPurchasePageRoutingModule,
        SuperModule,
        SexyModule
    ],
  declarations: [CreatePlanPurchasePage]
})
export class CreatePlanPurchasePageModule {}
