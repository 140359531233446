import {Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {firstValueFrom} from 'rxjs';
import {AuthSelectors} from '../../modules/authentication';
import {DigilockerDocTypes, DigilockerRequest, DigilockerService} from '../../modules/digilocker-request';
import {EncryptionKeysService} from '../../modules/encryption-keys';
import {ID, IdService } from '../../modules/id';
import { SID } from '../../modules/sid';


@Component({
  selector: 'digilocker',
  templateUrl: './digilocker.component.html',
  styleUrls: ['./digilocker.component.scss'],
})

export class DigilockerComponent {

  @Input() sid: SID

  activeAccount = this.store.select(AuthSelectors.selectActiveAccount)

  public url: string = null;
  public digilockerStatusCheckInterval = null

  public slides = {
    "start": "start",
    "digilockerAuth": "digilockerAuth"
  }

  public activeSlide = this.slides.start
  public digilockerRequest: DigilockerRequest = null
  public status: string = null
  public createDocument : ID = null;

  constructor(private digilockerService: DigilockerService,
              private encryptionKeyService: EncryptionKeysService,
              private store: Store,
              private documentApi : IdService
  ) {
  }

  init() {
    this.getUrl();
  }

  async getEncryptionKey() {
    const activeAccount = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccount))
    const res = await this.encryptionKeyService.read(activeAccount._id)
    if (!res) {
      this.encryptionKeyService.autoGenerate(false)
    }
  }

  async getUrl() {
    const res = await this.digilockerService.getUrl({
      documentType: DigilockerDocTypes.ADHAR, runFaceMatch: true
    })
    if (!res || !res.data) {
      throw res.errorMessage
    }
    this.url = res.data.url;
    this.digilockerRequest = res.data.digilockerRequest
  }

  onVerifyAadhaarClicked = async () => {
    window.open(this.url, '_blank');
    this.activeSlide = this.slides.digilockerAuth
    this.setDigilockerRequestStatusCheckInterval()
  }

  setDigilockerRequestStatusCheckInterval() {

    this.digilockerStatusCheckInterval = setInterval(async () => {

      const res = await this.digilockerService.findById(this.digilockerRequest._id)
      if (res?.data) {
        this.digilockerRequest = res.data
        if (this.digilockerRequest.status === 'failed') {
          clearInterval(this.digilockerStatusCheckInterval)
        }
        if (this.digilockerRequest.status == 'success') {
          clearInterval(this.digilockerStatusCheckInterval)
          this.onDigilockerRequestSuccess();
        }
      }

    }, 2000)

  }


  private async onDigilockerRequestSuccess() {
    this.createDocument = this.digilockerRequest.requestedDoc.createdDocument;
  }

}
