import {Component, EventEmitter, Input, Output} from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import {SID} from '../../models/SID';
import {SidRepository} from '../../+state/sid-repository.service';
import { firstValueFrom } from 'rxjs';
import { AccountType, DisplayService } from '@qid/core';
import { CreateSidDto } from '../../dto/create-sid.dto';
import { Store } from '@ngrx/store';
import {AuthSelectors} from "../../../authentication";

@Component({
  selector: 'sid-selector',
  templateUrl: './sid-selector.component.html',
  styleUrl: './sid-selector.component.scss',
})
export class SidSelectorComponent {

  @Input() selectedSids: SID[];
  @Input() pageMode: boolean;
  @Output() onSelect: EventEmitter<SID> = new EventEmitter<SID>();


  public sids$ = this.sidRepository.allGlobalSid$
  public organisationSID$ = this.sidRepository.allSids$;
  private createSidDto: CreateSidDto = new CreateSidDto();


  constructor(private sidRepository: SidRepository,
              private modalController: ModalController,
              private alertController: AlertController,
              private displayService: DisplayService,
              private store: Store,
  ) {
  }

  ngOnInit() {
    this.sidRepository.init()
  }

  ionViewDidEnter() {

  }

  addSid(sid: SID) {
    if (sid) {
      this.selectedSids.push(sid)
      this.onSelect.emit(sid)
    }
  }

  isSidSelected(sid: SID, selectedSids: SID[]): boolean {
    if (!selectedSids) {
      return false
    }
    return selectedSids.some(selectedSid => selectedSid._id === sid._id);
  }

  save() {
    this.modalController.dismiss({
      selectedSids: this.selectedSids
    })
  }

  close() {
    this.modalController.dismiss()
  }

  async createSid() {
    const alert =  await this.alertController.create({
      header: 'Create Custom Document',
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: 'Document Name'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {

          }
        },
        {
          text: 'Create',
          handler: async (data) => {
            this.createSidDto.name = data.name
            this.createSidDto.account_id = (await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId)))
            this.createSidDto.accountType = AccountType.organisation
            const res = await this.sidRepository.create(this.createSidDto)
            if (res) {
              await this.displayService.toast({
                message: 'Custom Document Created',
                color: 'success',
                icon: 'checkmark-outline',
              });
            }
          }
        }
      ],
    })
    await alert.present()
  }


}
