import {Injectable} from "@angular/core";
import { RestService } from "../../../services/rest.service";
import { OrganisationUser } from '../models/organisation-user.model';

@Injectable()
export class MasterOrganisationUserService {

  static readonly ApiUrl = 'master/organisation-user/'

  constructor(private restService: RestService,
  ) {
  }

  async getOrganisationUsers(orgId: string) {
    return (await this.restService.send<OrganisationUser[]>({
      url: MasterOrganisationUserService.ApiUrl + orgId,
      method: 'GET',
    })).data
  }
}
