import {OtpVerifier} from '../model/OtpVerifier';
import {OtpChannel} from "../enum/OtpChannel";

export class CreateUserDto {
  name: string;
  phoneVerifier: OtpVerifier = new OtpVerifier(OtpChannel.phone);
  emailVerifier: OtpVerifier = new OtpVerifier(OtpChannel.email);
  uid: string;
  createdOn: Date;
  createdByScan: any;
  createdByDevice: string;
  country: string;

  constructor() {
  }
}
