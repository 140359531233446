import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Directive({selector: '[sxWidth]'})
export class WidthDirective implements OnInit {

  @Input() sxWidth: string | 'full' | 'fill' | 'fit';
  @Input() sxWidthMobile: string | 'full' | 'fill' | 'fit' = null;

  constructor(private el: ElementRef,
              private breakpointObserver: BreakpointObserver
  ) {
  }

  ngOnInit() {

    if (this.sxWidthMobile && this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.setWidth(this.sxWidthMobile);
      return;
    }

    this.setWidth(this.sxWidth);

  }

  setWidth(width: any) {
    if (width == "full") {
      this.el.nativeElement.style.width = "100%"
      return;
    }

    if (width == "fill") {
      this.el.nativeElement.style.flex = "1 0 0px"
      return;
    }

    if (width == "fit") {
      this.el.nativeElement.style.flexShrink = "0"
      this.el.nativeElement.style.width = "min-content"
      return;
    }

    this.el.nativeElement.style.width = width
  }
}
