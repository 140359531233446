import {NgModule} from "@angular/core";
import {FamilyMemberService} from "./services/family-member.service";
import {FamilyMemberComponentsModule} from "./components/family-member-components.module";

@NgModule({
  imports: [
    FamilyMemberComponentsModule
  ],
  providers: [FamilyMemberService]
})

export class FamilyMemberModule {

}
