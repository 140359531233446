import {ActionReducer, MetaReducer} from "@ngrx/store";
import {AuthActions} from "../index";

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type == AuthActions.signOut.type) {
      state = undefined
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [logout];
