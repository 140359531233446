import {EntityState} from "@ngrx/entity";
import {Action, createAction, createFeatureSelector, createReducer, createSelector, on, props} from "@ngrx/store";
import {AccountType, AccountWiseRecords, createAccountWiseEntityAdapters} from "@qid/core";
import { AuthSelectors } from "../modules/authentication";

export function createFeatureStore<RecordType,
  RecordState extends EntityState<AccountWiseRecords<RecordType>> = EntityState<AccountWiseRecords<RecordType>>,
  AdditionalAccountWiseData = {}>
(featureKey: string, initialState = {}) {

  const {
    accountAdapter,
    recordAdapter,
    createAccount
  } = createAccountWiseEntityAdapters<RecordType, AdditionalAccountWiseData>(featureKey)

  const actions = autoCreateActions<RecordType>(featureKey);

  const reducer = autoCreateReducer<RecordType, RecordState>(actions, initialState, accountAdapter, recordAdapter);

  const selectors = autoCreateSelectors<RecordType>(featureKey, accountAdapter, recordAdapter)

  return {featureKey, reducer, actions, selectors, createAccount}

}


export function autoCreateReducer<RecordType, RecordState extends EntityState<AccountWiseRecords<RecordType>>>(actions: any, initialState = {}, accountAdapter, recordAdapter) {


  const recordSelectors = recordAdapter.getSelectors();

  const initialRecordState: RecordState =
    accountAdapter.getInitialState(initialState);

  const reducer = createReducer(
    initialRecordState,

    // Boilerplate Starts
    on(actions.addAccount, (state, {accountBasedEntity}) => {
      return accountAdapter.addOne(accountBasedEntity, state)
    }),
    on(actions.loadRecordsForAccountSuccess, (state, action) => {
      return accountAdapter.updateOne({
        id: action.account_id,
        changes: {
          records: recordAdapter.setAll(action.records, state.entities[action.account_id].records)
        }
      }, state)
    }),
    on(actions.addRecord, (state, action) => {
      return accountAdapter.updateOne({
        id: action.account_id,
        changes: {
          records: recordAdapter.setAll([action.record, ...recordSelectors.selectAll(state.entities[action.account_id].records)], state.entities[action.account_id].records)
        }
      }, state)
    }),
    on(actions.updateRecord, (state, action) => {
      return accountAdapter.updateOne({
        id: action.account_id,
        changes: {
          records: recordAdapter.updateOne({
            id: action.record_id,
            changes: {
              ...action.updatedRecord
            }
          }, state.entities[action.account_id].records)
        }
      }, state)
    }),
    on(actions.removeRecord, (state, action) => {
      return accountAdapter.updateOne({
        id: action.account_id,
        changes: {
          records: recordAdapter.removeOne(action.record_id, state.entities[action.account_id].records)
        }
      }, state)
    }),

  on(actions.loadAdditionalDataAccountSuccess, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        additionalData: action.data
      }
    }, state)
  }),
    // Boilerplate Ends

  );

  const recordReducer = (
    state: RecordState | undefined,
    action: Action
  ) => {
    return reducer(state, action);
  }


  return {recordReducer}

}


export function autoCreateActions<RecordType>(featureKey: string) {


  const addAccount = createAction(
    `[${featureKey}] Add new account`,
    props<{ accountBasedEntity: AccountWiseRecords<RecordType> }>()
  )

  const loadRecordsForAccount = createAction(
    `[${featureKey}] Load records for account`,
    props<{ account_id: string, accountType: AccountType }>()
  );


  const loadRecordsForAccountSuccess = createAction(
    `[${featureKey}] Load Shares for Account Success`,
    props<{ records: RecordType[], account_id: string }>()
  );

  const loadAdditionalDataAccountSuccess = createAction(
    `[${featureKey}] Load Additional Data for Account Success`,
    props<{ data: any, account_id: string }>()
  );

  const addRecord = createAction(
    `[${featureKey}] Add Record`,
    props<{ record: RecordType, account_id: string }>()
  );

  const updateRecord = createAction(
    `[${featureKey}] Update Record`,
    props<{ record_id: string, account_id: string, updatedRecord: RecordType }>()
  );

  const removeRecord = createAction(
    `[${featureKey}] Remove Record`,
    props<{ record_id: string, account_id: string }>()
  );


  return {
    addAccount, loadRecordsForAccount, loadRecordsForAccountSuccess, addRecord, updateRecord, removeRecord, loadAdditionalDataAccountSuccess
  }


}

export function autoCreateSelectors<RecordType>(featureKey: string, accountAdapter, recordAdapter) {

  const selectRecordState =
    createFeatureSelector<EntityState<AccountWiseRecords<RecordType>>>(
      featureKey
    );

  const {selectAll, selectEntities} = accountAdapter.getSelectors();
  const recordsSelector = recordAdapter.getSelectors();

  const selectAllRecordState = createSelector(
    selectRecordState,
    (state: EntityState<AccountWiseRecords<RecordType>>) => selectAll(state)
  );

  const selectStateEntities = createSelector(
    selectRecordState,
    (state: EntityState<AccountWiseRecords<RecordType>>) => selectEntities(state)
  );

  const selectActiveAccount = createSelector(
    selectStateEntities,
    AuthSelectors.selectActiveAccountId,
    (entities, activeAccountId) => (activeAccountId ? entities[activeAccountId] : undefined)
  );

  const selectActiveAccountAdditionalData = createSelector(
    selectActiveAccount,
    (selectedAccount) => {
      return (selectedAccount ? selectedAccount.additionalData : undefined)
    }
  );

  const selectActiveAccountRecords = createSelector(
    selectActiveAccount,
    (selectedAccount) => {
      return (selectedAccount ? recordsSelector.selectAll(selectedAccount.records) : undefined)
    }
  );


  return {selectRecordState, selectAllRecordState, selectStateEntities, selectActiveAccount, selectActiveAccountRecords, selectActiveAccountAdditionalData}

}
