<ion-header class="ion-no-border" mode="ios">
  <ion-toolbar>
    <ion-button slot="start" fill="clear" (click)="back()">Back</ion-button>
    <ion-title>Verify OTP</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div sxCol sxPadding="medium" sxAlign="center" sxGap="15px">
    <h2 sxTextAlign="center">
      An OTP has been sent to <br/><span>{{getPhoneFormatted(otpVerifier.receiverId)}}</span>
    </h2>


    <ng-otp-input
      class="otp-input"
      (onInputChange)="onInputChange($event, verifyOtpButton)"
      [config]="{
        length: 4,
        allowNumbersOnly: true,
        inputStyles: otpInputStyles
      }"
      type="tel"></ng-otp-input>

    <super-button sxWidth="full" #verifyOtpButton [action]="onVerifyOtpClicked" [showError]="true"
    >Verify
    </super-button>

    <div sxRow sxWidth="full" sxDistribution="spaceBetween" sxAlign="center" sxPadding="small">
      <p>Didn't receive OTP?</p>
      <super-button [action]="onResendClicked" padding="0" height="fit" background="transparent" color="dark"
                    iconEnd="reload-outline">
        Resend {{ timer > 0 ? 'in ' + timer + 's' : 'now' }}
      </super-button>
    </div>

    <div sxRow sxWidth="full" sxDistribution="start">
      <super-button padding="0" background="transparent" color="dark" iconStart="chevron-back-outline" height="fit"
                    type="clear"
                    class="change-phone" (click)="back()">{{backText}}
      </super-button>
    </div>
  </div>

</ion-content>
