<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button slot="start" fill="clear" color="dark" (click)="close()">Cancel</ion-button>
    <ion-title class="title">{{ title }}</ion-title>
    <super-button
      slot="end"
      background="transparent"
      height="fit"
      padding="0"
      color="primary"
      [action]="onSubmit">Done
    </super-button>
  </ion-toolbar>
</ion-header>
<ion-content>

  <div sxCol sxPadding="medium" sxGap="15px">
    <super-input [(value)]="inputValue" [label]="title" [helpNote]="remark" placeholder="{{ placeholder }}"
                 [type]="type"></super-input>

    <div class="datetime-wrapper" sxCenterize="col" *ngIf="type == 'datetime'">
      <ion-datetime [(ngModel)]="inputValue" presentation="date"></ion-datetime>
    </div>


    <ion-list *ngIf="isType">
      <ion-list-header>
        <ion-label>Field Type
        </ion-label>
      </ion-list-header>
      <ion-radio-group [(ngModel)]="selectedType">
        <ion-item *ngFor="let type of inputTypes">
          <ion-radio value="{{type}}">{{type}}</ion-radio>
        </ion-item>

      </ion-radio-group>
    </ion-list>
  </div>

</ion-content>
