import {NgModule} from "@angular/core";
import {SignaturePadModule} from "angular2-signaturepad";
import {SexyModule} from "@qid/sexy";
import {CommonModule} from "@angular/common";
import {ViewSignatureComponent} from "./view-signature/view-signature.component";
import {IonicModule} from "@ionic/angular";
import {SuperModule} from "@qid/super";

@NgModule({
  imports: [
    SignaturePadModule,
    SexyModule,
    SuperModule,
    CommonModule,
    IonicModule,
  ],
  declarations: [ViewSignatureComponent],
  exports: [ViewSignatureComponent],
})
export class SignatureComponentsModule {}
