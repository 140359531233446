import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DisplayService} from "@qid/core";

@Component({
  selector: "super-button",
  templateUrl: "./super-button.component.html",
  styleUrls: ["./super-button.component.scss"]
})
export class SuperButtonComponent implements OnInit {
  @Input() title: string;
  @Input() size: "v-small"| "small" | "medium" | "large" = "large";
  @Input() isDefaultLoader: boolean = true;
  @Input() confirmationTitle: string = null;
  @Input() type: SuperButtonType | any = SuperButtonType.fill;
  @Input() width: string = "full";
  @Input() icon: any;
  @Input() borderColor = null;
  @Input() background: any = null;
  @Input() color: any = null;
  @Input() iconStart: any;
  @Input() iconEnd: any;
  @Input() textAlign: "center" | "start" | "end";
  @Input() textSize: string = "medium";
  @Input() padding: "small" | "medium" | "large" | string = "medium";
  @Input() radius: "small" | "medium" | "large" | string = "medium";
  @Input() height: string = "60px";
  @Input() showError: boolean = false;
  @Input() errorInterface: 'stacked' | 'toast' | 'alert' = 'stacked';
  @Input() imageStart: string = null;
  @Input() action: () => void = null;

  @Output() handler: EventEmitter<any> = new EventEmitter();

  public errorMessage: string = null;

  public isLoading: boolean = false;


  constructor(private displayService: DisplayService) {
  }

  ngOnInit() {
    this.setSize();
  }

  async onClick() {

    if (!this.action || this.isLoading) {
      return;
    }

    try {
      this.errorMessage = null;
      this.isLoading = true;
      let res;
      if (this.confirmationTitle != null) {
        await this.displayService.deleteAlert(async () => {
          res = await this.action();
        }, this.confirmationTitle);
      } else {
        res = await this.action();
      }
      this.hideLoader();
      this.handler.emit(res);
    } catch (e) {
      console.error(e);
      switch (this.errorInterface) {
        case 'stacked': this.errorMessage = SuperButtonComponent.getErrorMessageToDisplay(e);break;
        case 'toast': this.displayService.toast({message :SuperButtonComponent.getErrorMessageToDisplay(e)});break;
      }
      this.hideLoader();
      this.handler.emit(e);
    }
  }

  hideLoader() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1);
  }

  getSpinnerColor() {
    if (this.color == "light") {
      return "light";
    }
    if (this.type == "dark") {
      return "dark";
    }
  }

  getBackground() {
    if (this.background) {
      return this.background;
    }
    if (this.type == "clear" || this.type == "outline") {
      return "transparent";
    }

    if (this.type == "light") {
      return "light";
    }

    return "dark";
  }


  getColor() {
    if (this.color) {
      return this.color;
    }

    if (this.background == "light" || this.type == "outline" || this.type == SuperButtonType.clear) {
      return "dark";
    }

    if (this.type == "light") {
      return "dark";
    }

    return "light";
  }

  getBorderColor() {
    if (this.borderColor) {
      return this.borderColor;
    }

    if (this.type == "outline") {
      return "one";
    }

    return "transparent";
  }

  setSize() {
    switch (this.size) {
      case "large":
        break;
      case "small" :
        this.height = "50px";
        this.textSize = "16px";
        break;
      case "v-small" :
        this.height = "40px";
        this.radius = '8px'
        this.padding = '5px 10px'
        this.textSize = "14px";
    }
  }

  private static getErrorMessageToDisplay(e : any){
    if(!e || e?.toString()?.toLowerCase().indexOf('typeerror') > -1){
      return 'Something went wrong, please try again';
    }

    if(typeof e === 'string'){
      return e;
    }

    if(typeof e === 'object' && e?.message){
      return e.message;
    }
  }
}

export enum SuperButtonType {
  fill = "fill",
  outline = "outline",
  clear = "clear"
}
