import {Injectable} from "@angular/core";
import {PlanPurchase} from "../models/plan-purchase.model";
import {firstValueFrom} from "rxjs";
import {Store} from "@ngrx/store";
import {Browser} from "@capacitor/browser";
import {RestService} from "../../../services/rest.service";
import {AuthSelectors} from "../../authentication";
import {UsersSelectors} from "../../user";

@Injectable()
export class PlanPurchaseService {

  public readonly apiEndpoint = 'plan-purchase'

  constructor(private restService: RestService,
              private store: Store
  ) {
  }


  async readAll() {

    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId))

    if (!account_id) {
      return
    }

    return (await this.restService.send<PlanPurchase[]>({
      url: this.apiEndpoint,
      method: 'GET',
      account_id: account_id,
      query: {
        orgId: account_id,
      }

    })).data;
  }

  async openPlanUpgrade() {
    const org = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccount))

    const authenticatedUser = await firstValueFrom(this.store.select(UsersSelectors.selectAuthenticatedUser))
    let userName = encodeURIComponent(authenticatedUser.name);
    let userEmail = encodeURIComponent(authenticatedUser.email);
    let orgName = encodeURIComponent(org.name);
    let orgPhone = encodeURIComponent(authenticatedUser.phoneNumber)
    await Browser.open({
      url: `https://oneqid.com/upgrade/?user_name=${userName}&user_email=${userEmail}&org_name=${orgName}&org_phone=${orgPhone}`,
      toolbarColor: '#f1f5f5',
      presentationStyle: 'popover',
    });
  }
}
