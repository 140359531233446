<div sxPadding="medium" sxCol sxGap="10px" *ngIf="!isProfileSlide">

  <div sxMargin="large 0 0">
    <p sxMargin="0 10px" sxTextSize="medium" sxColor="two" sxTextWeight="bold">One-time</p>
    <h1 sxMargin="0 10px">Verification</h1>
  </div>

  <div *ngIf="!faceId && !isFetchingFaceId">
    <div sxWidth="full" sxBackground="light" sxRadius="medium">
      <face-capture #faceCapture (onCapture)="onFaceCaptured($event)"></face-capture>
    </div>
  </div>


  <div *ngIf="faceId && !isFetchingFaceId" sxPadding="medium" sxRow sxAlign="center" sxBackground="light"
       sxDistribution="spaceBetween"
       sxBorderWidth="1px" sxRadius="medium" sxBorderColor="success">
    <div sxCenterize="row" sxGap="10px">
      <avatar-round [size]="50" [imageUrl]="faceId.imageUrl"></avatar-round>
      <p sxTextWeight="bold">Selfie<br>Verification</p>
    </div>
    <div sxRow sxAlign="center" sxGap="10px">
      <super-button [action]="onFaceChangeClicked" size="v-small" type="outline">Change</super-button>
      <ion-icon sxTextSize="28px" sxColor="success" name="shield-checkmark"></ion-icon>
    </div>

  </div>


  <div *ngIf="!verifiedAadhaar && faceId" sxPadding="medium" sxBackground="light" sxRadius="medium"
       sxBorderWidth="1px" sxBorderColor="one">
    <digilocker [hidden]="!idVerificationStatus" #digilockerComponent [sid]="sid"
                (documentFetched)="onDigilockerDocumentFetched($event)"></digilocker>
  </div>

  <div *ngIf="verifiedAadhaar" sxBackground="light"
       sxBorderWidth="1px" sxBorderColor="success"
       sxPadding="medium" sxRadius="medium">
    <div sxRow sxAlign="center" sxDistribution="spaceBetween" sxPadding="medium 0" sxWidth="full">
      <h1 sxMargin="0" sxTextSize="medium">Identity Verified</h1>
      <ion-icon sxTextSize="28px" sxColor="success" name="shield-checkmark"></ion-icon>
    </div>
    <id-card [document]="verifiedAadhaar"></id-card>
  </div>

  <super-button *ngIf="verifiedAadhaar" [action]="createProfile" iconEnd="chevron-forward-outline">Continue
  </super-button>

</div>

<div *ngIf="isProfileSlide"
     sxMatchParent sxRadius="medium" sxPadding="medium" sxCol sxDistribution="center">

  <div sxCol sxAlign="center" sxGap="10px" *ngIf="isCreatingProfile">
    <ion-skeleton-text sxCircle="100px" animated="true"></ion-skeleton-text>
    <ion-skeleton-text sxRadius="medium" sxWidth="150px" sxHeight="30px" animated="true"></ion-skeleton-text>
    <ion-skeleton-text sxRadius="medium" sxWidth="100px" sxHeight="20px" animated="true"></ion-skeleton-text>

    <div sxBackground="light" sxRadius="medium" sxPadding="medium" sxBorderColor="one" sxBorderWidth="1px" sxRow
         sxAlign="center" sxGap="10px">
      <p sxMargin="0" sxTextSize="small">Creating Verified Profile</p>
      <ion-spinner></ion-spinner>
    </div>

  </div>

  <div *ngIf="!isCreatingProfile">
    <div sxCol sxAlign="center" sxGap="10px" *ngIf="activeAccount$ | async as account">
      <avatar-round [imageUrl]="account?.displayPictureUrl"></avatar-round>
      <display-name></display-name>
      <p sxMargin="0" sxTextSize="small">{{account.email}}</p>

      <div sxBackground="light" sxRadius="medium" sxPadding="medium" sxBorderColor="success" sxBorderWidth="1px" sxRow
           sxAlign="center" sxGap="10px">
        <p sxMargin="0" sxTextSize="small" sxColor="success">Profile Verified</p>
      </div>
    </div>
    <super-button sxWidth="full" iconEnd="chevron-forward-outline">Continue</super-button>
  </div>


</div>
