import {inject, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as OrganisationUserActions from './organisation-user.actions';
import {firstValueFrom} from "rxjs";
import {AccountWiseRecords, NotificationPreference, OrganisationUserRoles} from "@qid/core";
import {createAccount} from "./organisation-user.reducer";
import { OrganisationUserService } from '../services/organisation-user.service';
import {CreateOrganisationUserDto, OrganisationUser, OrganisationUserSelectors} from '..';
import { AuthSelectors } from '../../authentication';
import {UsersSelectors} from "../../user";

@Injectable()
export class OrganisationUserDataRepository {

  private readonly store = inject(Store);
  private apiService = inject(OrganisationUserService)

  allOrganisationUsers$ = this.store.pipe(select(OrganisationUserSelectors.selectActiveAccountOrgUsers));
  activeAccount$ = this.store.pipe(select(OrganisationUserSelectors.selectActiveAccount));

  async init() {

    if (!await firstValueFrom(this.activeAccount$)) {
      await this.addAccount()
    }
  }

  private async addAccount() {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    const accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType));
    const accountBasedEntity: AccountWiseRecords<OrganisationUser> = createAccount(account_id, accountType);
    this.store.dispatch(OrganisationUserActions.addAccount({accountBasedEntity: accountBasedEntity}))
  }

  async getUserRole() {
    return this.store.select(OrganisationUserSelectors.selectActiveOrganisationUserRole)
  }


  public async onUserOrganisationFetched() {
    const user = await firstValueFrom(this.store.select(UsersSelectors.selectAuthenticatedUser));

    const userOrganisation = await firstValueFrom(this.store.select(OrganisationUserSelectors.selectUserOrganisations))

    if (!user || !user.defaultOrgId) {
      this.store.dispatch(OrganisationUserActions.switchUserOrganisation({organisation: null}))
      return;
    }

    if (!userOrganisation || userOrganisation.length < 1) {
      this.store.dispatch(OrganisationUserActions.switchUserOrganisation({organisation: null}))
      return
    }

    for (let org of userOrganisation) {
      if (org._id == user.defaultOrgId) {
        this.store.dispatch(OrganisationUserActions.switchUserOrganisation({organisation: org}))
      }
    }


  }


  async getAuthenticatedUserOrganisations() {
    const res = await this.apiService.readAllByUser()
    if (!res || res.errorMessage) {
      return []
    }
    return res.data;
  }

  async create(dto: CreateOrganisationUserDto) {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    const res = await this.apiService.create(dto);

    if (res && res.data) {
      this.store.dispatch(OrganisationUserActions.addRecord({
        record: res.data,
        account_id: account_id
      }))
    }

    return res
  }

  async update(record_id: string, updatedRecord: OrganisationUser) {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(OrganisationUserActions.updateRecord({
      record_id: record_id,
      updatedRecord: updatedRecord,
      account_id: account_id
    }))
  }

  async updatePermission(orgUser_id: string, isAdd: boolean, permission: string) {
    const res = await this.apiService.updatePermission(permission, isAdd, orgUser_id);

    if (res && res.data) {
      await this.update(orgUser_id, res.data)
    }
    return res
  }

  async updateRole(orgUser_id: string, role: OrganisationUserRoles) {
    const res = await this.apiService.updateRole(orgUser_id, role);

    if (res && res.data) {
      await this.update(orgUser_id, res.data)
    }
    return res
  }


  async updateNotificationPreferences(orgUser_id: string, notificationPreferences: NotificationPreference[]) {
    const res = await this.apiService.updateNotificationPreferences(notificationPreferences);

    if (res && res.data) {
      this.store.dispatch(OrganisationUserActions.updateActiveOrganisationUser({
        updatedRecord: res.data
      }))
    }
    return res
  }

  async delete(record_id: string) {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    const res = await this.apiService.delete(
      record_id,
      account_id
    );

    if (res?.data) {
      this.store.dispatch(OrganisationUserActions.removeRecord({record_id: record_id, account_id: account_id}))
    }

    return res

  };

}
