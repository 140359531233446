<div sxMinHeight="200px">
  <div *ngIf="isSidValid == false"
       sxCol sxAlign="center" sxGap="10px">
    <ion-icon sxColor="two" sxTextSize="40px" name="alert-circle"></ion-icon>
    <p sxTextSize="large" sxTextAlign="center" sxColor="two">This document cannot be fetched from Digilocker</p>
    <super-button sxWidth="full" type="outline" iconStart="chevron-back" (click)="onBackClicked()">Back
    </super-button>

  </div>

  <div *ngIf="isSidValid" sxCol sxGap="10px">

    <div sxRow sxAlign="center" sxDistribution="spaceBetween" sxWidth="full">
      <h1 sxMargin="0" sxTextWeight="bold" sxTextSize="large">Upload {{sid.name}}</h1>
      <img sxHeight="40px" [src]="ImageUtil.getImageSource(sid.logoUrl)"/>
    </div>

    <div *ngIf="!isProcessStarted" sxCol sxMargin="10px 0" sxPadding="15px 0 10px" sxAlign="center" sxGap="10px">


      <p sxTextSize="small" sxTextAlign="justify" sxWidth="fill" sxColor="two" sxMargin="0">I provide my consent
        to
        share my Aadhaar Number, Date of Birth and Name from my Aadhaar eKYC information with the Qid for the
        purpose of
        fetching my
        Certificate Name into DigiLocker</p>


      <super-button sxWidth="full" iconEnd="chevron-forward" [action]="openDigilockerLink">Agree & Continue
      </super-button>


    </div>

    <div *ngIf="isProcessStarted">

      <div *ngIf="digilockerRequest.status == 'open'" sxRadius="large" sxWidth="full" sxHeight="150px"
           sxPosition="relative"
           sxBorderWidth="1px" sxBorderColor="one">
        <ion-skeleton-text sxMargin="0" sxRadius="large" sxWidth="full" sxHeight="full" [animated]="true"
                           sxOverlayCenter
                           sxMatchParent></ion-skeleton-text>

        <div sxOverlayCenter sxMatchParent sxCenterize="col">
          <p>{{progressMessage}}</p>
          <super-button (click)="onCancelClicked()" sxWidth="100px" type="outline" size="v-small">Cancel
          </super-button>
        </div>

      </div>

      <div *ngIf="digilockerRequest.status == 'failed'">
        <div sxRow sxAlign="center" sxGap="10px" sxColor="warning">
          <ion-icon sxTextSize="30px" name="alert-circle-outline"></ion-icon>
          <p sxMargin="0">Failed</p>
        </div>
        <p>{{progressMessage}}</p>
        <p>{{digilockerRequest.errorMessage}}</p>
        <super-button (click)="onCancelClicked()" sxWidth="full">Try Again</super-button>
      </div>

      <id-card *ngIf="createDocument" sxWidth="full" [document]="createDocument"></id-card>

    </div>

  </div>

</div>


<div class="digilocker-brand-wrapper" sxMargin="10px 0 0" sxWidth="full" sxBorderColor="one"
     sxBorderWidth="1px 0 0"
     sxPadding="medium" sxCenterize="row" sxGap="10px">
  <img sxWidth="100px" src="assets/digilocker-logo.webp"/>
</div>





