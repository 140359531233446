import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlanPurchaseService} from "./services/plan-purchase.service";
import {CreatePlanPurchasePageModule} from "./pages/create-plan-purchase/create-plan-purchase.module";
import {ListPlanPurchasePageModule} from "./pages/list-plan-purchase/list-plan-purchase.module";
import {PlanPurchaseMasterService} from "./services/plan-purchase-master.service";


@NgModule({
  declarations: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    CreatePlanPurchasePageModule,
    ListPlanPurchasePageModule
  ],
  providers : [
    PlanPurchaseService,
    PlanPurchaseMasterService
  ]
})
export class PlanPurchaseModule { }
