import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({ selector: '[sxStickBottom]' })
export class StickBottomDirective implements OnInit {
  constructor(private el: ElementRef) {}

  ngOnInit() {
    try {
      this.el.nativeElement.style.bottom = 0;
      this.el.nativeElement.style.left = 0;
      this.el.nativeElement.style.position = 'fixed';
    } catch (e) {}
  }
}
