import {Injectable} from "@angular/core";
import {CreatePlanPurchaseDto} from "../dto/create-plan-purchase.dto";
import {PlanPurchase} from "../models/plan-purchase.model";
import {RestService} from "../../../services/rest.service";

@Injectable()
export class PlanPurchaseMasterService {

  public readonly apiEndpoint = 'master/plan-purchase'

  constructor(private restService: RestService
  ) {
  }


  create(createPlanPurchaseDto: CreatePlanPurchaseDto) {
    return this.restService.send({
      url: this.apiEndpoint,
      method: 'POST',
      body: createPlanPurchaseDto
    })
  }

  async readAll(orgId: string = null) {
    return (await this.restService.send<PlanPurchase[]>({
      url: this.apiEndpoint,
      method: 'GET',
      query: {
        orgId: orgId
      }
    })).data
  }

  async cancelPlan(planPurchaseId: string, remark: string) {
    return (await this.restService.send({
      url: this.apiEndpoint + '/deactivate/' + planPurchaseId,
      method: 'PUT',
      body: {
        remark: remark
      }
    })).data
  }
}
