<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-back-button slot="start"></ion-back-button>
        <ion-title>{{orgUser?.user?.name}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>

    <div sxPadding="medium">

        <div sxBackground="light"
             sxRadius="medium" sxPadding="medium" sxRow sxGap="10px"
             sxAlign="center"
        >

            <avatar-round [size]="80" [imageUrl]="orgUser?.user?.displayPictureUrl" [autoBase]="true"
                          [title]="orgUser?.user?.name"
            ></avatar-round>

            <div sxCol>
                <h2>{{orgUser?.user?.name}}</h2>
                <p>{{orgUser?.role | roleTitle}}</p>
            </div>

        </div>

      <!--        <super-button [action]="generateSharedKey">Change PIN</super-button>-->

      <!--        <div>-->
      <!--            <p>{{keys?.publicKey}}</p>-->
      <!--        </div>-->
    </div>

</ion-content>
