import {EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import * as DocumentsActions from './documents.actions';
import {ID} from "../../../models/ID";
import {AccountWiseRecords, createAccountWiseEntityAdapters} from "@qid/core";


export const DOCUMENTS_FEATURE_KEY = 'documents';

export interface DocumentsState extends EntityState<AccountWiseRecords<ID>> {
  selectedId?: string
}


export const {accountAdapter, recordAdapter, createAccount}
    = createAccountWiseEntityAdapters<ID>(DOCUMENTS_FEATURE_KEY);

const recordSelectors = recordAdapter.getSelectors();

export const initialDocumentsState: DocumentsState =
    accountAdapter.getInitialState({});

const reducer = createReducer(
    initialDocumentsState,

    // Boilerplate Starts
    on(DocumentsActions.addAccount, (state, {accountBasedEntity}) => {
      return (accountBasedEntity && accountBasedEntity.account_id) ? accountAdapter.addOne(accountBasedEntity, state) : {...state}
    }),

    on(DocumentsActions.loadRecordsForAccount, (state, action) => {
      return accountAdapter.updateOne({
        id: action.account_id,
        changes: {
          isLoading: true
        }
      }, state)
    }),

    on(DocumentsActions.loadRecordsForAccountSuccess, (state, action) => {
      if (!action.records || !action.account_id) {
        return {...state}
      }
      return accountAdapter.updateOne({
        id: action.account_id,
        changes: {
          isLoading: false,
          records: recordAdapter.setAll(action.records, state.entities[action.account_id]?.records)
        }
      }, state)
    }),

    on(DocumentsActions.addRecord, (state, action) => {
      if (!action.record || !action.account_id) {
        return {...state}
      }
      return accountAdapter.updateOne({
        id: action.account_id,
        changes: {
          records: recordAdapter.setAll([action.record, ...recordSelectors.selectAll(state.entities[action.account_id].records)], state.entities[action.account_id].records)
        }
      }, state)
    }),
    on(DocumentsActions.removeRecord, (state, action) => {
      return accountAdapter.updateOne({
        id: action.account_id,
        changes: {
          records: recordAdapter.removeOne(action.record_id, state.entities[action.account_id].records)
        }
      }, state)
    })
    // Boilerplate Ends


);

export function documentsReducer(
    state: DocumentsState | undefined,
    action: Action
) {
  return reducer(state, action);
}
