import {NgModule} from "@angular/core";
import {AddFamilyMemberComponent} from "./add-family-member/add-family-member.component";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";

@NgModule({
  imports: [
    IonicModule, CommonModule, SexyModule, SuperModule
  ],
  declarations: [
    AddFamilyMemberComponent
  ],
  exports: [
    AddFamilyMemberComponent
  ]
})

export class FamilyMemberComponentsModule {

}
