import {Injectable} from '@angular/core';
import {String} from 'typescript-string-operations';
import {Scanner} from '../models/scanner';
import {CreateScannerDto} from '../dto/create-scanner.dto';
import {UpdateScannerDto} from '../dto/update-scanner.dto';
import {firstValueFrom} from "rxjs";
import {Store} from "@ngrx/store";
import {ValidationUtil} from "@qid/core";
import {RestService} from "../../../services/rest.service";
import {AuthSelectors} from "../../authentication";
import {PaginatedResponse} from "../../../interface/PaginatedResponse";
import {ScannerTemplateOptions} from "../../../models/scanner-template-options";

@Injectable({
  providedIn: 'root',
})
export class ScannerApiService {
  private static readonly NodeMain = 'scanner/';
  private static readonly NodeId = ScannerApiService.NodeMain + '{0}';
  private static readonly NodeFind = ScannerApiService.NodeMain + 'get-scanner/{0}';
  private static readonly NodeQuery =
    ScannerApiService.NodeMain + '?orgId={0}&templateId={1}';

  // TO BE DEPRECATED
  private static readonly NodeQueryTemp =
    ScannerApiService.NodeMain + '?orgId={0}';

  private static readonly NodeAddTemplate =
    ScannerApiService.NodeMain + 'add-template/{0}?label={1}&templateId={2}';

  constructor(private restService: RestService,
              private store: Store
  ) {
  }

  public async create(qr: string, templates: ScannerTemplateOptions[], title?: string) {
    const activeOrg = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccount))
    const createScannerDto = new CreateScannerDto(qr, templates, title);
    createScannerDto.org = activeOrg._id
    return this.restService.send<Scanner>({
      url: ScannerApiService.NodeMain,
      method: "POST",
      body: createScannerDto
    });
  }

  public async read(id: string): Promise<Scanner> {
    return (await this.restService.send<Scanner>({
      url: String.format(ScannerApiService.NodeId, id),
      method: "GET"
    })).data
  }

  public async readAll(templateId: string): Promise<Scanner[]> {

    const activeOrg = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccount))
    // TO BE DEPRECATED
    let url = String.format(ScannerApiService.NodeQuery, activeOrg?._id, templateId)
    if (ValidationUtil.isStringEmpty(templateId)) {
      url = String.format(ScannerApiService.NodeQueryTemp, activeOrg?._id)
    }

    return (await this.restService.send<Scanner[]>({
      url: url,
      method: "GET"
    })).data;
  }

  public async masterReadAll(page = 1): Promise<PaginatedResponse<Scanner>> {
    return (
      await this.restService.send({
          url: ScannerApiService.NodeMain + "?page=" + page,
          method: 'GET',
        }
      )).data;
  }


  public async getScannerByQr(qr: string): Promise<Scanner> {
    return (await this.restService.send<Scanner>({
      url: String.format(ScannerApiService.NodeFind, qr),
      method: "GET",
      throwError: false
    })).data
  }


  public async addTemplateOption(id: string, label: string, templateId: string): Promise<Scanner> {
    return (await this.restService.send<Scanner>({
      url: String.format(ScannerApiService.NodeAddTemplate, id, label, templateId),
      method: "PUT"
    })).data
  }

  public async update(id: string, dto: UpdateScannerDto): Promise<Scanner> {
    return (await this.restService.send<Scanner>({
      url: String.format(ScannerApiService.NodeId, id),
      method: "PUT",
      body: dto
    })).data
  }

  public async unlink(id: string): Promise<Scanner> {
    return (await this.restService.send<Scanner>({
      url: String.format(ScannerApiService.NodeId, id),
      method: "PUT"
    })).data
  }

  public async delete(id: string): Promise<Scanner> {
    return (await this.restService.send<Scanner>({
      url: String.format(ScannerApiService.NodeId, id),
      method: "DELETE"
    })).data
  }

}
