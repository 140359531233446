import {Component, OnInit} from '@angular/core';
import {User} from "../../model/User";
import {UserService} from "../../services/user.service";
import {ModalController} from "@ionic/angular";
import {DisplayService} from "@qid/core";
import {OtpService} from "../../services/otp.service";
import {OtpVerifier} from "../../model/OtpVerifier";
import {OtpChannel} from "../../enum/OtpChannel";
import {Store} from "@ngrx/store";
import {firstValueFrom} from "rxjs";
import {UsersSelectors} from "../../../index";

@Component({
  selector: 'app-mobile-update',
  templateUrl: './mobile-update.component.html',
  styleUrls: ['./mobile-update.component.scss'],
})
export class MobileUpdateComponent implements OnInit {

  public user$ = this.store.select(UsersSelectors.selectAuthenticatedUser);
  public user: User;
  public phoneVerifier: OtpVerifier = new OtpVerifier(OtpChannel.phone);

  constructor(
    private userService: UserService,
    private modalController: ModalController,
    private displayService: DisplayService,
    private otpService: OtpService,
    private store: Store
  ) {
  }


  ngOnInit() {

  }

  ionViewDidEnter() {
    this.init()
  }

  async init() {
    this.user = await firstValueFrom(this.user$)
    if (this.user) {
      this.phoneVerifier.receiverId = this.user.phoneNumber;
    }
  }

  public sendPhoneOtp = async () => {
    if (this.user.phoneNumber == this.phoneVerifier.receiverId) {
      return
    }
    const verifyRes = await this.otpService.sendAndVerify(this.phoneVerifier);
    if (!verifyRes || !verifyRes.otpId) {
      return;
    }
    const updateRes = await this.userService.updateUserPhone(this.phoneVerifier);
    if (updateRes && updateRes.phoneNumber == this.phoneVerifier.receiverId) {
      this.close();
    }
  };

  close() {
    this.modalController.dismiss();
  }


}
