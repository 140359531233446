<div class="slide-successful" sxWidth="full" sxHeight="full" *ngIf="share && template">

  <div sxBackground="#003241" sxCol sxAlign="center" sxDistribution="center" sxPadding="medium 0 40px"
       sxColor="white" sxBorderWidth="0 0 5px" sxBorderColor="green">
    <img alt="checkmark" sxWidth="100px" src="/assets/imgs/qid-check.gif"/>
    <h2 sxTextAlign="center" sxPadding="0 20px">{{template.successfulMessage?.length > 0 ? template.successfulMessage : "Sharing Successful"}}</h2>
    <p sxMargin="small 0">{{template.purpose}}</p>
    <p class="details">{{ share?.lastUpdateTime | date : "medium" }}</p>
  </div>

  <div sxWidth="full" sxMargin="-20px 0 0 0" sxCol sxAlign="center">
    <div sxBackground="light" sxWidth="fit" sxRadius="100px" sxPadding="12px 18px 12px 12px" sxGap="10px"
         sxRow sxAlign="center" sxDistribution="spaceBetween" class="shadow-bottom">
      <avatar-round [size]="35" [imageUrl]="mainParticipant?.displayPictureUrl"
                    [title]="mainParticipant?.name"></avatar-round>
      <p sxWidth="fill" sxTextEllipse sxMargin="0">{{mainParticipant?.name}}</p>
    </div>
  </div>

  <div sxPadding="medium" sxCol sxGap="10px">
    <div sxWidth="full" sxBackground="base-shade" sxRadius="medium" sxPadding="medium"
         sxCol="" sxGap="10px" *ngIf="template?.postSubmissionRedirectUrl?.length > 0">
      <p sxMargin="0"><span sxTextWeight="bold">Redirecting to</span> {{template.postSubmissionRedirectUrl}}</p>
      <div sxWidth="full" sxHeight="5px" sxBackground="one" sxRadius="5px">
        <div class="prgrs-bar"></div>
      </div>
    </div>
    <div sxPadding="medium" sxBackground="light" sxRadius="medium" sxGap="10px" sxCol>
      <h1 sxMargin="20px 5px 0" sxColor="three" sxTextSize="28px">No more ID uploads!</h1>
      <p sxColor="two" sxMargin="0 5px 10px">Your Identity Documents are securely stored on <span class="qid-text-logo">qid</span>
      </p>
      <div *ngIf="userDocuments$ | async as documents" sxRow sxOverflow="scroll" sxGap="10px">
        <id-card [sxMinWidth]="documents.length > 1 ? '90%' : '100%'" *ngFor="let document of documents"
                 [document]="document" [account]="mainParticipant"></id-card>
      </div>

      <div sxRow sxAlign="stretch" sxDistribution="spaceBetween" sxHeight="100px" sxGap="10px">
        <div sxCenterize="col" sxWidth="fill" sxBorderColor="one" sxBorderWidth="1px" sxRadius="medium">
          <ion-icon sxTextSize="28px" name="qr-code-outline"></ion-icon>
          <p sxMargin="5px 0">Share</p>
        </div>
        <div sxCenterize="col" sxWidth="fill" sxBorderColor="one" sxBorderWidth="1px" sxRadius="medium">
          <ion-icon sxTextSize="28px" name="locate-outline"></ion-icon>
          <p sxMargin="5px 0">Track</p>
        </div>
        <div sxCenterize="col" sxWidth="fill" sxBorderColor="one" sxBorderWidth="1px" sxRadius="medium">
          <ion-icon sxTextSize="28px" name="shield-checkmark-outline"></ion-icon>
          <p sxMargin="5px 0">Control</p>
        </div>
      </div>
      <p sxPadding="5px" sxMargin="0" sxColor="two">Share with a QR scan, track everything, and keep control. Download
        the <span class="qid-text-logo">qid</span> app to manage and share your IDs easily.</p>
    </div>
    <div class="download-app-wrapper">
      <super-button *ngIf="!isMobile()" iconEnd="chevron-forward-outline">
        <a
          href="https://onelink.to/rdzbz9"
          target="_blank"
          sxColor="light"
          sxTextDecoration="none"
        >Download the App</a>
      </super-button>

      <h2 sxMargin="20px 0 0">www.oneqid.com</h2>

      <p>
        <a href="https://oneqid.com/support/">Support</a>
        <a href="https://blackstudio.design/qid-terms/">Terms & Conditions</a>
        <a href="https://oneqid.com/privacy-policy/">Privacy Policy</a>
      </p>

      <div class="watermark">
        <p>powered by qid</p>
      </div>
    </div>

  </div>


</div>
