<div sxCol sxGap="10px" sxPadding="medium" sxBackground="light" sxRadius="medium"
     *ngIf="participant?.otherFields?.length > 0">

    <div sxRow sxGap="10px" sxAlign="center" sxDistribution="spaceBetween" sxBorderColor="one" sxBorderWidth="0 0 1px">
        <div sxRow sxGap="10px" sxAlign="center">
            <avatar-round [imageUrl]="participant?.account?.displayPictureUrl" [title]="participant?.account?.name"
                          [size]="30"></avatar-round>
            <p>{{participant?.account?.name}}<span sxColor="two" sxTextSize="small"
                                                   sxMargin="0 5px">{{participant.account_id == user?._id && template.allowGroup ? '(You)' : ""}}</span>
            </p>
        </div>
        <div sxRow sxAlign="center">
            <super-button padding="0" height="fit" type="clear" [action]="removeParticipant" confirmationTitle="Remove">
                <ion-icon *ngIf="participant.account_id != user?._id" name="close-circle-outline"
                          sxTextSize="30px"></ion-icon>
            </super-button>

        </div>
    </div>

  <div class="fields-wrapper">
    <div *ngFor="let field of participant.otherFields" sxWidth="full"  sxMinWidth="300px">
      <super-input
        [label]="field.label ? field.label : field.name"
        [required]="field.isMandatory"
        [(value)]="field.value"
        [type]="field.type"
        [(errorNote)]="field.errorMessage"
        [options]="field.options"
        [validate]="true"
        [placeholder]="'Enter ' + (field.label ? field.label : field.name)"
      ></super-input>
    </div>
  </div>


</div>
