import {NgModule} from '@angular/core';
import {CreateEncryptionKeyComponent} from './create-encryption-key/create-encryption-key.component';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {SuperModule} from '@qid/super';
import {SexyModule} from '@qid/sexy';
import {UnlockEncryptionKeysComponent} from './unlock-encryption-keys/unlock-encryption-keys.component';

@NgModule({
    imports: [CommonModule, IonicModule, FormsModule, SuperModule, SexyModule],
    declarations: [CreateEncryptionKeyComponent, UnlockEncryptionKeysComponent],
    exports: [CreateEncryptionKeyComponent],
})
export class EncryptionKeysComponentsModule {
}
