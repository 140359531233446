import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'page-action',
  templateUrl: './page-action.component.html',
  styleUrls: ['./page-action.component.scss'],
})
export class PageActionComponent implements OnInit {

  @ViewChild('mainWrapper') mainWrapper: ElementRef
  @ViewChild('pageActionWrapper') pageActionWrapper: ElementRef

  constructor() {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

    const rect = this.mainWrapper.nativeElement.getBoundingClientRect();
    if (window.innerWidth < 700 || rect.width < 200) {
      return
    }

    this.pageActionWrapper.nativeElement.style.transform = 'none'
    this.pageActionWrapper.nativeElement.style.width = rect?.width + 'px'
    this.pageActionWrapper.nativeElement.style.left = '0'
  }
}
