import {Injectable} from '@angular/core';
import {Organisation} from '../model/Organisation';
import {Store} from "@ngrx/store";
import {RestService} from "../../../../services/rest.service";
import {PaginatedResponse} from "../../../../interface/PaginatedResponse";
import { data } from '@tensorflow/tfjs';

@Injectable({
  providedIn: 'root',
})
export class OrganisationMasterService {
  static readonly ApiEndpoint: string = 'master/organisation/'

  constructor(private restService: RestService,
              private store: Store
  ) {
  }

  public async readAll(pageNumber: number, searchTerm: string = ''): Promise<PaginatedResponse<Organisation>> {
    return (
      await this.restService.send({
      url: OrganisationMasterService.ApiEndpoint,
      method: 'GET',
      query: {
        searchTerm: searchTerm,
        page: pageNumber
      }
    })).data
  }

  async readOne(orgId: string) {
    return (await this.restService.send<Organisation>({
      url: OrganisationMasterService.ApiEndpoint + orgId,
      method: 'GET',
    })).data
  }


  async deleteOrg(orgId: string) {
    return (
      await this.restService.send({
      url: OrganisationMasterService.ApiEndpoint + 'delete',
      method: 'PUT',
      query: {
        orgId: orgId
      }
    })).data;
  }

  public getAnalytics(startDate: number = null, endDate: number = null) {
    return this.restService.send<number>({
      url: OrganisationMasterService.ApiEndpoint + `analytics?startDate=${startDate}&endDate=${endDate}`,
      method: 'GET',
    });
  }
}
