import {Directive, ElementRef, OnInit} from "@angular/core";

@Directive({selector: '[sxRow]'})
export class SoopRow implements OnInit {

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.style.display = "flex"
    this.el.nativeElement.style.flexDirection = "row"
  }

}
