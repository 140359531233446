<div
  sxPadding="20px 0"
  sxHeight="full"
  sxWidth="full"
  *ngIf="template"
>
  <quick-signup
    sxHeight="full"
    sxWidth="full"
    [isEmailRequired]="template.isEmailRequired"
    [isPhoneRequired]="template.isPhoneRequired"
    [isOtpVerification]="template.isOtpVerification"
    (onLogin)="onLogin($event)"
  ></quick-signup>
</div>
