import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({selector: '[sxMinWidth]'})
export class MinWidthDirective implements OnInit {
    @Input() sxMinWidth: string | 'full' | 'fill' | 'fit';

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        this.setWidth();
    }

    setWidth() {
        if (this.sxMinWidth == 'full') {
            this.el.nativeElement.style.minWidth = '100%';
            return;
        }

        if (this.sxMinWidth == 'fill') {
            this.el.nativeElement.style.flex = '1 0 0px';
            return;
        }

        if (this.sxMinWidth == 'fit') {
            this.el.nativeElement.style.flexShrink = '0';
            this.el.nativeElement.style.minWidth = 'min-content';
            return;
        }

        this.el.nativeElement.style.minWidth = this.sxMinWidth;
    }
}
