import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NameCorrectionPageRoutingModule} from './name-correction-routing.module';
import {NameCorrectionPage} from './name-correction.page';
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NameCorrectionPageRoutingModule,
    SexyModule,
    SuperModule
  ],
  declarations: [NameCorrectionPage]
})
export class NameCorrectionPageModule {
}
