import {parsePhoneNumber} from 'libphonenumber-js';

export class ValidationUtil {
  static validateEmail(email: string): boolean {
    if (!ValidationUtil.isEmailValid(email)) {
      throw "Email is invalid";
    }
    return true;
  }

  static isOnlyWhitespace(value: string): boolean {
    return typeof value === 'string' && value.trim().length === 0;
  }


  static isEmailValid(email: string): boolean {
    return !!(
      email &&
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
  }

  static validatePhone(phone: string) {
    if (!phone || !ValidationUtil.isValidPhone(phone)) {
      throw "Please provide valid Phone Number";
    }
  }

  static isNumberOnly(test: string): boolean {
    return /^[0-9]+$/.test(test);
  }

  static isValidPhone(phoneNumber: string) {
    phoneNumber = phoneNumber.replace("+", "")
    const phoneNumberObj = parsePhoneNumber(`+${phoneNumber}`);
    if (phoneNumberObj?.country == "IN" && phoneNumberObj.nationalNumber.length != 10) {
      return false;
    }
    return phoneNumberObj && phoneNumberObj.isValid()
  }

  static validateName(value: string, propertyName: string = "Name"): boolean {
    if (ValidationUtil.isStringEmpty(value)) {
      throw `${propertyName} cannot be empty`;
    }
    return true;
  }

  static isStringEmpty(value: string) {
    if (value) {
      return this.isOnlyWhitespace(value)
    }
    return !value || value.length < 1 || value == "null";
  }

  static isNull(value: string) {
    return !value || value == 'null';
  }

  static isTrue(value: any) {
    return value == true || value == "true";
  }

  static isFalse(value: any) {
    return value == false || value == "false";
  }
}
