import {Participant} from "@qid/common";
import {Field} from "@qid/field";
import {ShareParticipantDocumentsComponent} from "../share-participant-documents/share-participant-documents.component";
import {IdField} from "@qid/common";
import {DateUtil} from "@qid/core";

export class GatewayDocumentSubmissionUtil {

  public static validateParticipantDocumentFields(participant: Participant) {

    if (!GatewayDocumentSubmissionUtil.validateMandatoryIdFields(participant.mandatoryIds) ||
      !GatewayDocumentSubmissionUtil.validateOptionalIdFields(participant.optionalIds, participant) ||
      GatewayDocumentSubmissionUtil.getParticipantDocumentsClubbed(participant)?.length < 1
    ) {
      throw new Error("Some documents have not been uploaded");
    }
  }


  public static validateMandatoryIdFields(mandatoryIdFields: Field<IdField>[]): boolean {
    let isAllIdVerified: boolean = true;

    for (let idField of mandatoryIdFields) {
      if (!idField.data || !idField.data.documentAccess) {
        idField.errorMessage = "This ID is required";
        isAllIdVerified = false;
      }
    }

    return isAllIdVerified;
  }

  public static validateOptionalIdFields(optionalIds: Field<IdField>[], participant: Participant) {

    if (optionalIds.length < 1) {
      return true;
    }

    let idsCollected = 0;
    for (let idField of optionalIds) {
      if (idField.data.documentAccess && !idField.data.skip) {
        ++idsCollected;
      }
    }

    if (participant.optionalLabel == ShareParticipantDocumentsComponent.optionLabel.optional) {
      return true;
    }

    return idsCollected > 0;
  }


  public static getParticipantDocumentsClubbed(participant: Participant) {

    participant.documentsAccess = [];
    participant.sharedDocumentsIds = [];

    let allIdField = participant.mandatoryIds.concat(participant.optionalIds);

    for (let field of allIdField) {
      if (field.data.documentAccess && !field.data.skip) {
        participant.documentsAccess.push(field.data.documentAccess);
        participant.sharedDocumentsIds.push(field.data.documentAccess?.document?._id);
      }
    }
    return participant.documentsAccess
  }

  public static getParticipantAnalytics(participant: Participant) {

    const verifiedDocAccess = participant.documentsAccess.find(doc => doc.documentData?.verifyTime != null);

    if(verifiedDocAccess){
      const docData = verifiedDocAccess.documentData;
      const age = docData?.yearOfBirth?.length > 0? DateUtil.getAgeByBirthYear(+docData?.yearOfBirth) : DateUtil.getAge(docData?.dob);
      return {
        gender: docData?.gender,
        age : age,
        zipCode: docData?.address?.zipCode
      }
    }

    const manualFields = GatewayDocumentSubmissionUtil.aggregateManualFields(participant);

    if(!manualFields){
      return
    }

    const genderField = manualFields.find(field => field.name == 'gender')
    const dobField = manualFields.find(field => field.name == 'dob')
    const zipCodeField = manualFields.find(field => field.name == 'zipcode')

    const age = DateUtil.getAge(dobField?.value);

    return {
      gender: genderField?.value,
      age: age,
      zipCode: zipCodeField?.value
    }
  }

  public static aggregateManualFields = (participant: Participant) => {
    return participant?.documentsAccess?.flatMap(doc => doc?.documentData?.manualFields || []);
  };

}
