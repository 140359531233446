import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer} from '@ngrx/store';
import {Organisation} from "../model/Organisation";


export const ORGANISATIONS_FEATURE_KEY = 'organisations';

export interface OrganisationsState extends EntityState<Organisation> {
  selectedId?: string | number; // which Organisations record has been selected
}

export interface OrganisationsPartialState {
  readonly [ORGANISATIONS_FEATURE_KEY]: OrganisationsState;
}

export const organisationsAdapter: EntityAdapter<Organisation> =
  createEntityAdapter<Organisation>();

export const initialOrganisationsState: OrganisationsState =
  organisationsAdapter.getInitialState({});

const reducer = createReducer(
  initialOrganisationsState,
);

export function organisationsReducer(
  state: OrganisationsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
