import {Injectable} from "@angular/core";
import {ID} from "../../../models/ID";
import {RestService} from "../../../services/rest.service";

@Injectable()
export class DocumentDiService {

  private static readonly ApiEndpoint = "di/document/";

  constructor(
    private restService: RestService
  ) {
  }

  public async readOne(id: string): Promise<ID> {
    return (
      await this.restService.send<ID>({
        url: DocumentDiService.ApiEndpoint + `read`,
        method: "GET",
        query : {
          document_id : id
        }
      })
    ).data;
  }


}
