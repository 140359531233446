import {Directive, ElementRef, OnInit} from "@angular/core";

@Directive({selector: '[sxHorizontalScroll]'})
export class HorizontalScrollDirective implements OnInit {


  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.style.display = "flex"
    this.el.nativeElement.style.flexDirection = "row"
    this.el.nativeElement.style.overflow = "scroll"
    this.el.nativeElement.style.whiteSpace = "no-wrap"
  }

}
