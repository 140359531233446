<div sxCol sxGap="10px" sxBackground="light" sxBorderColor="one" sxBorderWidth="1px" sxRadius="medium"
     sxPadding="medium">
  <div sxRow sxDistribution="spaceBetween" sxAlign="center" sxGap="10px">
    <div sxWidth="50px" sxHeight="50px" sxRadius="small"
         sxCenterize="col" sxBackground="base" sxColor="two">
      <p *ngIf="stayDuration" sxMargin="0" sxTextSize="22px" sxTextWeight="bold">{{stayDuration}}</p>
      <p *ngIf="stayDuration" sxMargin="0" sxTextSize="10px">{{stayDuration > 1 ? 'days' : 'day'}}</p>
      <ion-icon *ngIf="!stayDuration" sxTextSize="30px" name="calendar-clear-outline"></ion-icon>
    </div>
    <div sxCol sxWidth="fill">
      <p sxMargin="0 0 5px" sxTextSize="12px" sxColor="two" sxTextWeight="bold">Check In</p>
      <p sxMargin="0" *ngIf="!share?.checkInData?.checkInTime">{{share?.createTime | date : 'mediumDate'}}</p>
      <p sxMargin="0"
         *ngIf="share?.checkInData?.checkInTime">{{share?.checkInData?.checkInTime | date : 'mediumDate'}}</p>
    </div>
    <div sxCol sxWidth="fill" sxBorderColor="one" sxBorderWidth="0 0 0 1px" sxPadding="0 0 0 10px">
      <div *ngIf="share?.checkInData?.checkOutTime">
        <p sxMargin="0 0 5px" sxTextSize="12px" sxColor="two" sxTextWeight="bold">Check Out</p>
        <p sxMargin="0">{{share?.checkInData?.checkOutTime | date : 'mediumDate'}}</p>
      </div>
      <button *ngIf="!share?.checkInData?.checkOutTime" sxBorderColor="one" sxBorderWidth="1px"
              sxGap="5px" sxPadding="10px" sxRadius="small" sxCenterize="row" sxBackground="light" sxColor="three"
              sxTextSize="16px" (click)="onCheckOutClicked()">
        Check out
        <ion-icon sxTextSize="14px" name="log-out-outline"></ion-icon>
      </button>
    </div>
  </div>
  <div *ngIf="share?.checkInData?.remark?.length > 0" sxBorderWidth="1px 0 0" sxBorderColor="one" sxPadding="5px 0 0">
    <p sxMargin="0" sxTextSize="small" sxColor="two"><span>Check Out Remark:</span> {{share?.checkInData?.remark}}</p>
  </div>
</div>


