import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ItemReorderEventDetail} from '@ionic/angular';
import {AgreementBullet, CollectionTemplateAgreement} from '../../models/CollectionTemplateAgreement';

@Component({
  selector: 'app-template-agreement-setter',
  templateUrl: './template-agreement-setter.component.html',
  styleUrls: ['./template-agreement-setter.component.scss'],
})
export class TemplateAgreementSetterComponent implements OnInit {
  @Input() collectionTemplateAgreements: CollectionTemplateAgreement[] = [];
  @Output() collectionTemplateAgreementsChange = new EventEmitter<CollectionTemplateAgreement[]>();
  selectedAgreementIndex: number = null;

  public baseIconUrl = 'assets/'
  public allIcons: { imgUrl: string, isSelected?: boolean }[] = [
    {imgUrl: 'no-drink.png'},
    {imgUrl: 'no-smoking.png'},
    {imgUrl: 'no-drugs.png'},
    {imgUrl: 'no-camera.png'},
    {imgUrl: 'no-pets.png'}
  ]

  constructor() {
  }

  ngOnInit() {
  }

  addPolicy() {
    this.collectionTemplateAgreements.push(new CollectionTemplateAgreement());
    this.selectedAgreementIndex = this.collectionTemplateAgreements.length - 1;
  }

  selectAgreement(agreement) {
    this.selectedAgreementIndex = agreement;
    this.setDefaultIcons();
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    this.collectionTemplateAgreements = ev.detail.complete(
      this.collectionTemplateAgreements
    );
    this.collectionTemplateAgreementsChange.emit(
      this.collectionTemplateAgreements
    );
  }

  remove(i: number) {
    this.collectionTemplateAgreements.splice(i, 1)
    this.selectAgreement(null)
  }

  addBullet() {
    this.collectionTemplateAgreements[this.selectedAgreementIndex].bullets.push(new AgreementBullet())
  }

  addIcon(imgName: string) {
    this.collectionTemplateAgreements[this.selectedAgreementIndex].icons.push({imgName: imgName})
  }

  removeBullet(index: number) {
    this.collectionTemplateAgreements[this.selectedAgreementIndex].bullets.splice(index, 1)
  }

  removeIcon(index: number) {
    this.collectionTemplateAgreements[this.selectedAgreementIndex].icons.splice(index, 1)
  }

  selectIcon(i: number) {
    this.allIcons[i].isSelected = !this.allIcons[i].isSelected;

    const imgNames: string[] = this.collectionTemplateAgreements[this.selectedAgreementIndex].icons?.map(icon => icon.imgName);

    if (this.allIcons[i].isSelected && imgNames.indexOf(this.allIcons[i].imgUrl) < 1) {
      this.addIcon(this.allIcons[i].imgUrl)
    }

    if (!this.allIcons[i].isSelected && imgNames.indexOf(this.allIcons[i].imgUrl) > -1) {
      this.removeIcon(imgNames.indexOf(this.allIcons[i].imgUrl))
    }

  }

  setDefaultIcons() {
    const imgNames: string[] = this.collectionTemplateAgreements[this.selectedAgreementIndex].icons?.map(icon => icon.imgName);
    for (let icon of this.allIcons) {
      icon.isSelected = imgNames.indexOf(icon.imgUrl) > -1
    }
  }

}
