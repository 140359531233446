import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'super-stack',
  templateUrl: './super-stack.component.html',
  styleUrls: ['./super-stack.component.scss'],
})
export class SuperStackComponent implements OnInit {
  @Input() direction: string = 'vertical';
  constructor() {}

  ngOnInit() {}
}
