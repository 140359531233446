import {Injectable} from "@angular/core";
import {CreatePlanDto} from "../dto/create-plan.dto";
import {Plan} from "../model/plan.model";
import {RestService} from "../../../../services/rest.service";

@Injectable()
export class PlanService {

  private static readonly ApiEndpoint = 'master/plan/';


  constructor(private restService: RestService) {
  }

  public create(createPlanDto: CreatePlanDto) {
    return this.restService.send({
      url: PlanService.ApiEndpoint,
      method: "POST",
      body: createPlanDto
    })
  }

  public readOne(id: string) {
    return this.restService.send<Plan>({
      url: PlanService.ApiEndpoint + id,
      method: "GET",
    })
  }

  public async readAll() {
    return (await this.restService.send<Plan[]>({
      url: PlanService.ApiEndpoint,
      method: "GET",
    })).data
  }

  public async readAllPublic() {
    return this.restService.send<Plan[]>({
      url: 'plan/public',
      method: "GET",
    })
  }

  update = async (plan: Plan) => {
    return this.restService.send({
      url: PlanService.ApiEndpoint + plan._id,
      method: "PUT",
      body: plan
    })

  }

  async delete(plan: Plan) {
    return this.restService.send({
      url: PlanService.ApiEndpoint + plan._id,
      method: "DELETE"
    })
  }
}
