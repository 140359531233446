<ion-header>
  <ion-toolbar>
    <div
      sxWidth="full"
      sxRow
      sxPadding="10px 12px"
      sxDistribution="start"
      sxGap="10px"
      sxAlign="center"
      *ngIf="scanner">
      <super-button
        padding="0" type="clear" size="small"
        *ngIf="inAppQrCode" fill="clear" (click)="closeModal()">
        <ion-icon sxTextSize="30px" name="arrow-back-outline"></ion-icon>
      </super-button>
      <div
        sxRow
        sxAlign="center"
        sxDistribution="start"
        sxWidth="fill"
        sxGap="10px">
        <avatar-round
          [autoBase]="true"
          [size]="40"
          [title]="scanner?.org?.name"
          [imageUrl]="scanner?.org?.displayPictureUrl"
        ></avatar-round>
        <p sxMargin="0" sxTextWeight="bold">{{ scanner?.org?.name }}</p>
      </div>
      <super-button
        *ngIf="activeSlide !== slides.welcome && !inAppQrCode"
        size="small"
        type="clear"
        padding="0"
        (click)="reload()"
      >Restart
      </super-button>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content #content>

<!--  <verified-signup #verfiedSignUp [hidden]="activeSlide != slides.digiLocker"></verified-signup>-->

  <p>{{testMessage}}</p>
  <gateway-welcome
    #gatewayWelcomeComponent
    [hidden]="activeSlide != slides.welcome"
    [collectionTemplate]="template"
    [scanner]="scanner"
  ></gateway-welcome>

  <gateway-authentication
    #gatewayAuthenticationComponent
    [template]="template"
    [hidden]="activeSlide != slides.authentication">
  </gateway-authentication>

  <gateway-continue-previous
    #gatewayContinuePreviousComponent
    [hidden]="activeSlide != slides.previousShare"
    [template]="template"
    [scanner]="scanner"
  >
  </gateway-continue-previous>

  <gateway-id-submission
    #gatewayIdSubmissionComponent
    [hidden]="activeSlide != slides.idSubmission"
    [organisation]="scanner?.org"
    [template]="template" [share]="share"
  ></gateway-id-submission>

  <gateway-selfie-verification
    #gatewaySelfieVerificationComponent [hidden]="activeSlide != slides.selfie"
    [share]="share">
  </gateway-selfie-verification>

  <gateway-form
    #gatewayFormComponent
    [hidden]="activeSlide!=slides.form"
    [template]="template" [share]="share"
  ></gateway-form>

  <gateway-policies
    #gatewayPoliciesComponent
    [hidden]="activeSlide!=slides.policy"
    [template]="template"
    [share]="share"
  ></gateway-policies>

  <gateway-signature
    #gatewaySignatureComponent
    [hidden]="activeSlide!=slides.signature"
    [template]="template" [share]="share"
  ></gateway-signature>

  <gateway-successful
    #gatewaySuccessfulComponent
    [hidden]="activeSlide!=slides.successful"
    [template]="template" [share]="share"
  ></gateway-successful>

  <div *ngIf="activeSlide === slides.loading" sxMatchParent="" sxCenterize="col">
    <ion-spinner *ngIf="!urlInvalid"></ion-spinner>
    <div *ngIf="urlInvalid">Looks like you've scanned the invalid QR</div>
  </div>

  <div id="verifier"></div>
</ion-content>
