import {Directive, ElementRef, Input, OnInit} from "@angular/core";


@Directive({selector: '[sxGap]'})
export class GapDirective implements OnInit {

  @Input() sxGap?: string = "5px";

  constructor(private el: ElementRef) {
  }

  ngOnInit() {

    try{
      this.el.nativeElement.style.gap = this.sxGap
    }catch (e){
      console.error("SX GAP PROPERTY", e)
    }
  }

}
