import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DisplayService} from "./services/display.service";
import {CoreConfig} from "./interface/CoreConfig";
import {CorePipeModule} from "./pipes/core-pipe.module";


@NgModule({
  imports: [CommonModule, CorePipeModule],
  providers: [DisplayService]
})
export class CoreModule {

  static environment = null;

  constructor() {
  }

  public static forRoot(config: CoreConfig): ModuleWithProviders<CoreModule> {

    if (config.environment) {
      CoreModule.environment = config.environment
    }

    return {
      ngModule: CoreModule
    };
  }


}
