<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-button fill="clear" slot="start" (click)="cancel()">Cancel</ion-button>
        <ion-title>Choose Industry</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="slide" *ngIf="activeSlide === 1">
        <ion-list>
            <ion-list-header>
                <ion-label>Choose Industry</ion-label>
            </ion-list-header>
            <ion-item
                    button=""
                    *ngFor="let industry of industries"
                    (click)="onIndustrySelected(industry)"
                    [hidden]="industry.parentIndustry">
                <ion-label>{{ industry.name }}</ion-label>
            </ion-item>
        </ion-list>
    </div>
    <div class="slide" *ngIf="activeSlide === 2">
        <img src="assets/imgs/factory_dy-0-a.svg" alt="img"/>
        <ion-list *ngIf="!isCreatingOrg">
            <ion-list-header>
                <ion-label>Choose Sub-Industry</ion-label>
            </ion-list-header>
            <ion-item
                    button=""
                    *ngFor="let industry of subIndustries"
                    (click)="onIndustrySelected(industry._id)">
                <ion-label>{{ industry.name }}</ion-label>
            </ion-item>
        </ion-list>
    </div>
</ion-content>
