import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements AfterViewInit {
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('imageUpload', { static: false }) imageUpload: ElementRef<HTMLInputElement>;
  @Input() imageUrl : string = ''
  @Output() croppedImageUrl = new EventEmitter<string>();
  private ctx: CanvasRenderingContext2D;
  private points: { x: number; y: number }[] = [];
  private draggingPoint: { x: number; y: number } = null;
  private draggingSide: number = null;
  private initialMousePos: { x: number; y: number } = null;
  private image = new Image();


  ngAfterViewInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.image.onload = () => {
      this.canvas.nativeElement.width = this.image.width;
      this.canvas.nativeElement.height = this.image.height;
      this.ctx.drawImage(this.image, 0, 0);
      this.points = [
        { x: 50, y: 50 },
        { x: this.image.width - 50, y: 50 },
        { x: this.image.width - 50, y: this.image.height - 50 },
        { x: 50 , y: this.image.height - 50 }
      ];
      this.drawQuadrilateral();
    };
    this.image.src = this.imageUrl
  }

  onMouseDown(event: MouseEvent) {
    const { x, y } = this.getMousePos(event);
    this.draggingPoint = this.points.find(point => this.isPointInRadius(x, y, point, 10));
    if (!this.draggingPoint) {
      this.draggingSide = this.getSideAtPosition(x, y);
      this.initialMousePos = {x,y};
    }
  }

  onMouseMove(event: MouseEvent) {
    const { x, y } = this.getMousePos(event);
    let highlightPoint = null
    if (this.draggingPoint) {
      this.draggingPoint.x = x;
      this.draggingPoint.y = y;
      this.redrawCanvas();
    }else if (this.draggingSide !== null) {
      this.moveSide(this.draggingSide, x - this.initialMousePos.x, y - this.initialMousePos.y);
      this.initialMousePos = { x, y };
      this.redrawCanvas();
    }else {
      highlightPoint = this.points.find(point => this.isPointInRadius(x, y, point, 10));
      this.redrawCanvas(highlightPoint);
    }
  }

  onMouseUp() {
    this.draggingPoint = null;
    this.draggingSide = null;
  }

  onMouseOut() {
    this.draggingPoint = null;
    this.draggingSide = null;
  }

  cropImage() {
    if (this.points.length === 4) {
      this.cropQuadrilateral();
    }
  }

  private getMousePos(evt: MouseEvent) {
    const rect = this.canvas.nativeElement.getBoundingClientRect();
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top
    };
  }

  private isPointInRadius(x: number, y: number, point: { x: number; y: number }, radius: number) {
    return Math.sqrt((x - point.x) + (y - point.y) ** 2) < radius;
  }

  private drawQuadrilateral(highlightPoint: { x: number, y: number } = null, highlightLine: number = null) {
    const blurRadius = 20;
    const canvasWidth = this.canvas.nativeElement.width;
    const canvasHeight = this.canvas.nativeElement.height;

    this.ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    this.ctx.drawImage(this.image, 0, 0);

    // Create a temporary canvas for the blurred background
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = canvasWidth;
    tempCanvas.height = canvasHeight;
    const tempCtx = tempCanvas.getContext('2d');

    // Draw the original image on the temporary canvas
    tempCtx.drawImage(this.image, 0, 0);

    // Create another temporary canvas for the blurred effect
    const blurCanvas = document.createElement('canvas');
    blurCanvas.width = canvasWidth;
    blurCanvas.height = canvasHeight;
    const blurCtx = blurCanvas.getContext('2d');

    // Apply blur effect to the entire canvas
    blurCtx.filter = `blur(${blurRadius}px)`;
    blurCtx.drawImage(tempCanvas, 0, 0);

    // Draw the blurred background on the main canvas
    this.ctx.drawImage(blurCanvas, 0, 0);

    // Clear the area inside the quadrilateral
    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.moveTo(this.points[0].x, this.points[0].y);
    for (let i = 1; i < this.points.length; i++) {
      let point = this.points[i];
      this.ctx.lineTo(point.x, point.y);
    }
    this.ctx.closePath();
    this.ctx.clip();
    this.ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    this.ctx.drawImage(this.image, 0, 0);
    this.ctx.restore();

    // Draw the quadrilateral
    this.ctx.beginPath();
    this.ctx.moveTo(this.points[0].x, this.points[0].y);
    for (let i = 1; i < this.points.length; i++) {
      let point = this.points[i];
      this.ctx.lineTo(point.x, point.y);
    }
    this.ctx.closePath();
    this.ctx.lineWidth = 3;
    this.ctx.strokeStyle = 'white';
    this.ctx.stroke();

    // Highlight points
    this.points.forEach((point) => {
      this.ctx.fillStyle = point === highlightPoint ? 'blue' : 'white';
      this.ctx.beginPath();
      this.ctx.arc(point.x, point.y, 10, 0, 2 * Math.PI);
      this.ctx.fill();
    });
    if (highlightLine !== null) {
      this.ctx.lineWidth = 5;
      this.ctx.strokeStyle = 'blue';
      this.ctx.beginPath();
      this.ctx.moveTo(this.points[highlightLine].x, this.points[highlightLine].y);
      this.ctx.lineTo(this.points[(highlightLine + 1) % this.points.length].x, this.points[(highlightLine + 1) % this.points.length].y);
      this.ctx.stroke();
    }
  }


  private isNearLine(cursor: { x: number, y: number }): number | null {
    const threshold = 5;
    for (let i = 0; i < this.points.length; i++) {
      const point1 = this.points[i];
      const point2 = this.points[(i + 1) % this.points.length];
      const distance = this.distanceToLine(cursor, point1, point2);
      if (distance < threshold) {
        return i;
      }
    }
    return null;
  }

  private isNearPoint(cursor: { x: number, y: number }): { x: number, y: number } | null {
    const threshold = 10;
    for (const point of this.points) {
      const distance = Math.sqrt(Math.pow(cursor.x - point.x, 2) + Math.pow(cursor.y - point.y, 2));
      if (distance < threshold) {
        return point;
      }
    }
    return null;
  }

  private distanceToLine(point: { x: number, y: number }, lineStart: { x: number, y: number }, lineEnd: { x: number, y: number }): number {
    const a = lineStart.y - lineEnd.y;
    const b = lineEnd.x - lineStart.x;
    const c = lineStart.x * lineEnd.y - lineEnd.x * lineStart.y;
    return Math.abs(a * point.x + b * point.y + c) / Math.sqrt(a * a + b * b);
  }
  // @HostListener('mousemove', ['$event'])
  onMouseMoves(event: MouseEvent) {
    const rect = this.canvas.nativeElement.getBoundingClientRect();
    const cursor = {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };

    const highlightPoint = this.isNearPoint(cursor);
    const highlightLine = this.isNearLine(cursor);

    this.drawQuadrilateral(highlightPoint, highlightLine);
  }

  private redrawCanvas(highlightPoint: { x: number, y: number } = null) {
    this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.ctx.drawImage(this.image, 0, 0);
    this.drawQuadrilateral(highlightPoint);
  }

  private getSideAtPosition(x: number, y: number) {
    for (let i = 0; i < this.points.length; i++) {
      const start = this.points[i];
      const end = this.points[(i + 1) % this.points.length];
      if (this.isPointNearLine(x, y, start, end, 5)) {
        return i;
      }
    }
    return null;
  }

  private isPointNearLine(x: number, y: number, start: { x: number; y: number }, end: { x: number; y: number }, tolerance: number) {
    const dx = end.x - start.x;
    const dy = end.y - start.y;
    const length = Math.sqrt(dx * dx + dy * dy);
    const distance = Math.abs(dy * x - dx * y + end.x * start.y - end.y * start.x) / length;
    return distance < tolerance;
  }

  private moveSide(sideIndex: number, dx: number, dy: number) {
    const start = this.points[sideIndex];
    const end = this.points[(sideIndex + 1) % this.points.length];
    start.x += dx;
    start.y += dy;
    end.x += dx;
    end.y += dy;
  }

  private cropQuadrilateral() {
    const minX = Math.min(...this.points.map(p => p.x));
    const minY = Math.min(...this.points.map(p => p.y));
    const maxX = Math.max(...this.points.map(p => p.x));
    const maxY = Math.max(...this.points.map(p => p.y));

    const offscreenCanvas = document.createElement('canvas');
    const offscreenCtx = offscreenCanvas.getContext('2d');
    offscreenCanvas.width = maxX - minX;
    offscreenCanvas.height = maxY - minY;

    offscreenCtx.drawImage(this.image, -minX, -minY);

    const imageData = offscreenCtx.getImageData(0, 0, offscreenCanvas.width, offscreenCanvas.height);
    const data = imageData.data;

    const mask = new Uint8ClampedArray(data.length);

    const isInsidePolygon = (x: number, y: number) => {
      let inside = false;
      for (let i = 0, j = this.points.length - 1; i < this.points.length; j = i++) {
        const xi = this.points[i].x, yi = this.points[i].y;
        const xj = this.points[j].x, yj = this.points[j].y;

        const intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
      }
      return inside;
    };

    for (let y = 0; y < offscreenCanvas.height; y++) {
      for (let x = 0; x < offscreenCanvas.width; x++) {
        if (isInsidePolygon(x + minX, y + minY)) {
          const index = (y * offscreenCanvas.width + x) * 4;
          mask[index] = data[index];
          mask[index + 1] = data[index + 1];
          mask[index + 2] = data[index + 2];
          mask[index + 3] = data[index + 3];
        }
      }
    }

    const croppedImage = new ImageData(mask, offscreenCanvas.width, offscreenCanvas.height);
    offscreenCtx.putImageData(croppedImage, 0, 0);
    const dataURL = offscreenCanvas.toDataURL('image/png');
    this.croppedImageUrl.emit(dataURL);
  }
}
