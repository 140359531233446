<ion-header  class="ion-no-border">
  <ion-toolbar>
    <ion-button slot="start" (click)="dismiss()" fill="clear">Cancel</ion-button>
    <ion-title >Activate Plan</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

    <div sxPadding="medium" sxCol sxDistribution="spaceBetween" sxHeight="full">
      <div sxCol sxGap="10px">
      <super-input label="Plans" placeholder="Select Plans" type="dropdown"
                   [options]="plans" dropDownLabelKey="name"
                   [(value)]="selectedPlan"
      ></super-input>

      <super-input label="Organisation" type="dropdown"
                   [options]="organisations" dropDownLabelKey="name"
                   [(value)]="selectedOrganisation"
      ></super-input>

      <div *ngIf="selectedPlan" sxCol sxGap="10px">
        <div *ngFor="let pricingModel of selectedPlan.planPricingModels" sxBackground="dark"
        sxRadius="medium" sxPadding="medium" sxColor="light"
             (click)="setPricingModel(pricingModel)"
        >
          <div sxCol sxAlign="center">
            <p sxMargin="medium 0" sxTextSize="medium">{{pricingModel.name}} Plan</p>
            <p sxMargin="0" sxTextSize="large">&#8377;{{pricingModel.amount}}</p>
            <p sxMargin="medium 0" sxTextSize="medium">Days: {{pricingModel.validityDays}}</p>
            <button sxWidth="full" sxTextAlign="center" sxPadding="medium" sxTextWeight="bold" sxRadiusOnly sxRadius="medium" >Continue</button>
          </div>
        </div>
      </div>
      </div>
    </div>
</ion-content>
