<ion-header class="ion-no-border" mode="ios">
  <ion-toolbar>
    <ion-button slot="start" (click)="dismiss()" fill="clear">Cancel</ion-button>
    <ion-title>Add Member</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div sxPadding="medium">
    <div *ngIf="activeSlide == slides.inputEmail" sxCol sxGap="10px">
      <super-input type="email" [stacked]="false"
                   [(value)]="addUserDto.email"
                   iconStart="mail-outline"
                   placeholder="Enter Member's Email"></super-input>

      <super-button [action]="fetchName" [showError]="true">Next</super-button>
    </div>

    <div *ngIf="activeSlide==slides.inputName" sxCol sxGap="10px">
      <div class="registered-label" *ngIf="userToBeAdded">
        <p>{{ addUserDto.email }}</p>
        <avatar-round
          [imageUrl]="userToBeAdded.displayPictureUrl"
          [title]="userToBeAdded.name"></avatar-round>
        <h1>{{ userToBeAdded.name }}</h1>
      </div>

      <super-input *ngIf="userToBeAdded === null" [stacked]="false"
                   [(value)]="addUserDto.name"
                   iconStart="person-outline"
                   placeholder="Enter Member's Name"></super-input>

      <super-button (click)="goToSlide(slides.inputRole)">Add</super-button>

    </div>

    <div *ngIf="activeSlide==slides.inputRole" sxCol sxGap="10px">
      <h3 sxMargin="20px 15px 5px" sxColor="two">Member Role</h3>
        <ion-radio-group [(ngModel)]="addUserDto.role">
          <ion-list sxRadius="small">
            <ion-item>
              <ion-icon name="ribbon-outline"></ion-icon>
              <ion-label>Admin</ion-label>
              <ion-radio slot="end" value="0"></ion-radio>
            </ion-item>
            <ion-item>
              <ion-icon name="briefcase-outline"></ion-icon>
              <ion-label>Editor</ion-label>
              <ion-radio slot="end" value="1"></ion-radio>
            </ion-item>
            <ion-item>
              <ion-icon name="eye-outline"></ion-icon>
              <ion-label>Viewer</ion-label>
              <ion-radio slot="end" value="2"></ion-radio>
            </ion-item>
          </ion-list>
        </ion-radio-group>
      <super-button [action]="addUser">Add</super-button>
    </div>


  </div>

</ion-content>
