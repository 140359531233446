import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'super-step-item',
  templateUrl: './super-step-item.component.html',
  styleUrls: ['./super-step-item.component.scss'],
})
export class SuperStepItemComponent implements OnInit {
  @Input() count: any;
  @Input() isTopLine: boolean = true;
  @Input() isBottomLine: boolean = true;
  @Input() success: boolean = false;
  constructor() {}

  ngOnInit() {}
}
