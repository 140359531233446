import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {CreateIdPageRoutingModule} from './create-id-routing.module';
import {CreateIdPage} from './create-id.page';
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";
import {IdComponentsModule} from '../../compenents/id-components.module';
import {CommonComponentsModule} from "../../../../components/common-components.module";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        CreateIdPageRoutingModule,
        SexyModule,
        SuperModule,
        IdComponentsModule,
        CommonComponentsModule
    ],
  declarations: [CreateIdPage]
})
export class CreateIdPageModule {
}
