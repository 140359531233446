import {Preferences} from "@capacitor/preferences";
import {ValidationUtil} from "@qid/core";
import { AuthenticationService } from "../services/authentication.service";


export class AuthenticationUtil {

  public static async getLocalAuthState() {
    const accessToken = await Preferences.get({key: AuthenticationService.storageKeyAccessToken})
    const refreshToken = await Preferences.get({key: AuthenticationService.storageKeyRefreshToken})
    const session_id = await Preferences.get({key: AuthenticationService.storageKeySession_id})
    if (!accessToken || ValidationUtil.isNull(accessToken.value)) {
      return {accessToken: null, refreshToken: null, session_id: null}
    }
    return {accessToken: accessToken.value, refreshToken: refreshToken.value, session_id: session_id.value}
  }

}
