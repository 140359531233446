import {Injectable} from '@angular/core';
import {Share} from '../model/Share';
import {QuotaCheckReport} from "@qid/quota";
import {RestService} from "../../../services/rest.service";


@Injectable()
export class ShareMasterService {
  static readonly ApiEndpoint = 'master/share/'

  constructor(
      private restService: RestService,
  ) {
  }


  async read(id: string) {
    return (await this.restService.send<Share>({
      url: ShareMasterService.ApiEndpoint + id,
      method: 'GET',
    })).data;
  }

  async readAll(page = 1) {
    return (
        await this.restService.send<any>({
          url: ShareMasterService.ApiEndpoint,
          method: 'GET',
          query: {
            page: page
          }
        })
    ).data;
  }


  public async delete(shareId: string): Promise<any> {
    return this.restService.send({
      url: ShareMasterService.ApiEndpoint + shareId,
      method: 'DELETE',
    });
  }

  public async getCreateShareQuotaReport(orgId: string) {
    return (await this.restService.send<QuotaCheckReport>({
      url: ShareMasterService.ApiEndpoint + 'quota/create',
      method: "GET",
      query: {
        orgId: orgId
      }
    })).data
  }

  public getAnalytics(startDate: number, endDate: number = new Date().getTime()) {
    return this.restService.send<number>({
      url: ShareMasterService.ApiEndpoint + 'share-analytics',
      method: 'GET',
      query: {
        startDate : startDate,
        endDate : endDate
      }
    });
  }
}
