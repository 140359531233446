import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'mock-search-bar',
    templateUrl: './mock-search-bar.component.html',
    styleUrls: ['./mock-search-bar.component.scss'],
})
export class MockSearchBarComponent implements OnInit {

    @Input() displayText: string;

    constructor() {
    }

    ngOnInit() {
    }

}
