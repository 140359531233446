import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {IonRouterOutlet, ModalController} from '@ionic/angular';
import {CreateFieldComponent} from '../create-field/create-field.component';
import {Field} from '../../models/Field';
import {ValidationUtil} from "@qid/core";


@Component({
  selector: 'field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class FieldComponent implements OnInit {
  @Input() field: Field;
  @Input() placeholder: string = 'Type Here';
  @Input() remarks: string;
  @Input() isRemove: boolean = false;
  @Input() isToggle: boolean = false;
  @Input() title: string = 'Create Field';
  @Input() showMandatory: boolean = true;
  @Output() onRemove: EventEmitter<any> = new EventEmitter();
  public isEnabled: boolean = true;

  constructor(
    private modalController: ModalController,
    @Optional() private routerOutlet: IonRouterOutlet
  ) {
  }

  ngOnInit() {
  }

  toggled() {
  }

  ngOnChanges() {
    if (ValidationUtil.isStringEmpty(this.field.label)) {
      this.field.label = this.field.name
    }
  }

  remove() {
    this.onRemove.emit();
  }

  async onInput() {
    if (this.field.isEdit) {
      return;
    }
    const modal = await this.modalController.create({
      component: CreateFieldComponent,
      keyboardClose: false,
      animated: true,
      componentProps: {
        field : this.field,
      },
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    if (res?.data?.field) {
      this.field = res.data.field;
    }
  }
}
