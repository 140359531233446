import {createAction, props} from '@ngrx/store';
import {AccountType} from "@qid/core";

// Auth Token

export const fetchPreviousAuthToken = createAction(
  '[Auth Guard] Fetch Previous Auth Token');

export const fetchAuthTokenSuccess = createAction(
    '[Auth/API] Fetch Auth Token Success',
    props<{
        token: string
    }>()
);

export const setActiveAccount = createAction(
  '[Auth/API] Set Active Account',
  props<{
    account: any,
    accountType: AccountType
  }>()
);

export const signInWithCustomToken = createAction(
    '[App] Sign In With Custom Token',
    props<{
        token: string
    }>()
)

export const signInAnonymously = createAction(
    '[App] Sign In Anonymously'
)

export const signInAnonymouslySuccess = createAction(
    '[App] Sign In Anonymously Success',
    props<{
        token: string
    }>()
)

export const signOut = createAction(
    '[App] SignOut'
)


