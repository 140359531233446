<p sxWidth="full" *ngIf="errorMessage && showError"
   sxTextSize="12px"
   sxColor="danger"
   sxMargin="0"
   sxPadding="small medium"
>{{errorMessage}}</p>
<button sxRow sxDistribution="spaceBetween"
        sxWidth="full"
        sxAlign="center" [sxHeight]="height" [sxPadding]="padding"
        [sxBackground]="getBackground()" [sxRadius]="radius" [sxColor]="getColor()"
        [sxTextSize]="textSize"
        (click)="onClick()"
        [sxBorderColor]="getBorderColor()"
        sxBorderWidth="1px"
        [class.disabled]="isLoading"
        sxGap="5px"
>

  <div sxTextSize="24px" sxCol sxDistribution="center" sxAlign="center" sxWidth="fit">
    <img [style.min-width]="'24px'" sxWidth="24px" *ngIf="imageStart" [src]="imageStart">
    <ion-icon *ngIf="iconStart" [name]="iconStart"></ion-icon>
  </div>
  <div sxWidth="fill" sxCol [align]="textAlign">
    <ng-content></ng-content>
  </div>
  <div sxCol sxDistribution="center" sxAlign="center" sxWidth="fit">
    <ion-icon [name]="iconEnd" *ngIf="iconEnd && !isLoading"></ion-icon>
    <ion-spinner
      class="loader"
      [color]="getColor()"
      *ngIf="isLoading && isDefaultLoader"></ion-spinner>
  </div>
</button>
