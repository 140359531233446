import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxOverflow]'})
export class OverflowDirective implements OnInit {

    @Input() sxOverflow: 'scroll' | 'hidden' | string;


    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        this.el.nativeElement.style.overflow = this.sxOverflow
    }

}
