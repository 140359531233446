import {NgModule} from "@angular/core";
import {SelectIndustryComponent} from "./select-industry/select-industry.component";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";


@NgModule(
    {
        imports: [IonicModule, CommonModule, FormsModule],
        declarations: [SelectIndustryComponent],
        exports: [SelectIndustryComponent],
    }
)

export class IndustryComponentsModule {

}
