import {Action, createReducer, on} from '@ngrx/store';
import * as AuthActions from './auth.actions';
import {AccountType} from "@qid/core";
import {RestService} from "../../../services/rest.service";


export const AUTH_FEATURE_KEY = 'Auth';

export interface AuthState {

  authToken: string;
  previousAuthTokenChecked: boolean,
  fetchingPreviousAuthToken: boolean,
  activeAccount: any,
  activeAccountType: AccountType

}

export const initialAuthState: AuthState = {

  authToken: null,
  fetchingPreviousAuthToken: false,
  previousAuthTokenChecked: false,
  activeAccount: null,
  activeAccountType: null
};

const reducer = createReducer(
  initialAuthState,

  on(AuthActions.fetchPreviousAuthToken, (state) => (
    {
      ...state,
      fetchingPreviousAuthToken: true,
    })),

  on(AuthActions.setActiveAccount, (state, {account, accountType}) => {
    RestService.setAccount(account?._id, accountType);
    return {
      ...state,
      activeAccount: account,
      activeAccountType: accountType
    }
  }),

  on(AuthActions.fetchAuthTokenSuccess, (state: AuthState, {token}) =>
    ({
      ...state,
      authToken: token,
      previousAuthTokenChecked: true,
      fetchingPreviousAuthToken: false
    })
  ),

  on(AuthActions.signInAnonymously, state => ({
    ...state,
    authenticatedUser: null,
    authToken: null
  })),

  on(AuthActions.signInAnonymouslySuccess, (state, {token}) => ({
    ...state,
    authToken: token
  })),

  on(AuthActions.signOut, state => ({
    ...state,
    authenticatedUser: null,
    authToken: null
  }))
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action);
}
