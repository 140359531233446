import {Component, Input} from '@angular/core';
import {CollectionTemplate, DocumentsRepository} from "@qid/common";
import {Share, ShareParticipantUtil, ShareService, ShareStatus} from "@qid/common";
import {Platform} from "@ionic/angular";
import {ValidationUtil} from "@qid/core";

@Component({
  selector: 'gateway-successful',
  templateUrl: './gateway-successful.component.html',
  styleUrl: './gateway-successful.component.scss',
})
export class GatewaySuccessfulComponent {

  @Input() share: Share;
  @Input() template: CollectionTemplate;
  public userDocuments$ = this.documentsRepository.activeAccountRecords$;public mainSharerName: string;
  public mainParticipant : any

  constructor(private platform: Platform,
              private shareService: ShareService,
              private documentsRepository: DocumentsRepository,
  ) {
  }

  async init() {
    const res = await this.shareService.update(this.share._id, {
      status: ShareStatus.completed,
      updateRemark: "Share Completed"
    });
    this.share.lastUpdateTime = res.data.lastUpdateTime
    this.mainParticipant = ShareParticipantUtil.findMainParticipant(this.share?.participants)?.account
    this.documentsRepository.init();
    this.checkRedirection();
    return res.data
  }

  public isMobile(): boolean {
    return this.platform.is("capacitor");
  }

  public checkRedirection(){
    if(ValidationUtil.isStringEmpty(this.template?.postSubmissionRedirectUrl)){
      return
    }
    setTimeout(()=>{
      location.replace(this.template.postSubmissionRedirectUrl)
    },5000)
  }


}
