import {NgModule} from "@angular/core";
import {AccountProfileViewComponent} from "./account-profile-view/account-profile-view.component";
import {SuperModule} from "@qid/super";
import {SexyModule} from "@qid/sexy";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";

@NgModule({
  declarations: [AccountProfileViewComponent],
  imports: [
    CommonModule,
    IonicModule,
    SuperModule,
    SexyModule
  ],
  exports: [AccountProfileViewComponent]
})

export class AccountProfileComponentsModule {

}
