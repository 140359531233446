import {Component, Input} from '@angular/core';
import {CryptoUtil} from "@qid/crypto";
import { EncryptionKeys } from '../../model/encryption-keys.model';
import { EncryptionKeysService } from '../../services/encryption-keys.service';

@Component({
    selector: 'oneqid-nx-unlock-encryption-keys',
    templateUrl: './unlock-encryption-keys.component.html',
    styleUrl: './unlock-encryption-keys.component.scss',
})
export class UnlockEncryptionKeysComponent {

    @Input() encryptionKeys: EncryptionKeys = null;
    passwordInput: string;

    constructor(private encryptionKeysService: EncryptionKeysService) {
    }

    unlock = async () => {
        try {
            let privateKey = await CryptoUtil.extractPrivateKeyFromPassword(this.passwordInput,
                this.encryptionKeys.key, this.encryptionKeys.salt
            )
        } catch (e) {
            console.log(e)
        }
    }
}
