import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {MatTabsModule} from "@angular/material/tabs";
import {SuperModule} from "@qid/super";
import {SexyModule} from "@qid/sexy";
import {EditOrganisationRelationshipPage} from "./edit-organisation-relationship-page.component";
import {EditOrganisationRelationshipPageRoutingModule} from "./edit-organisation-relationship-routing.module";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EditOrganisationRelationshipPageRoutingModule,
    SexyModule,
    MatTabsModule,
    SuperModule,
    SexyModule
  ],
  declarations: [EditOrganisationRelationshipPage],
})
export class EditOrganisationRelationshipPageModule {
}
