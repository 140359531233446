import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CollectionTemplate, CollectionTemplateAgreement, Share, ShareService} from '@qid/common';

@Component({
  selector: 'gateway-policies',
  templateUrl: './gateway-policies.component.html',
  styleUrl: './gateway-policies.component.scss',
})
export class GatewayPoliciesComponent {

  @Input() template: CollectionTemplate;
  @Input() share: Share;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  public agreements: CollectionTemplateAgreement;

  activePolicyIndex = 0

  constructor(public shareService: ShareService) {
  }

  onAgreements = async () => {

    if (!this.template.agreements || this.template.agreements.length < 1) {
      return true;
    }
    if (this.template.agreements[this.activePolicyIndex].mandatory && !this.template.agreements[this.activePolicyIndex].accepted) {
      throw "Please accept required terms & conditions";
    }

    if (this.activePolicyIndex == this.template.agreements.length - 1) {
      return this.updateShare();
    }

    this.activePolicyIndex++

  };

  private async updateShare() {
    this.share.agreements = this.template.agreements;
    const res = await this.shareService.update(this.share._id, {
      agreements: this.share.agreements,
      updateRemark: "Policies Accepted"
    });
    if (!res || res.errorMessage) {
      throw new Error(res?.errorMessage)
    }
    this.onSubmit.emit(this.share);
  }

}
