import { CollectionTemplate } from "../modules/collection-template";

export class ScannerTemplateOptions {
  label: string
  template: CollectionTemplate | string | any

  constructor(label: string = "", template: any = null) {
    this.label = label;
    this.template = template;
  }
}
