import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, from, map, of} from 'rxjs';
import * as UsersActions from './users.actions';
import {UserService} from "../services/user.service";
import { AuthenticationService } from '../../../authentication';
import {refreshAuthenticatedUser} from "./users.actions";


@Injectable()
export class UsersEffects {
  private actions$ = inject(Actions);
  private userService = inject(UserService)
  private authenticationService = inject(AuthenticationService)

  fetchAuthenticatedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.fetchAuthenticatedUser),
      exhaustMap(() =>
        from(this.userService.getUserByToken()).pipe(
          map(user => {
            // this.userService.authenticatedUser.next(user)
            this.authenticationService.setActiveEntityAsUser(user)
            return UsersActions.fetchAuthenticatedUserSuccess({authenticatedUser: user})
          })
        )
      ),
      catchError((error) => {
        return of(UsersActions.fetchAuthenticatedUserSuccess({authenticatedUser: null}));
      })
    )
  );

  refreshAuthenticatedUser= createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.refreshAuthenticatedUser),
      exhaustMap(() =>
        from(this.userService.getUserByToken()).pipe(
          map(user => {
            this.authenticationService.setActiveEntityAsUser(user)
            return UsersActions.refreshAuthenticatedUserSuccess({authenticatedUser: user})
          })
        )
      )
    )
  );


}
