import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxPosition]'})
export class PositionDirective implements OnInit {
    @Input() sxPosition: 'static' | 'fixed' | 'absolute' | 'relative';

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        try {
            this.el.nativeElement.style.position = this.sxPosition;
        } catch (e) {

        }
    }

}
