<page-loader #pageLoaderComponent>

  <div *ngIf="pendingShares" sxPadding="medium" sxCol sxGap="10px">

    <div sxColor="two" sxPadding="medium">
      <h2>Start where you left</h2>
      <p sxMargin="5px 0">
        We've saved your recent incomplete transaction as draft. You can continue with the draft or choose to
        Start
        New</p>
    </div>

    <div *ngFor="let share of pendingShares;index as i" sxPadding="medium" sxRadius="medium"
         sxBackground="light">
      <h2 sxTextSize="medium" sxMargin="10px 5px 0">{{share.template?.purpose}}</h2>
      <p sxMargin="5px 5px 15px">{{share.createTime | date : 'MMM d, y, h:mm a'}}</p>
      <div sxCol sxGap="10px">
        <super-button type="outline" color="primary"
                      iconEnd="chevron-forward"
                      [action]="onDraftShareSelected.bind(this,share)"
        >Continue with this
        </super-button>

        <super-button type="clear" color="danger" [action]="removeDraft.bind(this, share, i)">Remove Draft</super-button>

      </div>
    </div>
  </div>

</page-loader>
