import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ListChildOrgsPageRoutingModule} from './list-child-orgs-routing.module';
import {ListChildOrgsPage} from './list-child-orgs.page';
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ListChildOrgsPageRoutingModule,
    SuperModule,
    SexyModule
  ],
  declarations: [ListChildOrgsPage]
})
export class ListChildOrgsPageModule {
}
