import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {FaceIdApi} from "../../modules/face-id/services/face-id.api";
import {FaceCapture} from '../face-capture/face-capture.component';
import {ImageUtil} from "@qid/core";
import {FaceId} from "../../modules/face-id/model/face-id.model";
import {SID, SidService} from "../../modules/sid";
import {DigilockerComponent} from "../digilocker/digilocker.component";


@Component({
  selector: 'verified-signup',
  templateUrl: './verified-signup.component.html',
  styleUrls: ['./verified-signup.component.scss'],
})
export class VerifiedSignupComponent {

  @ViewChild('faceCapture') faceCapture: FaceCapture
  @ViewChild('digilockerComponent') digilockerComponent : DigilockerComponent
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  public faceId: FaceId = null;
  public isFetchingFaceId = true;
  public sid : SID;
  public idVerificationStatus : 'started' | 'success' | 'failed' = null;

  constructor(private faceIdApi: FaceIdApi,
              private sidService: SidService
  ) {
  }

  async init() {
    this.isFetchingFaceId = true;
    const res = await this.faceIdApi.read();
    this.isFetchingFaceId = false;
    if (res && res.data) {
      this.faceId = res.data;
      this.onFaceIdConfirmed();
    }
  }

  onFaceCaptured(imageData: string) {
    let image: any = {dataUrl: imageData}
    const file = ImageUtil.dataURLtoFile(
      image.dataUrl,
      new Date().getTime() + '.' + image.format
    );

    this.createFaceId(file)
  }

  private async createFaceId(file: File) {
    const res = await this.faceIdApi.create(file)
    if (res && res.data) {
      this.faceId = res.data
    }
    if (this.faceId) {
      this.onFaceIdConfirmed()
    }
  }


  onFaceIdConfirmed() {
   this.goToIdVerification();
  }

  async goToIdVerification(){
    this.idVerificationStatus = 'started'
    await this.getAadhaarSid()
    this.digilockerComponent.init()
  }

  async getAadhaarSid() {
    this.sid = await this.sidService.readOneByKeyName("ADHAR")
  }

}
