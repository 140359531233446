import {Injectable} from '@angular/core';
import {EncryptionKeys} from '../model/encryption-keys.model';
import {CreateEncryptionKeyDto} from '../dto/create-encryption-key.dto';
import {AesRsaEncryptedData, CryptoUtil} from "@qid/crypto";
import {ModalController} from "@ionic/angular";
import {UnlockEncryptionKeysComponent} from "../components/unlock-encryption-keys/unlock-encryption-keys.component";
import {RestService} from "../../../../services/rest.service";

@Injectable({
  providedIn: 'root'
})
export class EncryptionKeysService {

  static readonly ApiEndpoint = "encryption-keys/"

  static userEncryptionKeys: EncryptionKeys = null;
  static userLockingKey: string;

  static organisationEncryptionKeys: EncryptionKeys = null;
  static organisationLockingKey: string;

  static organisationUserEncryptionKeys: EncryptionKeys = null;
  static organisationUserLockingKey: string;

  constructor(private restService: RestService,
              private modalController: ModalController
  ) {
  }

  create(dto: CreateEncryptionKeyDto) {

    return this.restService.send<EncryptionKeys>({
      url: EncryptionKeysService.ApiEndpoint,
      method: "POST",
      body: dto
    })

  }

  public autoGenerate(isSendEmail: boolean = true) {
    return this.restService.send<EncryptionKeys>({
      url: EncryptionKeysService.ApiEndpoint + 'auto-generate/',
      method: "POST",
      query: {
        isSendEmail: isSendEmail
      }
    })
  }

  async read(account_id: string) {
    const response = await this.restService.send<EncryptionKeys>({
      method: "GET",
      url: EncryptionKeysService.ApiEndpoint,
      query: {
        account_id: account_id
      }
    })

    if (!response || response.errorMessage) {
      return null;
    }

    return response.data

  }

  public readAllChildKeys(parentKeysId: string) {
    return this.restService.send({
      url: EncryptionKeysService.ApiEndpoint + 'child-keys',
      method: 'GET',
      query: {
        parentKeysId: parentKeysId
      }
    })
  }

  public setParent(encryptionKeysId: string, parentKeysId: string, sharedParentKey: AesRsaEncryptedData) {
    return this.restService.send({
      url: EncryptionKeysService.ApiEndpoint + 'set-parent/',
      method: 'PUT',
      body: sharedParentKey,
      query: {
        encryptionKeysId: encryptionKeysId,
        parentKeysId: parentKeysId
      }
    })
  }

  async unlockEncryption() {
    if (EncryptionKeysService.userLockingKey) {
      return true;
    }

    const modal = await this.modalController.create({
      component: UnlockEncryptionKeysComponent,
      componentProps: {
        encryptionKeys: EncryptionKeysService.userEncryptionKeys
      }
    })

    await modal.present()

    const res = await modal.onDidDismiss();
    if (res && res.data.password) {
      EncryptionKeysService.userLockingKey = await CryptoUtil.extractLockingKeyFromPassword(res.data.password,
        EncryptionKeysService.userEncryptionKeys.salt
      )
    }
  }
}
