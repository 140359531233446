import {Directive, ElementRef, Input, OnInit} from "@angular/core";

const style = getComputedStyle(document.body)

@Directive({selector: '[sxBackground]'})
export class BackgroundDirective implements OnInit {

  @Input() sxBackground: 'clear' | 'dark' | 'light' | 'success' | 'danger' | 'darkGradient' | 'lightGradient' | 'warning' | "base" | "two" | "one" | string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {

    switch (this.sxBackground) {
      case "primary" :
      case "dark" :
      case "light" :
      case "success" :
      case "danger" :
      case "danger-tint" :
      case "danger-fade" :
      case  "one" :
      case  "two" :
      case  "three" :
      case  "warning" :
      case  "base" :
      case  "base-shade" :
        return this.el.nativeElement.style.backgroundColor = style.getPropertyValue(`--ion-color-${this.sxBackground}`)
      default :
        return this.el.nativeElement.style.backgroundColor = this.sxBackground
    }
  }

}
