<div class="id-image-picker-wrapper">
  <div
    class="add-image-wrapper"
    *ngIf="!image && !isLoadingImage"
    [class.error]="error?.length > 0">
    <h1>Upload {{ title }}</h1>
    <div class="button-wrapper">
      <button (click)="openCamera()">
        <ion-icon name="camera-outline"></ion-icon>
        Camera
      </button>
      <div class="divider"></div>
      <button (click)="openLibrary()">
        <ion-icon name="image-outline"></ion-icon>
        Library
      </button>
    </div>
  </div>
  <div
    class="image-preview-wrapper"
    *ngIf="image && !isLoadingImage"
    [style.backgroundImage]="'url(' + image + ')'">
    <div class="processing-wrapper" *ngIf="isProcessing">
      <ion-spinner color="light"></ion-spinner>
      <p>Verifying</p>
    </div>
    <div class="info-wrapper">
      <p>{{ title }}</p>
    </div>
    <button class="remove-button" (click)="removeImage()">
      <ion-icon name="trash-outline"></ion-icon>
    </button>
  </div>

  <p class="error-message" *ngIf="error">{{ error }}</p>

  <app-upload-image-button
    #uploadImageButtonComponent
    hidden
    (onImageUpload)="onImageLoad($event)">
  </app-upload-image-button>
</div>
