import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxCircle]'})
export class CircleDirective implements OnInit {
  @Input() sxCircle: string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.style.width = this.sxCircle;
    this.el.nativeElement.style.height = this.sxCircle;
    this.el.nativeElement.style.flexShrink = '0'
    this.el.nativeElement.style.borderRadius = '50%'
    this.el.nativeElement.style.overflow = 'hidden'
  }


}
