<div sxCol sxGap='10px' sxRadius="medium" sxBackground="light">

  <ion-list *ngIf="templates$ | async" sxBackground="light">

    <ion-item
      *ngFor="let template of templates$ | async" [detail]="true" button=""
      sxCapitalize="" (click)="onTemplateClicked(template)">
      <ion-label *ngIf="!isMultiSelection">{{template.purpose}}</ion-label>
      <ion-checkbox *ngIf="isMultiSelection" [checked]='isChecked(template)'
                    (ionChange)="onCheckboxClick(template, $event)"
                    justify="space-between"
      >{{ template.purpose }}</ion-checkbox>
    </ion-item>

    <p *ngIf="(sharedTemplates$ | async)?.length > 0" sxColor="two" sxMargin="0" sxTextWeight="bold"
       sxPadding="18px 18px 10px" sxBorderWidth="1px 0 0" sxBorderColor="one">Shared Forms</p>

    <ion-item
      *ngFor="let template of sharedTemplates$ | async" [detail]="true" button=""
      sxCapitalize="" (click)="onTemplateClicked(template)">
      <ion-label *ngIf="!isMultiSelection">{{template.purpose}}</ion-label>
      <ion-checkbox *ngIf="isMultiSelection" [checked]='isChecked(template)'
                    (ionChange)="onCheckboxClick(template, $event)"
                    justify="space-between"
      >{{ template.purpose }}</ion-checkbox>
    </ion-item>
  </ion-list>





</div>


