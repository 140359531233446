import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxTextStyle]'})
export class TextStyleDirective implements OnInit {

  @Input() sxTextStyle: 'italic' | 'normal' | string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    try {
      this.el.nativeElement.style.fontStyle = this.sxTextStyle
    } catch (e) {
      console.error("SX TEXT STYLE PROPERTY", e)
    }
  }


}
