import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScannerApiService} from './services/scanner-api.service';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {SexyModule} from '@qid/sexy';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromScanners from './+state/scanners.reducer';
import {ScannersEffects} from './+state/scanners.effects';
import {ScannersFacade} from './+state/scanners.facade';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    SexyModule,
    StoreModule.forFeature(
      fromScanners.SCANNERS_FEATURE_KEY,
      fromScanners.scannersReducer
    ),
    EffectsModule.forFeature([ScannersEffects])
  ],
  providers: [ScannerApiService, ScannersFacade],
})
export class ScannerModule {
}
