import {OtpChannel} from "../enum/OtpChannel";

export class SendOtpDto {
  channel: OtpChannel;
  receiverId: string;
  countryCode: string

  constructor(channel: OtpChannel, receiverId: string) {
    this.channel = channel;
    this.receiverId = receiverId
  }
}
