import {PlanPricingModel} from "../../plan";

export class CreatePlanPurchaseDto{

  plan : string;
  organisation : string;
  planPricingModel : PlanPricingModel
  planFamilyPricingOption: PlanPricingModel;
  familyMembers: number;
  purchasedByUser: string;
  purchasedByMasterUser: string;

}
