import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({selector: '[sxMaxWidth]'})
export class MaxWidthDirective implements OnInit {
    @Input() sxMaxWidth: string | 'full' | 'fill' | 'fit';

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        this.setWidth();
    }

    setWidth() {
        if (this.sxMaxWidth == 'full') {
            this.el.nativeElement.style.maxWidth = '100%';
            return;
        }

        if (this.sxMaxWidth == 'fill') {
            this.el.nativeElement.style.flex = '1 0 0px';
            //this.el.nativeElement.style.width = "1px"
            return;
        }

        if (this.sxMaxWidth == 'fit') {
            this.el.nativeElement.style.flexShrink = '0';
            this.el.nativeElement.style.maxWidth = 'min-content';
            return;
        }

        this.el.nativeElement.style.maxWidth = this.sxMaxWidth;
    }
}
