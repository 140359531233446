<div class="vertical-step-wrapper" sxRow sxDistribution="spaceBetween" sxAlign="center">
  <div class="counter-wrapper" sxCenterize="col">
    <div class="line"  [class.noLine]="!isTopLine"></div>
    <div class="count" sxCenterize="col" [class.success]="success">{{count}}</div>
    <div class="line" [class.noLine]="!isBottomLine"></div>
  </div>
  <div class="step-content-wrapper">
    <ng-content></ng-content>
  </div>
</div>
