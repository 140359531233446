import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxTextAlign]'})
export class TextAlignDirective implements OnInit {

  @Input() sxTextAlign: 'center' | 'left' | 'right' | 'justify';

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    try {
      this.el.nativeElement.style.textAlign = this.sxTextAlign
    } catch (e) {
      console.error("SX TEXT ALIGN PROPERTY", e)
    }
  }


}
