import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ViewSidComponent} from './view-sid/view-sid.component';
import {SexyModule} from '@qid/sexy';
import {SuperModule} from '@qid/super';
import {SidSelectorComponent} from './sid-selector/sid-selector.component';

@NgModule({
  declarations: [ViewSidComponent, SidSelectorComponent],
  imports: [CommonModule, FormsModule, IonicModule, SexyModule, SuperModule],
  exports: [ViewSidComponent, SidSelectorComponent],
})
export class SidComponentsModule {}
