import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {SexyModule} from "@qid/sexy";
import {FormsModule} from "@angular/forms";
import {AgreementViewComponent} from './agreement-view/agreement-view.component';
import {ShareTemplateComponent} from "./share-template/share-template.component";
import {TemplateFieldSetterComponent} from "./template-field-setter/template-field-setter.component";
import {TemplateIdSetterComponent} from "./template-id-setter/template-id-setter.component";
import {TemplateAgreementSetterComponent} from "./template-agreement-setter/template-agreement-setter.component";
import {FieldComponentsModule} from "@qid/field";
import {SuperModule} from '@qid/super';
import {SidComponentsModule} from "../../sid";


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    SexyModule,
    FormsModule,
    FieldComponentsModule,
    SuperModule,
    SidComponentsModule
  ],
  declarations: [
    AgreementViewComponent,
    ShareTemplateComponent,
    TemplateFieldSetterComponent,
    TemplateIdSetterComponent,
    TemplateAgreementSetterComponent
  ],
  exports: [
    AgreementViewComponent,
    ShareTemplateComponent,
    TemplateFieldSetterComponent,
    TemplateIdSetterComponent,
    TemplateAgreementSetterComponent
  ],


})
export class CollectionTemplateComponentModule {
}
