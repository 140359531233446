import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EditAccountProfilePageRoutingModule } from './edit-account-profile-routing.module';

import { EditAccountProfilePage } from './edit-account-profile.page';
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";
import {AccountAttributeComponentsModule} from "../../../account-attribute";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        EditAccountProfilePageRoutingModule,
        SexyModule,
        SuperModule,
        AccountAttributeComponentsModule
    ],
  declarations: [EditAccountProfilePage]
})
export class EditAccountProfilePageModule {}
