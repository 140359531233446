import {createFeatureSelector, createSelector} from '@ngrx/store';
import {accountAdapter, recordAdapter, SHARES_FEATURE_KEY, SharesState,} from './shares.reducer';
import { AuthSelectors } from '../../authentication';


// Lookup the 'Shares' feature state managed by NgRx
export const selectSharesState =
  createFeatureSelector<SharesState>(SHARES_FEATURE_KEY);

const {selectAll, selectEntities} = accountAdapter.getSelectors();

export const selectAllShares = createSelector(
  selectSharesState,
  (state: SharesState) => selectAll(state)
);

export const selectSharesEntities = createSelector(
  selectSharesState,
  (state: SharesState) => selectEntities(state)
);

export const selectActiveAccount = createSelector(
  selectSharesEntities,
  AuthSelectors.selectActiveAccountId,
  (entities, activeAccountId) => (activeAccountId ? entities[activeAccountId] : undefined)
);

//Shares

const shareSelectors = recordAdapter.getSelectors();

export const selectActiveAccountShares = createSelector(
  selectActiveAccount,
  (selectedAccount) => {
    return (selectedAccount ? shareSelectors.selectAll(selectedAccount.records) : undefined)
  }
);

export const selectActiveAccountPagination = createSelector(
  selectActiveAccount,
  (selectedAccount) => {
    return (selectedAccount ? selectedAccount.pagination : undefined)
  }
);

export const selectActiveAccountIsLoading = createSelector(
  selectActiveAccount,
  (selectedAccount) => {
    return (selectedAccount ? selectedAccount.isLoading : undefined)
  }
);
