import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DisplayService} from "@qid/core";

@Component({
  selector: "super-row",
  templateUrl: "./super-row.component.html",
  styleUrls: ["./super-row.component.scss"]
})
export class SuperRowComponent {

}


