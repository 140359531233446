import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CreatePlanPurchaseDto } from '../../dto/create-plan-purchase.dto';
import { PlanPurchaseMasterService } from '../../services/plan-purchase-master.service';
import { Plan, PlanPricingModel, PlanService } from '../../../plan';
import { Organisation, OrganisationMasterService } from '../../../organisation';

@Component({
  selector: 'app-create-plan-purchase',
  templateUrl: './create-plan-purchase.page.html',
  styleUrls: ['./create-plan-purchase.page.scss']
})
export class CreatePlanPurchasePage implements OnInit {

  public plans: Plan[] = [];
  public selectedPlan: Plan;

  @Input() organisations: Organisation[] = [];
  @Input() selectedOrganisation: Organisation;
  public createPlanPurchaseDto: CreatePlanPurchaseDto = new CreatePlanPurchaseDto();

  public selectedPlanPurchaseModel: PlanPricingModel = null;

  constructor(private modalController: ModalController,
              private planService: PlanService,
              private organisationMasterService: OrganisationMasterService,
              private planPurchaseMasterService: PlanPurchaseMasterService
  ) {
  }

  ngOnInit() {
    this.fetchPlans();
    this.fetchOrganisations();
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async fetchPlans() {
    const plans = await this.planService.readAll();
    if (plans) {
      this.plans = plans;
    }
  }

  async fetchOrganisations() {
    const organisationPaginatedResponse = await this.organisationMasterService.readAll(1);
    this.organisations = organisationPaginatedResponse.docs;
  }


  createPlan = async () => {

    this.createPlanPurchaseDto.plan = this.selectedPlan._id;
    this.createPlanPurchaseDto.organisation = this.selectedOrganisation._id;
    if (!this.selectedPlanPurchaseModel) {
      return;
    }
    this.createPlanPurchaseDto.planPricingModel = this.selectedPlanPurchaseModel;
    const res = await this.planPurchaseMasterService.create(this.createPlanPurchaseDto);
    if (res) {
      await this.modalController.dismiss({
        created: true
      });
    }
  };

  async setPricingModel(pricingModel: PlanPricingModel) {
    this.selectedPlanPurchaseModel = pricingModel;
    await this.createPlan();
  }
}
