import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {exhaustMap, from, map, of} from 'rxjs';
import {OrgRelationshipStore} from "./organisation-relationship.facade";
import {OrganisationRelationshipService} from './organisation-relationship.service';


@Injectable()
export class OrganisationRelationshipEffects {
  private actions$ = inject(Actions);
  private apiService = inject(OrganisationRelationshipService)
  private ACTIONS = OrgRelationshipStore.actions;

  addAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.ACTIONS.addAccount),
      exhaustMap((props) =>
        of(this.ACTIONS.loadRecordsForAccount({
          account_id: props.accountBasedEntity.account_id,
          accountType: props.accountBasedEntity.accountType
        }))
      )
    )
  );

  loadRecordsForAccount = createEffect(() =>
    this.actions$.pipe(
      ofType(this.ACTIONS.loadRecordsForAccount),
      exhaustMap((props) =>
        from(this.apiService.getChildren(props.account_id)).pipe(
          map(apiResponse => {
            const orgRelationships = apiResponse && apiResponse.data ? apiResponse.data : []
            return this.ACTIONS.loadRecordsForAccountSuccess({
              records: orgRelationships,
              account_id: props.account_id
            })
          })
        )
      )
    )
  );


}
