const url = 'https://staging.qidpro.xyz';
import { AppName } from "@qid/common";


export const environment = {
  appName: AppName.WebGateway,
  production: false,
  url: url + '/',
  imageBaseUrl: url + '/file/images/',
  collectUrl: 'https://devcollect.oneqid.com/rt/',
  waPhone : "15550876181",
  firebaseConfig: {
    apiKey: 'AIzaSyBxuBZsdWXju2GjYLmZ8JuzVXV1XdAKYVM',
    authDomain: 'qid-firebase.firebaseapp.com',
    projectId: 'qid-firebase',
    storageBucket: 'qid-firebase.appspot.com',
    messagingSenderId: '864506605593',
    appId: '1:864506605593:web:c2d3a3f0fccda1d631fa88',
    measurementId: 'G-B79M8E2EDT',
  },
};
