import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {exhaustMap, from, map, of} from 'rxjs';
import {AccountProfileApi} from "../services/account-profile.api";
import {AccountProfileStore} from "./account-profile.facade";
import {AccountProfile} from "../model/account-profile.model";

@Injectable()
export class AccountProfileEffects {
    private actions$ = inject(Actions);
    private apiService = inject(AccountProfileApi)
    private ACTIONS = AccountProfileStore.actions;

    addAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(this.ACTIONS.addAccount),
            exhaustMap((props) =>
                of(this.ACTIONS.loadRecordsForAccount({
                    account_id: props.accountBasedEntity.account_id,
                    accountType: props.accountBasedEntity.accountType
                }))
            )
        )
    );

    loadRecordsForAccount = createEffect(() =>
        this.actions$.pipe(
            ofType(this.ACTIONS.loadRecordsForAccount),
            exhaustMap((props) =>
                from(this.apiService.get()).pipe(
                    map(apiResponse => {
                        const profile: AccountProfile = apiResponse && apiResponse.data ? apiResponse.data : null
                        return this.ACTIONS.loadAdditionalDataAccountSuccess({
                            data: {
                                isProfileFetched : true,
                                profile: profile},
                            account_id: props.account_id
                        })
                    })
                )
            )
        )
    );


}
