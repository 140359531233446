import {NgModule} from '@angular/core';
import {UserComponentsModule} from './components/user-components.module';
import {OtpService} from './services/otp.service';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromUsers from './+state/users.reducer';
import {UsersEffects} from './+state/users.effects';
import {UsersFacade} from './+state/users.facade';
import {UserMasterApiService} from "./services/user-master-api.service";

@NgModule({
    imports: [
        UserComponentsModule,
        StoreModule.forFeature(fromUsers.USERS_FEATURE_KEY, fromUsers.usersReducer),
        EffectsModule.forFeature([UsersEffects]),
    ],
    providers: [OtpService, UsersFacade, UserMasterApiService],
})
export class UserModule {
}
