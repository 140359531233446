import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  accountAdapter,
  ORGANISATION_USER_FEATURE_KEY,
  OrganisationUserState,
  recordAdapter,
} from './organisation-user.reducer';
import {AuthSelectors} from "../../authentication";

// Lookup the 'OrganisationUser' feature state managed by NgRx
export const selectOrganisationUserState =
  createFeatureSelector<OrganisationUserState>(ORGANISATION_USER_FEATURE_KEY);

const {selectAll, selectEntities} = accountAdapter.getSelectors();

export const selectActiveOrganisationUser = createSelector(
  selectOrganisationUserState,
  (state: OrganisationUserState) => state.activeOrganisationUser
);

export const selectActiveOrganisationUserRole = createSelector(
  selectOrganisationUserState,
  (state: OrganisationUserState) => state.activeOrganisationUser?.role
);

export const selectActiveOrganisationUserNotificationPreferences = createSelector(
  selectOrganisationUserState,
  (state: OrganisationUserState) => state.activeOrganisationUser?.notificationPreferences
);

export const selectAllOrganisationUser = createSelector(
  selectOrganisationUserState,
  (state: OrganisationUserState) => selectAll(state)
);

export const selectOrganisationUserEntities = createSelector(
  selectOrganisationUserState,
  (state: OrganisationUserState) => selectEntities(state)
);

export const selectSelectedId = createSelector(
  selectOrganisationUserState,
  (state: OrganisationUserState) => state.selectedId
);

export const selectEntity = createSelector(
  selectOrganisationUserEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const selectActiveAccount = createSelector(
  selectOrganisationUserEntities,
  AuthSelectors.selectActiveAccountId,
  (entities, activeAccountId) => (activeAccountId ? entities[activeAccountId] : undefined)
);


export const selectUserOrganisations = createSelector(
  selectOrganisationUserState,
  (state: OrganisationUserState) => state.authenticatedUserOrganisations?.map(orgUser => orgUser.organisation)
);

export const selectUserOrganisationsLoading = createSelector(
  selectOrganisationUserState,
  (state: OrganisationUserState) => state.fetchingUserOrganisations
);


const orgUserSelector = recordAdapter.getSelectors();

export const selectActiveAccountOrgUsers = createSelector(
  selectActiveAccount,
  (selectedAccount) => {
    return (selectedAccount ? orgUserSelector.selectAll(selectedAccount.records) : undefined)
  }
);
