<div
  sxBackground="light"
  sxBorderWidth="0 0 1px"
  sxCursor="pointer"
  sxBorderColor="one"
  sxPadding="small medium" *ngIf="!headerOnly && viewSize == 'large' && !skeletonOnly">
  <div sxRow sxAlign="center" sxDistribution="spaceBetween" sxGap="10px" (click)="openViewSharePage()">
    <avatar-round
      [lock]="share.isQuotaLocked"
      sxWidth="35px"
      [size]="30"
      [imageUrl]="accountToDisplay?.displayPictureUrl"
      [title]="accountToDisplay?.name"></avatar-round>
    <div sxCol sxAlign="start" sxDistribution="start" sxWidth="25%">
      <p sxMargin="0" sxGap="5px" sxTextSize="16px">
        {{ accountToDisplay?.name }}
        <span *ngIf="getParticipantsCount() > 1">+ {{ sharerCount - 1 }}</span>
      </p>
      <p sxTextSize="12px" sxColor="two" sxMargin="0" sxTextEllipse>
        {{ share?.createTime | date : 'medium' }}
      </p>
    </div>

    <div sxWidth="15%" sxRow [sxColor]="getShareStatusColor()" sxGap="2px" sxAlign="center" sxDistribution="start">
      <ion-icon [name]="getShareStatusIcon()" sxTextSize="14px"></ion-icon>
      <p sxMargin="0" sxTextSize="12px">{{ getShareStatus() }}</p>
    </div>

    <p sxWidth="fill" sxTextSize="12px" sxColor="two" sxMargin="0" sxTextEllipse>
      {{ share?.template?.purpose }}
    </p>


    <div sxHeight="fit" sxWidth="190px" *ngIf="isShowCFormButton(share?.template?.purpose)">
      <super-button
        type="outline"
        padding="10px"
        height="fit"
        sxWidth="190px"
        textSize="12px"
        (click)="copyTransactionId($event)"
      >Copy C-Form code
      </super-button>
    </div>
  </div>
</div>

<div
  sxBackground="light"
  sxBorderWidth="0 0 1px"
  sxCursor="pointer"
  sxBorderColor="one"
  sxRow
  sxGap="10px"
  sxAlign="center"
  sxPadding="medium" *ngIf="viewSize == 'small'" (click)="openViewSharePage()">

  <avatar-round [size]='50'
                [lock]="share.isQuotaLocked && ((activeAccountType$ | async) == 'organisation')"
                [imageUrl]='accountToDisplay?.displayPictureUrl' [title]='accountToDisplay?.name'></avatar-round>


  <div sxCol sxMaxWidth="fill" sxAlign="start" sxGap="5px" sxOverflow="hidden">
    <h1 sxTextWeight="normal" sxMargin="0" sxTextSize="16px" sxTextEllipse>{{accountToDisplay?.name}}</h1>
    <div sxRow sxAlign="center" sxGap="5px" sxWidth="full">
      <div *ngIf="share.status == ShareStatus.open" sxColor="warning" sxRow sxAlign="center" sxGap="5px">
        <ion-icon name="alert-circle"></ion-icon>
        Pending
      </div>
      <div *ngIf="share.status == ShareStatus.cancelled" sxColor="danger" sxRow sxAlign="center" sxGap="5px">
        <ion-icon name="close-circle"></ion-icon>
        Cancelled
      </div>
      <p sxMaxWidth="fill" sxTextEllipse sxMargin="0" sxColor="two" sxTextSize="small"
         class='summary'>{{getShareSummary()}}</p>
    </div>
  </div>


  <p sxTextSize="12px" sxColor="two">{{share?.createTime | date : 'MMM d, h:mm a'}}</p>


</div>


<div sxBackground="light"
     sxBorderWidth="0 0 1px"
     sxCursor="pointer"
     sxBorderColor="one"
     sxCol
     sxAlign="stretch"
     *ngIf="viewSize == 'self-collect'">

  <div sxPadding="medium" sxRow sxAlign="center" sxDistribution="spaceBetween" sxGap="10px">
    <avatar-round [size]='50'
                  [lock]="share.isQuotaLocked && ((activeAccountType$ | async) == 'organisation')"
                  [imageUrl]='accountToDisplay?.displayPictureUrl' [title]='accountToDisplay?.name'></avatar-round>


    <div sxCol sxMaxWidth="fill" sxAlign="start" sxGap="5px" sxOverflow="hidden">
      <h1 sxTextWeight="normal" sxMargin="0" sxTextSize="16px" sxTextEllipse>{{accountToDisplay?.name}}</h1>
      <p sxMargin="0" sxTextSize="small" sxColor="two">{{share?.template?.purpose}}</p>
    </div>
    <p sxTextSize="12px" sxMargin="0 5px" sxColor="two">{{share?.createTime | date : 'MMM d, h:mm a'}}</p>

  </div>

  <div sxRow sxAlign="stretch" sxDistribution="spaceBetween" sxBorderColor="one" sxBorderWidth="1px 0 0">
    <div sxPadding="small medium" sxCenterize>
      <div sxTextSize="small" *ngIf="share.status == ShareStatus.open" sxColor="warning" sxRow sxAlign="center"
           sxGap="5px">
        <ion-icon sxTextSize="medium" name="alert-circle"></ion-icon>
        Pending
      </div>
      <div sxTextSize="small" *ngIf="share.status == ShareStatus.completed" sxColor="success" sxRow sxAlign="center"
           sxGap="5px">
        <ion-icon sxTextSize="medium" name="checkmark-circle"></ion-icon>
        Completed
      </div>
      <div sxTextSize="small" *ngIf="share.status == ShareStatus.cancelled" sxColor="danger" sxRow sxAlign="center"
           sxGap="5px">
        <ion-icon sxTextSize="medium" name="remove-circle"></ion-icon>
        Cancelled
      </div>
    </div>

    <div sxRow sxGap="5px">
      <super-button (click)="continueClicked.emit()" *ngIf="share.status == ShareStatus.open" size="v-small"
                    sxBorderColor="one" sxBorderWidth="0 1px" color="warning" iconEnd="sync-outline"
                    background="light">Continue
      </super-button>
      <super-button (click)="openViewSharePage()" size="v-small" background="light" iconEnd="chevron-forward-outline">
        View
      </super-button>
    </div>

  </div>


</div>

<div *ngIf="skeletonOnly" sxWidth="full" sxRow sxAlign="center" sxGap="10px" sxPadding="medium"
     sxBorderColor="one" sxBorderWidth="0 0 1px 0"
>
  <ion-skeleton-text sxCircle="50px" [animated]="true"></ion-skeleton-text>
  <div sxCol>
    <ion-skeleton-text sxWidth="100px" sxHeight="16px" [animated]="true"></ion-skeleton-text>
    <ion-skeleton-text sxWidth="50px" sxHeight="12px" [animated]="true"></ion-skeleton-text>
  </div>

</div>

