import {Injectable} from '@angular/core';
import {String} from 'typescript-string-operations';
import {Scan} from '../model/Scan';
import {RestService} from "../../../services/rest.service";
import {Scanner} from "../../scanner";


@Injectable({
  providedIn: 'root',
})
export class ScanService {
  private static readonly NodeScan = 'scan/';
  private static readonly NodeScanQR = ScanService.NodeScan + 'qr/{0}';
  static readonly nodeAnalytics: string =
  ScanService.NodeScan + 'analytics?startDate={0}&endDate={1}';
  public static Scan : Scan = null;
  constructor(private restService: RestService) {
  }

  public async scan(id: string): Promise<{
    scanner: Scanner;
    scan: any; template: any }> {
    return (await this.restService.send<any>({
      url: String.format(ScanService.NodeScanQR, id),
      method: "GET"
    })).data;
  }

  public getAnalytics(
    // orgId: string = null,
    startDate: number = null,
    endDate: number = new Date().getTime()
  ) {
    return this.restService.send<number>({
      url: String.format(ScanService.nodeAnalytics, startDate, endDate),
      method: 'GET',
    });
  }
}
