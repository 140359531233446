import {Injectable} from "@angular/core";
import {CreateOrganisationUserDto} from "../dto/create-organisation-user.dto";
import {OrganisationUser} from "../models/organisation-user.model";
import {Store} from "@ngrx/store";
import {firstValueFrom} from "rxjs";
import {AccountType, NotificationPreference, OrganisationUserRoles} from "@qid/core";
import { RestService } from "../../../services/rest.service";
import {AuthSelectors} from "../../authentication";

@Injectable()
export class OrganisationUserService {

  static readonly ApiUrl = 'organisation-user/'

  public static activeUser: OrganisationUser = null;

  constructor(private restService: RestService,
              private store: Store
  ) {
  }

  async create(dto: CreateOrganisationUserDto) {

    const activeOrg = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccount))

    return this.restService.send<OrganisationUser>({
        url: OrganisationUserService.ApiUrl,
        method: 'POST',
        body: dto,
        account_id: activeOrg._id
      }
    )

  }

  async read(orgUserId: string) {
    const activeOrg = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccount))
    return this.restService.send<OrganisationUser>({
        url: OrganisationUserService.ApiUrl + orgUserId,
        method: 'GET',
        account_id: activeOrg._id
      }
    )
  }

  async readAll() {
    const activeOrg = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccount))
    if (!activeOrg) {
      return;
    }
    return this.restService.send<OrganisationUser[]>({
        url: OrganisationUserService.ApiUrl,
        method: 'GET',
        account_id: activeOrg._id
      }
    )
  }

  async readAllByUser() {
    return this.restService.send<OrganisationUser[]>({
        url: OrganisationUserService.ApiUrl + 'user-organisations',
        method: 'GET'
      }
    )
  }


  async updatePermission(permission: string, isAdd: any, orgUser_id: string) {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId))
    return this.restService.send<OrganisationUser>(
      {
        url: OrganisationUserService.ApiUrl + 'permission',
        method: 'PUT',
        query: {
          permission: permission,
          orgUser_id: orgUser_id,
          isAdd: isAdd
        },
        account_id : account_id,
        accountType : AccountType.organisation
      }
    )
  }

  async updateRole(orgUser_id : string, role : OrganisationUserRoles) {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId))
    return this.restService.send<OrganisationUser>(
      {
        url: OrganisationUserService.ApiUrl + 'role',
        method: 'PUT',
        query: {
          role: role,
          orgUser_id: orgUser_id,
        },
        account_id : account_id,
        accountType : AccountType.organisation
      }
    )
  }



  async updateNotificationPreferences(notificationPreferences : NotificationPreference[]) {
    return this.restService.send<OrganisationUser>(
      {
        url: OrganisationUserService.ApiUrl + 'notification-preferences',
        method: 'PUT',
        body : notificationPreferences
      }
    )
  }

  async delete(orgUser_id: string, org_id: string) {
    return this.restService.send<OrganisationUser>(
      {
        url: OrganisationUserService.ApiUrl + orgUser_id,
        method: 'DELETE',
        account_id: org_id
      }
    )
  }
}
