import {Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {CameraSource} from '@capacitor/camera';
import {UploadImageButtonComponent} from "@qid/super";

@Component({
  selector: 'id-image-picker',
  templateUrl: './id-image-picker.component.html',
  styleUrls: ['./id-image-picker.component.scss'],
})
export class IdImagePickerComponent implements OnInit {
  @ViewChild('uploadImageButtonComponent')
  uploadImageButtonComponent: UploadImageButtonComponent;

  @Input() title: string = 'Id';
  @Input() error: string = null;
  @Input() value: string = '';
  @Input() isProcessing: boolean = false;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  public image = '';
  isLoadingImage: boolean = false;

  constructor() {
  }

  ngOnInit() {
    if (this.value && this.value[0] != null) {
      this.onImageLoad(this.value[0]);
    }
  }

  async openLibrary() {
    await this.uploadImageButtonComponent.upload(CameraSource.Photos, this.title);
  }

  async openCamera() {
    await this.uploadImageButtonComponent.upload(CameraSource.Camera, this.title);
  }

  removeImage() {
    this.value = null;
    this.image = null;
    this.valueChange.emit(this.value);
  }

  onImageLoad(file: any) {
    if (!file) {
      return;
    }
    if (!this.value) {
      this.value = "";
    }

    this.value = file;
    this.image = file.url;

    this.error = null;
    this.valueChange.emit(this.value);
  }


}
