import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CreatePlanPurchasePage } from './create-plan-purchase.page';

const routes: Routes = [
  {
    path: '',
    component: CreatePlanPurchasePage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreatePlanPurchasePageRoutingModule {}
