import {Pipe, PipeTransform} from "@angular/core";
import {TextUtil} from "@qid/core";

@Pipe({
  standalone: true,
  name: 'formatPhone',
})
export class FormatPhonePipe implements PipeTransform {
  transform(value: string): string {
    return TextUtil.getFormattedPhoneNumber(value)
  }
}
