import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList
} from "@angular/core";
import {DisplayService} from "@qid/core";
import {SuperRowComponent} from "../super-row/super-row.component";

@Component({
  selector: "super-table",
  templateUrl: "./super-table.component.html",
  styleUrls: ["./super-table.component.scss"]
})
export class SuperTableComponent implements AfterContentInit{

  @ContentChildren(SuperRowComponent) superRowComponents: QueryList<SuperRowComponent>;

  ngAfterContentInit() {
    this.superRowComponents.forEach(col => {
    });
  }


}


