export class CollectionTemplateAgreement {
  title: string;
  description: string;
  url: string;
  urlButtonText: string = "Read the complete policy"
  mandatory: boolean = true;
  checkboxTitle = "I Agree"
  accepted: boolean;
  bullets: AgreementBullet[] = []
  icons: AgreementIcon[] = []

  constructor() {
  }
}

export class AgreementBullet {
  title: string;
  text: string;
}

export class AgreementIcon {
  iconName?: string;
  imgName: string;
}
