import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {AbsoluteValue} from "../util/AbsoluteValue";

@Directive({selector: '[sxLineHeight]'})
export class LineHeightDirective implements OnInit {
  @Input() sxLineHeight: string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    try {
      this.el.nativeElement.style.lineHeight = this.sxLineHeight
    } catch (e) {
      console.error("SX LINE HEIGHT", e)
    }
  }


}
