import {Directive, ElementRef, Inject, Input, OnInit} from "@angular/core";
import {Environment, ENVIRONMENT} from "@qid/env";

@Directive({selector: '[sxBackgroundImage]'})
export class BackgroundImageDirective implements OnInit {

    @Input() sxBackgroundImage: string;
    @Input() sxBackgroundImageAutoUrl: boolean = true;

    public baseUrl = this.environment.imageBaseUrl

    constructor(private el: ElementRef,
                @Inject(ENVIRONMENT) private environment: Environment
    ) {
    }

    ngOnInit() {

        if (!this.sxBackgroundImage) {
            return
        }

        if (this.sxBackgroundImageAutoUrl && this.sxBackgroundImage.indexOf('/') == -1) {
            this.sxBackgroundImage = this.baseUrl + this.sxBackgroundImage;
        }

        this.el.nativeElement.style.backgroundSize = ""
        return this.el.nativeElement.style.backgroundImage = `url(${this.sxBackgroundImage})`
    }

}
