<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-back-button slot='start'></ion-back-button>
        <ion-title>Branches</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>

    <div sxRow sxDistribution="end" sxAlign="center" sxGap="10px" sxPadding="25px medium 10px" sxBorderWidth="0 0 1px 0"
         sxBorderColor="one">
        <p sxWidth="fill" sxColor='two' sxPadding="0 10px 0 0" sxTextSize='small'>If your business has multiple
            locations or franchises,
            easily manage them all under your main business account</p>
        <super-button id="click-trigger" radius="small" borderColor="one" size="small" type="light"
                      [action]='openOrgSelector'>Add existing
            Business Account
        </super-button>
        <super-button radius="small" borderColor="one" size="small" type="light" [action]='onCreateOrgClicked'>Create new
            Business Account
        </super-button>
    </div>

    <div sxPadding='medium' sxCol sxGap="10px" *ngIf="parentOrganisation$ | async as orgRelation">
        <p sxColor="two" sxMargin="10px 10px 0px">Parent branch</p>
        <div sxPadding='medium' sxRadius='medium' sxBackground='light'
             sxRow sxDistribution='spaceBetween' sxAlign='center' sxGap='10px'>
            <div sxGap="10px" sxCenterize="row">
                <avatar-round [size]='50' [imageUrl]='orgRelation?.parentOrg?.displayPictureUrl'></avatar-round>
                {{orgRelation?.parentOrg?.name}}
            </div>
        </div>
    </div>

    <div sxPadding='medium' sxCol sxGap="10px" *ngIf="organisationRelationships$ | async as relations">
        <p *ngIf="relations.length > 0" sxColor="two" sxMargin="10px 10px 5px">Branches Under {{(activeAccount$ | async)?.name}}</p>
        <div *ngFor='let orgRelation of relations' sxPadding='medium' sxRadius='medium'
             sxBackground='light'
             sxCursor="pointer"
             sxRow sxDistribution='start' sxAlign='center' sxGap='10px' (click)="openOrgRelationEdit(orgRelation)">
            <avatar-round [size]='50' [title]="orgRelation?.childOrg?.name"
                          [imageUrl]='orgRelation?.childOrg?.displayPictureUrl'></avatar-round>
            {{orgRelation?.childOrg?.name}}
        </div>
    </div>

    <ion-popover #addOrgPopover trigger="click-trigger" triggerAction="click" showBackdrop="false"
                 dismissOnSelect="true">
        <ng-template>
            <ion-list *ngIf="organisationsThatCanBeAdded">
                <ion-item *ngFor="let org of organisationsThatCanBeAdded; index as i"
                          (click)="setParentOrganisation(org._id,i)">
                    <avatar-round [imageUrl]="org.displayPictureUrl" [size]="30" sxWidth="30px"
                                  slot="start"></avatar-round>
                    <ion-label>{{org.name}}</ion-label>
                </ion-item>
            </ion-list>
        </ng-template>
    </ion-popover>



</ion-content>
