import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {User} from "firebase/auth";
import {ID} from "../../../../models/ID";
import {CreateIdPage} from "../../pages/create-id/create-id.page";
import {ViewIdPage} from "../../pages/view-id/view-id.page";
import {DocumentAccess, DocumentAccessService} from "../../../document-access";
import {SidService} from "../../../sid";
import {Field} from "@qid/field";
import {IdField} from "../../interface/IdField";
import {ImageUtil} from "@qid/core";
import {ViewDocumentOptionsPage} from "../../../../pages/view-document-options/view-document-options.page";
import {DeviceUtil} from "../../../../utils/device.util";


@Component({
  selector: "id-uploader",
  templateUrl: "./id-upload.component.html",
  styleUrls: ["./id-upload.component.scss"]
})
export class IdUploadComponent implements OnInit {

  @Input() field: Field<IdField>
  @Input() account: User | any = null;
  @Output() onIdUpload: EventEmitter<any> = new EventEmitter();

  public uploaded: boolean = false;

  public selectedDocumentAccess: DocumentAccess = null;
  public allDocumentAccess: DocumentAccess[] = [];

  public isLoading: boolean = true;

  constructor(private modalController: ModalController,
              private documentAccessService: DocumentAccessService,
              private sidService: SidService
  ) {
  }

  ngOnInit() {
    this.fetchSid()
  }

  async fetchSid() {
    if (!this.field.data?.sidId) {
      return
    }
    this.field.data.sid = await this.sidService.readOne(this.field.data.sidId)
  }

  ngOnChanges() {
    this.matchId();
  }

  async matchId() {

    this.isLoading = true;

    const res = await this.documentAccessService.findAll(this.field.data.sidId, this.account?._id)

    if (res && res.data && res.data.length > 0) {
      this.allDocumentAccess = res.data;

      const verifiedDocumentAccess = this.allDocumentAccess.find(access => access.document.verificationTime != null)

      verifiedDocumentAccess ? this.selectDocumentAccess(verifiedDocumentAccess) :
        this.selectDocumentAccess(this.allDocumentAccess.at(-1))
    }

    this.isLoading = false;

  }

  async createId() {
    const modal = await this.modalController.create({
      component: CreateIdPage,
      componentProps: {
        sidId: this.field.data.sidId,
        account: this.account
      },
      animated: DeviceUtil.isPowerful()
    });

    await modal.present();
    const modalRes = await modal.onDidDismiss();
    if (modalRes && modalRes.data.id) {

      let docAccess = null
      let attempts = 0;
      while (attempts < 10) {
        const res = await this.documentAccessService.findByDocumentId(modalRes.data.id._id);
        if (res && res.data?.length > 0) {
          docAccess = res.data[0];
          break;
        }
        attempts++;
      }
      this.selectDocumentAccess(docAccess);
    }
  }

  async checkAfterDocumentCreate() {
    let attempts = 0;
    const previousLength = this.allDocumentAccess?.length || 0
    while (attempts < 10) {
      await this.matchId()
      if (this.allDocumentAccess?.length > previousLength) {
        break
      }
      attempts++;
    }
    return true
  }


  async viewId() {
    if (!this.selectedDocumentAccess) {
      return;
    }
    const modal = await this.modalController.create({
      component: ViewIdPage,
      componentProps: {
        id: this.selectedDocumentAccess.document,
        documentData: this.selectedDocumentAccess.documentData,
        showEditOptions: false
      },
      animated: false
    });
    await modal.present();
  }

  async openViewIdPage(id: ID) {

    if (!id) {
      return
    }

    const modal = await this.modalController.create({
      component: ViewIdPage,
      componentProps: {
        id: id,
      },
      animated: false,
    });
    modal.present();
  }

  selectDocumentAccess(documentAccess: DocumentAccess) {
    this.selectedDocumentAccess = documentAccess;
    this.field.data.documentAccess = documentAccess;
    this.field.errorMessage = null;
    this.onIdUpload.emit();
  }

  protected readonly ImageUtil = ImageUtil;

  async openViewDocumentOptionsPage() {

    const modal = await this.modalController.create({
      component: ViewDocumentOptionsPage,
      componentProps: {
        sid: this.field.data.sid,
        documentAccesses: this.allDocumentAccess,
        selectedAccess: this.selectedDocumentAccess,
        onCreateDocument: () => {
          this.createId()
        }
      },
      initialBreakpoint: 0.7,
      breakpoints: [0, 0.7, 1],
      animated: DeviceUtil.isPowerful()
    })
    await modal.present();
    const modalResponse = await modal.onDidDismiss();

    if (modalResponse.data?.selectedAccess) {
      this.isLoading = true;
      setTimeout(() => {
        this.selectedDocumentAccess = modalResponse.data.selectedAccess;
        this.isLoading = false;
      }, 500)

    }

  }

}
