import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {AbsoluteValue} from "../util/AbsoluteValue";
const style = getComputedStyle(document.body)

@Directive({selector: '[sxNote]'})
export class NoteDirective implements OnInit {

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.style.fontSize = "12px"
    this.el.nativeElement.style.color = style.getPropertyValue(`--ion-color-two`)
    this.el.nativeElement.style.margin = "0 5px 10px"
  }


}
