import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxMaxHeight]'})
export class MaxHeightDirective implements OnInit {

    @Input() sxMaxHeight: string | 'full' | 'fit' | "fill";

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        this.setHieght()
    }

    setHieght() {
        switch (this.sxMaxHeight) {
            case "full" :
                return this.el.nativeElement.style.height = "100%";
            case "fill" :
                return this.el.nativeElement.style.flex = "1 0 0px"
            default :
                return this.el.nativeElement.style.height = this.sxMaxHeight;
        }

    }
}
