import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CollectionTemplate} from "@qid/common";

@Component({
  selector: 'gateway-authentication',
  templateUrl: './gateway-authentication.component.html',
  styleUrl: './gateway-authentication.component.scss',
})
export class GatewayAuthenticationComponent {

  @Input() template : CollectionTemplate;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>()

  onLogin(user) {
    this.onSubmit.emit(user)
  }


}
