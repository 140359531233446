import {Component, Input, OnInit} from '@angular/core';
import {ID} from '../../models/ID';
import {Address} from "../../../../interface/address.inteface";
import {DocumentData} from "../../models/document-data.model";


@Component({
  selector: 'sid-view',
  templateUrl: './sid-view.component.html',
  styleUrls: ['./sid-view.component.scss'],
})
export class SidViewComponent implements OnInit {

  @Input() id: ID = null
  @Input() documentData: DocumentData = null
  public sidKeys = {
    aadhaar: "aadhaar",
    pan: "pan",
    dl: 'dl'
  };

  constructor() {
  }

  ngOnInit() {
  }

  setData() {

  }

  getStyledIdNumber(number: string) {
    if (number?.length % 4 != 0) {
      return number;
    }
    let joy = number.match(/.{1,4}/g);
    return joy.join(' ');
  }

  getAddressLine() {
    const address: Address = this.documentData?.address;
    return `${address.house}, ${address.street}, ${address.dist}, ${address.state}, - ${address.zipCode}`
  }
}
