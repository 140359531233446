import {NgModule} from "@angular/core";
import {WidthDirective} from "./directives/width.directive";
import {SoopCol} from "./directives/col.directive";
import {DistributionDirective} from "./directives/distribution.directive";
import {HeightDirective} from "./directives/height.directive";
import {SoopRow} from "./directives/row.directive";
import {AlignDirective} from "./directives/align.directive";
import {PaddingDirective} from "./directives/padding.directive";
import {RadiusDirective} from "./directives/radius.directive";
import {BackgroundDirective} from "./directives/background.directive";
import {ColorDirective} from "./directives/color.directive";
import {TextSizeDirective} from "./directives/text-size.directive";
import {TextAlignDirective} from "./directives/text-align.directive";
import {GapDirective} from "./directives/gap.directive";
import {MarginDirective} from "./directives/margin.directive";
import {BorderColorDirective} from "./directives/borderColor.directive";
import {BorderWidthDirective} from "./directives/borderWidth.directive";
import {TextStyleDirective} from "./directives/text-style.directive";
import {TextWeightDirective} from "./directives/text-weight.directive";
import {LongPressDirective} from "./directives/longPress.directive";
import {BackgroundImageDirective} from "./directives/backgroundImage.directive";
import {BackgroundImageSize} from "./directives/backgroundImageFill.directive";
import {BackgroundPositionDirective} from "./directives/backgroundPosition.directive";
import {TextDecorationDirective} from "./directives/text-decoration.directive";
import {CapitalizeDirective} from "./directives/capitalize.directive";
import {PositionDirective} from "./directives/position.directive";
import {HorizontalScrollDirective} from "./directives/horizontal-scroll.directive";
import {FlexShrinkDirective} from "./directives/flex-shrink.directive";
import {NoteDirective} from "./directives/note.directive";
import {LineHeightDirective} from "./directives/line-height.directive";
import {MatchParentDirective} from "./directives/matchParent.directive";
import {CenterizeDirective} from "./directives/centerize.directive";
import {CursorDirective} from "./directives/cursor.directive";
import {MaxWidthDirective} from "./directives/maxWidth.directive";
import {OverflowDirective} from "./directives/overflow.directive";
import {MaxHeightDirective} from "./directives/maxHeight.directive";
import {TextSlideDirective} from "./directives/text-slide";
import {StickBottomDirective} from "./directives/stickBottom.directive";
import {RadiusOnlyDirective} from "./directives/radius-only.directive";
import {TextEllipseDirective} from "./directives/text-ellipse.directive";
import {WrapDirective} from "./directives/wrap.directive";
import {CircleDirective} from "./directives/circle.directive";
import {FlexDirectionDirective} from "./directives/flex-direction.directive";
import {BottomDirective} from "./directives/bottom.directive";
import {TextWrapDirective} from "./directives/text-wrap.directive";
import {MinWidthDirective} from "./directives/minWidth.directive";
import {MinHeightDirective} from "./directives/minHeight.directive";
import {OverlayCenterDirective} from "./directives/overlay-center.directive";

@NgModule({
  imports: [],
  declarations: [
    WidthDirective,
    HeightDirective,
    MinHeightDirective,
    MaxWidthDirective,
    MinWidthDirective,
    SoopCol,
    SoopRow,
    DistributionDirective,
    AlignDirective,
    PaddingDirective,
    RadiusDirective,
    BackgroundDirective,
    ColorDirective,
    TextSizeDirective,
    TextAlignDirective,
    TextEllipseDirective,
    GapDirective,
    MarginDirective,
    BorderColorDirective,
    BorderWidthDirective,
    TextStyleDirective,
    TextWeightDirective,
    LongPressDirective,
    BackgroundImageDirective,
    BackgroundImageSize,
    BackgroundPositionDirective,
    TextDecorationDirective,
    CapitalizeDirective,
    PositionDirective,
    HorizontalScrollDirective,
    FlexShrinkDirective,
    NoteDirective,
    LineHeightDirective,
    MatchParentDirective,
    CenterizeDirective,
    CursorDirective,
    OverflowDirective,
    MaxHeightDirective,
    TextSlideDirective,
    StickBottomDirective,
    RadiusOnlyDirective,
    WrapDirective,
    CircleDirective,
    FlexDirectionDirective,
    BottomDirective,
    TextWrapDirective,
    OverlayCenterDirective
  ],
  exports: [
    WidthDirective,
    HeightDirective,
    MinHeightDirective,
    MaxWidthDirective,
    MinWidthDirective,
    SoopCol,
    SoopRow,
    DistributionDirective,
    AlignDirective,
    PaddingDirective,
    RadiusDirective,
    BackgroundDirective,
    ColorDirective,
    TextSizeDirective,
    TextAlignDirective,
    TextEllipseDirective,
    GapDirective,
    MarginDirective,
    BorderColorDirective,
    BorderWidthDirective,
    TextStyleDirective,
    TextWeightDirective,
    LongPressDirective,
    BackgroundImageDirective,
    BackgroundImageSize,
    BackgroundPositionDirective,
    TextDecorationDirective,
    CapitalizeDirective,
    PositionDirective,
    HorizontalScrollDirective,
    FlexShrinkDirective,
    NoteDirective,
    LineHeightDirective,
    MatchParentDirective,
    CenterizeDirective,
    CursorDirective,
    OverflowDirective,
    MaxHeightDirective,
    TextSlideDirective,
    StickBottomDirective,
    RadiusOnlyDirective,
    WrapDirective,
    CircleDirective,
    FlexDirectionDirective,
    BottomDirective,
    TextWrapDirective,
    OverlayCenterDirective
  ]
})
export class SexyModule {
}
