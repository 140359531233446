import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";

@Component({
  selector: 'super-skeleton',
  templateUrl: './super-skeleton.component.html',
  styleUrls: ['./super-skeleton.component.scss'],
})
export class SuperSkeletonComponent implements OnInit {

  @Input() iterations: any = [1, 2, 3];

  @Input() padding: 'small' | 'medium' | 'large' | string = "medium";

  @Input() gap = "10px"

  @Input() config: SuperEntityListViewConfig;


  constructor() {
  }

  ngOnInit() {

  }

  getComponentProps(entity: any) {
    if (!entity || !this.config?.componentPropsResolver) {
      return {}
    }
    return this.config.componentPropsResolver(entity)
  }

  onEntityClick(entity: any) {
    if (!entity || !this.config?.onClick) {
      return {}
    }
    this.config.onClick(entity);
  }
}

export interface SuperEntityListViewConfig {

  entities$: Observable<any[]>;
  isLoading$?: Observable<boolean>
  component: any,
  componentPropsResolver?: (entity: any) => {}
  onClick?: (entity: any) => {}
}
