import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginatedResponse} from '@qid/core';

@Component({
  selector: 'super-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() paginatedResponse: PaginatedResponse<any> = {};
  @Output() change: EventEmitter<number> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() previous: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onNext() {
    this.change.emit(++this.paginatedResponse.page);
  }

  onPrev() {
    if (this.paginatedResponse.page <= 1) {
      return;
    }
    this.change.emit(--this.paginatedResponse.page);
  }
}
