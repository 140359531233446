<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button fill="clear" slot="start" (click)="close()">
      Cancel
    </ion-button>
    <ion-title> {{ user?.phoneNumber ? 'Update' : 'Add' }} Phone</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div sxPadding="medium" sxCol sxGap="10px">
    <super-input fixedCountry="India"
                 type="phone"
                 placeholder="Enter 10 Digit Mobile Number"
                 label="Phone Number"
                 iconStart="call-outline"
                 [(value)]="phoneVerifier.receiverId"
                 helpNote="If you wish to update your phone number, an OTP will be sent to the new number provided."
    ></super-input>

    <super-button [action]="sendPhoneOtp">Update Phone Number</super-button>
  </div>


</ion-content>
