import {NgModule} from '@angular/core';
import {EditAccountProfilePageModule} from './pages/edit-account-profile/edit-account-profile.module';
import {AccountProfileApi} from './services/account-profile.api';
import {ViewAccountProfilePageModule} from './pages/view-account-profile/view-account-profile.module';
import {StoreModule} from '@ngrx/store';
import {AccountProfileFacade, AccountProfileStore,} from './state/account-profile.facade';
import {EffectsModule} from '@ngrx/effects';
import {AccountProfileEffects} from './state/account-profile.effects';
import {AccountProfileComponentsModule} from "./components/account-profile-components.module";

@NgModule({
  imports: [
    ViewAccountProfilePageModule,
    EditAccountProfilePageModule,
    StoreModule.forFeature(
      AccountProfileStore.featureKey,
      AccountProfileStore.reducer.recordReducer
    ),
    EffectsModule.forFeature([AccountProfileEffects]),
    AccountProfileComponentsModule
  ],
  providers: [AccountProfileApi, AccountProfileFacade],

})
export class AccountProfileModule {
}
