import {Component, OnInit} from '@angular/core';
import {OrganisationUserService} from "../../services/organisation-user.service";
import {ActivatedRoute} from "@angular/router";
import {OrganisationUser} from "../../models/organisation-user.model";
import {ModalController} from "@ionic/angular";
import {AesRsaEncryptedData, CryptoUtil} from "@qid/crypto";
import {firstValueFrom} from "rxjs";
import {Store} from "@ngrx/store";
import {CreateEncryptionKeyComponent, EncryptionKeys, EncryptionKeysService} from "../../../encryption-keys";
import {AuthSelectors} from "../../../authentication";

@Component({
  selector: 'view-organisation-user',
  templateUrl: './view-organisation-user.page.html',
  styleUrls: ['./view-organisation-user.page.css'],
})
export class ViewOrganisationUserPage implements OnInit {

  public orgUser: OrganisationUser

  public keys: EncryptionKeys = null;

  constructor(private organisationUserService: OrganisationUserService,
              private activatedRoute: ActivatedRoute,
              private modalController: ModalController,
              private encryptionKeysService: EncryptionKeysService,
              private store: Store
  ) {
  }

  ngOnInit() {
  }

  async ionViewDidEnter() {
    await this.fetchRouterData()
    this.fetchEncryptionKey();
  }

  async fetchRouterData() {
    if (this.activatedRoute.snapshot.paramMap.get('id')) {
      const orgUserId = this.activatedRoute.snapshot.paramMap.get('id');
      const res = await this.organisationUserService.read(orgUserId);
      if (res && res.data) {
        this.orgUser = res.data;
      }
    }
  }

  createPinForOrganisationUser = async () => {
    const modal = await this.modalController.create({
      component: CreateEncryptionKeyComponent,
      componentProps: {
        entityId: this.orgUser._id
      }
    })
    modal.present();
  }

  fetchEncryptionKey = async () => {
    const keys = await this.encryptionKeysService.read(this.orgUser._id)
    this.keys = keys;
  }

  generateSharedKey = async () => {
    const activeOrg = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccount))
    const orgKeys = await this.encryptionKeysService.read(activeOrg._id)

    const orgPrivateKeyPlainText = await CryptoUtil.extractPrivateKeyFromPassword('12345678', orgKeys.key, orgKeys.salt);

    const aesRsaEncryptedSharedKeys: AesRsaEncryptedData = await CryptoUtil.encryptDataWithRsaAndAes({
      key: orgPrivateKeyPlainText
    }, this.keys.publicKey)

    const res = await this.encryptionKeysService.setParent(this.keys._id, orgKeys._id, aesRsaEncryptedSharedKeys);

  }

}
