import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthenticationService} from './services/authentication.service';
import {SuperModule} from '@qid/super';
import {SexyModule} from '@qid/sexy';
import {IonicModule} from '@ionic/angular';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromAuth from './+state/auth.reducer';
import {AuthEffects} from './+state/auth.effects';
import {AuthenticationApiService} from "./services/authentication-api.service";
import {EncryptionKeysComponentsModule} from "../encryption-keys";

@NgModule({
    imports: [
        CommonModule,
        SuperModule,
        SexyModule,
        IonicModule,
        EncryptionKeysComponentsModule,
        StoreModule.forFeature(fromAuth.AUTH_FEATURE_KEY, fromAuth.authReducer),
        EffectsModule.forFeature([AuthEffects])
    ],
    providers: [AuthenticationService, AuthenticationApiService]
})
export class AuthenticationModule {
}
