import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {exhaustMap, from, map, of} from 'rxjs';
import {SidService} from "../services/sid.service";
import { SidActions } from '..';


@Injectable()
export class SidEffects {
  private actions$ = inject(Actions);
  private apiService = inject(SidService)

  // Boilerplate Starts
  private ACTIONS = SidActions;

  addAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.ACTIONS.addAccount),
      exhaustMap((props) =>
        of(this.ACTIONS.loadRecordsForAccount({
          account_id: props.accountBasedEntity.account_id,
          accountType: props.accountBasedEntity.accountType
        }))
      )
    )
  );

  loadSharesForAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.ACTIONS.loadRecordsForAccount),
      exhaustMap((props) => {
          return from(this.apiService.readAllOrg(props.account_id)).pipe(
            map(sids => {
              return this.ACTIONS.loadRecordsForAccountSuccess({
                records: sids || [],
                account_id: props.account_id
              })
            })
          )
        }
      )
    )
  );

  // Boilerplate Ends

}
