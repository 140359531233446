import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ID} from "../../../../models/ID";
import {IdService} from '../../services/id.service';
import {DisplayService} from "@qid/core";
import {PageLoaderComponent} from "@qid/super";
import {SID} from "../../../sid";

@Component({
  selector: 'app-id-verification',
  templateUrl: './id-verification.page.html',
  styleUrls: ['./id-verification.page.scss'],
})
export class IdVerificationPage implements OnInit {

  @ViewChild('pageLoaderComponent') pageLoaderComponent: PageLoaderComponent

  @Input() sid: SID = null;
  @Input() id: ID = null;

  constructor(private idService: IdService,
              private modalController: ModalController,
              private displayService: DisplayService
  ) {
  }

  ngOnInit() {
  }

  ionViewDidEnter() {

  }


  back() {
    this.modalController.dismiss()
  }
}
