import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertController, IonPopover, LoadingController, ModalController} from '@ionic/angular';
import {Organisation, OrganisationService} from '../../../organisation';
import {OrganisationUserActions, OrganisationUserSelectors} from "../../../organisation-user";
import {AuthSelectors} from "../../../authentication";
import {Store} from "@ngrx/store";
import {OrganisationRelationshipService} from "../../services/organisation-relationship.service";
import {OrganisationRelationshipFacade} from "../../services/organisation-relationship.facade";
import {CreateOrganisationRelationshipDto} from "../../dto/create-organisation-relationship.dto";
import {OrganisationRelationship} from "../../model/organisation-relationship.model";
import {
  EditOrganisationRelationshipPage
} from "../edit-organisation-relationship/edit-organisation-relationship-page.component";
import {firstValueFrom} from 'rxjs';
import {OrganisationUserRoles} from "@qid/core";

@Component({
  selector: 'app-list-child-orgs',
  templateUrl: './list-child-orgs.page.html',
  styleUrls: ['./list-child-orgs.page.scss'],
})
export class ListChildOrgsPage implements OnInit {

  @ViewChild('addOrgPopover') addOrgPopover: IonPopover

  public activeAccount$ = this.store.select(AuthSelectors.selectActiveAccount);

  public organisationRelationships$ = this.orgRelationshipsFacade.childOrganisations$;
  public parentOrganisation$ = this.orgRelationshipsFacade.parentBranchRealation$;

  public organisationsThatCanBeAdded: Organisation[] = null

  constructor(private modalController: ModalController,
              private store: Store,
              private organisationRelationshipService: OrganisationRelationshipService,
              private orgRelationshipsFacade: OrganisationRelationshipFacade,
              private loadingController: LoadingController,
              private alertController: AlertController,
              private organisationService: OrganisationService,
  ) {

  }

  ngOnInit() {
    this.orgRelationshipsFacade.initParentBranch();
  }


  openOrgSelector = async () => {
    if (!this.organisationsThatCanBeAdded) {
      this.organisationsThatCanBeAdded = await this.getOrganisations()
    }

  }

  onCreateOrgClicked = async () => {

    const alert = await this.alertController.create({
        header: 'Create Organisation',
        message: 'Please enter the name of the organisation',
        inputs: [
          {
            name: 'text',
            type: 'text',
            placeholder: 'Organisation Name',
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Create',
            handler: async (data) => {
              const loader = await this.loadingController.create({
                message: 'Creating Account'
              })
              loader.present()
              const parentOrg : OrganisationRelationship = await firstValueFrom(this.parentOrganisation$)
              const org = await this.organisationService.create({
                name: data.text,
                industry: parentOrg?.parentOrg?.industry?._id,
              })
              loader.dismiss()
              if (org) {
                this.store.dispatch(OrganisationUserActions.fetchUserOrganisations())
                await this.setParentOrganisation(org._id, null)
              }
            }
          }
        ]
      })
    alert.present();
  }

  async setParentOrganisation(childOrgId: string, index: number) {

    const loader = await this.loadingController.create({
      message: 'Adding Branch'
    })

    loader.present();

    const activeAccount: Organisation = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccount))

    const dto: CreateOrganisationRelationshipDto = {
      parentOrg: activeAccount._id,
      childOrg: childOrgId
    }
    const res = await this.organisationRelationshipService.create(dto)

    loader.dismiss();

    if (res && res.data) {
      index ? this.organisationsThatCanBeAdded.splice(index, 1) : null;
      this.orgRelationshipsFacade.refresh()
    }

  }

  async openOrgRelationEdit(orgRelationship: OrganisationRelationship) {
    const modal = await this.modalController.create({
      component: EditOrganisationRelationshipPage,
      componentProps: {
        orgRelationship: orgRelationship
      }
    })
    modal.present();
    const res = await modal.onDidDismiss()

    if (res?.data?.deleted == true) {
      this.orgRelationshipsFacade.refresh()
    }
  }

  async getOrganisations(): Promise<Organisation[]> {
    const state = await firstValueFrom(
        this.store.select(OrganisationUserSelectors.selectOrganisationUserState)
    );

    if (!state?.authenticatedUserOrganisations?.length) {
      return [];
    }

    const activeAccount = await firstValueFrom(this.activeAccount$)

    const allOrganisations = state.authenticatedUserOrganisations
        .filter(({role, organisation}) =>
            (role >= OrganisationUserRoles.ADMIN && organisation._id !== activeAccount._id))

        .map(({organisation}) => organisation);

    if (!allOrganisations.length) {
      return [];
    }

    const organisationRelationships = await firstValueFrom(this.organisationRelationships$);

    if (!organisationRelationships?.length) {
      return allOrganisations;
    }

    const alreadyAddedOrganisations = new Set(
        organisationRelationships.map(({childOrg}) => childOrg._id)
    );

    const uniqueOrganisations = allOrganisations.filter(
        ({_id}) => !alreadyAddedOrganisations.has(_id)
    );

    return uniqueOrganisations;
  }


}
