import {AccountAttributeType} from "../../account-attribute";
import {AccountAttribute} from "../../account-attribute/model/account-attribute.model";

export class AccountProfileUtil {

  public static getAttributeByType(allAttributes: AccountAttribute[], attributeType: AccountAttributeType): AccountAttribute {

    const attribute = allAttributes.find(attribute => attribute.attributeType == attributeType);

    return attribute || {attributeType: attributeType}
  }

}
