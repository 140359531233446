import {createAction, props} from '@ngrx/store';
import {AccountType, AccountWiseRecords} from "@qid/core";
import {ID} from "../../../models/ID";


//Boilerplate Starts

const FEATURE_KEY = 'Documents'
const ENTITY_MODEL: ID = null;

export const addAccount = createAction(
    `[${FEATURE_KEY}] Add new account`,
    props<{
        accountBasedEntity: AccountWiseRecords<typeof ENTITY_MODEL>
    }>()
)

export const loadRecordsForAccount = createAction(
    `[${FEATURE_KEY}] Load records for account`,
    props<{
        account_id: string,
        accountType: AccountType
    }>()
);


export const loadRecordsForAccountSuccess = createAction(
    `[${FEATURE_KEY}] Load Records for Account Success`,
    props<{
        records: typeof ENTITY_MODEL[],
        account_id: string
    }>()
);

export const addRecord = createAction(
    `[${FEATURE_KEY}] Add Record`,
    props<{
        record: typeof ENTITY_MODEL,
        account_id: string
    }>()
);

export const removeRecord = createAction(
    `[${FEATURE_KEY}] Remove Record`,
    props<{
        record_id: string,
        account_id: string
    }>()
);


// Boilerplate Ends
