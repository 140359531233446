import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  accountAdapter,
  COLLECTION_TEMPLATE_FEATURE_KEY,
  CollectionTemplateState,
  recordAdapter,
} from './collection-template.reducer';
import { AuthSelectors } from '../../authentication';

export const selectCollectionTemplateState =
  createFeatureSelector<CollectionTemplateState>(
    COLLECTION_TEMPLATE_FEATURE_KEY
  );

const {selectAll, selectEntities} = accountAdapter.getSelectors();
const recordsSelector = recordAdapter.getSelectors();

export const selectAllCollectionTemplate = createSelector(
  selectCollectionTemplateState,
  (state: CollectionTemplateState) => selectAll(state)
);

export const selectCollectionTemplateEntities = createSelector(
  selectCollectionTemplateState,
  (state: CollectionTemplateState) => selectEntities(state)
);

export const selectActiveAccount = createSelector(
  selectCollectionTemplateEntities,
  AuthSelectors.selectActiveAccountId,
  (entities, activeAccountId) => (activeAccountId ? entities[activeAccountId] : undefined)
);

export const selectActiveAccountRecords = createSelector(
  selectActiveAccount,
  (selectedAccount) => {
    return (selectedAccount ? recordsSelector.selectAll(selectedAccount.records) : undefined)
  }
);


