import {Component, EventEmitter, Input, OnInit, Output, ViewChild,} from "@angular/core";
import {SignatureService} from "../../services/signature.service";
import {ImageUtil} from "@qid/core";
import {Signature} from "../../model/Signature";
import SignaturePad from "signature_pad";

@Component({
  selector: "view-signature",
  templateUrl: "./view-signature.component.html",
  styleUrls: ["./view-signature.component.scss"],
})
export class ViewSignatureComponent implements OnInit {

  @ViewChild("signaturePad") signaturePad: SignaturePad;
  @Input() familyMember_id: string = null;
  @Input() signatoryName: string = null;
  @Input() signature: Signature = null;
  @Input() allowCreate: boolean = true;
  @Input() allowEdit: boolean = true;
  @Output() signatureChange: EventEmitter<Signature> =
    new EventEmitter<Signature>();
  @Input() onSubmit: () => {};
  isDrawComplete: boolean = false;
  fetchingSignature: boolean = false;

  constructor(private signatureService: SignatureService) {
  }

  ngOnInit() {
  }


  getImage(url) {
    return ImageUtil.getImageSource(url);
  }

  resetSign() {
    this.signature = null;
    this.isDrawComplete = false;
    this.signaturePad?.clear();
  }

  checkSign = async () => {
    if (this.signature && this.signature.signatureImageUrl) {
      return this.submit();
    }

    const base64 = this.signaturePad?.toDataURL();
    if (!base64 || (await ImageUtil.isBase64PNGBlank(base64))) {
      throw "Signature is required";
    }
    this.signature = await this.saveSignature();
    this.submit();
  };

  submit() {
    this.signatureChange.emit(this.signature);
    this.resetSign();
    return this.onSubmit();
  }

  saveSignature = async () => {
    let file = ImageUtil.dataURLtoFile(
      this.signaturePad.toDataURL(),
      "signature.png"
    );
    const res = await this.signatureService.create(file, this.familyMember_id);
    if (!res || res?.errorMessage) {
      throw res?.errorMessage;
    }
    return res.data;
  };
}
