import {Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import {IonModal, ModalController} from "@ionic/angular";
import {ID} from "../../../../models/ID";
import {DocumentData} from "../../models/document-data.model";
import {CreateIdDto} from "../../dto/create-id.dto";
import {AccountType, DisplayService, ValidationUtil} from "@qid/core";
import {CryptoUtil} from "@qid/crypto";
import {NameCorrectionPage} from "../name-correction/name-correction.page";
import {IdService} from "../../services/id.service";
import {PageLoaderComponent} from "@qid/super";
import {CreateIdPageUtil} from "./create-id-page.util";
import {UpdateDocumentDto} from "../../dto/update-document.dto";
import {DocumentsRepository} from "../../+state/documents-repository.service";
import {User} from "../../../user";
import {SID, SidService} from "../../../sid";
import {DocumentOcrResult, OcrService} from "../../../ocr";
import {SidUtil} from "../../../sid/util/sid.util";
import {DigilockerComponent} from "../../../../components/digilocker/digilocker.component";


@Component({
  selector: "app-create-id",
  templateUrl: "./create-id.page.html",
  styleUrls: ["./create-id.page.scss"]
})
export class CreateIdPage implements OnInit {

  @ViewChild("pageLoaderComponent") pageLoaderComponent: PageLoaderComponent;

  @ViewChild('documentDetailsSection') documentDetailsSection: ElementRef;

  @ViewChild('digilockerModal') digilockerModal: IonModal

  @ViewChild('digilockerComponent') digilockerComponent: DigilockerComponent

  @Input() id: ID;

  @Input() sidId: string;

  @Input() verifiedUpload: boolean = false;

  @Input() account: User | any = null;

  public sid: SID = null;
  public errorMessage: string = null;

  public documentData: DocumentData = new DocumentData();

  public autofillDone: boolean = false;
  public isShowOcrLoader: boolean = false;
  public autofillAttempts = 0;

  public documentOCRResult: DocumentOcrResult;
  public documentNumberErrorMessage: string;

  public slides = {
    uploadPhoto: 'uploadPhoto',
    manualFields: 'manualFields'
  }
  public activeSlide: string = this.slides.uploadPhoto

  constructor(
      private sidService: SidService,
      private displayService: DisplayService,
      private ocrService: OcrService,
      private modalController: ModalController,
      private idService: IdService,
      private documentRepository: DocumentsRepository,
      private modalController1: ModalController
  ) {
  }

  ngOnInit() {
  }

  async ionViewDidEnter() {
    this.autofillAttempts = 0;
    await this.fetchSid();
    this.pageLoaderComponent.hideLoader();
  }

  async fetchSid() {
    this.sid = await this.sidService.readOne(this.sidId);
    if (!this.sid) {
      return;
    }

    this.documentData.manualFields = this.sid.manualFields;
  }

  performDocumentOCR = async (autofill: boolean = false): Promise<DocumentOcrResult> => {

    this.isShowOcrLoader = true;

    let files: any[] = CreateIdPageUtil.validateAndGetAllPhotosFile(this.sid);

    const res = await this.ocrService.ocrWithGpt(files, this.sid._id, this.account._id, autofill);

    this.isShowOcrLoader = false;

    if (!res) {
      throw new Error("Document images could not be scanned");
    }

    if (!res.data) {
      throw new Error(res.errorMessage);
    }

    this.documentOCRResult = res.data
    CreateIdPageUtil.autofillFieldsValue(this.documentOCRResult.extractedFields, this.documentData)
    this.documentData.scannedImages = this.documentOCRResult.scannedImages;
    if (autofill || !this.documentData.idNumber || this.documentData.idNumber?.length < 1) {
      this.documentData.idNumber = this.documentOCRResult.extractedIdNumber;
    }
    this.documentData.masterKeyString = this.documentOCRResult.masterKeyString;
    this.documentData.masterKeyWords = this.documentOCRResult.masterKeyWords;
    // Check Name

    if (this.documentOCRResult.nameMatchPercentage < 100 && ValidationUtil.isFalse(this.sid.canSkipNameCheck)) {


      if (ValidationUtil.isStringEmpty(this.documentOCRResult.extractedNameOnId)) {
        throw new Error("Your name not found on document, retry with clear and correct pictures.")
      }

      const nameMatchResolved = await this.onNameMatchError();

      if (!nameMatchResolved) {
        throw new Error("Name does not match, retry with clear and correct pictures.")
      }
    }

    return res.data

  };

  autofill = async () => {
    try {
      this.autofillAttempts++;
      await this.performDocumentOCR(true);
      if (!this.sid.confirmAutofillFromUser) {
        this.verifyUpload()
      }
    } catch (e) {
      this.isShowOcrLoader = false;
      if (e?.message) {
        this.displayService.alert(e.message);
      }
    }
  };

  /**
   * Verifies the upload by performing validations and creating/updating ID with images.
   * @returns {Promise<void>} Resolves when the upload is verified successfully.
   * @throws {string} Throws an error message if the upload verification fails.
   */
  verifyUpload = async (): Promise<void> => {

    try {

      // Check id Required ID Photos are uploaded
      CreateIdPageUtil.validateAndGetAllPhotosFile(this.sid);

      // Check if Required Manual Fields are filled
      CreateIdPageUtil.validateManualFields(this.sid, this.documentData);


      // DO OCR
      if (!this.documentOCRResult) {
        await this.performDocumentOCR();
      }

      // Check ID Number
      if (ValidationUtil.isFalse(this.sid.canSkipIdNumberCheck) && (!this.documentData.idNumber || this.documentData.idNumber?.length < 1)) {
        this.documentNumberErrorMessage = "Required"
        throw new Error(`Please enter ${this.sid.name} number`)
      }


      //Step 6 : Create/Update Document
      this.id ? await this.update() : await this.createId();


    } catch (e) {
      if (e && e.message) {
        throw e.message;
      }
    }


  };

  async createId() {

    // const aesRsaEncryptedData = await CryptoUtil.encryptDataWithRsaAndAes(this.documentData, EncryptionKeysService.Keys.publicKey);
    let createIDDto: CreateIdDto = {
      account_id: this.account._id,
      accountType: AccountType.user,
      sid: SidUtil.getSnapshot(this.sid),
      documentData: this.documentData,
      documentNumberMasked: CreateIdPageUtil.maskIdNumber(this.documentData.idNumber),
      documentNumberHash: await CryptoUtil.generatePBKDF2Hash(this.documentData.idNumber, this.documentData.idNumber),
      aesRsaEncryptedData: null,
    }

    const apiResponse = await this.idService.create(createIDDto);
    this.documentRepository.refresh();
    if (apiResponse && apiResponse.data) {

      this.closeModal({...apiResponse.data, sid: this.sid});
    }

  }

  async update() {

    const dto: UpdateDocumentDto = {
      documentNumberMasked: CreateIdPageUtil.maskIdNumber(this.documentData.idNumber),
      documentNumberHash: await CryptoUtil.generatePBKDF2Hash(this.documentData.idNumber, this.documentData.idNumber),
      documentData: this.documentData
    }

    const apiResponse = await this.idService.update(this.id._id, dto);
    this.documentRepository.refresh();
    if (apiResponse && apiResponse.data) {
      this.closeModal(apiResponse.data);
    }

  }

  async onNameMatchError(): Promise<boolean> {

    const modal = await this.modalController.create(
        {
          component: NameCorrectionPage,
          componentProps: {
            userInput: this.account.name,
            owner: {...this.account},
            documentImageScanResult: this.documentOCRResult
          },
          animated: false
        }
    );

    await modal.present();
    const modalRes = await modal.onDidDismiss();

    if (modalRes?.data && modalRes?.data?.nameUpdated == true) {
      if (modalRes?.data?.name) {
        this.account.name = modalRes?.data?.name
      }
      return true
    }


  }

  closeModal(id = null) {
    this.modalController.dismiss({id: id});
  }

  onImageSelected() {
    this.documentData.scannedImages = [];
    this.documentOCRResult = null
    this.autofillDone = false;
  }

  async onDigilockerClicked() {
    await this.digilockerModal.present();
    this.digilockerComponent.init();

  }

}

