import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxFlexShrink]'})
export class FlexShrinkDirective implements OnInit {

  @Input() sxFlexShrink: string = "0";

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.style.flexShrink = this.sxFlexShrink
  }

}
