<div sxCol sxGap="10px" sxMargin="20px 0" sxPadding="medium"
sxBorderColor="one" sxBorderWidth="1px" sxRadius="small"
>
  <div sxRow sxAlign="center" sxDistribution="spaceBetween">
    <p sxMargin="0">Last updated</p>
    <p sxMargin="0">{{ lastUpdateTime | date : 'short' }}</p>
  </div>
  <div sxRow sxAlign="center" sxDistribution="spaceBetween">
    <p sxMargin="0">Created on</p>
    <p sxMargin="0">{{ createTime | date : 'short' }}</p>
  </div>
  <div *ngIf="_id" sxRow sxAlign="center" sxDistribution="spaceBetween">
    <p sxMargin="0">ID</p>
    <p sxMargin="0">{{_id}}</p>
  </div>
</div>
