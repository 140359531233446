import {CryptoUtil} from "./CryptoUtil";

export class RsaUtil {

  public static Algorithm = "RSA-OAEP";
  public static ModulusLength = 2048;
  public static HashAlgorithm = "SHA-256";
  public static PublicExponent = new Uint8Array([0x01, 0x00, 0x01]);


  public static async generateRSAKeyPair(): Promise<{
    publicKeyBase64: string, privateBase64: string
  }> {


    const keyPair = await window.crypto.subtle.generateKey(
      {
        name: RsaUtil.Algorithm,
        modulusLength: RsaUtil.ModulusLength,
        publicExponent: RsaUtil.PublicExponent,
        hash: RsaUtil.HashAlgorithm
      },
      true,
      ["encrypt", "decrypt"]
    );

    const publicKey = await window.crypto.subtle.exportKey("spki", keyPair.publicKey);
    const privateKey = await window.crypto.subtle.exportKey("pkcs8", keyPair.privateKey);

    const publicKeyBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(publicKey)));
    const privateBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(privateKey)));

    return { publicKeyBase64, privateBase64 };


  }


  public static async encrypt(publicKeyBase64: string, message: any): Promise<string> {

    const publicKey: CryptoKey = await RsaUtil.getPublicKeyFromBase64(publicKeyBase64);

    const encryptionResponse = await window.crypto.subtle.encrypt(
      {
        name: RsaUtil.Algorithm
      },
      publicKey,
      CryptoUtil.encode(message)
    );

    return CryptoUtil.arrayBufferToBase64(encryptionResponse);
  }


  public static async decryptData(cryptoKey: string, encryptedDataB64: string): Promise<string> {


    const encryptedDataBuffer = CryptoUtil.base64ToArrayBuffer(encryptedDataB64);

    const privateKey = await RsaUtil.getPrivateKeyFromBase64(cryptoKey);

    const decryptedData = await window.crypto.subtle.decrypt(
      {
        name: RsaUtil.Algorithm
      },
      privateKey,
      encryptedDataBuffer
    );

    return CryptoUtil.decode(decryptedData);

  }

  private static getPublicKeyFromBase64(publicKeyBase64:string):Promise<CryptoKey> {

    const binaryData = CryptoUtil.base64ToUint8Array(publicKeyBase64);

    return window.crypto.subtle.importKey(
      "spki",
      binaryData,
      {
        name: RsaUtil.Algorithm,
        hash: RsaUtil.HashAlgorithm
      },
      true,
      ["encrypt"]
    );

  }

  private static getPrivateKeyFromBase64(privateKeyBase64: string) : Promise<CryptoKey> {

    const binaryData = CryptoUtil.base64ToUint8Array(privateKeyBase64);

    return window.crypto.subtle.importKey(
      "pkcs8",
      binaryData,
      {
        name: RsaUtil.Algorithm,
        hash: RsaUtil.HashAlgorithm
      },
      true,
      ["decrypt"]
    );

  }

}
