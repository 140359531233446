import {SID} from "../models/SID";

export class SidUtil {

  public static getSnapshot(sid: SID): SID {
    return {
      _id: sid._id,
      name: sid.name,
      logoUrl: sid.logoUrl,
      keyName: sid.keyName
    }
  }

}
