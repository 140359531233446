import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CollectionTemplate, User} from "@qid/common";
import {Share, ShareService} from "@qid/common";
import {Field} from "@qid/field";

@Component({
  selector: 'gateway-form',
  templateUrl: './gateway-form.component.html',
  styleUrl: './gateway-form.component.scss',
})
export class GatewayFormComponent {

  @Input() template: CollectionTemplate;
  @Input() share: Share;
  @Output() onSubmit: EventEmitter<Field[]> = new EventEmitter<Field[]>();
  public user: User;
  public termsChecked: boolean = false;

  constructor(private shareService: ShareService) {
  }

  ngOnInit() {
  }

  init() {
  }
  submitClicked = async () => {

    if (!this.validateOtherFields()) {
      throw new Error("Please fill all the required fields")
    }

    if (!this.termsChecked) {
      throw "👆Confirmation required: Check the box to verify the information above."
    }

    let participants = this.share.participants.map((participant) => {
      return {
        ...participant, documents : [], optionalIds : []
      }
    })

    const res = await this.shareService.update(this.share._id,
      {
        participants: participants,
        otherFields: this.share.otherFields,
        updateRemark: "Form Details Submitted"
      }
    )

    if (!res || res.errorMessage) {
      throw new Error(res?.errorMessage)
    }

    this.onSubmit.emit()
  };

  private validateOtherFields() {

    let isAllValid: boolean = true;

    if (this.template.otherInfo == null || this.template.otherInfo.length < 1) {
      return true;
    }

    let fieldsToCheck = this.share.otherFields ? this.share.otherFields : [];

    for (let participant of this.share.participants) {
      if (participant.otherFields) {
        fieldsToCheck = fieldsToCheck.concat(participant.otherFields);
      }
    }

    for (let field of fieldsToCheck) {
      if (field.errorMessage) {
        isAllValid = false;
        continue;
      }
      if (
        field.isMandatory &&
        (field.value == null || field.value.length < 1)
      ) {
        field.errorMessage = "Required";
        isAllValid = false;
      }
    }

    return isAllValid;
  }

  autoFillOtherFields() {
    let allFields = {};
    let allManualFields = [];

    let ids = this.share?.participants[0]?.mandatoryIds.concat(this.share?.participants[0]?.optionalIds);

    ids.forEach((field) => {
      if (!field || !field.data?.documentAccess?.documentData?.manualFields) {
        return;
      }
      allManualFields = allManualFields.concat(field.data.documentAccess?.documentData?.manualFields);
    });

    allManualFields.forEach((field) => {
      allFields[field.name?.toLowerCase()] = field.value;
    });

    this.template.otherInfo.forEach((otherInfo) => {
      if (allFields[otherInfo.name?.toLowerCase()]?.toString()?.length > 0) {
        otherInfo.value = allFields[otherInfo.name?.toLowerCase()];
      }
    });
  }
}
