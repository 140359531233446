<ion-header *ngIf="pageMode">
  <ion-toolbar>
    <ion-button slot="start" fill="clear" (click)="cancel()">Back</ion-button>
    <ion-title>Form Documents</ion-title>
    <ion-button slot="end" fill="clear" (click)="openSidSelector()">
      <ion-icon name="add"></ion-icon>
      Add
    </ion-button>
  </ion-toolbar>
</ion-header>
<ion-content>

  <div sxRow sxMaxHeight="full" sxBackground="base">
    <div
      sxWidth="35%"
      sxBorderWidth="0 1px 0 0"
      sxBorderColor="one"
      id="sid-panel">

      <sid-selector [selectedSids]="selectedSids" [pageMode]="false" (onSelect)="addSid($event)"></sid-selector>

    </div>

    <div sxWidth="fill" sxPadding="medium">
      <ion-reorder-group
        [disabled]="false"
        (ionItemReorder)="handleReorder($any($event))">
        <div
          *ngFor="let id of acceptedIds; let i = index"
          sxRow
          sxDistribution="spaceBetween"
          sxAlign="center"
          sxMargin="5px 0"
          sxRadius="small">
          <field
            sxWidth="fill"
            [field]="id"
            [isRemove]="true"
            (onRemove)="removeId(i)"></field>
          <ion-reorder></ion-reorder>
        </div>
      </ion-reorder-group>
    </div>
  </div>

</ion-content>
<ion-footer *ngIf="pageMode">
  <page-action>
    <super-button sxWidth="full" (click)="save()">Save</super-button>
  </page-action>
</ion-footer>
