import {Participant} from "../model/participant";
import {ParticipantRoles} from "../enum/participant-roles.enum";

export class ShareParticipantUtil {


  static findOrganisingParticipant(participants: Participant[]) {

    for (let participant of participants) {
      if (participant.roles.indexOf(ParticipantRoles.organiser) > -1) {
        return participant
      }
    }
    return null
  }

  static findMainParticipant(participants: Participant[]) {

    for (let participant of participants) {
      if (participant.roles.indexOf(ParticipantRoles.admin) > -1 && participant.roles.indexOf(ParticipantRoles.sharer) > -1) {
        return participant
      }
    }
    return null
  }

}
