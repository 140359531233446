import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Directive({selector: '[sxHeight]'})
export class HeightDirective implements OnInit {

  @Input() sxHeight: string | 'full' | 'fit' | "fill";
  @Input() sxHeightMobile: string | 'full' | 'fill' | 'fit' = null;

  constructor(private el: ElementRef,
              private breakpointObserver : BreakpointObserver
              ) {
  }

  ngOnInit() {

    if (this.sxHeightMobile && this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      this.setHeight(this.sxHeightMobile);
      return;
    }

    this.setHeight(this.sxHeight);

  }

  setHeight(value : string) {
    switch (value) {
      case "full" :
        return this.el.nativeElement.style.height = "100%";
      case "fill" :
        return this.el.nativeElement.style.flex = "1 0 0px"
      default :
        return this.el.nativeElement.style.height = value;
    }

  }
}
