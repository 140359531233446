import {Injectable} from '@angular/core';
import {String} from 'typescript-string-operations';
import {ImageUtil} from "@qid/core";
import {Field} from "@qid/field";
import {DocumentOcrResult} from "../model/document-ocr-result.model";
import {RestService} from "../../../services/rest.service";

@Injectable({
  providedIn: 'root',
})
export class OcrService {
  public static readonly NodeExtractText = 'ocr/magic-scan?flushImage={0}';
  public static readonly NodeIdOcr = 'ocr/id-ocr?sid={0}&createId={1}&updateId={2}';
  public static readonly NodeOcrWithGPT = `ocr/owg`
  constructor(private restService: RestService) {
  }

    public async ocrWithGpt(imageFiles: any[], sidId: string, ownerUserId: string, autofill: boolean = false) {

    const data = await ImageUtil.getCompressedFormData(imageFiles)
        return this.restService.sendFiles<DocumentOcrResult>({
      url: 'ocr/owg',
      body: data,
      method: 'POST',
      isFile: true,
      query : {
        sidId : sidId,
        autofill : autofill,
        ownerUserId : ownerUserId
      }
    });
  }

  public async nameMatch(userInputName: string, aiName :string){

    return this.restService.send<any>({
      url: 'ocr/name-match',
      method: 'POST',
      query: {
        userEnteredName : userInputName,
        ocrExtractedName : aiName
      }
    });
  }

  public async idOcr(imageFiles: any[], sidId: string, updateId : string = null) {
    for (let image of imageFiles) {
      if (image && image.file) {
        imageFiles.push(await ImageUtil.compressImage(image.file));
      }
    }

    let data = new FormData();
    for (let file of imageFiles) {
      data.append('files', file);
    }

    return this.restService.sendFiles<any>({
      url: String.format(OcrService.NodeIdOcr, sidId, true,updateId),
      body: data,
      method: 'POST',
      isFile: true
    });
  }

  public async getIdWithVerified(
    field: Field<any>
  ): Promise<Field<any>> {
    try {
      field.data.processing = true;
      field.data.id = null;
      field.data.id = (await this.idOcr(field.value, field.data.sidId)).data;
      field.data.processing = false;
      if (!field.data.sid) {
        field.data.id.name = field.name;
      }
    } catch (e) {
      field.data.processing = false;
      field.data.id = null;
      field.errorMessage = e.errorMessage;
    }

    return field;
  }

  async cancelScan() {
    //TODO
  }
}
