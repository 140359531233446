import {inject, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as UsersActions from './users.actions';
import * as UsersSelectors from './users.selectors';
import {UserService} from "../services/user.service";
import {User} from "../model/User";

@Injectable()
export class UsersFacade {
    private readonly store = inject(Store);
    private readonly userService = inject(UserService)
    /**
     * Combine pieces of state using createSelector,
     * and expose them as observables through the facade.
     */
    loaded$ = this.store.pipe(select(UsersSelectors.selectUsersLoaded));
    allUsers$ = this.store.pipe(select(UsersSelectors.selectAllUsers));
    selectedUsers$ = this.store.pipe(select(UsersSelectors.selectEntity));

    /**
     * Use the initialization action to perform one
     * or more tasks in your Effects.
     */
    init() {
        this.store.dispatch(UsersActions.initUsers());
    }

    async updateUser(user: User) {
        const res = await this.userService.updateUser(user)
        if (!res || res.errorMessage) {
            throw res.errorMessage
        }
        if (res.data) {
            this.store.dispatch(UsersActions.fetchAuthenticatedUserSuccess({authenticatedUser: res.data}))
        }
    }

    refresh(){
      return this.store.dispatch(UsersActions.refreshAuthenticatedUser())
    }

}
