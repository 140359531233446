<ion-header>
  <ion-toolbar>
    <ion-title>Add Member</ion-title>
    <ion-button fill="clear" slot="start" (click)="close()">Back</ion-button>
  </ion-toolbar>
</ion-header>
<ion-content>

  <div sxCol sxWidth="full" sxPadding="medium" sxGap="10px">
    <super-input label="Name" [required]="true" [(value)]="createFamilyMemberDto.name"
                 helpNote="Use the same name as on government ID to avoid issues uploading the IDs."
    ></super-input>
    <super-input label="Phone" *ngIf="isPhoneRequired" [required]="isPhoneRequired" type="phone"
                 [(value)]="createFamilyMemberDto.phoneNumber"
                 [(countryCode)]="createFamilyMemberDto.countryCode"
    ></super-input>
    <super-input label="Email" *ngIf="isEmailRequired" [required]="isEmailRequired" type="email"
                 [(value)]="createFamilyMemberDto.email"></super-input>
    <super-button [action]="addParticipant" [showError]="true">Add</super-button>
  </div>

  <div sxPadding="medium" sxCol sxGap="10px" sxBorderWidth="1px 0 0" sxBorderColor="one" sxMargin="15px 0">
    <h2 sxColor="three" sxMargin="10px 12px" *ngIf="familyMembers?.length > 0">Recently added members</h2>
    <div *ngFor="let member of familyMembers; let i= index">
      <div *ngIf="member.user" sxPadding="medium"
           sxBackground="light" sxRadius="medium"
           sxRow sxAlign="center" sxDistribution="spaceBetween" sxHeight="80px">
        <div sxRow sxGap="10px" sxAlign="center">
          <avatar-round [imageUrl]="member.user?.displayPictureUrl" [title]="member?.user?.name"
                        [size]="30"></avatar-round>
          <div sxCol sxGap="5px">
            <p sxMargin="0">{{member.user?.name}}</p>
            <p sxMargin="0" sxTextSize="small" sxColor="two" *ngIf="member.user.email">{{member.user?.email}}</p>
            <p sxMargin="0" sxTextSize="small" sxColor="two"
               *ngIf="member.user.phoneNumber">{{member.user?.countryCode}} {{member.user?.phoneNumber}}</p>
          </div>

        </div>
        <div sxRow sxAlign="center" sxGap="10px" sxWidth="fit">
          <super-button type="clear" size="small"
                        confirmationTitle="Delete Family Member"
                        sxWidth="60px"
                        [action]="deleteMember.bind(this, i)"
          >
            <ion-icon sxTextSize="26px" name="close-circle-outline"></ion-icon>
          </super-button>
          <p sxWidth="71px" *ngIf="member.status?.toLowerCase() == 'invited'"
             (click)="showInviteMessage(member.user?.name)" sxColor="warning">Invite Pending</p>
          <super-button *ngIf="member.status?.toLowerCase() == 'connected'" (click)="selectMember(member)" type="clear"
                        size="small" borderColor="one">Add
          </super-button>
        </div>


      </div>
    </div>


  </div>

</ion-content>
