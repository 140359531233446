import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImageUtil} from "@qid/core";
import {ID} from "../../../../models/ID";
import {IdService} from "../../services/id.service";
import {SID, SidService} from "../../../sid";

@Component({
  selector: 'id-card',
  templateUrl: './id-card.component.html',
  styleUrls: ['./id-card.component.scss'],
})
export class IdCardComponent implements OnInit {

  @Input() document: ID;
  @Input() account: any;
  @Input() documentId: string;
  @Input() sidId: string = null;
  @Input() sid: SID = null;
  @Input() mandatory: boolean = true;
  @Output() uploadClicked: EventEmitter<any> = new EventEmitter();
  @Output() skipToggle: EventEmitter<any> = new EventEmitter();
  @Output() viewIdModel: EventEmitter<any> = new EventEmitter();


  public isDocumentDeleted: boolean = false;

  constructor(private sidService: SidService,
              private documentService: IdService,
  ) {
  }

  ngOnInit() {
    this.fetchSid();
    if (!this.document && this.documentId) {
      this.fetchDocument();
    }
  }

  async fetchDocument() {
    const res = await this.documentService.readOne(this.documentId)
    if (!res) {
      this.isDocumentDeleted = true;
    }
    if (res) {
      this.document = res;
      this.sid = this.document.sid;
    }
    if (this.account) {
      this.document.account = this.account
    }
  }

  async fetchSid() {

    if (this.sid != null) {
      return
    }
    if (!this.sid && this.document) {
      this.sid = this.document.sid
      return
    }

    if (this.sidId) {
      this.sid = await this.sidService.readOne(this.sidId)
    }

  }

  getStyledIdNumber(number: string) {
    if (number?.length % 4 != 0) {
      return number;
    }
    let joy = number.match(/.{1,4}/g);
    return joy.join(' ');
  }

  getImageUrl(url) {
    return ImageUtil.getImageSource(url)
  }

  emitUpload() {
    if (!this.sid) {
      return
    }
    this.uploadClicked.emit()
  }

  emitViewId() {
    if (!this.document) {
      return;
    }
    this.viewIdModel.emit(this.document);
  }

  onToggle(event: any) {
    if (this.document) {
      this.document.skip = !event.detail.checked
    }
    this.skipToggle.emit(!event.detail.checked)
  }
}
