<div id="id-list" sxBackground="light" sxRadius="medium">

  <div *ngIf="isLoading" sxRow sxAlign="stretch" sxPadding="small">

    <ion-skeleton-text [animated]="true" sxWidth="70px" sxHeight="70px" sxRadius="medium"></ion-skeleton-text>

    <div sxWidth="fill" sxGap="5px" sxCol sxPadding="medium" sxDistribution="center">
      <ion-skeleton-text [animated]="true" sxWidth="130px" sxHeight="16px" sxRadius="small"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true" sxWidth="110px" sxHeight="12px" sxRadius="small"></ion-skeleton-text>
    </div>

  </div>


  <div *ngIf="selectedDocumentAccess && !isLoading" sxRow sxAlign="stretch" sxPadding="small">
    <div sxWidth="70px" sxHeight="70px" sxCenterize="col"
         sxRadius="medium" sxBorderColor="one" sxBorderWidth="1px">
      <img sxMatchParent class="cover-fit" *ngIf="selectedDocumentAccess?.documentData?.scannedImages?.length > 0"
           [src]="ImageUtil.getImageSource(selectedDocumentAccess?.documentData?.scannedImages[0].imageUrl)">

      <img sxWidth="75%" sxHeight="75%" class="contain-fit"
           *ngIf="selectedDocumentAccess?.documentData?.scannedImages?.length < 1"
           [src]="ImageUtil.getImageSource(selectedDocumentAccess?.document?.sid.logoUrl)">
    </div>

    <div sxWidth="fill" sxGap="5px" sxCol sxPadding="medium" sxDistribution="center" (click)="viewId()">
      <div *ngIf="selectedDocumentAccess" sxCol sxGap="5px">
        <p sxMargin="0" sxTextSize="small"
           class="uppercase">{{selectedDocumentAccess?.document?.documentNumberMasked || 'xx xx xxxx'}}</p>
        <p sxMargin="0" sxColor="two" sxTextSize="small">Saved on {{selectedDocumentAccess?.createTime | date}}</p>
      </div>

    </div>

    <div sxRow sxAlign="center" sxPadding="medium" *ngIf="field?.data?.sid?.variants == 0">
      <div sxCircle="40px" sxCenterize sxBorderWidth="1px" sxBorderColor="one"
           (click)="openViewDocumentOptionsPage()">
        <ion-icon name="pencil"></ion-icon>
      </div>
    </div>
  </div>


  <div *ngIf="!selectedDocumentAccess && !isLoading">
    <super-button (click)="createId()" type="outline" borderColor="transparent">
      Upload
    </super-button>
  </div>


</div>
<p class="error-message" *ngIf="field.errorMessage">{{field.errorMessage}}</p>
