import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {ApiModules, ApiOperations, OrganisationUserRoles} from "@qid/core";
import {OrganisationUser} from '../models/organisation-user.model';
import {selectActiveOrganisationUser} from "../+state/organisation-user.selectors";


@Directive({
  standalone: true,
  selector: '[permissionModule]'
})
export class PermissionDirective implements OnInit {

  @Input() permissionModule: ApiModules;
  @Input() permissionOperation: ApiOperations;

  constructor(
    private el: ElementRef,
    private store: Store
  ) {
  }

  ngOnInit() {
    this.init()
  }

  async init() {

    this.store.select(selectActiveOrganisationUser).subscribe((orgUser) => {

      if (orgUser == null || +orgUser.role <= +OrganisationUserRoles.ADMIN) {
        return;
      }

      const permission = this.permissionModule + '_' + this.permissionOperation
      if (orgUser.permissions.indexOf(permission) < 0) {
        this.hideElement();
      }
    })

  }


  hideElement() {
    this.el.nativeElement.remove();
  }

  public static hasPermission(orgUser: OrganisationUser, module: ApiModules, operation: ApiOperations | string) {
    if (+orgUser?.role <= +OrganisationUserRoles.ADMIN) {
      return true
    }
    return orgUser?.permissions?.indexOf(module + "_" + operation) > -1;
  }
}
