import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ViewIdPageRoutingModule} from './view-id-routing.module';
import {ViewIdPage} from './view-id.page';
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";
import {IdComponentsModule} from '../../compenents/id-components.module';
import {FormatPhonePipe} from "../../../../pipes/format-phone.pipe";


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ViewIdPageRoutingModule,
        SexyModule,
        IdComponentsModule,
        SuperModule,
        FormatPhonePipe
    ],
  declarations: [ViewIdPage]
})
export class ViewIdPageModule {
}
