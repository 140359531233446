<ion-header>
  <ion-toolbar>
    <ion-title>
      <p class="qid-text-logo" sxTextSize="24px">qid</p>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div sxPadding="medium" *ngIf="profile">

    <div sxPadding="medium" sxRadius="medium" sxBackground="light"
         sxCol sxAlign="center">

      <div *ngIf="profile.name?.verificationMethod"
           sxRadius="50px" sxBorderWidth="1px" sxBorderColor="one" sxPadding="small medium">
        <p sxMargin="0" sxColor="two" sxTextSize="small">{{profile?.name?.verificationMethod}}</p>
      </div>

      <avatar-round [imageUrl]="AccountProfileUtil.getAttributeByType(profile.defaultAccountAttributes, AccountAttributeType.displayPicture)?.value" ></avatar-round>

      <div sxRow sxAlign="center" sxGap="5px">
        <h1 sxMargin="0" sxTextSize="large">{{profile?.name?.value}}</h1>
        <img sxWidth="25px" sxHeight="25px" src="assets/blue-tick.png">
      </div>

      <p sxTextSize="small" sxColor="two" sxTextAlign="center">{{profile.bio}}</p>

      <div sxRow sxGap="10px">
        <super-button size="v-small" type="outline">Save Contact</super-button>
        <super-button size="v-small">Connect</super-button>
      </div>

    </div>

  </div>

  <div sxPadding="medium">

    <div sxPadding="medium" sxRadius="medium"
         sxBackground="light">
      <h3>Verify more about Manu</h3>
      <p>You can request to collect more verified details from {{profile?.name?.value}}, for example, government IDs</p>
      <super-button size="small">Send Verification Request</super-button>
    </div>

  </div>


</ion-content>
