import {Injectable} from '@angular/core';
import * as ExcelJS from 'exceljs';
import {DatePipe} from '@angular/common';
import {TextUtil} from "@qid/core";
import {Share} from "../model/Share";
import {ExportSharesUtil} from "../utils/export-shares.util";
import {ParticipantRoles} from "../enum/participant-roles.enum";
import {CollectionTemplate} from "../../collection-template";


@Injectable()
export class ExportExcelService {

  constructor(private datePipe: DatePipe) {
  }


  public async exportExcel(
    shares: Share[], selectedTemplate: CollectionTemplate = null
  ) {

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Data');

    sheet.columns = ExportExcelService.getHeaderColumns(
      shares,
      selectedTemplate
    );

    for (const index in shares) {
      const serialNo = Number(index) + 1;
      this.addRowForEachParticipant(serialNo, shares[index], selectedTemplate, sheet)
      sheet.getRow(+index).alignment = {wrapText: true}
    }

    sheet.getRow(1).font = {bold: true};
    sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center'};
    sheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'FFBFBFBF'},
      bgColor: {argb: 'FFBFBFBF'},
    };

    await ExportSharesUtil.downloadFile(workbook);
  }

  private static getHeaderColumns(
    shares: Share[],
    template: CollectionTemplate = null
  ) {
    let columns: any[] = [
      {header: 'Serial', key: 'serial', width: 5},
      {header: 'Date', key: 'date', width: 10},
      {header: 'Time', key: 'time', width: 10},
      {header: 'Name', key: 'senderName', width: 20},
      {header: 'Email', key: 'senderEmail', width: 20},
      {header: 'Phone Number', key: 'senderPhoneNumber', width: 20},
      {header: 'Document', key: 'idName', width: 20},
      {header: 'Purpose', key: 'templateName', width: 20},
    ];

    if (!template || !template.otherInfo) {
      columns.push({
        header: 'Other Details',
        key: 'otherDetails',
        width: 60,
        style: {alignment: {wrapText: true}},
      });

      return columns;
    }

    for (let info of template.otherInfo) {
      let header = info.label ? info.label : info.value;
      columns.push({
        header: header,
        key: TextUtil.removeAllSpaces(header),
        width: 20,
        style: {alignment: {wrapText: true}},
      });
    }

    return columns;
  }

  private getOtherDetails(share: Share) {
    if (!share || !share.otherFields) {
      return '';
    }
    let list = '';
    for (let field of share.otherFields) {
      list += `${field.label}: ${field.value} \n`;
    }
    return list.toString();
  }


  private addRowForEachParticipant(serialNumber: number, share: Share, template: CollectionTemplate, worksheet: ExcelJS.Worksheet) {

    let index = 0;

    for (let participant of share.participants) {

      if (participant.roles.indexOf(ParticipantRoles.organiser) > -1) {
        continue;
      }

      let row: any = {
        serial: serialNumber?.toString() || "",
        templateName: share?.template?.purpose || '',
        senderName: participant?.account?.name || '',
        senderEmail: participant?.account?.email || '',
        senderPhoneNumber: participant?.account?.phoneNumber || '',
        date: share?.createTime ? this.datePipe.transform(share.createTime, 'mediumDate') : "",
        time: share?.createTime ? this.datePipe.transform(share.createTime, 'shortTime') : "",
        idName: participant.documentsAccess?.map(access => `${access?.document?.sid?.name} : ${access?.document?.documentNumberMasked || ''}`).join('\r\n'),
      };

      if (index > 0) {
        row.serial = '';
        row.date = '';
        row.time = '';
        row.templateName = ''
      }

      if (!template) {
        row.otherDetails = this.getOtherDetails(share);
      } else {
        for (let info of share.otherFields) {
          let header = info.label ? info.label : info.value;
          row[TextUtil.removeAllSpaces(header)] = info?.value;
        }
      }

      worksheet.addRow(row)
      index++;
    }

  }


}
