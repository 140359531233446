import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({selector: '[sxTextEllipse]'})
export class TextEllipseDirective implements OnInit {

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.style.overflow = 'hidden'
    this.el.nativeElement.style.whiteSpace = 'nowrap'
    this.el.nativeElement.style.textOverflow = 'ellipsis'
  }


}
