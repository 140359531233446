import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {OrganisationRelationshipService} from './services/organisation-relationship.service';
import {StoreModule} from '@ngrx/store';
import {OrganisationRelationshipFacade, OrgRelationshipStore} from "./services/organisation-relationship.facade";
import {EffectsModule} from '@ngrx/effects';
import {OrganisationRelationshipEffects} from "./services/organisation-relationship.effects";
import {
  EditOrganisationRelationshipPageModule
} from "./pages/edit-organisation-relationship/edit-organisation-relationship-page.module";
import {ListChildOrgsPageModule} from "./pages/list-child-orgs/list-child-orgs.module";


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
      EditOrganisationRelationshipPageModule,
      ListChildOrgsPageModule,
    StoreModule.forFeature(
      OrgRelationshipStore.featureKey,
      OrgRelationshipStore.reducer.recordReducer
    ),
    EffectsModule.forFeature([OrganisationRelationshipEffects]),
  ],
  providers: [OrganisationRelationshipService,
    OrganisationRelationshipFacade
  ],
})
export class OrganisationRelationshipModule {
}
