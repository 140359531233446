import {Component, OnInit} from '@angular/core';
import {User} from "../../model/User";
import {UserService} from "../../services/user.service";
import {ModalController} from "@ionic/angular";
import {DisplayService, ValidationUtil} from "@qid/core";
import {OtpService} from "../../services/otp.service";
import {OtpVerifier} from "../../model/OtpVerifier";
import {OtpChannel} from "../../enum/OtpChannel";
import {Store} from "@ngrx/store";
import {firstValueFrom} from "rxjs";
import {UsersActions, UsersSelectors} from "../../../index";

@Component({
  selector: 'app-email-update',
  templateUrl: './email-update.component.html',
  styleUrls: ['./email-update.component.scss'],
})
export class EmailUpdateComponent implements OnInit {

  public user: User;

  public emailVerifier: OtpVerifier = new OtpVerifier(OtpChannel.email);


  constructor(
    private userService: UserService,
    private modalController: ModalController,
    private displayService: DisplayService,
    private otpService: OtpService,
    private store: Store
  ) {
  }

  ngOnInit() {

  }

  async ionViewDidEnter() {
    this.user = await firstValueFrom(this.store.select(UsersSelectors.selectAuthenticatedUser))
  }

  close() {
    this.modalController.dismiss();
  }

  public sendEmailOtp = async () => {

    if (!ValidationUtil.isEmailValid(this.emailVerifier.receiverId)) {
      throw "Enter a Valid Email Address"
    }

    const emailCheckResponse = await this.userService.checkEmail(this.emailVerifier.receiverId)

    if (emailCheckResponse && emailCheckResponse._id) {
      throw "Email already associated with another account";
    }

    await this.otpService.sendAndVerify(this.emailVerifier,
      async (otpVerifier, otpInput) => {
        otpVerifier.otp = otpInput;
        const updateResponse = await this.userService.updateUserEmail(otpVerifier);

        if (updateResponse.errorMessage) {
          throw new Error(updateResponse.errorMessage);
        }

        if (updateResponse.data) {
          this.modalController.dismiss()
          this.store.dispatch(UsersActions.fetchAuthenticatedUserSuccess({authenticatedUser: updateResponse.data}))
          this.displayService.toast({
            message: "Email Updated Successfully",
            color: "success"
          });
          return true
        }
      }
    );

  };

}
