import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {AbsoluteValue} from "../util/AbsoluteValue";

@Directive({selector: '[sxTextWrap]'})
export class TextWrapDirective implements OnInit {
  @Input() sxTextWrap: 'wrap' | 'nowrap' | 'pre-wrap' | string;


  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    try {
      this.el.nativeElement.style.whiteSpace = this.sxTextWrap
    } catch (e) {
      console.error("SX WRAP PROPERTY", e)
    }
  }


}
