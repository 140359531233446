import {inject, Injectable} from "@angular/core";
import {Store} from "@ngrx/store";


@Injectable()
export class OrganisationDataRepositoryService {

  //Boilerplate Starts
  private readonly store = inject(Store);








}
