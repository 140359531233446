<div *ngIf="activeSlide == slides.instructions" sxPadding="medium"
     sxWidth="full" sxHeight="full" sxCol sxGap="10px" sxDistribution="spaceBetween" sxAlign="center">

  <div class="movement-instructions-wrapper">
    <img id="mvmntRing" src="assets/mvmnt-ring.png">
    <div class="instruction-wrapper">
      <img src="assets/face-icon.png">
    </div>
  </div>

  <h1>Take a Selfie</h1>

  <super-button sxMargin="0 0 20px" sxWidth="full" iconEnd="chevron-forward" (click)="onStartScanClicked()">Continue
  </super-button>

</div>

<div [hidden]="activeSlide != slides.scan" sxPadding="medium">

  <div class="video-container">
    <div class="movement-indicator" *ngIf="isFaceDetected" [class.movementCaptured]="isCaptured">
      <div class="part" [class.partTopActive]="isTopActive"></div>
      <div class="part" [class.partRightActive]="isRightActive"></div>
      <div class="part" [class.partLeftActive]="isLeftActive"></div>
      <div class="part" [class.partBottomActive]="isBottomActive"></div>
    </div>

    <div class="preview-wrapper" [class.previewRound]="isFaceDetected"
         [class.previewWobble]="!isFaceDetected">

      <video #videoElement class="input_video" autoplay playsinline></video>
      <canvas [hidden]="!isFaceDetected" #canvasElement></canvas>

      <div class="scanner-effect" *ngIf="!isFaceDetected"></div>

      <div class="message-box" *ngIf="distanceMessage && isFaceDetected" sxCenterize="col" sxPadding="medium">
        <p>{{distanceMessage}}</p>
      </div>

      <div class="countdown-wrapper" *ngIf="isFaceRotationScanned" sxCenterize>
        <h1>{{countDownNumber}}</h1>
        <div *ngIf="isCaptured" [class.countdownCaptured]="isCaptured && !cameraCapturedImageData" sxCenterize>
          <ion-icon sxTextSize="80px" sxColor="light" name="checkmark-circle"></ion-icon>
        </div>
      </div>
      <div class="captured-preview" sxCircle="320px" sxBorderWidth="15px" sxBorderColor="success"
           sxBackground="success" [hidden]="!cameraCapturedImageData">
        <canvas #cameraResultCanvas></canvas>
      </div>
    </div>

  </div>


  <div sxWidth="fill" sxCenterize *ngIf="!cameraCapturedImageData" sxPadding="medium">
    <p sxTextSize="24px" sxTextWeight="bold" sxColor="three" sxTextAlign="center">{{instruction}}</p>
  </div>

  <div *ngIf="cameraCapturedImageData" sxWidth="full" sxCol sxGap="10px" sxMargin="15px 0">
    <div sxCenterize="row" sxGap="10px" sxMargin="0 0 15px">
      <h1 sxTextAlign="center" sxColor="success" sxMargin="0" sxTextSize="large">Face Captured</h1>
    </div>

    <super-button iconEnd="chevron-forward" (click)="onPhotoConfirmed(true)">Confirm</super-button>
    <super-button type="clear" (click)="onRetakeClicked()">Re-take</super-button>
  </div>


</div>

<div *ngIf="activeSlide == slides.plainCapture" sxWidth="fill" sxCol sxAlign="center" sxGap="15px" sxPadding="20px">

  <video [hidden]="cameraCapturedImageData != null" class="plain-video" #videoElementPlain autoplay playsinline></video>
  <img *ngIf="cameraCapturedImageData" class="plain-preview" [src]="cameraCapturedImageData">
  <canvas [hidden]="true" #canvasElementPlan></canvas>
  <super-button *ngIf="!cameraCapturedImageData" sxWidth="full" [action]="onPlainCapturesClicked">Capture</super-button>
  <div *ngIf="cameraCapturedImageData" sxWidth="full" sxHeight="60px" sxRadius="medium" sxBackground="one" sxCenterize="col">
    <ion-spinner></ion-spinner>
  </div>

</div>

