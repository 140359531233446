import {Injectable} from "@angular/core";
import {AlertController, LoadingController, ToastController, ToastOptions} from "@ionic/angular";

@Injectable({
  providedIn: "root"
})
export class DisplayService {

  constructor(
    private alertController: AlertController,
    private toastController: ToastController,
    private loadingCtrl: LoadingController
  ) {
  }

  async alert(header: string, subHeader: string = null) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      buttons: [
        {
          text: "Okay"
        }
      ]
    });
    return alert.present();
  }

  public async showLoading() {
    const loading = await this.loadingCtrl.create({
      message: 'Loading...',
      spinner: 'bubbles',
    });
    loading.present();
  }

  public async hideLoading() {
    await this.loadingCtrl.dismiss();
  }

  async deleteAlert(callback, title = "Confirm", content = "Are you sure?") {
    const alert = await this.alertController.create({
      header: title,
      subHeader: content,
      buttons: [
        {
          text: "Cancel"
        },
        {
          text: "Delete",
          role: "destructive",
          handler: async () => {
            await callback();
          }
        }
      ]
    });
    return alert.present();
  }


  async toast(
    opts?: ToastOptions
  ) {
    if (!opts.duration) {
      opts.duration = 1200
    }
    const toast = await this.toastController.create(opts);
    return toast.present();
  }
}
