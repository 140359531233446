import {Injectable} from "@angular/core";
import {AccountProfile} from "../model/account-profile.model";
import {ImageUtil} from "@qid/core";
import {AccountAttribute} from "../../account-attribute/model/account-attribute.model";
import { RestService } from "../../../services/rest.service";


@Injectable()
export class AccountProfileApi {

  private static readonly ApiEndpoint = "account-profile/"
  private static readonly ApiEndpointOpen = "account-profile-open/"

  constructor(private restService: RestService) {
  }

  public create() {
    return this.restService.send({
      url: AccountProfileApi.ApiEndpoint,
      method: "POST"
    })
  }

  public get() {
    return this.restService.send<AccountProfile>({
      url: AccountProfileApi.ApiEndpoint,
      method: "GET"
    })
  }

  public updateDefaultAttribute(attribute_id: string) {
    return this.restService.send<AccountProfile>({
      url: AccountProfileApi.ApiEndpoint + 'default-attribute',
      method: "PUT",
      query: {
        attribute_id: attribute_id
      }
    })
  }

  getOpenProfileByUsername(username: string) {
    return this.restService.send<AccountProfile>({
      url: AccountProfileApi.ApiEndpointOpen + username,
      method: "GET"
    })
  }

  async updateDisplayPicture(file: string) {

    const formData = await ImageUtil.getCompressedFormData([file]);

    return this.restService.sendFiles<AccountAttribute>({
      url: AccountProfileApi.ApiEndpoint + 'display-picture',
      method: "POST",
      body: formData
    })

  }
}
