<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>Branch Account</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>


  <div sxPadding="medium" sxCol sxGap="10px">

    <div sxPadding="medium" sxRow sxAlign="center" sxGap="10px" sxRadius="medium" sxBorderColor="one"
         sxBorderWidth="1px">
      <avatar-round [size]="50" title="{{orgRelationship?.childOrg?.name}}" [imageUrl]="orgRelationship?.childOrg?.displayPictureUrl"></avatar-round>
      <p>{{orgRelationship?.childOrg?.name}}</p>
    </div>


    <div sxRow sxBackground="light" sxPadding="medium" sxRadius="medium" sxDistribution="spaceBetween"
         *ngIf="orgRelationship?.childOrg?._id as accountId">
      <p>Account ID</p>
      <p sxMargin="0" sxCursor="text" sxRow sxAlign="center" sxColor="two">{{accountId}}
      </p>
    </div>


    <div sxBackground="light" sxRadius="medium" sxPadding="10px 0">
      <ion-item>
        <ion-toggle [(ngModel)]="orgRelationship.shareAllTemplates">Share All Forms</ion-toggle>
      </ion-item>
    </div>


    <super-button [action]="showAlert" color="danger" background="light">Remove Branch</super-button>

  </div>


</ion-content>
