<ion-menu contentId="main-content" side="end">
    <ion-content class="ion-padding">

        <div sxPadding="0 small" sxMargin="0 0 20px" (click)="closeMenu()" sxCursor="pointer">
            <ion-icon sxTextSize="26px" sxColor="three" name="arrow-back-outline"></ion-icon>
        </div>

        <div sxCol sxGap="5px" sxPadding="medium" *ngIf="activeAccount$ | async as account">
            <avatar-round [size]="70" [imageUrl]="account.displayPictureUrl"></avatar-round>
            <display-name></display-name>
            <p sxTextSize="small" sxMargin="0" sxColor="two">{{account.email}}</p>
            <p sxTextSize="small" sxMargin="0" sxColor="two">{{account.phoneNumber | formatPhone}}</p>
        </div>

        <ion-list sxRadius="small" sxMargin="10px 0">
            <ion-item>
                <ion-icon sxTextSize="18px" slot="start" name="person-outline"></ion-icon>
                Edit Profile
            </ion-item>
            <ion-item>
                <ion-icon sxTextSize="18px" slot="start" name="id-card-outline"></ion-icon>
                Manage Documents</ion-item>
        </ion-list>
        <ion-list sxRadius="small" sxMargin="10px 0">
            <ion-item>
                <ion-icon sxTextSize="18px" slot="start" name="help-circle-outline"></ion-icon>
                Help</ion-item>
            <ion-item (click)="onLogoutClicked()">
                <ion-icon sxTextSize="18px" slot="start" name="log-out-outline"></ion-icon>
                Logout</ion-item>
        </ion-list>

    </ion-content>
</ion-menu>
<div class="ion-page" id="main-content">
    <ion-header>
        <ion-toolbar>
            <div
                    sxWidth="full"
                    sxRow
                    sxPadding="10px 12px"
                    sxDistribution="start"
                    sxGap="10px"
                    sxAlign="center"
                    *ngIf="scanner">
                <super-button
                        padding="0" type="clear" size="small"
                        *ngIf="inAppQrCode" fill="clear" (click)="closeModal()">
                    <ion-icon sxTextSize="30px" name="arrow-back-outline"></ion-icon>
                </super-button>
                <div
                        sxRow
                        sxAlign="center"
                        sxDistribution="start"
                        sxWidth="fill"
                        sxGap="10px">
                    <avatar-round
                            [autoBase]="true"
                            [size]="40"
                            [title]="scanner?.org?.name"
                            [imageUrl]="scanner?.org?.displayPictureUrl"
                    ></avatar-round>
                    <p sxMargin="0" sxTextWeight="bold">{{ scanner?.org?.name }}</p>
                </div>
               <ion-buttons *ngIf="!inAppQrCode && template && (activeAccount$ | async)">
                    <ion-menu-button color="dark"></ion-menu-button>
                </ion-buttons>
            </div>
        </ion-toolbar>
    </ion-header>
    <ion-content #content>

        <verified-signup #verifiedSignUp [hidden]="activeSlide != slides.verifiedSignUp"></verified-signup>

        <gateway-welcome
            #gatewayWelcomeComponent
            [hidden]="activeSlide != slides.welcome"
            [collectionTemplate]="template"
            [scanner]="scanner"
        ></gateway-welcome>

        <gateway-authentication
            #gatewayAuthenticationComponent
            [template]="template"
            [hidden]="activeSlide != slides.authentication">
        </gateway-authentication>

        <gateway-continue-previous
            #gatewayContinuePreviousComponent
            [hidden]="activeSlide != slides.previousShare"
            [template]="template"
            [scanner]="scanner"
        >
        </gateway-continue-previous>

        <gateway-id-submission
            #gatewayIdSubmissionComponent
            [hidden]="activeSlide != slides.idSubmission"
            [organisation]="scanner?.org"
            [template]="template" [share]="share"
        ></gateway-id-submission>

        <gateway-selfie-verification
            #gatewaySelfieVerificationComponent [hidden]="activeSlide != slides.selfie"
            [share]="share">
        </gateway-selfie-verification>

        <gateway-form
            #gatewayFormComponent
            [hidden]="activeSlide!=slides.form"
            [template]="template" [share]="share"
        ></gateway-form>

        <gateway-policies
            #gatewayPoliciesComponent
            [hidden]="activeSlide!=slides.policy"
            [template]="template"
            [share]="share"
        ></gateway-policies>

        <gateway-signature
            #gatewaySignatureComponent
            [hidden]="activeSlide!=slides.signature"
            [template]="template" [share]="share"
        ></gateway-signature>

        <gateway-successful
            #gatewaySuccessfulComponent
            [hidden]="activeSlide!=slides.successful"
            [template]="template" [share]="share"
        ></gateway-successful>

        <div *ngIf="activeSlide === slides.loading" sxMatchParent="" sxCenterize="col">
            <ion-spinner *ngIf="!urlInvalid"></ion-spinner>
            <div *ngIf="urlInvalid">Looks like you've scanned the invalid QR</div>
        </div>

        <div id="verifier"></div>
    </ion-content>

</div>
