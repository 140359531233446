import {createAction, props} from '@ngrx/store';
import {OrganisationUser} from "../models/organisation-user.model";
import {AccountType, AccountWiseRecords} from "@qid/core";
import {Organisation} from '../../organisation/lib/model/Organisation';

const FEATURE_KEY = 'Organisation User'
const ENTITY_MODEL: OrganisationUser = null;

export const addAccount = createAction(
  '[Organisation User/Data Repository] Add Account for Organisation User',
  props<{ accountBasedEntity: AccountWiseRecords<OrganisationUser> }>()
)

export const fetchUserOrganisations = createAction(
  '[Tabs Page] Fetch User Organisation'
)

export const fetchUserOrganisationsSuccess = createAction(
  '[App Service] Load User Organisation Success',
  props<{
    organisations: OrganisationUser[]
  }>()
)

export const switchUserOrganisation = createAction(
  '[App/API] Switch Organisation Selected',
  props<{
    organisation: Organisation
  }>()
);
export const switchUserOrganisationSuccess = createAction(
  '[App/API] Switch Organisation Success',
);

export const loadOrganisationUsersForAccount = createAction(
  '[Organisation User/Data Repository] Load Organisation Users for Account',
  props<{ account_id: string, accountType: AccountType }>()
);


export const loadOrganisationUsersForAccountSuccess = createAction(
  '[Organisation User/Data Repository] Load Organisation Users for Account Success',
  props<{ organisationUsers: OrganisationUser[], account_id: string }>()
);

export const addRecord = createAction(
  `[${FEATURE_KEY}] Add Record`,
  props<{ record: typeof ENTITY_MODEL, account_id: string }>()
);

export const updateRecord = createAction(
  `[${FEATURE_KEY}] Update Record`,
  props<{ record_id: string, updatedRecord: typeof ENTITY_MODEL, account_id: string }>()
);

export const removeRecord = createAction(
  `[${FEATURE_KEY}] Remove Record`,
  props<{ record_id: string, account_id: string }>()
);


export const updateActiveOrganisationUser = createAction(
  `[${FEATURE_KEY}] Update Active Organisation User`,
  props<{ updatedRecord: typeof ENTITY_MODEL }>()
);
