import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ListPlanPurchasePageRoutingModule } from './list-plan-purchase-routing.module';
import { ListPlanPurchasePage } from './list-plan-purchase.page';
import { SexyModule } from '@qid/sexy';
import { SuperModule } from '@qid/super';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ListPlanPurchasePageRoutingModule,
    SexyModule,
    SuperModule
  ],
  declarations: [ListPlanPurchasePage]
})
export class ListPlanPurchasePageModule {}
