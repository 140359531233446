import {createFeatureSelector, createSelector} from '@ngrx/store';
import {accountAdapter, DOCUMENTS_FEATURE_KEY, DocumentsState, recordAdapter,} from './documents.reducer';
import { AuthSelectors } from '../../authentication';

// Lookup the 'Documents' feature state managed by NgRx
export const selectDocumentsState = createFeatureSelector<DocumentsState>(
    DOCUMENTS_FEATURE_KEY
);


const {selectAll, selectEntities} = accountAdapter.getSelectors();
const recordsSelector = recordAdapter.getSelectors();

export const selectAllDocuments = createSelector(
    selectDocumentsState,
    (state: DocumentsState) => selectAll(state)
);

export const selectDocumentsEntities = createSelector(
    selectDocumentsState,
    (state: DocumentsState) => selectEntities(state)
);

export const selectSelectedId = createSelector(
    selectDocumentsState,
    (state: DocumentsState) => state.selectedId
);

export const selectEntity = createSelector(
    selectDocumentsEntities,
    selectSelectedId,
    (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);


export const selectActiveAccount = createSelector(
    selectDocumentsEntities,
    AuthSelectors.selectActiveAccountId,
    (entities, activeAccountId) => (activeAccountId ? entities[activeAccountId] : undefined)
);

export const selectActiveAccountRecords = createSelector(
    selectActiveAccount,
    (selectedAccount) => {
        return (selectedAccount ? recordsSelector.selectAll(selectedAccount.records) : undefined)
    }
);

export const selectActiveAccountIsLoading = createSelector(
  selectActiveAccount,
  (selectedAccount) => {
    return (selectedAccount ? selectedAccount.isLoading : undefined)
  }
);
