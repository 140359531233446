import {Component, Inject, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {Clipboard} from '@capacitor/clipboard';
import {SocialSharing} from "@awesome-cordova-plugins/social-sharing/ngx";
import {Environment, ENVIRONMENT} from "@qid/env";
import {DisplayService} from "@qid/core";


@Component({
    selector: 'app-share-template',
    templateUrl: './share-template.component.html',
    styleUrls: ['./share-template.component.scss'],
})
export class ShareTemplateComponent implements OnInit {


    @Input("number") number;
    @Input("template") template: any;
    baseUrl: string = this.environment.collectUrl;


    constructor(
        private modelController: ModalController,
        private displayService: DisplayService,
        private socialSharing: SocialSharing,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
    }

    ngOnInit() {
    }

    close() {
        this.modelController.dismiss()

    }

    async sendWhatsappMessage() {

    }


    async copyToClipboard() {
        await Clipboard.write({
            string: `Welcome to qid !!, Share Your Id with ${this.template.org.name}, with the help of the following link \n` + `${this.baseUrl}${this.template._id}`
        });

        await this.displayService.toast({message: "Copied To Clip-Board"})
        this.close()
    }

    public getSMSUrl() {
        return `sms:+91${this.number}?body=Welcome%20to%20qid%20%21%21%2C%20Share%20Your%20Id%20with%20${this.template.org.name}%2C%20with%20the%20help%20of%20the%20following%20link%20%0A${this.baseUrl}${this.template._id}`
    }


    async getWhatsAppURL() {
        await this.socialSharing.shareViaWhatsAppToReceiver(
            this.number, `Welcome to qid !!, Share Your Id with ${this.template.org.name}, with the help of the following link \n` + `${this.baseUrl}${this.template._id}`, null
        )
    }
}
