import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AUTH_FEATURE_KEY, AuthState} from './auth.reducer';


// Lookup the 'Auth' feature state managed by NgRx
export const selectAuthState =
  createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const selectAuthToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.authToken
);

export const selectPreviousAuthTokenFetched = createSelector(
  selectAuthState,
  (state: AuthState) => state.previousAuthTokenChecked
);

export const selectActiveAccount = createSelector(
  selectAuthState,
  (state: AuthState) => state.activeAccount
);

export const selectActiveAccountId = createSelector(
  selectAuthState,
  (state: AuthState) => state.activeAccount?._id
);

export const selectActiveAccountType = createSelector(
  selectAuthState,
  (state: AuthState) => state.activeAccountType
);



