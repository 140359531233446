export class AccountUtil{

  static getAccountSnapshot(account: any) {
    return {
      _id : account?._id,
      name: account?.name,
      email: account?.email,
      phoneNumber: account?.phoneNumber,
      displayPictureUrl: account?.displayPictureUrl,
    };
  }

}
