import {Component, Input, OnInit} from '@angular/core';
import {AccountAttribute} from "../../model/account-attribute.model";

@Component({
  selector: 'account-attribute-view',
  templateUrl: './account-attribute-view.component.html',
  styleUrls: ['./account-attribute-view.component.scss'],
})
export class AccountAttributeViewComponent implements OnInit {

  @Input() label : string = null;
  @Input() attribute : AccountAttribute = null;

  constructor() { }

  ngOnInit() {}

}
