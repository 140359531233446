import {Injectable} from "@angular/core";
import {ID} from "../models/ID";
import {CreateIdDto} from "../dto/create-id.dto";
import {UpdateDocumentDto} from "../dto/update-document.dto";
import {RestService} from "../../../services/rest.service";
import {RestResponse} from "../../../interface/RestInterface";

@Injectable()
export class IdService {

  private static readonly ApiEndpoint = "id/";
  private static readonly nodeSubmitStep = IdService.ApiEndpoint + "id-verification";

  constructor(
    private restService: RestService
  ) {
  }

  public async readOne(id: string): Promise<ID> {
    return (
      await this.restService.send<ID>({
        url: IdService.ApiEndpoint + id,
        method: "GET"
      })
    ).data;
  }

  public async readAll(): Promise<ID[]> {
    return (
      await this.restService.send<ID[]>({
        url: IdService.ApiEndpoint,
        method: "GET"
      })
    ).data;
  }

  public async create(createIdDto: CreateIdDto) {
    return this.restService.send<ID>({
      url: IdService.ApiEndpoint,
      body: createIdDto,
      method: "POST"
    });
  }

  public async update(document_id: string, dto: UpdateDocumentDto): Promise<RestResponse<ID>> {
    return this.restService.send<ID>({
      url: IdService.ApiEndpoint + document_id,
      body: dto,
      method: "PUT",
      isFile: true
    });
  }


  async findAllByOwnerAndCreatedBy(account_id: string) {
    return (
      await this.restService.send<ID[]>({
        url: IdService.ApiEndpoint + "family-member",
        method: "GET",
        query: {
          account_id: account_id
        }
      })
    ).data;
  }


  async encryptPlainId(idId: string) {
    return (
      await this.restService.send<ID>({
        url: IdService.ApiEndpoint + "encrypt-plain-id",
        method: "POST",
        query: {
          idId: idId
        }
      })
    ).data;
  }

}
