import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, from, map, of, switchMap} from 'rxjs';
import * as OrganisationUserActions from './organisation-user.actions';
import {OrganisationUserService} from '../services/organisation-user.service';
import {OrganisationUserDataRepository} from './organisation-user-data-repository.service';
import { AuthenticationService } from '../../authentication';
import {UserService, UsersSelectors} from '../../user';

@Injectable()
export class OrganisationUserEffects {
  private actions$ = inject(Actions);
  private authenticationService = inject(AuthenticationService)
  private organisationUsersApiService = inject(OrganisationUserService)
  private organisationUsersDataRepository = inject(OrganisationUserDataRepository)
  private userService = inject(UserService)

  fetchUserOrganisations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUserActions.fetchUserOrganisations),
      exhaustMap(() =>
        from(this.organisationUsersDataRepository.getAuthenticatedUserOrganisations()).pipe(
          map((orgs) => {
            this.organisationUsersDataRepository.onUserOrganisationFetched();
            return OrganisationUserActions.fetchUserOrganisationsSuccess({organisations: orgs})
          })
        )
      ),
      catchError((error) => {
        return of(OrganisationUserActions.fetchUserOrganisationsSuccess({organisations: []}));
      })
    )
  );

  switchUserOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUserActions.switchUserOrganisation),
      switchMap((props) => {
        this.userService.setUserDefaultOrganisation(props.organisation ? props.organisation._id : null);
        this.authenticationService.setActiveEntity(props.organisation, UsersSelectors.selectAuthenticatedUser)
        return of(OrganisationUserActions.switchUserOrganisationSuccess())
      })
    )
  )

  addAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUserActions.addAccount),
      exhaustMap((props) =>
        of(OrganisationUserActions.loadOrganisationUsersForAccount({
          account_id: props.accountBasedEntity.account_id,
          accountType: props.accountBasedEntity.accountType
        }))
      )
    )
  );

  loadSharesForAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUserActions.loadOrganisationUsersForAccount),
      exhaustMap((props) => {
          return from(this.organisationUsersApiService.readAll()).pipe(
            map(response => {
              const orgUsers = response?.data ? response.data : []
              return OrganisationUserActions.loadOrganisationUsersForAccountSuccess({
                organisationUsers: orgUsers,
                account_id: props.account_id
              })
            })
          )
        }
      )
    )
  );

}
