import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidService} from './services/sid.service';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromSid from './+state/sid.reducer';
import {SidEffects} from './+state/sid.effects';
import {SidRepository} from './+state/sid-repository.service';
import {SidMasterApiService} from "./services/sid-master-api.service";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSid.SID_FEATURE_KEY, fromSid.sidReducer),
    EffectsModule.forFeature([SidEffects]),
  ],
  providers: [SidService, SidMasterApiService, SidRepository],
})
export class SidModule {
}
