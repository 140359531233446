<div sxCol sxGap="10px">
    <div
            sxBackground="light"
            sxPadding="medium"
            sxCol
            sxRadius="medium"
            sxPosition="relative"
    >
        <div sxRow sxAlign="center" sxDistribution="spaceBetween" sxWidth="full">
            <h2 sxMargin="0">Signature</h2>
            <super-button
                    (click)="resetSign()"
                    size="small"
                    type="outline"
                    iconStart="refresh-outline"
            >Redraw
            </super-button>
        </div>

        <div sxCenterize="col" sxHeight="200px" *ngIf="fetchingSignature">
            <ion-spinner></ion-spinner>
        </div>

        <div sxCenterize="col" sxHeight="200px" *ngIf="!fetchingSignature">
            <img
                    sxHeight="full"
                    *ngIf="signature?._id"
                    src="{{ getImage(signature.signatureImageUrl) }}"
            />
            <div sxPosition="relative" sxCenterize="col" *ngIf="!signature?._id">
                <p
                        id="signatureLabel"
                        sxColor="two"
                        sxWidth="full"
                        sxTextAlign="center"
                >
                    Draw your signature here
                </p>
                <signature-pad sxHeight="full" #signaturePad></signature-pad>
            </div>
        </div>

        <div
                sxBorderColor="two"
                sxBorderWidth="1px 0 0"
                sxRow
                sxDistribution="end"
                sxAlign="center"
                sxWidth="full">
            <p sxWidth="fill">
                I <span *ngIf="signatoryName" sxTextWeight="bold">{{signatoryName}},&nbsp;</span>confirm that the
                Government
                IDs and information provided are true and
                accurate to the best of my knowledge
            </p>
        </div>
    </div>

    <super-button
            iconEnd="chevron-forward"
            [showError]="true"
            [action]="checkSign"
    >Submit
    </super-button
    >
</div>
