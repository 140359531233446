import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {CollectionTemplateAgreement} from "../../models/CollectionTemplateAgreement";

@Component({
  selector: "app-agreement-view",
  templateUrl: "./agreement-view.component.html",
  styleUrls: ["./agreement-view.component.scss"]
})
export class AgreementViewComponent implements OnInit {

  @Input() agreement: CollectionTemplateAgreement;
  @Input() viewOnly: boolean = false;
  @Output() agreementChange = new EventEmitter<CollectionTemplateAgreement>();
  public baseIconUrl = 'assets/'

  constructor() {
  }

  ngOnInit() {

  }

}
