<ion-header *ngIf="pageMode">
  <ion-toolbar>
    <ion-button slot="start" fill="clear" (click)="close()">Cancel</ion-button>
    <ion-title>Form Fields</ion-title>
    <ion-button slot="end" fill="clear" (click)="save()">Save</ion-button>
  </ion-toolbar>
</ion-header>
<ion-content>


  <div sxPadding="medium">

    <ion-reorder-group
      sxWidth="full"
      [disabled]="false"
      (ionItemReorder)="handleReorder($any($event))">

      <div *ngFor="let field of otherInfo; let i = index" sxWidth="full" class="field-wrapper"
      (click)="selectedFieldIndex = i" [class.field-active]="selectedFieldIndex == i" sxMargin="0 0 10px 0">

        <div *ngIf="!field.sectionBreak">
          <div sxPadding="4px">
            <input sxBorderWidth="0" sxWidth="300px" sxHeight="20px" sxBackground="transparent" [(ngModel)]="field.label"
                   placeholder="Label" sxRadius="2px">

          </div>

          <div sxRow sxWidth="full" sxGap="10px">
            <input sxWidth="fill" sxPadding="medium" sxBorderWidth="1px" sxBorderColor="one"
                   sxBackground="light" sxRadius="10px" sxColor="two" [(ngModel)]="field.placeholder" placeholder="Placeholder">
            <div sxRow sxGap="5px" sxAlign="center">
              <ion-icon sxCursor="pointer" (click)="removeOtherInfo(field, $event)" sxColor="danger" sxTextSize="30px"
                        name="close-circle-outline"></ion-icon>
              <button class="add-element-button" id="add-element-button-{{i}}" (click)="onAddElementClicked($event)">
                <ion-icon name="add-outline"></ion-icon>
              </button>
              <ion-reorder></ion-reorder>
            </div>
          </div>

          <div *ngIf="selectedFieldIndex == i" sxBorderWidth="1px 0 0" sxBorderColor="one" sxMargin="15px 0 0">
            <div sxRow sxGap="10px" sxPadding="medium 0">
              <ion-item
                sxWidth="fill"
                sxWidthMobile="full"
                sxBorderColor="one"
                sxBorderWidth="1px"
                sxRadius="small">
                <ion-select label-placement="stacked" label="Field Type" [(ngModel)]="field.type">
                  <ion-select-option
                    *ngFor="let type of inputTypes"
                    [value]="type"
                  >{{ type }}</ion-select-option
                  >
                </ion-select>
              </ion-item>
              <ion-item
                sxWidth="fill"
                sxWidthMobile="full"
                sxBorderColor="one"
                sxBorderWidth="1px"
                sxRadius="small">
                <ion-input label-placement="stacked" label="Field ID" [(ngModel)]="field.name"></ion-input>
              </ion-item>
              <ion-item
                sxWidth="fill"
                sxWidthMobile="full"
                sxBorderColor="one"
                sxBorderWidth="1px"
                sxRadius="small">
                <ion-checkbox [(ngModel)]="field.isMandatory">Mandatory</ion-checkbox>
              </ion-item>
            </div>

            <ion-item
              *ngIf="field.type === 'dropdown'"
              sxBorderWidth="1px"
              sxRadius="small"
              sxBorderColor="one">
              <ion-textarea
                label-placement="stacked"
                label="Dropdown Options"
                placeholder="Comma Separated"
                [(ngModel)]="field.options"></ion-textarea>
            </ion-item>

            <ion-item
              *ngIf="template?.allowGroup"
              sxWidth="fill"
              sxWidthMobile="full"
              sxBorderColor="one"
              sxBorderWidth="1px"
              sxRadius="small">
              <ion-checkbox [(ngModel)]="field.isForEachParticipant">Repeat for all Participants</ion-checkbox>
            </ion-item>

          </div>
        </div>

        <div *ngIf="field.sectionBreak" sxRow sxDistribution="spaceBetween" sxBorderColor="one" sxBorderWidth="1px 0 0" sxPadding="22px 0 0" >
          <div sxCol sxPadding="0 small">
            <input sxMargin="0 0 5px 0" sxColor="three" sxBorderWidth="0" sxBackground="transparent" sxTextSize="large" sxTextWeight="bold" [(ngModel)]="field.sectionBreak.title" placeholder="Section Title">
            <input sxColor="two" sxBorderWidth="0" sxMaxWidth="full" sxBackground="transparent" sxTextSize="small" [(ngModel)]="field.sectionBreak.description" placeholder="Description">
          </div>
          <div sxRow sxGap="5px" sxAlign="center">
            <ion-icon sxCursor="pointer" (click)="removeOtherInfo(field ,$event)" sxColor="danger" sxTextSize="30px"
                      name="close-circle-outline"></ion-icon>
            <button class="add-element-button" id="add-element-button-{{i}}" (click)="onAddElementClicked($event)">
              <ion-icon name="add-outline"></ion-icon>
            </button>
            <ion-reorder></ion-reorder>
          </div>
        </div>

        <ion-popover mode="ios" trigger="add-element-button-{{i}}" [showBackdrop]="false" [dismissOnSelect]="true">
          <ng-template>
            <ion-list>
              <ion-item button="" (click)="addNewField(i)">Add Field</ion-item>
              <ion-item button="" (click)="addNewField(i, true)">Add Section Break</ion-item>
            </ion-list>
          </ng-template>
        </ion-popover>


      </div>

    </ion-reorder-group>

    <button class="add-custom" (click)="addNewField()">
      Add New field
      <ion-icon name="add-circle-outline"></ion-icon>
    </button>
  </div>

</ion-content>
