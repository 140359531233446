<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>Purchases</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div sxRow sxDistribution="spaceBetween" sxGap="10px" sxPadding="medium">

    <div sxWidth="80%" >
      <table sxWidth="full">
        <tr>
          <th>Organisation</th>
          <th>Plan</th>
          <th>Validity</th>
          <th>Activation Date</th>
          <th>Expiry Date</th>
        </tr>
        <tr *ngFor="let planPurchase of planPurchases">
          <td>{{planPurchase?.organisation?.name}}</td>
          <td>{{planPurchase?.plan?.name}}</td>
          <td>{{planPurchase?.planPricingModel?.validityDays}} Days</td>
          <td>{{planPurchase?.activationTime | date : 'mediumDate'}}</td>
          <td>{{planPurchase?.expireTime | date : 'mediumDate'}}</td>
        </tr>
      </table>

    </div>
    <div sxCol sxWidth="20%" sxBorderColor="one" sxBorderWidth="1px" sxRadius="medium" sxPadding="medium">

      <super-button background="light" (click)="activatePlan()">Activate Plan</super-button>

    </div>

  </div>

</ion-content>
