import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Field} from "@qid/field";
import {CollectionTemplate} from "../../models/CollectionTemplate";
import { AlertController, ModalController } from '@ionic/angular';
import { FieldInputType, ValidationUtil } from '@qid/core';


@Component({
  selector: 'app-template-field-setter',
  templateUrl: './template-field-setter.component.html',
  styleUrls: ['./template-field-setter.component.scss'],
})
export class TemplateFieldSetterComponent implements OnInit {

  @Input() template: CollectionTemplate;
  @Input() otherInfo: Field[] = [];
  @Input() pageMode: boolean = false;
  @Output() otherInfoChange = new EventEmitter<Field[]>();
  inputTypes = Object.values(FieldInputType);
  selectedFieldIndex = null

  constructor(private modalController: ModalController,
              private alertController: AlertController,) {
  }

  ngOnInit() {

  }

  public addNewField(i = null, sectionBreak = false) {
    i = i ?? this.otherInfo?.length - 1;
    this.otherInfo.splice(i + 1, 0, new Field());
    this.otherInfo[i + 1].isForEachParticipant = false;
    this.selectedFieldIndex = i + 1;
    if (sectionBreak) {
      this.otherInfo[i + 1].sectionBreak = {}
      this.otherInfo[i + 1].label = "Section Break"
    }
  }


  async removeOtherInfo(otherInfo: Field, event: Event) {
    event.stopPropagation();
    if (!ValidationUtil.isStringEmpty(otherInfo.value) || !ValidationUtil.isStringEmpty(otherInfo.placeholder) || !ValidationUtil.isStringEmpty(otherInfo.label)  || !ValidationUtil.isStringEmpty(otherInfo.name)) {
      const alert = await this.alertController.create({
        header: 'Are You Sure!',
        message: `you want to remove ${otherInfo.label ?? 'this field'} ?`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          }, {
            text: 'Delete',
            handler: () => {
              this.otherInfo.splice(this.otherInfo.indexOf(otherInfo), 1);
            }
          }
        ]
      })
      await alert.present();
      return;
    }
    this.otherInfo.splice(this.otherInfo.indexOf(otherInfo), 1);
  }

  handleReorder(ev: any) {
    this.otherInfo = ev.detail.complete(this.otherInfo);
    this.update()
  }

  update() {
    this.otherInfoChange.emit(this.otherInfo)
  }

  save() {
    this.modalController.dismiss({
      fields: this.otherInfo
    })
  }

  close() {
    this.modalController.dismiss()
  }

  onAddElementClicked($event: MouseEvent) {
    $event.stopPropagation();
  }
}
