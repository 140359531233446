import {NgModule} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {SexyModule} from "@qid/sexy";
import {FormsModule} from "@angular/forms";
import {SuperModule} from "@qid/super";
import {ShareLogViewComponent} from "./share-log-view/share-log-view.component";
import {ExportPdfService} from "../services/export-pdf.service";
import { SignatureComponentsModule } from "../../signature";
import { CollectionTemplateModule } from "../../collection-template";
import { IdComponentsModule } from "../../id";
import {ShareCheckinViewComponent} from "./share-checkin-view/share-checkin-view.component";


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    SexyModule,
    SuperModule,
    SignatureComponentsModule,
    CollectionTemplateModule,
    FormsModule,
    IdComponentsModule
  ],
  declarations: [
    ShareLogViewComponent, ShareCheckinViewComponent
  ],
  exports: [
    ShareLogViewComponent, ShareCheckinViewComponent
  ],
  providers: [ExportPdfService]
})
export class ShareComponentsModule {

}
