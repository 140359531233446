import {EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import * as SharesActions from './shares.actions';
import {AccountType, AccountWiseRecords, createAccountWiseEntityAdapters} from "@qid/core";
import {ReadShareQuery} from '../model/ReadShareQuery';
import {Share} from '../model/Share';


export const SHARES_FEATURE_KEY = 'shares';


export interface SharesState extends EntityState<AccountWiseRecords<Share>> {

  readShareQuery: ReadShareQuery

}

export const {
  accountAdapter,
  recordAdapter
} = createAccountWiseEntityAdapters<Share>(SHARES_FEATURE_KEY)

const recordSelectors = recordAdapter.getSelectors()

export function createAccount(account_id: string, accountType: AccountType): AccountWiseRecords<Share> {
  return {
    account_id: account_id,
    accountType: accountType,
    records: recordAdapter.getInitialState(),
    hasPagination: true
  }
}

export const initialSharesState: SharesState = accountAdapter.getInitialState({
  readShareQuery: new ReadShareQuery()
});

const reducer = createReducer(
  initialSharesState,
  on(SharesActions.addAccount, (state, {accountBasedEntity}) => {
    return (accountBasedEntity && accountBasedEntity.account_id) ?
      accountAdapter.addOne(accountBasedEntity, state) : {...state}
  }),

  on(SharesActions.setReadShareQuery, (state, {query}) => ({
    ...state,
    readShareQuery: query
  })),

  on(SharesActions.loadSharesForAccount, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        isLoading: true
      }
    }, state)
  }),

  on(SharesActions.loadShareForAccountSuccess, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.setAll(action.shares, state.entities[action.account_id].records),
        isLoading: false,
        pagination: action.pagination
      }
    }, state)
  }),

  on(SharesActions.addRecord, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.setAll([action.record, ...recordSelectors.selectAll(state.entities[action.account_id].records)], state.entities[action.account_id].records)
      }
    }, state)
  }),

  on(SharesActions.updateRecord, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.updateOne({
          id: action.record_id,
          changes: {
            ...action.updatedRecord
          }
        }, state.entities[action.account_id].records)
      }
    }, state)
  }),

  on(SharesActions.deleteShare, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.removeOne(action.share_id, state.entities[action.account_id].records)
      }
    }, state)
  }),

  on(SharesActions.addNextPageRecords, (state, action) => {
    return accountAdapter.updateOne({
      id: action.account_id,
      changes: {
        records: recordAdapter.addMany(action.records, state.entities[action.account_id].records),
        pagination: action.pagination
      }
    }, state)
  }),
);

export function sharesReducer(state: SharesState | undefined, action: Action) {
  return reducer(state, action);
}
