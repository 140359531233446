import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AddOrgUserPage} from './add-org-user.page';

const routes: Routes = [
    {
        path: '',
        component: AddOrgUserPage,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AddOrgUserPageRoutingModule {
}
