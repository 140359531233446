import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";
import {CreateFieldComponent} from './create-field/create-field.component';
import {FieldComponent} from './field/field.component';


@NgModule({
    declarations: [CreateFieldComponent, FieldComponent],
    imports: [IonicModule, CommonModule, FormsModule, SexyModule, SuperModule],
    exports: [CreateFieldComponent, FieldComponent]
})

export class FieldComponentsModule {

}
