import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentAccessService} from "./service/document-access.service";

@NgModule({
    imports: [CommonModule],
    providers: [DocumentAccessService]
})
export class DocumentAccessModule {
}
