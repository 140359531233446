import {Directive, ElementRef, OnInit, Renderer2, RendererFactory2} from "@angular/core";

@Directive({selector: '[sxTextSlide]'})
export class TextSlideDirective implements OnInit {

  private renderer: Renderer2;
  constructor(private el: ElementRef,
              private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  ngOnInit() {
    this.renderer.setStyle(this.el.nativeElement.parentNode, 'overflow', 'hidden');
    this.el.nativeElement.style.whiteSpace = "nowrap"
    this.el.nativeElement.style.textContent = "slide"
    this.el.nativeElement.style.margin = "0"
    this.el.nativeElement.style.animation = "slide 5s linear infinite"
    const keyframesStyle = `
      @keyframes slide {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-200%);
        }
      }
    `;
    const styleElement = this.renderer.createElement('style');
    this.renderer.appendChild(styleElement, this.renderer.createText(keyframesStyle));
    this.renderer.appendChild(document.head, styleElement);
  }
}
