import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Signature, SignatureService} from "@qid/common";
import {CollectionTemplate} from "@qid/common";
import {ParticipantRoles, Share, ShareService} from "@qid/common";

@Component({
  selector: 'gateway-signature',
  templateUrl: './gateway-signature.component.html',
  styleUrl: './gateway-signature.component.scss',
})
export class GatewaySignatureComponent {

  @Input() template: CollectionTemplate;
  @Input() share: Share;
  @Output() onSubmit: EventEmitter<Share> = new EventEmitter<Share>()

  indexes: number[] = [];
  activeParticipantIndex = 0;

  public signature: Signature;
  public fetchingSignature: boolean;

  constructor(private signatureService: SignatureService,
              private shareService: ShareService
  ) {
  }

  async init() {

    this.fetchingSignature = true;
    const signature = await this.signatureService.findOneByUser();
    this.fetchingSignature = false;
    if (signature) {
      this.signature = signature;
    }

    let i: number = -1;

    for (let participant of this.share.participants) {

      i++;
      if (!participant || !participant.roles) {
        continue;
      }
      if (participant.roles.indexOf(ParticipantRoles.sharer) == -1) {
        continue;
      }
      if (!this.template.isParticipantSignature && participant.roles.indexOf(ParticipantRoles.admin) == -1) {
        continue;
      }

      if (this.signature && participant.account_id == this.signature.user) {
        participant.signature = this.signature
      }

      this.indexes.push(i);

    }

    this.activeParticipantIndex = this.indexes[0];

  }

  onSignatureSubmit = async () => {
    if (!this.share.participants[this.activeParticipantIndex].signature) {
      throw "Please Sign and Check terms";
    }

    this.share.participants[this.activeParticipantIndex].signatureImageUrl = this.share.participants[this.activeParticipantIndex].signature?.signatureImageUrl

    let currentIndex = this.indexes.indexOf(this.activeParticipantIndex)

    if (currentIndex < this.indexes.length - 1) {
      this.activeParticipantIndex = this.indexes[currentIndex + 1];
      return
    }

    await this.shareService.update(this.share._id, {participants: this.share.participants, updateRemark : "Signature Submitted"});
    this.onSubmit.emit(this.share);
  };
}
