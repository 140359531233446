<div sxRow sxGap="10px" sxAlign="center">

  <p sxColor="two" sxMargin="0" sxTextSize="smal" *ngIf="paginatedResponse.page">
    Showing {{((paginatedResponse.page - 1) * 30) + 1}}
    -{{ ((paginatedResponse.page - 1) * 30) + paginatedResponse.size}} of {{paginatedResponse.totalDocs}}</p>

  <div sxRow sxGap="10px">
    <button class="navigation-arrow" (click)="previousPage()">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </button>

    <button class="navigation-arrow" (click)="nextPage()">
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </button>
  </div>


  <!--    <div sxRow sxDistribution="center" *ngIf="paginatedResponse.totalPages > 0">-->
  <!--      <ng-container *ngFor="let pageNumber of getVisiblePageNumbers()">-->
  <!--        <button-->
  <!--          [class.active-pagintion-button]="pageNumber === activePageNumber"-->
  <!--          [class.pagination-button]="pageNumber !== activePageNumber"-->
  <!--          (click)="goToPage(pageNumber)"-->
  <!--        >-->
  <!--          {{ pageNumber }}-->
  <!--        </button>-->
  <!--      </ng-container>-->
  <!--    </div>-->
</div>


