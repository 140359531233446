import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {Capacitor} from "@capacitor/core";


@Directive({
  standalone: true,
  selector: '[hideForPlatform]'
})
export class HideForPlatformDirective implements OnInit {

  @Input() hideForPlatform: 'ios' | 'android' | 'web' | 'native';

  constructor(
    private el: ElementRef
  ) {
  }

  ngOnInit() {
    this.init()
  }

  async init() {

    if (this.hideForPlatform == "web" && !Capacitor.isNativePlatform()) {
      this.hideElement()
    }

    if (this.hideForPlatform == "native" && Capacitor.isNativePlatform()) {
      this.hideElement()
    }

    if (this.hideForPlatform == "android" && Capacitor.getPlatform() == 'android') {
      this.hideElement()
    }

    if (this.hideForPlatform == "ios" && Capacitor.getPlatform() == 'ios') {
      this.hideElement()
    }

  }


  hideElement() {
    this.el.nativeElement.remove();
  }


}
