<div
  *ngIf="activeSlide == slides.loading"
  sxWidth="full"
  sxHeight="full"
  sxCol
  sxAlign="center"
  sxDistribution="center">

  <ion-spinner></ion-spinner>
</div>
<div
  sxWidth="full"
  sxHeight="full"
  sxPadding="medium"
  sxCol
  sxGap="10px"
  sxAlign="stretch"
  sxDistribution="center"
  *ngIf="activeSlide == slides.loggedIn">

  <div *ngIf="authenticatedUser$ | async  as user" sxCol sxGap="10px" sxAlign="center" sxWidth="full">

    <div sxWidth="full" sxBackground="light" sxRadius="medium" sxCol sxAlign="center"
         sxGap="10px" sxPadding="medium" sxBorderColor="one" sxBorderWidth="1px">
      <p>You're logged in as</p>

      <avatar-round [title]="user?.name" [imageUrl]="user.displayPictureUrl"></avatar-round>

      <display-name textSize="26px" iconSize="28px"></display-name>

      <p sxColor="two" sxTextSize="medium" sxMargin="0" *ngIf="user?.email">{{user.email}}</p>
      <super-button sxWidth="full" sxMargin="10px 0 20px" [action]="onContinueAsLoggedInUserClicked"
                    iconEnd="chevron-forward-outline">
        Continue
      </super-button>
    </div>


    <super-button sxWidth="full" iconEnd="log-out-outline"
                  (click)="useOtherAccount()" *ngIf="user && !defaultEmail" type="outline">
      Logout
    </super-button>
  </div>

</div>

<div
  sxWidth="full"
  sxHeight="full"
  sxPadding="medium"
  sxCol
  sxGap="10px"
  sxAlign="stretch"
  sxDistribution="center"
  *ngIf="activeSlide == slides.defaultCredential">

  <div *ngIf="defaultCredentialUser" sxCol sxGap="10px" sxAlign="center">

    <avatar-round [title]="defaultCredentialUser?.name"
                  [imageUrl]="defaultCredentialUser.displayPictureUrl"></avatar-round>

    <p>Send OTP</p>
    <super-button sxWidth="full" [action]="onEmail" iconEnd="chevron-forward-outline">
      {{ "Continue as " + defaultCredentialUser.name }}
    </super-button>
  </div>

</div>

<div *ngIf="activeSlide == slides.signIn"
     sxCol
     sxGap="10px"
     sxWidth="fill"
     sxHeight="full"
     sxAlign="stretch"
     sxPadding="medium">


  <div *ngIf="!isLoginWithEmail" sxGap="10px" sxCol>

    <div *ngIf="existingAccountFoundAgainst == 'email' && existingAccount.phoneNumber"
         sxRadius="small" sxGap="10px" sxBackground="base-shade" sxPadding="medium" sxRow sxAlign="center" sxDistribution="spaceBetween">
      <p sxMargin="0" sxTextSize="small">To continue as {{existingAccount.name}}, enter the full phone
        number: {{existingAccount.phoneNumber}} below.</p>
      <ion-icon sxTextSize="32px" sxCursor="pointer" (click)="existingAccountFoundAgainst = null" name="close-circle-outline"></ion-icon>
    </div>
    <super-input type="phone" placeholder="Enter Phone Number"
                 [(value)]="createUserDto.phoneVerifier.receiverId"
                 [showCountrySelector]="true"
    ></super-input>

    <super-button [action]="onContinueWithPhone" [showError]="true" iconEnd="chevron-forward-outline">Continue with
      Phone
    </super-button>
  </div>

  <div *ngIf="isLoginWithEmail" sxGap="10px" sxCol>
    <div *ngIf="existingAccountFoundAgainst == 'phone' && existingAccount.email"
         sxRadius="small" sxGap="10px" sxBackground="base-shade" sxPadding="medium" sxRow sxAlign="center" sxDistribution="spaceBetween">
      <p sxMargin="0" sxTextSize="small">To continue as {{existingAccount.name}}, enter the full
        email: {{existingAccount.email}} below.</p>
      <ion-icon sxTextSize="32px" sxCursor="pointer" (click)="existingAccountFoundAgainst = null" name="close-circle-outline"></ion-icon>
    </div>
    <super-input [(value)]="createUserDto.emailVerifier.receiverId"
                 iconStart="mail-outline"
                 placeholder="Enter your Email" type="email"
    ></super-input>
    <super-button [action]="onContinueWithEmail" iconEnd="chevron-forward-outline" [showError]="true">Continue with
      Email
    </super-button>
  </div>


  <div class="or-label"><p>OR</p></div>
  <super-button *ngIf="!isLoginWithEmail" iconStart="mail-outline" background="light" color="dark"
                (click)="switchToContinueWithEmail()" textAlign="start">
    Continue with Email
  </super-button>
  <super-button *ngIf="isLoginWithEmail" iconStart="call-outline" background="light" color="dark"
                (click)="switchToContinueWithPhone()" textAlign="start">
    Continue with Phone
  </super-button>


  <div id="verifier"></div>
  <p class="terms-label">
    By continuing, you agree to our
    <a href="https://oneqid.com/privacy-policy/">Privacy policy</a> &
    <a href="https://oneqid.com/terms-of-use/">Terms of Use</a>
  </p>


</div>


<!--User Name Slide-->
<div sxCol sxGap="10px" sxPadding="medium" *ngIf="activeSlide === slides.signUp">

  <super-input [(value)]="createUserDto.name" placeholder="Full Name"
               iconStart="person-outline" [required]="true" label="Full Name"
  ></super-input>
  <super-input [readonly]="createUserDto.emailVerifier.otpId != null"
               [required]="isEmailRequired"
               [(value)]="createUserDto.emailVerifier.receiverId"
               [validate]="true"
               placeholder="Email"
               label="Email"
               type="email"
               iconStart="mail-outline"></super-input>

  <super-input [readonly]="createUserDto.phoneVerifier.otpId != null"
               [required]="isPhoneRequired"
               [(value)]="createUserDto.phoneVerifier.receiverId"
               [validate]="true"
               placeholder="Phone Number"
               label="Phone Number"
               type="phone"></super-input>

  <super-button sxMargin="10px 0 0" [action]="quickSignup" [showError]="true" iconEnd="chevron-forward-outline">
    Continue
  </super-button>

</div>

<div *ngIf="activeSlide == slides.accountFound" sxPadding="medium">

  <div sxCol sxCenterize sxBackground="light" sxRadius="medium"
       sxPadding="medium">

    <p sxTextWeight="bold">Existing account found</p>
    <avatar-round [imageUrl]="existingAccount.displayPictureUrl"
                  [title]="existingAccount.name"
                  size="60"></avatar-round>
    <h1 sxMargin="5px" sxTextSize="20px">{{existingAccount.name}}</h1>
    <p sxTextSize="16px" sxMargin="2px 0">{{existingAccount.email}}</p>
    <p sxTextSize="16px" sxMargin="2px 0">{{existingAccount.phoneNumber}}</p>
    <div sxCol sxWidth="full" sxGap="5px">
      <p sxColor="two" sxTextAlign="center">Log in to this account</p>
      <super-button *ngIf="existingAccount.email" type="outline" textAlign="start" iconEnd="chevron-forward-outline"
                    [action]="onExistingEmailOtpClicked">
        Email OTP
      </super-button>
      <super-button *ngIf="existingAccount.phoneNumber" type="outline" textAlign="start"
                    [action]="onExistingPhoneOtpClicked" iconEnd="chevron-forward-outline">Phone OTP
      </super-button>
    </div>

    <p sxColor="two">OR</p>
    <super-button sxWidth="full" type="outline" textAlign="start" iconEnd="chevron-forward-outline">Use different
      account
    </super-button>
  </div>

</div>
