import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxTextWeight]'})
export class TextWeightDirective implements OnInit {

  @Input() sxTextWeight: 'bold' | 'normal' | string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    try {
      this.el.nativeElement.style.fontWeight = this.sxTextWeight
    } catch (e) {
      console.error("SX TEXT WEIGHT PROPERTY", e)
    }
  }


}
