import {Component, Input, OnInit} from '@angular/core';
import {EncryptionKeysService} from "../../services/encryption-keys.service";
import {CryptoUtil} from "@qid/crypto";

@Component({
  selector: 'create-encryption-keys',
  templateUrl: './create-encryption-key.component.html',
  styleUrls: ['./create-encryption-key.component.scss'],
})
export class CreateEncryptionKeyComponent implements OnInit {

  @Input() entityId: string;

  slides = {
    passwordOptions: 'passwordOptions',
    setPassword: 'setPassword',
    confirmation: 'confirmation'
  }

  activeSlide = this.slides.passwordOptions

  inputPassword: string;
  inputConfirmPassword: string;

  constructor(private encryptionService: EncryptionKeysService) {
  }

  ngOnInit() {
  }

  goToSlide(slide: string) {
    this.activeSlide = slide
  }

  async autoGeneratePassword() {
    this.goToSlide(this.slides.confirmation)
    const res = await this.encryptionService.autoGenerate()
  }

  async generateKeyPhrase() {

  }

  generateKeysWithPassword = async () => {
    const keys = await CryptoUtil.generateUserKeys(this.inputPassword);
    const res = await this.encryptionService.create({...keys, entityId: this.entityId});
  }
}
