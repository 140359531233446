<ion-header>
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">Cancel</ion-button>
    <ion-title>Verify {{sid?.name}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <page-loader #pageLoaderComponent displayText="Verifying {{sid?.name}}">

  </page-loader>

</ion-content>
