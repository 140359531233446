import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Scanner} from "@qid/common";
import {CollectionTemplate} from "@qid/common";

@Component({
    selector: 'gateway-welcome',
    templateUrl: './gateway-welcome.component.html',
    styleUrl: './gateway-welcome.component.scss',
})
export class GatewayWelcomeComponent {

    @Input() scanner: Scanner;

    @Input() collectionTemplate: CollectionTemplate;

    @Output() onSubmit: EventEmitter<CollectionTemplate> = new EventEmitter<CollectionTemplate>()

    constructor() {

    }


    public submit(collectionTemplate: CollectionTemplate) {
        this.onSubmit.emit(collectionTemplate);
    }

}
