import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {CollectionTemplate} from "@qid/common";
import {Store} from "@ngrx/store";
import {firstValueFrom} from "rxjs";
import {Participant, ParticipantRoles} from "@qid/common";
import { User, UsersSelectors } from "@qid/common";


@Component({
    selector: "share-participant-form",
    templateUrl: "./share-participant-form.component.html",
    styleUrls: ["./share-participant-form.component.scss"]
})
export class ShareParticipantFormComponent implements OnInit {


    @Input() participant: Participant;
    @Input() template: CollectionTemplate;
    @Output() remove: EventEmitter<any> = new EventEmitter<any>();
    user: User;
    removeParticipant: any;

    constructor(
        private store: Store
    ) {
    }

    async ngOnInit() {
        this.user = await firstValueFrom(this.store.select(UsersSelectors.selectAuthenticatedUser))

        this.participant.otherFields = []

        if (this.participant.roles.indexOf(ParticipantRoles.sharer) < 0) {
            return
        }

        for (let field of this.template.otherInfo) {
            if (field.isForEachParticipant) {
                this.participant.otherFields.push(JSON.parse(JSON.stringify(field)));
            }

        }

    }

}
