import {NgModule} from "@angular/core";
import {BrowseMasterTemplatesComponent} from "./components/browse-master-templates/browse-master-templates.component";
import {MasterTemplateService} from "./components/services/master-template.service";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {SuperModule} from "@qid/super";
import {SexyModule} from "@qid/sexy";

@NgModule(
    {
        imports: [
            IonicModule,
            CommonModule,
            SuperModule,
            SexyModule
        ],
        exports: [BrowseMasterTemplatesComponent],
        declarations: [BrowseMasterTemplatesComponent],
        providers: [MasterTemplateService]
    }
)
export class MasterTemplateModule {

}
