<div sxPadding="medium">

  <div *ngIf="activeSlide == slides.passwordOptions" sxCol sxAlign="center">

    <ion-icon sxTextSize="70px" name="document-lock-outline"></ion-icon>
    <h2>End-to-End Encryption</h2>
    <h4>We keep your data secure and private</h4>

    <p sxColor="two">Select one option</p>
    <div sxCol sxPadding="medium" sxGap="10px">
      <div sxPadding="medium" sxHeight="80px" sxRadius="medium" sxRow sxAlign="center" sxWidth="full"
           sxDistribution="spaceBetween"
           sxGap="10px" sxBackground="light" (click)="goToSlide(slides.setPassword)">
        <p sxMargin="0" sxMaxWidth="70%">I'll choose my own password</p>
        <ion-icon name="arrow-forward-outline"></ion-icon>
      </div>
      <div sxPadding="medium" sxHeight="80px" sxRadius="medium" sxRow sxAlign="center" sxWidth="full"
           sxDistribution="spaceBetween"
           sxGap="10px" sxBackground="light" (click)="autoGeneratePassword()">
        <p sxMargin="0" sxMaxWidth="70%">Generate Password and Email me</p>
        <ion-icon name="arrow-forward-outline"></ion-icon>
      </div>

    </div>

  </div>

  <div *ngIf="activeSlide==slides.setPassword" sxGap="10px" sxCol sxAlign="center">

    <ion-icon name="arrow-back-outline" (click)="goToSlide(slides.passwordOptions)"></ion-icon>
    <h1>Set Password</h1>
    <input [(ngModel)]="inputPassword" placeholder="Password" type="password">
    <input [(ngModel)]="inputConfirmPassword" placeholder="Confirm Password" type="password">
    <super-button [action]="generateKeysWithPassword">Submit</super-button>

  </div>

</div>
