import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {exhaustMap, filter, from, map, of} from 'rxjs';
import * as DocumentsActions from './documents.actions';
import {IdService} from "../services/id.service";

@Injectable()
export class DocumentsEffects {

  private actions$ = inject(Actions);
  private apiService = inject(IdService);
  private ACTIONS = DocumentsActions;

  // Boilerplate Starts

  addAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.ACTIONS.addAccount),
      filter(props => props.accountBasedEntity != null),
      exhaustMap((props) =>
        of(this.ACTIONS.loadRecordsForAccount({
          account_id: props.accountBasedEntity.account_id,
          accountType: props.accountBasedEntity.accountType
        }))
      )
    )
  );

  loadRecordsForAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.ACTIONS.loadRecordsForAccount),
      exhaustMap((props) => {
          return from(this.apiService.readAll()).pipe(
            map(ids => {
              const records = ids ? ids : []
              return this.ACTIONS.loadRecordsForAccountSuccess({
                records: records,
                account_id: props.account_id
              })
            })
          )
        }
      )
    )
  );

  // Boilerplate Ends

}
