import {OrganisationUserRoles} from "@qid/core";

export class CreateOrganisationUserDto {

    email: string;

    createdBy: string

    role : OrganisationUserRoles

    user: string

    name: string;

}

