import {Directive, ElementRef, Input, OnInit} from "@angular/core";

const style = getComputedStyle(document.body)

@Directive({selector: '[sxBorderColor]'})
export class BorderColorDirective implements OnInit {

  @Input() sxBorderColor: 'dark' | 'light' | 'success' | 'danger' | 'darkGradient' | 'lightGradient' | 'one' | 'two' | 'three' | string;

  constructor(private el: ElementRef) {

  }

  ngOnInit() {
    this.el.nativeElement.style.borderStyle = "solid"
    switch (this.sxBorderColor) {
      case "dark" :
      case "light" :
      case "success" :
      case "danger" :
      case  "one" :
      case  "two" :
      case  "three" :
        return this.el.nativeElement.style.borderColor = style.getPropertyValue(`--ion-color-${this.sxBorderColor}`)
      default :
        return this.el.nativeElement.style.borderColor = this.sxBorderColor
    }

  }

}
