<ion-header>
  <ion-toolbar>
    <ion-back-button slot="start"></ion-back-button>
    <ion-title>Edit Profile</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div sxPadding="medium">

    <div *ngIf="profile$ | async as profile" sxCol sxGap="10px">


      <avatar-round
        sxMargin="0 auto"
        [imageUrl]="AccountProfileUtil.getAttributeByType(profile.defaultAccountAttributes, AccountAttributeType.displayPicture)?.value"
        [isEditMode]="true"
        [onImageAction]='updateProfilePhoto'
      ></avatar-round>

      <account-attribute-view [attribute]="profile.name" label="Full Name"></account-attribute-view>

      <account-attribute-view
        [attribute]="getAttributeByType(profile.defaultAccountAttributes, AccountAttributeType.dob)"
        label="Date of birth"
        (click)="onChangeAttributeClicked(AccountAttributeType.dob, 'Date of birth')"
      ></account-attribute-view>

      <account-attribute-view
        [attribute]="getAttributeByType(profile.defaultAccountAttributes, AccountAttributeType.email)" label="Email"
        (click)="onChangeAttributeClicked(AccountAttributeType.dob, 'Email')"
      ></account-attribute-view>
      <!--      <account-attribute-view *ngFor="let attribute of profile.defaultAccountAttributes" [attribute]="attribute"-->
      <!--                              label="Date of birth"></account-attribute-view>-->
    </div>


  </div>


</ion-content>
