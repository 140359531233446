<div sxBackground="black" sxCol sxHeight="full">

    <div sxRow sxWidth="full" sxDistribution="spaceBetween" sxAlign="center" sxPadding="medium">
        <div></div>
        <h1 sxMargin="0" sxTextSize="medium" sxColor="light">Capture Document</h1>
        <div>
            <button sxBackground="light" sxCircle="30px" (click)="close()" sxCenterize>
                <ion-icon sxTextSize="24px" name="close-outline"></ion-icon>
            </button>
        </div>
    </div>

    <div *ngIf="activeSlide == slides.camera" sxHeight="full" sxBackground="black" sxCol sxDistribution="spaceBetween"
         sxPadding="20px 0">
        <div class="video-container">
            <video #video id="video" autoplay></video>
            <canvas #cameraResultCanvas></canvas>
            <div id="document-box" sxCenterize>
                <div id="guide-box"></div>
                <div id="doc-label" *ngIf="imageTitle">
                    <p>{{imageTitle}}</p>
                </div>
            </div>
        </div>

        <div sxWidth="fill" sxCol sxAlign="center" sxGap="15px" sxPadding="20px">
            <div sxCenterize="col">
                <div sxCenterize sxCircle="70px" sxBorderWidth="5px" sxBorderColor="light">
                    <button (click)="onCaptureClicked()" sxCircle="55px" sxBackground="light"></button>
                </div>
                <p sxTextSize="small" sxColor="light">Tip: Hit Space Bar to Capture</p>
            </div>

            <div sxWidth="180px">
                <super-button size="v-small" (click)="onCameraSourceChangeClicked()" iconStart="videocam-outline">Select
                    Camera
                </super-button>
                <ion-select *ngIf="devices" [style.visibility]="'hidden'" #cameraSourceSelect
                            [(ngModel)]="selectedDevice" (ionChange)="onDeviceChanges()">
                    <ion-select-option *ngFor="let device of devices"
                                       [value]="device">{{device.label}}</ion-select-option>
                </ion-select>
            </div>

        </div>


    </div>

    <div *ngIf="activeSlide == slides.crop" sxWidth="full" sxPadding="medium" sxOverflow="scroll" sxCol sxAlign="center" sxGap="10px">
      <image-cropper #imageCropperComponent [imageUrl]="cameraCapturedImageData" (croppedImageUrl)="setCroppedImageUrl($event)"   ></image-cropper>
        <div sxRow sxAlign="center" sxDistribution="center" sxGap="10px">
            <super-button sxWidth="200px" type="outline" iconStart="reload-outline" (click)="onRetakeClicked()"
                          color="light" borderColor="light">Re-take
            </super-button>
            <super-button sxWidth="200px" type="light" iconStart="checkmark-outline" (click)="onCropClicked()">Save
            </super-button>
        </div>
    </div>

    <div *ngIf="activeSlide == slides.preview" sxWidth="full" sxCol sxAlign="center" sxGap="10px" sxPadding="medium">
        <img alt="Click-Again" sxWidth="full" sxHeight="500px" [src]="resultImage">
      <canvas [hidden]="true" #rotate ></canvas>
      <div sxColor="light" sxPadding="0 0 medium 0" sxWidth="full" sxCenterize="row" >
        <ion-icon size="large" name="reload-outline" (click)="handleRotate()"></ion-icon>
      </div>
        <div sxRow sxAlign="center" sxDistribution="center" sxPadding="medium" sxGap="10px">
            <super-button sxWidth="200px" type="outline" iconStart="reload-outline" (click)="onRetakeClicked()"
                          color="light" borderColor="light">Re-take
            </super-button>
            <super-button sxWidth="200px" type="light" iconStart="checkmark-outline" (click)="onSaveImageClicked()">
                Upload
            </super-button>
        </div>

    </div>


</div>

