import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AuthSelectors} from '../../modules/authentication';
import {DigilockerRequest, DigilockerService} from '../../modules/digilocker-request';
import {SID} from '../../modules/sid';
import {ImageUtil, ValidationUtil} from "@qid/core";
import {ModalController} from "@ionic/angular";
import {RequestStatus} from "../../enum/request-status.enum";
import {ID} from "../../models/ID";


@Component({
  selector: 'digilocker',
  templateUrl: './digilocker.component.html',
  styleUrls: ['./digilocker.component.scss'],
})

export class DigilockerComponent {

  @Input() sid: SID
  @Output() documentFetched = new EventEmitter<ID>()
  activeAccount = this.store.select(AuthSelectors.selectActiveAccount)

  public url: string = null;
  public digilockerStatusCheckInterval = null
  public digilockerRequest: DigilockerRequest = null
  public status: string = null
  public createDocument: ID = null;

  public isSidValid: boolean = null;
  public isProcessStarted: boolean = false;

  public progressMessage: string = null;

  constructor(private digilockerService: DigilockerService,
              private store: Store,
              private modalController: ModalController
  ) {
  }

  ngOnInit() {
    if (!this.sid || ValidationUtil.isStringEmpty(this.sid.digilockerDocType)) {
      return;
    }
    this.isSidValid = true;
  }

  init() {

    console.log(this.sid)

    if (!this.sid || ValidationUtil.isStringEmpty(this.sid.digilockerDocType)) {
      return;
    }
    this.isSidValid = true;
    this.getUrl();
  }

  async getUrl() {
    const res = await this.digilockerService.getUrl({
      documentType: this.sid.digilockerDocType, runFaceMatch: true
    })
    if (!res || !res.data) {
      throw res.errorMessage
    }
    this.url = res.data.url;
    this.digilockerRequest = res.data.digilockerRequest
  }


  openDigilockerLink = async () => {
    window.open(this.url, '_blank');
    this.isProcessStarted = true;
    this.progressMessage = "Waiting"
    this.setDigilockerRequestStatusCheckInterval()
  }

  setDigilockerRequestStatusCheckInterval() {

    this.digilockerStatusCheckInterval = setInterval(async () => {

      const res = await this.digilockerService.findById(this.digilockerRequest._id)
      if (res?.data) {
        this.onDigilockerRequestFetched(res.data)
      }

    }, 2000)

  }

  private onDigilockerRequestFetched(request: DigilockerRequest) {

    this.digilockerRequest = request

    this.updateProgressMessage();

    if (this.digilockerRequest.status === RequestStatus.failed) {
      clearInterval(this.digilockerStatusCheckInterval)
      this.onRequestFailed()
    }
    if (this.digilockerRequest.status == RequestStatus.completed) {
      clearInterval(this.digilockerStatusCheckInterval)
      this.onDigilockerRequestSuccess();
    }
  }

  private async onDigilockerRequestSuccess() {
    this.createDocument = this.digilockerRequest.requestedDoc.createdDocument;
    this.documentFetched.emit(this.createDocument)
  }

  onBackClicked() {
    this.modalController.dismiss()
  }

  updateProgressMessage() {

    if (!this.digilockerRequest.authResponse) {
      this.progressMessage = 'Authenticating with Digilocker';
    } else if (this.digilockerRequest.requestedDoc.documentFetch === 'started') {
      this.progressMessage = 'Fetching Document';
    } else if (this.digilockerRequest.requestedDoc.documentFetch === 'success' && this.digilockerRequest.requestedDoc.faceVerification === 'started') {
      this.progressMessage = 'Performing Face Verification';
    } else if (this.digilockerRequest.requestedDoc.faceVerification === 'success' && this.digilockerRequest.requestedDoc.documentCreation === 'started') {
      this.progressMessage = 'Creating Document';
    } else if (this.digilockerRequest.requestedDoc.documentCreation === 'success') {
      this.progressMessage = 'Process Completed Successfully';
    } else if (this.digilockerRequest.authResponse) {
      this.progressMessage = 'Authorized';
    }
  }


  onRequestFailed() {
    this.progressMessage = this.digilockerRequest.errorCode
    // switch (this.digilockerRequest.errorCode){
    //   case 'AUTHORISATION_FAILED' : break;
    // }
  }

  async onCancelClicked() {
    clearInterval(this.digilockerStatusCheckInterval);
    this.digilockerRequest = null;
    this.isProcessStarted = false;
    await this.getUrl()
  }

  protected readonly ImageUtil = ImageUtil;
}
