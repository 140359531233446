import {NgModule} from "@angular/core";
import {FaceIdApi} from "./services/face-id.api";

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [FaceIdApi]
})
export class FaceIdModule {

}
