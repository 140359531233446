import {Component, Input, OnInit} from "@angular/core";
import {AlertController, LoadingController, ModalController} from "@ionic/angular";
import {PinchToZoomComponent} from "@qid/super";
import {DocumentOcrResult, ScannedImage} from "../../../ocr";
import {User, UserService} from "../../../user";

@Component({
  selector: "app-name-correction",
  templateUrl: "./name-correction.page.html",
  styleUrls: ["./name-correction.page.scss"]
})
export class NameCorrectionPage implements OnInit {

  @Input() images: any[];
  @Input() userInput: string;
  @Input() documentImageScanResult: DocumentOcrResult;
  @Input() owner: User = null;

  selectedNameType: "system-generated" | "user-entered" = "system-generated";

  constructor(private modalController: ModalController,
              private userService: UserService,
              private alertController: AlertController,
              private loadingController: LoadingController
  ) {
  }

  ngOnInit() {

  }

  close() {
    this.modalController.dismiss();
  }

  updateNameAsPerOcr = async () => {
    if (this.selectedNameType == "user-entered") {
      return this.modalController.dismiss({nameUpdated: true, name: this.owner["name"]});
    }
    this.owner["name"] = this.documentImageScanResult.extractedNameOnId;
    await this.userService.updateUser(this.owner);
    this.documentImageScanResult.nameMatchPercentage = 100;
    return this.modalController.dismiss({nameUpdated: true, name: this.owner["name"]});
  };

  async viewIdImage(image: ScannedImage) {
    const modal = await this.modalController.create({
      component: PinchToZoomComponent,
      componentProps: {
        imageUrl: image.imageUrl
      },
      animated: false
    });
    modal.present();
  }

  reUploadImages() {
    return this.modalController.dismiss({reUpload: true});
  }

  async onNameSelected(nameType: "system-generated" | "user-entered") {
    if (nameType == "user-entered" && +this.documentImageScanResult.nameMatchPercentage < 50) {
      const alert = await this.alertController.create({
        header: 'Confirm Name',
        subHeader: `Set ${this.userInput} as the correct name?`,
        buttons: [
          {
            text: "No",
            role: "cancel"
          },
          {
            text: "Yes",
            handler: async () => {
              this.selectedNameType = nameType;
              this.documentImageScanResult.nameMatchPercentage = 51;
              const loader = await this.loadingController.create({message: "Updating..."})
              loader.present();
              await this.updateNameAsPerOcr();
              loader.dismiss();
            }
          }
        ]
      })
      alert.present();
      return;
    }
    this.selectedNameType = nameType;
  }
}
