import {Injectable} from "@angular/core";
import {String} from "typescript-string-operations";
import {MasterTemplate} from "../../models/MasterTemplate";
import {CreateMasterTemplateDto} from "../../dto/create-master-template.dto";
import {Store} from "@ngrx/store";
import {firstValueFrom} from "rxjs";
import {RestService} from "../../../../services/rest.service";
import {AuthSelectors} from "../../../authentication";
import {CollectionTemplate} from "../../../collection-template";

@Injectable()
export class MasterTemplateService {
  private static ApiEndpoint = 'master-template/';
  private static URL_CLONE = MasterTemplateService.ApiEndpoint + 'clone?masterTemplateId={0}&orgId={1}';
  private static URL_INDUSTRY = MasterTemplateService.ApiEndpoint + "?industry={0}";

  constructor(private restService: RestService,
              private store: Store
  ) {
  }

  public async create(dto: CreateMasterTemplateDto) {
    return (
      await this.restService.send<MasterTemplate>({
        url: MasterTemplateService.ApiEndpoint,
        body: dto,
        method: 'POST',
      })
    ).data;
  }

  public async read(templateId: string) {
    return (
      await this.restService.send<MasterTemplate>({
        url: MasterTemplateService.ApiEndpoint + templateId,
        method: 'GET',
      })
    ).data;
  }

  async readAll(industryId: string = null): Promise<MasterTemplate[]> {
    return (await this.restService.send<MasterTemplate[]>({
      url: String.format(MasterTemplateService.URL_INDUSTRY, industryId),
      method: 'GET'
    })).data
  }

  public async clone(masterTemplateId: string) {
    const org = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccount))
    if (!org || !org._id) {
      return
    }
    return (await this.restService.send<CollectionTemplate>({
      url: String.format(MasterTemplateService.URL_CLONE, masterTemplateId, org._id),
      method: 'POST'
    })).data
  }

  async update(dto: CreateMasterTemplateDto, id: string) {
    return (
      await this.restService.send<MasterTemplate>({
        url: MasterTemplateService.ApiEndpoint + id,
        body: dto,
        method: 'PUT',
      })
    ).data;
  }

  public async delete(templateId: string) {
    return await this.restService.send({
      url: MasterTemplateService.ApiEndpoint + templateId,
      method: 'DELETE',
    });
  }
}
