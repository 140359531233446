import {createAction, props} from '@ngrx/store';
import {Scanner} from "../models/scanner";
import {AccountWiseRecords} from "@qid/core";


//Boilerplate Starts

const FEATURE_KEY = 'Scanner'
const ENTITY_MODEL: Scanner = null;

//Account

export const addAccount = createAction(
  '[Scanners/API] Add Account for Scanners',
  props<{ accountBasedEntity: AccountWiseRecords<Scanner> }>()
)


// Scanners

export const loadScannersForAccount = createAction(
  '[Scanners/API] Load Scanner for account',
  props<{ account_id: string }>()
);

export const addScanner = createAction(
  '[Scanners/API] Add Scanner',
  props<{ scanner: Scanner, account_id: string }>()
);


export const removeScanner = createAction(
  '[Scanners/API] Remove Scanner',
  props<{ scanner_id: string, account_id: string }>()
);

export const loadScannersSuccess = createAction(
  '[Scanners/API] Load Scanners Success',
  props<{ scanners: Scanner[], account_id: string }>()
);

export const loadScannersFailure = createAction(
  '[Scanners/API] Load Scanners Failure',
  props<{ error: any }>()
);


export const updateRecord = createAction(
  `[${FEATURE_KEY}] Update Record`,
  props<{ record_id: string, account_id: string, updatedRecord: typeof ENTITY_MODEL }>()
);
