import {Directive, ElementRef, Input, OnInit} from "@angular/core";

@Directive({selector: '[sxMatchParent]'})
export class MatchParentDirective implements OnInit {
  @Input() sxWidth: string;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.style.width = "100%"
    this.el.nativeElement.style.height = "100%"
  }

}
