<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-button slot="start" fill="clear" (click)="closeModal()">Back</ion-button>
    <ion-title [hidden]="!sid">{{id ? 'Update' : 'Add'}} {{ sid?.name }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div
    *ngIf="isShowOcrLoader"
    class="rocker-screen"
    sxWidth="full"
    sxHeight="full"
    sxCol
    sxAlign="center"
    sxDistribution="center"
    sxGap="10px"
  >
    <p sxMargin="0" sxTextSize="20px" sxColor="three" sxTextAlign="center">
      Scanning your {{sid.name}}...<br/>
      <br/>Your ID is equally sensitive and private to both you and
      <span sxTextWeight="bold" sxTextStyle="italic">qid</span>.
    </p>
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  </div>

  <page-loader #pageLoaderComponent>

    <div sxPadding="0 medium" *ngIf="sid" sxWidth="full">
      <p sxPadding="0 medium" sxColor="three" *ngIf="!sid.canSkipNameCheck">
        <span sxTextWeight="bold">{{account?.name}}</span> should be clearly visible
        on your {{sid.name}}.
      </p>
      <id-image-picker
        *ngFor="let photo of sid.photos"
        [title]="photo.name"
        [(value)]="photo.file"
        [error]="photo.errorMessage"
        (valueChange)="onImageSelected()"
      ></id-image-picker>


      <section #documentDetailsSection [hidden]="!documentOCRResult">
        <h1 sxMargin="25px 10px 0" sxColor="three" sxTextSize="medium">{{sid.name}} details</h1>
        <div *ngIf="sid?.allowManualEnterIdNumber" sxPadding="10px 0 5px">
          <super-input
            [label]="sid.name + ' Number'"
            [required]="!sid.canSkipIdNumberCheck"
            [(value)]="documentData.idNumber"
            [errorNote]="documentNumberErrorMessage"
          ></super-input>
        </div>

        <div *ngIf="sid?.manualFields" sxCol sxGap="5px" sxAlign="stretch">
          <super-input
            *ngFor="let field of documentData.manualFields"
            [label]="field.label"
            [(value)]="field.value"
            [required]="field.isMandatory"
            [placeholder]="field.placeholder"
            [type]="field.type"
            [(errorNote)]="field.errorMessage"
            [validate]="true"
            [options]="field.options"
          ></super-input>
        </div>

      </section>


      <div
        sxRow sxWidth="full"
        sxDistribution="center" sxAlign="center"
        sxColor="two" sxGap="5px" sxMargin="10px 0 140px">
        <ion-icon name="shield-checkmark"></ion-icon>
        <p>Secure Upload</p>
      </div>

      <page-action sxWidth="full">

        <super-button [action]="autofill" *ngIf="!documentOCRResult"
                      [showError]="true" iconEnd="chevron-forward">Upload
        </super-button>

        <super-button *ngIf="documentOCRResult"
                      [action]="verifyUpload" [showError]="true"
                      iconEnd="chevron-forward">{{id ? 'Update' : 'Save'}}</super-button>

      </page-action>


    </div>


  </page-loader>
</ion-content>
