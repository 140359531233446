import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {AbsoluteValue} from "../util/AbsoluteValue";

@Directive({selector: '[sxMargin]'})
export class MarginDirective implements OnInit {


  @Input() sxMargin: 'small' | 'medium' | 'large' | string;

  static readonly themeValues = {
    small: "5px",
    medium: "15px",
    large: "20px"
  }

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    try {
      this.el.nativeElement.style.margin = AbsoluteValue.getSplitValues(this.sxMargin, MarginDirective.themeValues);
    } catch (e) {
      console.error("SX PADDING Margin", e)
    }
  }


}

