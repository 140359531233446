<div
  sxPadding="medium"
  sxBackground="light"
  sxRadius="medium"
  sxWidth="fill"
  sxCursor="pointer"
  sxHeight="full"
  sxCol *ngIf="agreement"
>
  <h3 sxColor="three">{{ agreement.title}}</h3>
  <div sxWidth="full" sxMaxHeight="fill" sxOverflow="scroll">
    <p sxTextSize="14px" sxColor="three">
      {{agreement.description}}
    </p>
    <div sxCol>
      <div *ngFor="let bullet of agreement.bullets" sxRow sxGap="10px"
           sxMargin="0 0 10px"
      >
        <div sxBackground="dark" sxCircle="10px" sxMargin="6px 0"></div>
        <div sxGap="10px">
          <p sxMargin="0" sxColor="three" sxTextSize="medium" *ngIf="bullet.title">{{bullet.title}}</p>
          <p sxMargin="0" sxColor="two" sxTextSize="small" *ngIf="bullet.text">{{bullet.text}}</p>
        </div>
      </div>
    </div>

    <div sxRow sxWrap="wrap" sxGap="10px">
      <div *ngFor="let icon of agreement.icons">
        <img sxWidth="50px" sxHeight="50px" [src]="baseIconUrl + icon.imgName" alt="{{icon.imgName}}">
      </div>
    </div>

    <div sxRow sxAlign="center" sxColor="primary" *ngIf="agreement.url">
      <a [href]="agreement.url" target="_blank">{{agreement.urlButtonText}}</a>
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </div>
  </div>
  <div
    sxMargin="10px 0 0"
    sxBorderWidth="1px 0 0"
    sxBorderColor="one"
    sxPadding="10px 0" *ngIf="!viewOnly">
    <ion-item>
      <ion-checkbox labelPlacement="start" [(ngModel)]="agreement.accepted">
        {{agreement.checkboxTitle}}
      </ion-checkbox>
    </ion-item>
  </div>
</div>
