import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {AbsoluteValue} from "../util/AbsoluteValue";

@Directive({selector: '[sxPadding]'})
export class PaddingDirective implements OnInit {


  @Input() sxPadding: 'small' | 'medium' | 'large' | string;

  static readonly themeValues = {
    small: "5px",
    medium: "15px",
    large: "20px"
  }

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    try {
      this.el.nativeElement.style.padding = AbsoluteValue.getSplitValues(this.sxPadding, PaddingDirective.themeValues);
    } catch (e) {
      console.error("SX PADDING PROPERTY", e)
    }
  }


}

export enum PaddingOptions {
  'small', 'medium', 'large'
}
