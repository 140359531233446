<ion-modal #photoViewerModal>
  <ng-template>
    <div sxCol sxDistribution="spaceBetween" sxWidth="full" sxHeight="full" sxAlign="center"
         sxPadding="medium" sxBackground="base">

      <div sxWidth="full" sxRow sxDistribution="spaceBetween" sxAlign="center">
        <div sxWidth="30px" sxHeight="30px"></div>
        <p class="qid-text-logo" sxTextSize="large">qid</p>
        <button sxCircle="30px" sxBackground="base-shade" sxCenterize (click)="back()">
          <ion-icon sxTextSize="24px" name="close-outline"></ion-icon>
        </button>
      </div>

      <div sxWidth="90%" sxRadius="medium" sxHeight="fill" sxCenterize>
        <div *ngIf="zoomType=='pinch'" #img id='image' sxMatchParent sxCenterize='col'>
          <img sxWidth="full" [src]="getUrl()">
        </div>

        <div *ngIf="zoomType=='mouse'" class="image-container" (mousemove)="zoomImage($event)"
             (mouseleave)="onFocusOut()">
          <img #mainImage id="main-image" [src]="getUrl()" sxRadius="medium" alt="Product Image">
          <div #zoomResult id="zoom-result" sxRadius="medium"></div>
        </div>
      </div>

      <div sxRadius="medium" sxPadding="medium">
        <super-button
          type="outline"
          size="small"
          [action]="shareToLocal"
          iconStart="download-outline"
          background="light"
          textAlign="start">
          Download
        </super-button>
      </div>

    </div>
  </ng-template>

</ion-modal>
