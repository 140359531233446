import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {firstValueFrom, Observable} from 'rxjs';
import {createFeatureStore} from '../../../utils/feature-store.util';
import {AccountType, AccountWiseRecords} from '@qid/core';
import {EntityState} from '@ngrx/entity';
import {AuthSelectors} from '../../authentication';
import {AccountAttribute} from "../model/account-attribute.model";
import {AccountAttributeApi} from "../services/account-attribute.api";

interface AdditionalAccountWiseData {

}

const initialAccountWiseDataState = {};

export const AccountAttributeStore =
  createFeatureStore<AccountAttribute, EntityState<AccountWiseRecords<AccountAttribute, AdditionalAccountWiseData>>>('account-attribute');

@Injectable()
export class AccountAttributeFacade {

  private readonly apiService = inject(AccountAttributeApi);

  public activeAccount$ = this.store.select(AccountAttributeStore.selectors.selectActiveAccount);

  public allAttributes$: Observable<AccountAttribute[]> = this.store.select(AccountAttributeStore.selectors.selectActiveAccountRecords)


  constructor(private store: Store) {

  }

  async init() {
    if (!await firstValueFrom(this.activeAccount$)) {
      await this.addAccount();
    }
  }

  private async addAccount() {

    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    if (!account_id) {
      return;
    }
    const accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType));

    const accountBasedEntity: AccountWiseRecords<AccountAttribute> = AccountAttributeStore.createAccount(account_id, accountType, initialAccountWiseDataState);

    this.store.dispatch(AccountAttributeStore.actions.addAccount({accountBasedEntity: accountBasedEntity}));

  }

  async refresh() {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(AccountAttributeStore.actions.loadRecordsForAccount({
      account_id: account_id, accountType: AccountType.organisation
    }));
  }


}
