import {Field} from '@qid/field';
import {MetaData} from '@qid/core';
import {Participant} from "./participant";
import {ShareStatus} from "../enum/share-status.enum";
import {CollectionTemplate, CollectionTemplateAgreement} from '../../collection-template';


export class Share extends MetaData {

  template: CollectionTemplate;
  status: ShareStatus
  otherFields: Field[];
  isQuotaLocked: boolean;
  participants: Participant[] = [];
  agreements: CollectionTemplateAgreement[];
  scanAndShareDurationSeconds: number;
  masterKeyString: string;
  checkInData: {
    checkInTime: Date,
    checkOutTime: Date,
    remark : string
  };

}

