import {Injectable} from "@angular/core";
import {CreateFamilyMemberDto} from "../dto/create-family-member.dto";
import {FamilyMember} from "../models/family.member";
import {RestService} from "@qid/common";

@Injectable()
export class FamilyMemberService {

  private static readonly ApiEndpoint = "family-member/";

  constructor(private restService: RestService) {

  }

  public async create(createFamilyMemberDto: CreateFamilyMemberDto) {
    return (await this.restService.send<FamilyMember>({
      url: FamilyMemberService.ApiEndpoint,
      method: "POST",
      body: createFamilyMemberDto
    })).data;
  }

  public async readALl() {
    return (await this.restService.send<any[]>({
      url: FamilyMemberService.ApiEndpoint,
      method: "GET"
    })).data;
  }

  async delete(memberId) {
    return (await this.restService.send<boolean>({
      url: FamilyMemberService.ApiEndpoint,
      method: "DELETE",
      query: {
        memberId: memberId
      }
    })).data;
  }
}
