import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {CreatePlanPurchasePage} from "../create-plan-purchase/create-plan-purchase.page";
import {PlanPurchaseService} from "../../services/plan-purchase.service";
import {PlanPurchase} from "../../models/plan-purchase.model";
import {PlanPurchaseMasterService} from "../../services/plan-purchase-master.service";

@Component({
  selector: 'app-list-plan-purchase',
  templateUrl: './list-plan-purchase.page.html',
  styleUrls: ['./list-plan-purchase.page.scss'],
})
export class ListPlanPurchasePage implements OnInit {

  public planPurchases: PlanPurchase [] = []

  constructor(private modalController: ModalController,
              private planPurchaseMasterService: PlanPurchaseMasterService,
  ) {
  }

  ngOnInit() {
    this.fetchPlanPurchase()
  }

  async fetchPlanPurchase() {
    this.planPurchases = await this.planPurchaseMasterService.readAll()
  }

  async activatePlan() {
    const modal = await this.modalController.create({
      component: CreatePlanPurchasePage
    })
    modal.present()
  }


}
